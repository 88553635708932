/* eslint-disable no-unused-vars */
import React, { useContext, useState } from "react";
import Navbar from "../core/components/NavBar";
import UserContext from "../core/UserContext";
// Axios
import axios from "../api/axios";

const JointQuotation = () => {
  const { userData, setUserData } = useContext(UserContext);
  const [state, setState] = useState({
    title: "",
    name: "",
    email: "",
    phoneNumber: "",
    birth: "",
    nic_date: "",
    join_loan: false,
    term_number: 20,
    initial_sum_assured: 1000000,
    term_option: "",
    gender: "",
    loan_type: "",
    payment_insurance_plan: "",
    with_refund: false,
  });
  const [errors, setErrors] = useState({
    title: null,
    name: null,
    email: null,
    nic_date: null,
    phoneNumber: null,
    birth: null,
    term_number: null,
    gender: null,
    initial_sum_assured: null,
    term_option: null,
    loan_type: null,
    payment_insurance_plan: null,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const submitData = async () => {
    const url = process.env.REACT_APP_API_URL + "/quotation";
    try {
      setLoading(true);
      setError(null);
      const { data } = await axios.post(url, state);
      setLoading(false);
      setErrors({
        title: null,
        name: null,
        email: null,
        nic_date: null,
        phoneNumber: null,
        birth: null,
        term_number: null,
        gender: null,
        initial_sum_assured: null,
        term_option: null,
        loan_type: null,
        payment_insurance_plan: null,
      });
      if (data.success) {
        //show quotation result
        // console.log(data);
      } else {
        setError(
          data.message
            ? data.message
            : "Registration was unsuccessful, please try again"
        );
      }
    } catch (error) {
      //Handing Form Errors
      setLoading(false);
      setError(null);
      if (error?.response?.data?.errors) {
        const errorsFromServer = error.response.data.errors;
        console.error(errorsFromServer);
        let errorData = {};
        if (errorsFromServer.length > 0) {
          errorsFromServer.forEach((err) => {
            if (!errorData[err.field]) {
              errorData[err.field] = [];
            }
            errorData[err.field].push(err.message);
          });
        }
        setErrors(errorData);
      } else {
        setError("Registration was unsuccessful, please try again");
      }
    }
  };

  const sendQuotationAsEmail = () => {};
  return (
    <>
      <Navbar
        name={
          userData
            ? `${userData?.data?.firstname} ${userData?.data?.lastname}`
            : ""
        }
      />
      <div className="container">
        <h4 className="mt-4">Check Quotation</h4>
        <div className="card" style={{ marginTop: 70 }}>
          <div className="card-header">
            <div>
              <nav className="nav nav-pills">
                <a className="nav-link " href={`${process.env.REACT_APP_BASENAME}/quote`}>
                  Single Quotation
                </a>
                <a
                  className="nav-link active"
                  aria-current="page"
                  href={`${process.env.REACT_APP_BASENAME}/joint-quote`}
                >
                  Joint Quotation
                </a>
              </nav>
            </div>
          </div>
          <div className="card-body">
            <div className="mx-lg-5 px-lg-5">
              <form className="forms-sample">
                <div className="form-group row mb-3">
                  <label htmlFor="title" className="col-sm-4  col-form-label">
                    Title
                  </label>
                  <div className="col-sm-8 ">
                    <select
                      onChange={(event) => {
                        setState({ ...state, title: event.target.value });
                      }}
                      value={state.title}
                      className={`form-select ${errors.title && "is-invalid"}`}
                      aria-label="Default select example"
                    >
                      <option value="">Select...</option>
                      <option value="Mr">Mr</option>
                      <option value="Mr">Ms</option>
                      <option value="Mrs">Mrs</option>
                      <option value="Miss">Miss</option>
                      <option value="Dr">Dr</option>
                    </select>
                    {errors.title && (
                      <div className="invalid-feedback">
                        {errors.title.map((message) => {
                          return <div>{message}</div>;
                        })}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row mb-3">
                  <label htmlFor="name" className="col-sm-4  col-form-label">
                    Name
                  </label>
                  <div className="col-sm-8 ">
                    <input
                      onChange={(event) => {
                        setState({ ...state, name: event.target.value });
                      }}
                      value={state.name}
                      type="text"
                      className={`form-control ${errors.name && "is-invalid"}`}
                      id="name"
                      placeholder="Full Name"
                    />
                    {errors.name && (
                      <div className="invalid-feedback">
                        {errors.name.map((message) => {
                          return <div>{message}</div>;
                        })}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row mb-3">
                  <label htmlFor="birth" className="col-sm-4  col-form-label">
                    Date of Birth
                  </label>
                  <div className="col-sm-8 ">
                    <input
                      onChange={(event) => {
                        setState({ ...state, birth: event.target.value });
                      }}
                      value={state.birth}
                      type="date"
                      className={`form-control ${errors.birth && "is-invalid"}`}
                      id="birth"
                      placeholder="Full Name"
                    />
                    {errors.birth && (
                      <div className="invalid-feedback">
                        {errors.birth.map((message) => {
                          return (
                            <div>{message.replace("birth", "birthday")}</div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row mb-3">
                  <label
                    htmlFor="nic_date"
                    className="col-sm-4  col-form-label"
                  >
                    NIC Date
                  </label>
                  <div className="col-sm-8 ">
                    <input
                      onChange={(event) => {
                        setState({ ...state, nic_date: event.target.value });
                      }}
                      value={state.nic_date}
                      type="date"
                      className={`form-control ${
                        errors.nic_date && "is-invalid"
                      }`}
                      id="nic_date"
                      placeholder="Full Name"
                    />
                    {errors.nic_date && (
                      <div className="invalid-feedback">
                        {errors.nic_date.map((message) => {
                          return (
                            <div>{message.replace("nic_date", "NIC date")}</div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row mb-3">
                  <label htmlFor="gender" className="col-sm-4  col-form-label">
                    Gender
                  </label>
                  <div className="col-sm-8 ">
                    <select
                      onChange={(event) => {
                        setState({ ...state, gender: event.target.value });
                      }}
                      value={state.gender}
                      className={`form-select ${errors.gender && "is-invalid"}`}
                      aria-label="Default select example"
                    >
                      <option value="">Select...</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                    {errors.gender && (
                      <div className="invalid-feedback">
                        {errors.gender.map((message) => {
                          return <div>{message}</div>;
                        })}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row mb-3">
                  <label
                    htmlFor="join_loan"
                    className="col-sm-4  col-form-label"
                  >
                    Join Loan
                  </label>
                  <div className="col-sm-8 ">
                    <div
                      className="form-check form-check-inline"
                      onClick={() => setState({ ...state, join_loan: false })}
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        name="joinLoanRadio"
                        id="joinLoanRadioNo"
                        checked={!state.join_loan}
                      />
                      <label className="form-check-label" for="joinLoanRadioNo">
                        No
                      </label>
                    </div>
                    <div
                      className="form-check form-check-inline"
                      onClick={() => setState({ ...state, join_loan: true })}
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        name="joinLoanRadio"
                        id="joinLoanRadioYes"
                        checked={state.join_loan}
                      />
                      <label
                        className="form-check-label"
                        for="joinLoanRadioYes"
                      >
                        Yes
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-group row mb-3">
                  <label
                    htmlFor="initial_sum_assured"
                    className="col-sm-4  col-form-label"
                  >
                    Sum Assured
                  </label>
                  <div className="col-sm-8 ">
                    <input
                      onChange={(event) => {
                        setState({
                          ...state,
                          initial_sum_assured: event.target.value,
                        });
                      }}
                      value={state.initial_sum_assured}
                      type="number"
                      className={`form-control ${
                        errors.initial_sum_assured && "is-invalid"
                      }`}
                      id="initial_sum_assured"
                      placeholder="Full Name"
                    />
                    {errors.initial_sum_assured && (
                      <div className="invalid-feedback">
                        {errors.initial_sum_assured.map((message) => {
                          return <div>{message}</div>;
                        })}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row mb-3">
                  <label
                    htmlFor="term_option"
                    className="col-sm-4  col-form-label"
                  >
                    Term Option
                  </label>
                  <div className="col-sm-8 ">
                    <select
                      onChange={(event) => {
                        setState({ ...state, term_option: event.target.value });
                      }}
                      value={state.term_option}
                      className={`form-select ${
                        errors.term_option && "is-invalid"
                      }`}
                      aria-label="Default select example"
                    >
                      <option value="">Select...</option>
                      <option value="years">Years</option>
                      <option value="month">Month</option>
                    </select>
                    {errors.term_option && (
                      <div className="invalid-feedback">
                        {errors.term_option.map((message) => {
                          return <div>{message}</div>;
                        })}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row mb-3">
                  <label
                    htmlFor="term_number"
                    className="col-sm-4  col-form-label"
                  >
                    Duration of Loan
                  </label>
                  <div className="col-sm-8 ">
                    <input
                      onChange={(event) => {
                        setState({ ...state, term_number: event.target.value });
                      }}
                      value={state.term_number}
                      type="number"
                      className={`form-control ${
                        errors.term_number && "is-invalid"
                      }`}
                      id="term_number"
                      placeholder="eg. 20"
                    />
                    {errors.term_number && (
                      <div className="invalid-feedback">
                        {errors.term_number.map((message) => {
                          return <div>{message}</div>;
                        })}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row mb-3">
                  <label
                    htmlFor="loan_type"
                    className="col-sm-4  col-form-label"
                  >
                    Loan Type
                  </label>
                  <div className="col-sm-8 ">
                    <select
                      onChange={(event) => {
                        setState({ ...state, loan_type: event.target.value });
                      }}
                      value={state.loan_type}
                      className={`form-select ${
                        errors.loan_type && "is-invalid"
                      }`}
                      aria-label="Default select example"
                    >
                      <option value="">Select...</option>
                      <option value="capital-interest">Capital Interest</option>
                      {/* <option value="interest">Interest</option> */}
                    </select>
                    {errors.loan_type && (
                      <div className="invalid-feedback">
                        {errors.loan_type.map((message) => {
                          return <div>{message}</div>;
                        })}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row mb-3">
                  <label
                    htmlFor="payment_insurance_plan"
                    className="col-sm-4  col-form-label"
                  >
                    Payment Frequency
                  </label>
                  <div className="col-sm-8 ">
                    <select
                      onChange={(event) => {
                        setState({
                          ...state,
                          payment_insurance_plan: event.target.value,
                        });
                      }}
                      value={state.payment_insurance_plan}
                      className={`form-select ${
                        errors.payment_insurance_plan && "is-invalid"
                      }`}
                      aria-label="Default select example"
                    >
                      <option value="">Select...</option>
                      <option value="yearly">Yearly</option>
                      <option value="half-yearly">Half-Yearly</option>
                      <option value="quarterly">Quarterly</option>
                      <option value="monthly">Monthly</option>
                      <option value="single">Single</option>
                    </select>
                    {errors.payment_insurance_plan && (
                      <div className="invalid-feedback">
                        {errors.payment_insurance_plan.map((message) => {
                          return <div>{message}</div>;
                        })}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row mb-3">
                  <label
                    htmlFor="with_refund"
                    className="col-sm-4  col-form-label"
                  >
                    With Refund
                  </label>
                  <div className="col-sm-8 ">
                    <div
                      className="form-check form-check-inline"
                      onClick={() => setState({ ...state, with_refund: false })}
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        name="withRefundRadio"
                        id="withRefundRadioNo"
                        checked={!state.with_refund}
                      />
                      <label
                        className="form-check-label"
                        for="withRefundRadioNo"
                      >
                        No
                      </label>
                    </div>
                    <div
                      className="form-check form-check-inline"
                      onClick={() => setState({ ...state, with_refund: true })}
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        name="withRefundRadio"
                        id="withRefundRadioYes"
                        checked={state.with_refund}
                      />
                      <label
                        className="form-check-label"
                        for="withRefundRadioYes"
                      >
                        Yes
                      </label>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button
                    onClick={() => {
                      submitData();
                    }}
                    type="button"
                    className="btn btn-ila me-2 mb-3"
                    disabled={loading}
                  >
                    {loading && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}{" "}
                    Proceed
                  </button>
                  <button
                    onClick={() => {
                      sendQuotationAsEmail();
                    }}
                    type="button"
                    className="btn btn-ila me-2 mb-3"
                    disabled={loading}
                  >
                    {loading && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}{" "}
                    Send quotation as pdf to email
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JointQuotation;
