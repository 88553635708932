/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from "react";
import Navbar from "../core/components/NavBar";
// import UserContext from "../core/UserContext";
import { useNavigate } from "react-router-dom";
import { getUserDataFromLocalStorage } from "../core/utils";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
// import { axios } from "../core/utils";
import TogglePassword from "../core/components/toggle-password/TogglePassword";

const ChangePassword = () => {
  // const { userData, setUserData } = useContext(UserContext);
  const userData = getUserDataFromLocalStorage();
  const axiosPrivate = useAxiosPrivate(userData);
  const navigate = useNavigate();
  // console.log("User details----", userData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const oldPasswordRef = useRef();
  const newPasswordRef = useRef();
  const confirmPasswordRef = useRef();
  const [state, setState] = useState({
    currentPassword: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});

  const changePassword = async () => {
    const url = process.env.REACT_APP_API_URL + `/worker/first/update-password`;
    // const url = process.env.REACT_APP_API_URL + `/user/update-password`;

    if (
      state.currentPassword.length === 0 ||
      state.confirmPassword.length === 0 ||
      state.password.length === 0
    ) {
      setError("All fields are required!");
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const { data } = await axiosPrivate.patch(url, state);
      setErrors({});
      setLoading(false);
      if (data.success) {
        setError(null);
        setSuccess("Your password has been successfully changed");
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } else {
        setError(data.message ? data.message : "Password Change Unsuccessful");
      }
    } catch (error) {
      setLoading(false);
      setError(null);
      if (
        error?.response?.data?.errors &&
        Object.keys(error?.response?.data?.errors).length > 0
      ) {
        const errorsFromServer = error.response.data.errors;
        console.error(errorsFromServer);
        let errorData = {};
        if (errorsFromServer.length > 0) {
          errorsFromServer.forEach((err) => {
            if (!errorData[err.field]) {
              errorData[err.field] = [];
            }
            //extra error handling
            errorData[err.field].push(err.message);
          });
        }
        setErrors(errorData);
      } else if (error?.response?.data?.message) {
        setError(error?.response?.data?.message);
      } else {
        setError("Password Change Unsuccessful");
      }
    }
  };

  return (
    <>
      <Navbar
        name={
          userData
            ? `${userData?.data?.firstname} ${userData?.data?.lastname}`
            : ""
        }
      />
      <div className="container">
        <h4 className="mt-4">Change Password</h4>
        <div className="container-scroller">
          <div className="container-fluid page-body-wrapper full-page-wrapper">
            <div className="content-wrapper d-flex align-items-center auth px-0">
              <div className="row w-100 mx-0">
                <div className="col-md-6 grid-margin stretch-card mx-auto">
                  <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                    {error && (
                      <div className="alert alert-danger" role="alert">
                        {error}
                      </div>
                    )}
                    {success && (
                      <div className="alert alert-success" role="alert">
                        {success}
                      </div>
                    )}
                    <form className="pt-1">
                      <div className="login_wrapper">
                        <div className="formsix-pos position-relative">
                          <div className="form-group i-email">
                            <input
                              type="password"
                              value={state.currentPassword}
                              ref={oldPasswordRef}
                              onChange={(event) => {
                                setState({
                                  ...state,
                                  currentPassword: event.target.value,
                                });
                              }}
                              className="form-control"
                              required=""
                              id="email2"
                              placeholder="Old Password*"
                            />
                            {errors.currentPassword && (
                              <div className="text-danger small">
                                {errors.currentPassword.map((message) => {
                                  return <div>{message}</div>;
                                })}
                              </div>
                            )}
                            <TogglePassword inputRef={oldPasswordRef} />
                          </div>
                        </div>
                        <div className="formsix-e position-relative">
                          <div className="form-group i-password">
                            <input
                              ref={newPasswordRef}
                              value={state.password}
                              onChange={(event) => {
                                setState({
                                  ...state,
                                  password: event.target.value,
                                });
                              }}
                              type="password"
                              className="form-control"
                              required=""
                              id="password1"
                              placeholder="New Password *"
                            />
                            {errors.password && (
                              <div className="text-danger small">
                                {errors.password.map((message) => {
                                  return <div>{message}</div>;
                                })}
                              </div>
                            )}
                          </div>
                          <TogglePassword inputRef={newPasswordRef} />
                        </div>
                        <div className="formsix-e position-relative">
                          <div className="form-group i-password">
                            <input
                              ref={confirmPasswordRef}
                              value={state.confirmPassword}
                              onChange={(event) => {
                                setState({
                                  ...state,
                                  confirmPassword: event.target.value,
                                });
                              }}
                              type="password"
                              className="form-control"
                              required=""
                              id="password2"
                              placeholder="Confirm Password *"
                            />
                            {errors.confirmPassword && (
                              <div className="text-danger small">
                                {errors.confirmPassword.map((message) => {
                                  return <div>{message}</div>;
                                })}
                              </div>
                            )}
                          </div>
                          <TogglePassword inputRef={confirmPasswordRef} />
                        </div>
                        <div className="login_btn_wrapper">
                          <a
                            className="btn btn-ila login_btn"
                            onClick={changePassword}
                            disabled={loading}
                          >
                            {loading && (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            )}{" "}
                            Save{" "}
                          </a>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
