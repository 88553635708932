import React from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./CustomNumberInput.css";

const CustomNumberInput = ({
  defaultCountry,
  errors,
  state,
  setState,
  fieldName,
  ref,
  quotation,
}) => {
  return (
    <PhoneInput
      ref={ref}
      defaultCountry={defaultCountry ? defaultCountry : "MU"}
      className={`form-control d-flex ${
        errors.phoneNumber && "is-invalid"
      } ila-inactive-input`}
      placeholder="Enter phone number"
      value={fieldName ? state[fieldName] : state.phoneNumber}
      onChange={(val) => {
        // // debugger;
        // if (quotation != undefined) {
        //   if (!quotation.userId) {
        if (fieldName) {
          const newState = { ...state };
          newState[fieldName] = val;
          setState(newState);
        } else {
          setState({ ...state, phoneNumber: val });
        }
        // } else {
        //   setState(state);
        // }
        // } else {
        //   if (fieldName) {
        //     const newState = { ...state };
        //     newState[fieldName] = val;
        //     setState(newState);
        //   } else {
        //     setState({ ...state, phoneNumber: val });
        //   }
        // }
      }}
    />
  );
};

export default CustomNumberInput;
