/* eslint-disable eqeqeq */
import React from "react";
import {
  getAutoPassList,
  getClavicleCollarboneHamstringToeFingerList,
  getHipList,
  getKneeList,
  getShoulderList,
  getBackpainList,
  getMentalHealthList,
} from "../../../../core/utils";
import { MultiSelect } from "react-multi-select-component";
import CurrencyInput from "react-currency-input-field";

const PersonalCover = ({ number, state, setState }) => {
  // eslint-disable-next-line no-unused-vars
  const autoPassList = getAutoPassList();
  const clavicleCollarboneHamstringToeFingerList =
    getClavicleCollarboneHamstringToeFingerList();
  const kneeList = getKneeList();
  const hipList = getHipList();
  const shoulderList = getShoulderList();
  const backPainList = getBackpainList();
  const mentalHealthList = getMentalHealthList();

  return (
    <div>
      <div className="mx-4">
        <div className="row my-3">
          <div className="col-sm-12 mb-2">
            <h6 className="fw-normal">Personal Protection</h6>
            <h6 className="fw-bold">
              {number}. (a) How much cover do you already have (including death
              in service benefits and critical illness protection)
            </h6>
          </div>

          <div className="col-sm-12 mb-2">
            {/* First life assured */}
            {state.life == "main-life" && (
              <div className="row mx-2 mb-3">
                <div className="col-sm-12 d-flex">
                  <div className="col-sm-3 ">
                    <CurrencyInput
                      className={`form-control`}
                      onValueChange={(event) => {
                        setState({
                          ...state,
                          personal_cover_main_life: event,
                        });
                      }}
                      prefix="Rs "
                      value={state.personal_cover_main_life}
                    />
                  </div>
                </div>
              </div>
            )}

            {/* Second life assured */}
            {state.life == "joint-life" && (
              <>
                <div className="row mx-2 mb-4 mt-2">
                  <div className="col-sm-12 d-flex">
                    <div className="col-sm-3 ">
                      <CurrencyInput
                        className={`form-control`}
                        onValueChange={(event) => {
                          setState({
                            ...state,
                            personal_cover_joint_life: event,
                          });
                        }}
                        prefix="Rs "
                        value={state.personal_cover_joint_life}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* Additional question */}
            {state.personal_cover_main_life ||
            state.personal_cover_joint_life ? (
              <div className="col-sm-12 mb-3 mt-3 px-2">
                <div className="col-sm-12 mb-2">
                  <h6 className="fw-bold">
                    (b) What is the reason for the existing cover? E.g family
                    protection, etc…
                  </h6>

                  <div className="px-2">
                    {state.personal_cover_main_life && (
                      <div>
                        <div className="col-sm-12 d-flex">
                          <div className="col-sm-8 ">
                            <textarea
                              className="form-control w-100"
                              onChange={(e) => {
                                setState({
                                  ...state,
                                  personal_cover_main_life_reason:
                                    e.target.value,
                                });
                              }}
                            >
                              {state.personal_cover_main_life_reason}
                            </textarea>
                          </div>
                        </div>
                      </div>
                    )}

                    {state.life == "joint-life" && (
                      <>
                        {state.personal_cover_joint_life && (
                          <div>
                            <div className="col-sm-12 d-flex">
                              <div className="col-sm-8 ">
                                <textarea
                                  className="form-control w-100"
                                  onChange={(e) => {
                                    setState({
                                      ...state,
                                      personal_cover_joint_life_reason:
                                        e.target.value,
                                    });
                                  }}
                                >
                                  {state.personal_cover_joint_life_reason}
                                </textarea>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalCover;
