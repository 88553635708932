import React from "react";

const UnderwritingHiv = ({ state, setState, number }) => {
  return (
    <div>
      <div className="mx-4">
        <div className="row my-3">
          <div className="col-sm-12 mb-2">
            <h6 className="fw-bold">
              {number}. Have you been tested for HIV, if yes, please select?
            </h6>{" "}
          </div>
          <div className="col-sm-12 mb-2">
            <div className="row mx-2">
              <div
                className="col-sm-12 form-check"
                onClick={() => {
                  setState({ ...state, hiv: "negative" });
                }}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  checked={state.hiv == "negative"}
                ></input>
                <label className="form-check-label" htmlFor="refund1">
                  Negative
                </label>
              </div>

              <div
                className="col-sm-12 form-check"
                onClick={() => {
                  setState({ ...state, hiv: "positive" });
                }}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  checked={state.hiv == "positive"}
                ></input>
                <label className="form-check-label" htmlFor="refund2">
                  Positive
                </label>
              </div>

              <div
                className="col-sm-12 form-check"
                onClick={() => {
                  setState({ ...state, hiv: "inconclusive" });
                }}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  checked={state.hiv == "inconclusive"}
                ></input>
                <label className="form-check-label" htmlFor="refund2">
                  Inconclusive
                </label>
              </div>

              <div
                className="col-sm-12 form-check"
                onClick={() => {
                  setState({ ...state, hiv: "never-done-a-test" });
                }}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  checked={state.hiv == "never-done-a-test"}
                ></input>
                <label className="form-check-label" htmlFor="refund2">
                  I have never had an HIV test done
                </label>
              </div>

              <div
                className="col-sm-12 form-check"
                onClick={() => {
                  setState({ ...state, hiv: "not-sure" });
                }}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  checked={state.hiv == "not-sure"}
                ></input>
                <label className="form-check-label" htmlFor="refund2">
                  Not sure
                </label>
              </div>

              <div
                className="col-sm-12 form-check"
                onClick={() => {
                  setState({ ...state, hiv: "awaiting-results" });
                }}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  checked={state.hiv == "awaiting-results"}
                ></input>
                <label className="form-check-label" htmlFor="refund2">
                  I am awaiting the test results
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnderwritingHiv;
