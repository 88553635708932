import React from "react";

const MedicalConditionCheckBox = ({
  state,
  setState,
  medicalConditionKey,
  medicalConditionValue,
}) => {
  return (
    <td
      style={{ cursor: "pointer" }}
      onClick={() => {
        const medicalCondition = state.medicalCondition;
        medicalCondition[medicalConditionKey] = medicalConditionValue;
        setState({
          ...state,
          medicalCondition,
        });
      }}
    >
      <input
        readOnly
        class="form-check-input ms-1"
        type="radio"
        checked={
          state.medicalCondition
            ? state.medicalCondition[medicalConditionKey] ===
              medicalConditionValue
            : false
        }
      ></input>
    </td>
  );
};

export default MedicalConditionCheckBox;
