/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
// import SocialButton from "../core/components/SocialButton";
import beach from "./beach.png";
import ilaLogo from "../core/assets/images/ila-logo.png";
import "./Login.css";
// Axios
import axios from "../api/axios";
import { saveUserDataInLocalStorage } from "../core/utils";
import UserContext from "../core/UserContext";

const Login = () => {
  const [payload, setPayload] = useState({
    username: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const { userData, setUserData } = useContext(UserContext);
  const navigate = useNavigate();

  const login = async () => {
    const url = process.env.REACT_APP_API_URL + "/auth/worker/login";
    if (payload.username.length === 0 || payload.password.length === 0) {
      setError("Both username and password are required!");
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const { data } = await axios.post(url, payload, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      // console.log("Data-----", data);
      setLoading(false);
      if (data.success) {
        setError(null);
        saveUserDataInLocalStorage(data);
        setUserData(data);
        if (!data.data.isFirstUpdatePasswordCompleted) {
          navigate("/change-password");
        } else if (!data.data.areInitialDetailsCompleted) {
          navigate("/profile");
        } else {
          navigate("/quotations");
        }
      } else {
        // console.log("Set error Message");
        setError(data.message ? data.message : "Login unsuccessful");
      }
    } catch (error) {
      // console.log("Set error catch", error.response.data.message);
      if (!error?.response) {
        setError("No Server Response");
      } else {
        // setError(
        //   error?.response?.data?.message
        //     ? error.response.data.message
        //     : "Invalid credentials, please try again."
        // );
        setError("Invalid credentials, please try again.");
      }
      setLoading(false);
    }
  };

  return (
    // <div className="container-scroller">
    //   <div className="container-fluid page-body-wrapper full-page-wrapper">
    //     <div className="content-wrapper d-flex align-items-center auth px-0">
    //       <div className="row w-100 mx-0">
    //         <div className="col-md-8 col-lg-6 grid-margin stretch-card mx-auto">
    //           <div className="auth-form-light text-left py-5 px-4 px-sm-5">
    //             {error && (
    //               <div className="alert alert-danger" role="alert">
    //                 {error}
    //               </div>
    //             )}
    //             <h4>Hello! let's get started</h4>
    //             <h6 className="fw-light">Sign in to continue.</h6>
    //             <form className="pt-1">
    //               <div className="login_wrapper">
    //                 <div className="formsix-pos">
    //                   <div className="form-group i-email">
    //                     <input
    //                       type="text"
    //                       onChange={(event) => {
    //                         setPayload({
    //                           ...payload,
    //                           username: event.target.value,
    //                         });
    //                       }}
    //                       value={payload.username}
    //                       className="form-control"
    //                       id="email2"
    //                       placeholder="Username *"
    //                     />
    //                   </div>
    //                 </div>
    //                 <div className="formsix-e">
    //                   <div className="form-group i-password">
    //                     <input
    //                       onChange={(event) => {
    //                         setPayload({
    //                           ...payload,
    //                           password: event.target.value,
    //                         });
    //                       }}
    //                       value={payload.password}
    //                       type="password"
    //                       className="form-control"
    //                       id="password2"
    //                       placeholder="Password *"
    //                     />
    //                   </div>
    //                 </div>

    //                 <div className="login_btn_wrapper">
    //                   <a
    //                     onClick={login}
    //                     type="button"
    //                     className="btn btn-ila login_btn"
    //                     disabled={loading}
    //                   >
    //                     {loading && (
    //                       <span
    //                         className="spinner-border spinner-border-sm"
    //                         role="status"
    //                         aria-hidden="true"
    //                       ></span>
    //                     )}{" "}
    //                     Login{" "}
    //                   </a>
    //                 </div>
    //               </div>
    //             </form>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div
      style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
      className="d-flex mx-0"
    >
      <div
        style={{ background: "#BEC663" }}
        className=" h-100 green-side p-0"
      ></div>
      <div
        style={{
          backgroundImage: `url(${beach})`,
          backgroundSize: "cover",
          backgroundPosition: "right",
        }}
        className=" p-0 flex-grow-1"
      ></div>
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
        className="d-flex  align-items-center flex-wrapper"
      >
        <div
          className="form-card"
          style={{ background: "white", borderRadius: 10 }}
        >
          <h2 style={{ color: "#707070", fontSize: 38 }}>Hello Partner!</h2>
          <h2 style={{ color: "#707070", fontSize: 38, marginTop: -10 }}>
            Let's Get Started!
          </h2>
          <div className="mt-4" style={{ color: "#8e8e8e", fontSize: 18 }}>
            Sign in to continue
          </div>
          {error && (
            <div className="alert alert-danger mt-3" role="alert">
              {error}
            </div>
          )}
          <input
            style={{ marginTop: 40 }}
            type="text"
            onChange={(event) => {
              setPayload({
                ...payload,
                username: event.target.value,
              });
            }}
            value={payload.username}
            className="form-control ila-input"
            placeholder="Username *"
          />
          <input
            onChange={(event) => {
              setPayload({
                ...payload,
                password: event.target.value,
              });
            }}
            value={payload.password}
            type="password"
            className="form-control mt-3 ila-input"
            id="password2"
            placeholder="Password *"
          />
          <a
            onClick={login}
            type="button"
            className="btn submit-btn"
            disabled={loading}
          >
            {loading && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}{" "}
            Sign in{" "}
          </a>
          <div className="d-flex justify-content-end">
            <img style={{ width: 120, marginTop: 60 }} src={ilaLogo} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
