import React, { useEffect } from "react";
import ReflexoneConditionControlledCheckBox from "../../../core/components/ReflexoneConditionControlledCheckBox";

const ReflexoneControl = ({ state, setState, number }) => {
  useEffect(() => {
    if (!state.conditionControlled) {
      setState({ ...state, conditionControlled: {} });
    }
  }, []);
  return (
    <div>
      <div className="mx-4">
        <div className="row my-3">
          <div className="col-sm-12 mb-2">
            <h6 className="fw-bold">{number}. Was your :</h6>{" "}
          </div>
          <div className="col-sm-12 my-3">
            <div className="row mx-2">
              <table className="table table-bordered ">
                <thead>
                  <tr classNama="font-size-sm">
                    <th width="50%"></th>
                    <th className="table-title">Yes</th>
                    <th className="table-title">No</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Diabetes well controlled</td>

                    <ReflexoneConditionControlledCheckBox
                      state={state}
                      setState={setState}
                      conditionControlledKey="diabetesWellControlled"
                      conditionControlledValue={true}
                    />
                    <ReflexoneConditionControlledCheckBox
                      state={state}
                      setState={setState}
                      conditionControlledKey="diabetesWellControlled"
                      conditionControlledValue={false}
                    />
                  </tr>
                  <tr>
                    <td>Hypertension well controlled</td>

                    <ReflexoneConditionControlledCheckBox
                      state={state}
                      setState={setState}
                      conditionControlledKey="hypertensionWellControlled"
                      conditionControlledValue={true}
                    />
                    <ReflexoneConditionControlledCheckBox
                      state={state}
                      setState={setState}
                      conditionControlledKey="hypertensionWellControlled"
                      conditionControlledValue={false}
                    />
                  </tr>
                  <tr>
                    <td>Cholesterol below or equal to 7</td>

                    <ReflexoneConditionControlledCheckBox
                      state={state}
                      setState={setState}
                      conditionControlledKey="cholestrolBelowOrEqualToSeven"
                      conditionControlledValue={true}
                    />
                    <ReflexoneConditionControlledCheckBox
                      state={state}
                      setState={setState}
                      conditionControlledKey="cholestrolBelowOrEqualToSeven"
                      conditionControlledValue={false}
                    />
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReflexoneControl;
