/* eslint-disable eqeqeq */
import React from "react";
import {
  getAutoPassList,
  getClavicleCollarboneHamstringToeFingerList,
  getHipList,
  getKneeList,
  getShoulderList,
  getBackpainList,
  getMentalHealthList,
} from "../../../../core/utils";
import { MultiSelect } from "react-multi-select-component";
import CurrencyInput from "react-currency-input-field";

const PersonalAssets = ({ number, state, setState }) => {
  // eslint-disable-next-line no-unused-vars
  const autoPassList = getAutoPassList();
  const clavicleCollarboneHamstringToeFingerList =
    getClavicleCollarboneHamstringToeFingerList();
  const kneeList = getKneeList();
  const hipList = getHipList();
  const shoulderList = getShoulderList();
  const backPainList = getBackpainList();
  const mentalHealthList = getMentalHealthList();

  return (
    <div>
      <div className="mx-4">
        <div className="row my-3">
          <div className="col-sm-12 mb-2">
            <h6 className="fw-normal">Personal Protection</h6>
            <h6 className="fw-bold">
              {number}. Please give details of your assets and liabilities.
            </h6>
          </div>

          {/* First Life Assured */}
          {state.life == "main-life" && (
            <div className="px-4 mb-3 mt-2">
              <table className="w-100">
                <th>Assets (Brief Description & Value)</th>
                <th>Liabilities (Brief Description & Value)</th>

                <tbody>
                  <tr>
                    <td>
                      <label className="fw-normal col-12 text-dark pt-1">
                        Property
                      </label>
                      <textarea
                        onChange={(e) => {
                          setState({
                            ...state,
                            assets_property_main_life: e.target.value,
                          });
                        }}
                        className="form-control w-75"
                      >
                        {state.assets_property_main_life}
                      </textarea>
                    </td>
                    <td>
                      <label className="fw-normal col-12 text-dark pt-1">
                        Mortgages
                      </label>
                      <textarea
                        onChange={(e) => {
                          setState({
                            ...state,
                            liability_mortgage_main_life: e.target.value,
                          });
                        }}
                        className="form-control w-75"
                      >
                        {state.liability_mortgage_main_life}
                      </textarea>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <label className="fw-normal col-12 text-dark pt-1">
                        Investments
                      </label>
                      <textarea
                        onChange={(e) => {
                          setState({
                            ...state,
                            assets_investments_main_life: e.target.value,
                          });
                        }}
                        className="form-control w-75"
                      >
                        {state.assets_investments_main_life}
                      </textarea>
                    </td>
                    <td>
                      <label className="fw-normal col-12 text-dark pt-1">
                        Loans
                      </label>
                      <textarea
                        onChange={(e) => {
                          setState({
                            ...state,
                            liability_loan_main_life: e.target.value,
                          });
                        }}
                        className="form-control w-75"
                      >
                        {state.liability_loan_main_life}
                      </textarea>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <label className="fw-normal col-12 text-dark pt-1">
                        Unquoted Equities
                      </label>
                      <textarea
                        onChange={(e) => {
                          setState({
                            ...state,
                            assets_entities_main_life: e.target.value,
                          });
                        }}
                        className="form-control w-75"
                      >
                        {state.assets_entities_main_life}
                      </textarea>
                    </td>
                    <td>
                      <label className="fw-normal col-12 text-dark pt-1">
                        Others (please specify)
                      </label>
                      <textarea
                        onChange={(e) => {
                          setState({
                            ...state,
                            liability_others_main_life: e.target.value,
                          });
                        }}
                        className="form-control w-75"
                      >
                        {state.liability_others_main_life}
                      </textarea>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <label className="fw-normal col-12 text-dark pt-1">
                        Others (please specify)
                      </label>
                      <textarea
                        onChange={(e) => {
                          setState({
                            ...state,
                            assets_others_main_life: e.target.value,
                          });
                        }}
                        className="form-control w-75"
                      >
                        {state.assets_others_main_life}
                      </textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {/* Second Life Assured */}
          {state.life == "joint-life" && (
            <div className="px-4 mb-3 mt-3">
              <table className="w-100">
                <th>Assets (Brief Description & Value)</th>
                <th>Liabilities (Brief Description & Value)</th>

                <tbody>
                  <tr>
                    <td>
                      <label className="fw-normal col-12 text-dark pt-1">
                        Property
                      </label>
                      <textarea
                        onChange={(e) => {
                          setState({
                            ...state,
                            assets_property_joint_life: e.target.value,
                          });
                        }}
                        className="form-control w-75"
                      >
                        {state.assets_property_joint_life}
                      </textarea>
                    </td>
                    <td>
                      <label className="fw-normal col-12 text-dark pt-1">
                        Mortgages
                      </label>
                      <textarea
                        onChange={(e) => {
                          setState({
                            ...state,
                            liability_mortgage_joint_life: e.target.value,
                          });
                        }}
                        className="form-control w-75"
                      >
                        {state.liability_mortgage_joint_life}
                      </textarea>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <label className="fw-normal col-12 text-dark pt-1">
                        Investments
                      </label>
                      <textarea
                        onChange={(e) => {
                          setState({
                            ...state,
                            assets_investments_joint_life: e.target.value,
                          });
                        }}
                        className="form-control w-75"
                      >
                        {state.assets_investments_joint_life}
                      </textarea>
                    </td>
                    <td>
                      <label className="fw-normal col-12 text-dark pt-1">
                        Loans
                      </label>
                      <textarea
                        onChange={(e) => {
                          setState({
                            ...state,
                            liability_loan_joint_life: e.target.value,
                          });
                        }}
                        className="form-control w-75"
                      >
                        {state.liability_loan_joint_life}
                      </textarea>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <label className="fw-normal col-12 text-dark pt-1">
                        Unquoted Equities
                      </label>
                      <textarea
                        onChange={(e) => {
                          setState({
                            ...state,
                            assets_entities_joint_life: e.target.value,
                          });
                        }}
                        className="form-control w-75"
                      >
                        {state.assets_entities_joint_life}
                      </textarea>
                    </td>
                    <td>
                      <label className="fw-normal col-12 text-dark pt-1">
                        Others (please specify)
                      </label>
                      <textarea
                        onChange={(e) => {
                          setState({
                            ...state,
                            liability_others_joint_life: e.target.value,
                          });
                        }}
                        className="form-control w-75"
                      >
                        {state.liability_others_joint_life}
                      </textarea>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <label className="fw-normal col-12 text-dark pt-1">
                        Others (please specify)
                      </label>
                      <textarea
                        onChange={(e) => {
                          setState({
                            ...state,
                            assets_others_joint_life: e.target.value,
                          });
                        }}
                        className="form-control w-75"
                      >
                        {state.assets_others_joint_life}
                      </textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PersonalAssets;
