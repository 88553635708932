import React, { useState } from 'react';
import './TogglePassword.css';

// You should import font awesome in public/index.html


const TogglePassword = ({inputRef}) =>{
    const [showPassword, setShowPassword] = useState(false);
    return <span onClick={()=>{
        if(showPassword)
        {inputRef.current.setAttribute("type","password");}
        else{inputRef.current.setAttribute("type","text")}
        setShowPassword(!showPassword); 
    }} 
        className="wrapper">
        {!showPassword ?
        <i  className="fa fa-eye-slash"></i> : 
        <i  className="fa fa-eye"></i>}
    </span>
}


export default TogglePassword;