import React, { useState } from "react";
import CustomNumberInput from "../../core/components/custom-number-input/CustomNumberInput";
import { getAutoPassList } from "../../core/utils";
import TextInput from "react-autocomplete-input";
import "react-autocomplete-input/dist/bundle.css";
import { MultiSelect } from "react-multi-select-component";
import { useRef } from "react";

const UnderwritingSummary = ({ consent, setConsent, state, setState }) => {
  const autoPassList = getAutoPassList();
  const phoneNumberRef = useRef();
  const autoPassListOptions = getAutoPassList().map((item) => {
    return { label: item, value: item };
  });
  return (
    <div>
      <div className="mx-4">
        <div className="row my-3">
          <div className="col-sm-12 mb-2">
            <ul>
              <li>
                It is very important to declare all information honestly. We are
                usually able to offer better rates with more information.
                Failure to do so will never count in your favour.
              </li>
              <li>
                We may need to contact your doctor for more information or to
                verify information essential in the processing of your
                application.
              </li>
              <li>
                Please confirm your Regular Treating Doctor or Clinic and
                Telephone number
              </li>
            </ul>
            <div className="col-sm-12 mb-2">
              <h6 className="fw-bold">
                Do you currently have a doctor treating you?
              </h6>{" "}
            </div>
            <div className="col-sm-12 mb-2">
              <div className="row mx-2">
                <div
                  className="col-sm-12 form-check"
                  onClick={() => {
                    setState({
                      ...state,
                      doctorTreatingYou: true,
                    });
                  }}
                >
                  <input
                    className="form-check-input"
                    type="radio"
                    checked={state.doctorTreatingYou == true}
                  ></input>

                  <label className="form-check-label">Yes</label>
                </div>
                <div
                  className="col-sm-12 form-check"
                  onClick={() => {
                    setState({
                      ...state,
                      doctorTreatingYou: false,
                    });
                  }}
                >
                  <input
                    className="form-check-input"
                    type="radio"
                    checked={state.doctorTreatingYou == false}
                  ></input>

                  <label className="form-check-label">No</label>
                </div>
              </div>
            </div>
            {state.doctorTreatingYou && (
              <>
                <div className="form-group row mb-3">
                  <h6 className="fw-bold mt-3">
                    Name of treating Doctor or Clinic
                  </h6>
                  <div className="col-sm-8 ">
                    <input
                      onChange={(event) => {
                        setState({
                          ...state,
                          nameOfTreatingDoctorOrClinic: event.target.value,
                        });
                      }}
                      value={state.nameOfTreatingDoctorOrClinic}
                      type="text"
                      className={`form-control`}
                      id="name"
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="form-group row mb-3">
                  <h6 className="fw-bold mt-3">Phone number</h6>
                  <div className="col-sm-8 ">
                    <CustomNumberInput
                      errors={{}}
                      ref={phoneNumberRef}
                      setState={setState}
                      state={state}
                      fieldName="phoneOfTreatingDoctorOrClinic"
                    />
                  </div>
                </div>
                <div className="form-group row mb-3">
                  <div className="col-sm-12 mt-4">
                    <input
                      className="form-check-input me-3 ms-2"
                      checked={consent}
                      onClick={() => {
                        setConsent(!consent);
                      }}
                      type="checkbox"
                    />
                    I consent that I authorise Island Life Assurance Co. Ltd to
                    contact my Treating Doctor or Clinic for more information
                  </div>
                </div>
              </>
            )}

            <div className="col-sm-12 mb-2">
              <h6 className="fw-bold">
                Has any application for life or Disability cover on your life
                ever been declined because of an existing or previous medical
                condition?
              </h6>{" "}
            </div>
            <div className="col-sm-12 mb-2">
              <div className="row mx-2">
                <div
                  className="col-sm-12 form-check"
                  onClick={() => {
                    setState({
                      ...state,
                      hasPreviouslyDeclinedLifeOrDisabilityBecauseOfMedicalCondition: true,
                    });
                  }}
                >
                  <input
                    className="form-check-input"
                    type="radio"
                    checked={
                      state.hasPreviouslyDeclinedLifeOrDisabilityBecauseOfMedicalCondition ==
                      true
                    }
                  ></input>

                  <label className="form-check-label">Yes</label>
                </div>
                <div
                  className="col-sm-12 form-check"
                  onClick={() => {
                    setState({
                      ...state,
                      hasPreviouslyDeclinedLifeOrDisabilityBecauseOfMedicalCondition: false,
                    });
                  }}
                >
                  <input
                    className="form-check-input"
                    type="radio"
                    checked={
                      state.hasPreviouslyDeclinedLifeOrDisabilityBecauseOfMedicalCondition ==
                      false
                    }
                  ></input>

                  <label className="form-check-label">No</label>
                </div>
              </div>
            </div>
            {state.hasPreviouslyDeclinedLifeOrDisabilityBecauseOfMedicalCondition && (
              <>
                <h6 className="fw-bold mt-3">Select reason</h6>
                <div className="col-sm-8 mb-4">
                  <MultiSelect
                    options={autoPassListOptions}
                    value={state.previouslyDeclinedLifeOrDisabilityCondition}
                    onChange={(val) => {
                      setState({
                        ...state,
                        previouslyDeclinedLifeOrDisabilityCondition: val,
                      });
                    }}
                    isCreatable={true}
                    onCreateOption={(value) => ({
                      label: value.toUpperCase(),
                      value: value.toUpperCase(),
                    })}
                    labelledBy="Select"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnderwritingSummary;
