import React from "react";

const UnderwritingAlcohol = ({ state, setState, number }) => {
  return (
    <div>
      <div className="mx-4">
        <div className="row my-3">
          <div className="col-sm-12 mb-2">
            <h6 className="fw-bold">
              {number}. Do you consume alcoholic drinks, if yes please specify,
              how many units per week (a unit is 20 ml strong spirits, 300 ml
              beer or 100 ml wine)?
            </h6>{" "}
          </div>
          <div className="col-sm-12 mb-2">
            <div className="row mx-2">
              <div
                className="col-sm-12 form-check"
                onClick={() => {
                  setState({
                    ...state,
                    numberOfAlchoholicDrinksPerWeek: "no-i-dont-consume",
                  });
                }}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  checked={
                    state.numberOfAlchoholicDrinksPerWeek == "no-i-dont-consume"
                  }
                ></input>
                <label className="form-check-label" htmlFor="refund1">
                  No, I don’t consume
                </label>
              </div>

              <div
                className="col-sm-12 form-check"
                onClick={() => {
                  setState({
                    ...state,
                    numberOfAlchoholicDrinksPerWeek: "less-than-22-units",
                  });
                }}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  checked={
                    state.numberOfAlchoholicDrinksPerWeek ==
                    "less-than-22-units"
                  }
                ></input>
                <label className="form-check-label" htmlFor="refund2">
                  Less than 22 units
                </label>
              </div>

              <div
                className="col-sm-12 form-check"
                onClick={() => {
                  setState({
                    ...state,
                    numberOfAlchoholicDrinksPerWeek: "22-units-and-above",
                  });
                }}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  checked={
                    state.numberOfAlchoholicDrinksPerWeek ==
                    "22-units-and-above"
                  }
                ></input>
                <label className="form-check-label" htmlFor="refund2">
                  22 units and above
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnderwritingAlcohol;
