/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
// Axios
import axios from "../api/axios";
import {
  getOnboardingData,
  saveUserDataInLocalStorage,
  setOnboardingData,
  setOTPData,
} from "../core/utils";
import UserContext from "../core/UserContext";

const Verification = () => {
  const { userData, setUserData } = useContext(UserContext);
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [resendingOtp, setResendingOtp] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const sendOTPToUser = async () => {
    let payload = null;
    const email = searchParams.get("email");
    const phone = searchParams.get("phone");
    const provider = searchParams.get("provider");
    if (provider === "email") {
      payload = {
        otp,
        email,
        provider,
        phoneNumber: null,
      };
    } else {
      payload = {
        otp,
        phoneNumber: phone,
        provider,
        email: null,
      };
    }

    const url = process.env.REACT_APP_API_URL + "/user/otp-generation";
    try {
      setResendingOtp(true);
      const response = await axios.post(url, payload);
      setSuccess("Otp sent");
      setResendingOtp(false);
    } catch (err) {
      setResendingOtp(false);
      // console.log(err);
    }
  };
  const verify = async () => {
    const email = searchParams.get("email");
    const phone = searchParams.get("phone");
    const provider = searchParams.get("provider");
    const source = searchParams.get("source");
    let url = process.env.REACT_APP_API_URL + "/user/activation";

    if (source === "final") {
      url = process.env.REACT_APP_API_URL + "/user/otp-login";
    }
    try {
      let payload = null;
      if (provider === "email") {
        payload = {
          otp,
          email,
          provider,
          phoneNumber: null,
        };
      } else {
        payload = {
          otp,
          phoneNumber: phone,
          provider,
          email: null,
        };
      }

      payload.otp = parseInt(payload.otp, 10);

      setLoading(true);
      const { data } = await axios.post(url, payload);
      setLoading(false);
      if (data.success) {
        if (source === "onboarding") {
          const onBoardingData = getOnboardingData();
          setOnboardingData({
            ...onBoardingData,
            verificationDone: true,
          });
          setOTPData(data);
          setSuccess(
            "Activation successful, you can proceed to generate quotations"
          );
          setError(null);
          setTimeout(() => {
            navigate("/quotation");
          }, 3000);
        } else if (source === "final") {
          saveUserDataInLocalStorage(data);
          setUserData(data);
          navigate("/quotations");
        } else {
          setSuccess("Activation successful, you can now login");
          setError(null);
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        }
      } else {
        setSuccess(null);

        setError(
          data.message
            ? error.response.data.message
              ? error.response.data.message
              : data.message
            : "Your account wasn't verified successfully"
        );
      }
    } catch (error) {
      setLoading(false);
      setError(
        error.response.data.message
          ? error.response.data.message
          : "Your account wasn't verified successfully"
      );
      setSuccess(null);
    }
  };

  return (
    <div className="container-scroller my-5">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-md-6 grid-margin stretch-card mx-auto">
              <div className="card">
                <div className="card-body">
                  {error && (
                    <div className="alert alert-danger" role="alert">
                      {error}
                    </div>
                  )}
                  {success && (
                    <div className="alert alert-success" role="alert">
                      {success
                        ? success
                        : "You account has been successfully Verified"}
                    </div>
                  )}
                  <h4 className="card-title">You are almost there</h4>
                  <p className="card-description">
                    Check your email for OTP code to verify your account
                  </p>
                  <div className="login_wrapper">
                    <div>
                      <div className="form-group row mb-3">
                        {/* <label htmlFor="otp" className="col-sm-4 col-form-label">OTP Code</label> */}
                        <div className="col-sm-12">
                          <input
                            onChange={(event) => {
                              setOtp(event.target.value, 10);
                            }}
                            value={otp}
                            type="text"
                            className={`form-control`}
                            id="otp"
                            placeholder="OTP code"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="login_btn_wrapper">
                      <a
                        type="button"
                        onClick={() => {
                          verify();
                        }}
                        className="btn btn-danger login_btn"
                        disabled={loading}
                      >
                        {loading && (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}{" "}
                        Verify{" "}
                      </a>
                    </div>
                    <div className="d-flex justify-content-center">
                      <a
                        className="btn btn-light text-center"
                        onClick={() => {
                          sendOTPToUser();
                        }}
                      >
                        {resendingOtp && (
                          <span className="spinner-border-sm spinner-border"></span>
                        )}{" "}
                        Resend OTP
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verification;
