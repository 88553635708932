import React from "react";

const ReflexoneConditionControlledCheckBox = ({
  state,
  setState,
  conditionControlledKey,
  conditionControlledValue,
}) => {
  return (
    <td
      style={{ cursor: "pointer" }}
      onClick={() => {
        const conditionControlled = state.conditionControlled;
        conditionControlled[conditionControlledKey] = conditionControlledValue;
        setState({
          ...state,
          conditionControlled,
        });
      }}
    >
      <input
        readOnly
        class="form-check-input ms-1"
        type="radio"
        checked={
          state.conditionControlled
            ? state.conditionControlled[conditionControlledKey] ===
              conditionControlledValue
            : false
        }
      ></input>
    </td>
  );
};

export default ReflexoneConditionControlledCheckBox;
