/* eslint-disable eqeqeq */
import React from "react";
import {
  getAutoPassList,
  getClavicleCollarboneHamstringToeFingerList,
  getHipList,
  getKneeList,
  getShoulderList,
  getBackpainList,
  getMentalHealthList,
} from "../../../../core/utils";
import { MultiSelect } from "react-multi-select-component";

const PersonalApplications = ({ number, state, setState }) => {
  // eslint-disable-next-line no-unused-vars
  const autoPassList = getAutoPassList();
  const clavicleCollarboneHamstringToeFingerList =
    getClavicleCollarboneHamstringToeFingerList();
  const kneeList = getKneeList();
  const hipList = getHipList();
  const shoulderList = getShoulderList();
  const backPainList = getBackpainList();
  const mentalHealthList = getMentalHealthList();

  return (
    <div>
      <div className="mx-4">
        <div className="row my-3">
          <div className="col-sm-12 mb-2">
            <h6 className="fw-normal">Personal Protection</h6>
            <h6 className="fw-bold">
              {number}. Are any other applications currently being made to other
              life assurance companies?
            </h6>
          </div>
          <div className="col-sm-12 mb-2">
            {/* First life assured */}
            {state.life == "main-life" && (
              <>
                <div className="row mx-2 mb-3">
                  <div className="col-sm-12 d-flex">
                    <div
                      className="col col-1 form-check"
                      onClick={() => {
                        setState({
                          ...state,
                          other_applications_main_life: true,
                        });
                      }}
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        checked={state.other_applications_main_life == true}
                      ></input>
                      <label className="form-check-label">Yes</label>
                    </div>
                    <div
                      className="col col-1 form-check"
                      onClick={() => {
                        setState({
                          ...state,
                          other_applications_main_life: false,
                        });
                      }}
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        checked={state.other_applications_main_life == false}
                      ></input>
                      <label className="form-check-label">No</label>
                    </div>
                  </div>
                </div>
                {/* First life additional questions */}
                {state.other_applications_main_life && (
                  <div className="col-sm-12 mb-3 px-3">
                    <div className="col-lg-8 mb-2">
                      <div className="form-group row mb-3">
                        <div className="col-sm-12 mb-2">
                          <lael className="mt-1">
                            If Yes, please give details.
                          </lael>{" "}
                        </div>

                        <div className="col-sm-8 ">
                          <textarea
                            onChange={(e) => {
                              setState({
                                ...state,
                                other_applications_main_details: e.target.value,
                              });
                            }}
                            className="form-control w-100"
                          >
                            {state.other_applications_main_details}
                          </textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            {/* Second life assured */}
            {state.life == "joint-life" && (
              <>
                <div className="row mx-2 mb-3 mt-2">
                  <div className="col-sm-12 d-flex">
                    <div
                      className="col col-1 form-check"
                      onClick={() => {
                        setState({
                          ...state,
                          other_applications_joint_life: true,
                        });
                      }}
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        checked={state.other_applications_joint_life == true}
                      ></input>
                      <label className="form-check-label">Yes</label>
                    </div>
                    <div
                      className="col col-1 form-check"
                      onClick={() => {
                        setState({
                          ...state,
                          other_applications_joint_life: false,
                        });
                      }}
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        checked={state.other_applications_joint_life == false}
                      ></input>
                      <label className="form-check-label">No</label>
                    </div>
                  </div>
                </div>
                {state.other_applications_joint_life && (
                  <div className="col-sm-12 mb-3 px-3">
                    <div className="col-lg-8 mb-2">
                      <div className="form-group row mb-3">
                        <div className="col-sm-12 mb-2">
                          <lael className="mt-1">
                            If Yes, please give details.
                          </lael>{" "}
                        </div>

                        <div className="col-sm-8 ">
                          <textarea
                            onChange={(e) => {
                              setState({
                                ...state,
                                other_applications_joint_details:
                                  e.target.value,
                              });
                            }}
                            className="form-control w-100"
                          >
                            {state.other_applications_joint_details}
                          </textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalApplications;
