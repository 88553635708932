/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useRef, useState } from "react";
import CustomNumberInput from "../core/components/custom-number-input/CustomNumberInput";
import Navbar from "../core/components/NavBar";
import UserContext from "../core/UserContext";
import Moment from "moment";
import SignatureCanvas from "react-signature-canvas";
// import uniqid from "uniqid";
import WhatsappNumberInput from "../core/components/whatsapp-number-input/WhatsappNumberInput";
// import QuotationView from "../quotation-view/QuotationView";
// Axios
import {
  getUserDataFromLocalStorage,
  // Buffer,
  saveUserDataInLocalStorage,
} from "../core/utils";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const Profile = () => {
  // eslint-disable-next-line no-unused-vars
  const { userData, setUserData } = useContext(UserContext);
  const userDataStorage = getUserDataFromLocalStorage();
  const axiosPrivate = useAxiosPrivate(userDataStorage);
  const [editMode, setEditMode] = useState(false);
  const [isUpdatingDetails, setIsUpdatingDetails] = useState(false);
  const [updateSuccessful, setUpdateSuccessful] = useState(false);
  const agentSignatureRef = useRef();
  // const [isVerifyingDetails, setIsVerifyingDetails] = useState(false);
  // const [showSuccessMessage, setShowSuccessMessage] = useState();
  const [error, setError] = useState();
  const phoneNumberRef = useRef();
  const whatsappNumberRef = useRef();
  // const [base64Signature, setBase64Signature] = useState();
  const [state, setState] = useState({
    firstname: "",
    lastname: "",
    email: "",
    activityStatus: false,
    title: "",
    birth: "",
    phoneNumber: "",
    whatsappNumber: "",
  });

  const getGroupingName = (value) => {
    let text;
    switch (value) {
      case "DR":
        text = "Direct Sales";
        break;
      case "BRO":
        text = "Broker";
        break;
      case "SP":
        text = "Salesperson";
        break;
      case "IA":
        text = "Individual Agent";
        break;
      default:
        text = null;
        break;
    }
    return text;
  };
  const getRoleName = (value) => {
    let text;
    switch (value) {
      case "superadmin":
        text = "Super Admin";
        break;
      case "admin":
        text = "Admin";
        break;
      case "LA-HO":
        text = "ILA Head Office Direct Sales";
        break;
      case "DCJ":
      case "DST":
        text = "Direct Sales";
        break;
      case "BRO":
        text = "Broker";
        break;
      case "BA":
        text = "ILA Head Office Direct Sales";
        break;
      case "SD":
        text = "Salesperson Direct";
        break;
      case "IA":
        text = "Individual Agent";
        break;
      case "SPIA":
        text = "Salesperson - Individual Agent";
        break;
      default:
        text = "Undefined";
        break;
    }
    return text;
  };
  // const roleName = getRoleName(data.data.roles);
  // const groupingName = getGroupingName(data.data.grouping);
  // const countries = getCountries();

  const updateProfileDetails = async () => {
    setError(null);
    // const url = process.env.REACT_APP_API_URL + `/user/update-details`;
    const url = process.env.REACT_APP_API_URL + `/worker/first/update-details`;
    const payload = {
      ...state,
    };

    const signatureImage = agentSignatureRef.current
      .getTrimmedCanvas()
      .toDataURL("image/png");

    if (agentSignatureRef.current.isEmpty()) {
      setError("Please sign before you can proceed");
      return;
    }
    if (!agentSignatureRef) {
      setError("Please agree to terms before you can proceed");
      return;
    }
    setError(null);

    payload.signature = signatureImage;

    setIsUpdatingDetails(true);
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await axiosPrivate.patch(url, payload);

      setIsUpdatingDetails(false);
      saveDetailsLocally();
      setUpdateAsDoneLocally();
      setUpdateSuccessful(true);
      // console.log(response);
    } catch (err) {
      setIsUpdatingDetails(false);
      setError("Could update user details");
      // console.log(err);
    }
  };

  const saveDetailsLocally = () => {
    const data = getUserDataFromLocalStorage();
    data.data = {
      ...data.data,
      ...state,
      name: `${state.firstname} ${state.lastname}`,
    };
    saveUserDataInLocalStorage(data);
  };

  useEffect(() => {
    const data = getUserDataFromLocalStorage();

    const firstname = data ? data?.data?.firstname : "";
    const lastname = data ? data?.data?.lastname : "";
    const email = data ? data?.data?.email : "";
    const title = data ? data?.data?.title : "";
    const phoneNumber = data ? data?.data?.phoneNumber : "";
    const whatsappNumber = data ? data?.data?.whatsappNumber : "";
    const gender = data ? data?.data?.gender : "";
    const newCode = data ? `${data?.data?.newCode}` : "";
    const activityStatus = data ? data?.data?.activityStatus : false;
    const grouping = data
      ? `${data?.data?.grouping} (${getGroupingName(data?.data?.grouping)})`
      : "";
    const roles = data
      ? `${data?.data?.roles} (${getRoleName(data?.data?.roles)})`
      : "";
    const birth = data ? new Moment(data?.data?.birth) : "";
    const consentSignature = data ? data?.data?.consentSignature : false;

    // console.log("Signature Type");
    // console.log(typeof data?.data?.signature);

    setState({
      firstname,
      lastname,
      title,
      phoneNumber,
      whatsappNumber,
      activityStatus,
      gender,
      email,
      roles,
      grouping,
      birth,
      newCode,
      consentSignature,
    });

    // if (data.data.signature) {

    //   if (typeof data.data.signature == "object") {
    //     const signature = Buffer.from(data.data.signature).toString();
    //     setBase64Signature(signature);
    //   }
    // }
  }, []);

  const setUpdateAsDoneLocally = () => {
    const signatureImage = agentSignatureRef.current
      .getCanvas()
      .toDataURL("image/png");

    const userDetails = getUserDataFromLocalStorage();
    userDetails.data.areInitialDetailsCompleted = true;
    userDetails.data.signature = signatureImage;
    userDetails.data.consentSignature = state.consentSignature;
    saveUserDataInLocalStorage(userDetails);
  };
  return (
    <>
      <Navbar
        name={
          userData
            ? `${userData?.data?.firstname} ${userData?.data?.lastname}`
            : ""
        }
      />
      <div className="container">
        <h4 className="mt-4" style={{ color: "#707070" }}>
          Profile
        </h4>

        <div
          className="card mt-5 p-5"
          style={{ background: "#C1C1C115", border: "none" }}
        >
          {updateSuccessful && (
            <div className="alert alert-success m-3">
              {" "}
              <i className="fa fa-check me-2"></i>Your Details have been saved
              successfully
            </div>
          )}
          {error && (
            <div className="alert alert-danger m-3">
              {" "}
              <i className="fa fa-warning me-2"></i>
              {error}
            </div>
          )}
          <span
            style={{ borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}
            className={`badge  ${
              state.activityStatus ? "bg-success" : "bg-danger"
            }`}
          >
            {/* {state.activityStatus ? "active" : "in-active"} */}
          </span>
          <div className="d-flex justify-content-end">
            <div className="d-flex p-3">
              {!editMode && (
                <a
                  onClick={() => {
                    setEditMode(true);
                  }}
                  style={{ fontSize: 18, fontWeight: "bold" }}
                  className="btn btn-text ila-grey-text"
                >
                  <span className="fa fa-edit me-2"></span>Edit Profile
                </a>
              )}
              {editMode && (
                <>
                  <a
                    onClick={() => {
                      setEditMode(false);
                    }}
                    className="btn btn-light me-2"
                  >
                    <i className="fa fa-times"></i> Cancel
                  </a>
                  <a
                    disabled={isUpdatingDetails}
                    onClick={updateProfileDetails}
                    className="btn btn-light"
                  >
                    {isUpdatingDetails && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}{" "}
                    <i className="fa fa-save"></i> Save changes
                  </a>
                </>
              )}
            </div>
          </div>

          <div className="card-body">
            <div className="mb-3 ">
              <label htmlFor="firstname" className="ila-form-label">
                Title{" "}
              </label>
              <div className="ila-grey-text">{state.title}</div>

              {/* <div className="col-sm-10">
                <select
                  value={state.title}
                  onChange={(event) => {
                    setState({ ...state, title: event.target.value });
                  }}
                  disabled={!editMode}
                  className="form-select"
                  aria-label="Title Select"
                >
                  <option value="Mr">Mr</option>
                  <option value="Ms">Ms</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Miss">Miss</option>
                  <option value="Dr">Dr</option>
                </select>
              </div> */}
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="mb-3">
                  <label className="ila-form-label" htmlFor="firstname">
                    First Names
                  </label>
                  <div>
                    <input
                      type="text"
                      value={state.firstname}
                      onChange={(event) => {
                        setState({ ...state, firstname: event.target.value });
                      }}
                      disabled={!editMode}
                      className="form-control  ila-inactive-input"
                      id="firstname"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label className="ila-form-label" htmlFor="lastname">
                    Last Name
                  </label>
                  <div>
                    <input
                      type="text"
                      value={state.lastname}
                      onChange={(event) => {
                        setState({ ...state, lastname: event.target.value });
                      }}
                      disabled={!editMode}
                      className="form-control  ila-inactive-input"
                      id="lastname"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="mb-3">
                  <label className="ila-form-label" htmlFor="newCode">
                    Agent Code
                  </label>
                  <div>
                    <input
                      type="text"
                      value={state.newCode}
                      // onChange={(event) => {
                      //   setState({ ...state, newCode: event.target.value });
                      // }}
                      disabled={true}
                      className="form-control  ila-inactive-input"
                      id="newCode"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label className="ila-form-label" htmlFor="grouping">
                    Grouping
                  </label>
                  <div>
                    <input
                      type="text"
                      value={state.grouping}
                      disabled={true}
                      className="form-control  ila-inactive-input"
                      id="grouping"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="mb-3">
                  <label className="ila-form-label" htmlFor="roles">
                    Role
                  </label>
                  <div>
                    <input
                      type="text"
                      value={state.roles}
                      disabled={true}
                      className="form-control  ila-inactive-input"
                      id="roles"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label className="ila-form-label" htmlFor="email">
                    Email
                  </label>
                  <div>
                    <input
                      type="text"
                      value={state.email}
                      disabled={true}
                      className="form-control  ila-inactive-input"
                      id="email"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="mb-3">
                  <label className="ila-form-label" htmlFor="roles">
                    Gender
                  </label>
                  <div>
                    <select
                      disabled={true}
                      // onChange={(event) => {
                      //   setState({ ...state, gender: event.target.value });
                      // }}
                      value={state.gender}
                      className={`form-select ila-inactive-input dropdown`}
                    >
                      <option value="">Select...</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label className="ila-form-label" htmlFor="phoneNumber">
                    Phone Number
                  </label>
                  <div>
                    {editMode ? (
                      <CustomNumberInput
                        ref={phoneNumberRef}
                        disabled={true}
                        errors={{}}
                        state={state}
                        setState={setState}
                      />
                    ) : (
                      <div className="mt-md-2">{state.phoneNumber}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="mb-3">
                  <label className="ila-form-label" htmlFor="whatsappNumber">
                    Whatsapp Number
                  </label>
                  <div>
                    {editMode ? (
                      <WhatsappNumberInput
                        ref={whatsappNumberRef}
                        disabled={true}
                        errors={{}}
                        state={state}
                        setState={setState}
                      />
                    ) : (
                      <div className="mt-md-2">{state.whatsappNumber}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div style={{ marginTop: 40 }}>
              <div className="row">
                {/* {base64Signature && (
                  <div className="col-lg-6">
                    <img src={base64Signature} className="w-100" />
                  </div>
                )} */}
                <div className="col-lg-6">
                  <h2>eSignature Pad</h2>

                  <div className="mb-2">
                    <label className="form-check-label">
                      <input
                        className="me-3"
                        type="checkbox"
                        onClick={() => {
                          setState({
                            ...state,
                            consentSignature: !state.consentSignature,
                          });
                        }}
                        checked={state.consentSignature}
                      />
                      <div className="d-inline-block">
                        By signing, you agree to that your signature can be used
                        on quotations
                      </div>
                    </label>
                  </div>

                  <SignatureCanvas
                    ref={agentSignatureRef}
                    penColor="black"
                    style={{ background: "blue" }}
                    canvasProps={{
                      width: 500,
                      height: 200,
                      className: "sigCanvas",
                    }}
                  />

                  <div className="d-flex justify-content-start">
                    <a
                      className="btn btn-light"
                      onClick={() => {
                        agentSignatureRef.current.clear();
                      }}
                    >
                      Clear <i className="fa fa-refresh"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
