/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import uniqid from "uniqid";
import moment from "moment";
// Axios
import useAxiosPrivate from "./hooks/useAxiosPrivate";
import { getTotalPremium, getUserDataFromLocalStorage } from "./core/utils";

const MipsRedirectPage = () => {
  const userData = getUserDataFromLocalStorage();
  const axiosPrivate = useAxiosPrivate(userData);
  const params = useParams();
  // const quotationId = params.quotationId;
  const [quotation, setQuotation] = useState();

  const getQuotationDetails = async (fromShadowUpdate = false) => {
    const id = params.quotationId;
    const url = process.env.REACT_APP_API_URL + `/quotation/${id}`;

    try {
      const response = await axiosPrivate.get(url);
      // debugger;
      if (response.data.success === true) {
        const _quotation = response.data.data;
        _quotation.quotationFormData = JSON.parse(_quotation.quotationFormData);
        // // debugger;
        const totalPremium = getTotalPremium(_quotation);
        _quotation.totalPremium = totalPremium;
        // setRefund(_quotation.quotationFormData.with_refund);
        setQuotation(_quotation);
        return _quotation;
      }
      // console.log(response);
    } catch (error) {
      // console.log(error);
    }
  };

  const updateQuotationStatus = async (payload, qtn) => {
    const url =
      process.env.REACT_APP_API_URL +
      `/quotation/worker/status-update/${params.quotationId}`;

    try {
      payload = { ...payload, userId: qtn?.user?.id };
      const { data } = await axiosPrivate.patch(url, payload);

      if (!data.success) {
        // console.log(
        //   data.message
        //     ? data.message
        //     : "Quotation update was unsuccessful, please try again"
        // );
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const createPaymentTransaction = async () => {
    const url =
      process.env.REACT_APP_API_URL + `/payment-transaction/worker/create`;

    const dateOfPayment = new moment().format("YYYY-MM-DD");
    const premiumDate = new moment().format("YYYY-MM-DD");
    const payload = {
      paymentChannelId: parseInt(params.paymentChannelId, 10),
      userId: parseInt(params.userId, 10),
      policyNumber: params.policyNumber.replace("-", "/"),
      amountExpected: parseFloat(params.amountExpected),
      quotationId: parseInt(params.quotationId, 10),
      amountPaid: parseFloat(params.amountPaid),
      externalPaymentReference: "",
      internalPaymentReference: params.internalPaymentReference,
      transactionType: "initial",
      status: "confirmed",
      dateOfPayment,
      premiumDate,
    };

    // debugger;

    try {
      const response = await axiosPrivate.post(url, payload);
    } catch (err) {
      // console.log(err);
    }
  };

  const createPaymentChoice = async () => {
    const url = process.env.REACT_APP_API_URL + `/payment-choice`;

    const payload = {
      paymentChannelId: parseInt(params.paymentChannelId, 10),
      premiumDueDay: 5,
      quotationId: parseInt(params.quotationId, 10),
      paymentType: "gateway",
    };

    // debugger;

    try {
      const response = await axiosPrivate.post(url, payload);
    } catch (err) {
      // console.log(err);
    }
  };

  const saveDetailsAndProceed = async () => {
    const opType = params.operationType;
    const _quotation = await getQuotationDetails();

    if (opType === "choice") {
      await createPaymentChoice();
      await createPaymentTransaction();
      await updateQuotationStatus(
        {
          status: "accepted",
          paymentStatus: "firstpayment",
        },
        _quotation
      );
    } else if (opType === "transaction") {
      await createPaymentTransaction();
      await updateQuotationStatus(
        {
          status: "accepted",
          paymentStatus: "firstpayment",
        },
        _quotation
      );
    }

    window.location.href = `${window.location.origin}/${process.env.REACT_APP_BASENAME}/policies`;
  };
  useEffect(() => {
    saveDetailsAndProceed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="row justify-content-center">
      <div className="col-md-4">
        <div class="card border-0" style={{ marginTop: 30, borderRadius: 2 }}>
          <div
            style={{ borderRadius: 2 }}
            class="card-header border-0 ila-bg p-4"
          >
            <h5 class="card-title text-white">Finalizing Payment</h5>
          </div>
          <div
            style={{ background: "#C1C1C115" }}
            class="card-body p-4 text-center"
          >
            <span className="spinner-border"></span>
            <p class="card-text">Please wait while we finalize payment</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MipsRedirectPage;
