/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import "./Underwriting.css";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import lodash from "lodash";
import Moment from "moment";
// import { withSwal } from "react-sweetalert2";
// Axios
import {
  canModifyQuotation,
  isUnderwritingReferred,
  getTotalPremium,
  getUserDataFromLocalStorage,
  processUnderwritingData,
} from "../core/utils";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
// Components
import Navbar from "../core/components/NavBar";
import UserContext from "../core/UserContext";
import UnderwritingCovid from "./underwriting-covid/UnderwritingCovid";
import UnderwritingHeight from "./underwriting-height/UnderwritingHeight";
import UnderwritingMedicalCondition from "./underwriting-medicalcondition/UnderwritingMedicalCondition";
import UnderwritingChronic from "./underwriting-chronic/UnderwritingChronic";
import UnderwritingCheckup from "./underwriting-checkup/UnderwritingCheckup";
import UnderwritingAdmission from "./underwriting-admission/UnderwritingAdmission";
import UnderwritingSensory from "./underwriting-sensory/UnderwritingSensory";
import UnderwritingSpecialist from "./underwriting-specialist/UnderwritingSpecialist";
import UnderwritingBooked from "./underwriting-booked/UnderwritingBooked";
import UnderwritingTobacco from "./underwriting-tobacco/UnderwritingTobacco";
import UnderwritingHiv from "./underwriting-hiv/UnderwritingHiv";
import UnderwritingAlcohol from "./underwriting-alcohol/UnderwritingAlcohol";
// import TerminalDisplay from "./TerminalDisplay";
import ReflexoneConditions from "./reflexes/reflex-one/ReflexoneConditions";
import ReflexoneControl from "./reflexes/reflex-one/ReflexoneControl";
import ReflexoneDiagnose from "./reflexes/reflex-one/ReflexoneDiagnose";
import ReflexoneMedication from "./reflexes/reflex-one/ReflexoneMedication";
import ReflexoneTest from "./reflexes/reflex-one/ReflexoneTest";
import ReflexFourConditions from "./reflexes/reflex-four/ReflexFourConditions";
import UnderwritingSummary from "./underwriting-summary/UnderwritingSummary";
import PersonalApplications from "./cummulative-risk/cummulative-purpose-personal/personal-applications/PersonalApplications";
import CummulativePurpose from "./cummulative-risk/cummulative-purpose/CummulativePurpose";
import PersonalCover from "./cummulative-risk/cummulative-purpose-personal/personal-cover/PersonalCover";
import PersonalIncome from "./cummulative-risk/cummulative-purpose-personal/personal-income/PersonalIncome";
import PersonalDependents from "./cummulative-risk/cummulative-purpose-personal/personal-dependents/PersonalDependents";
import PersonalPolicyReason from "./cummulative-risk/cummulative-purpose-personal/personal-policy-reason/PersonalPolicyReason";
import PersonalSumAssured from "./cummulative-risk/cummulative-purpose-personal/personal-sum-assured/PersonalSumAssured";
import PersonalAssets from "./cummulative-risk/cummulative-purpose-personal/personal-assets/PersonalAssets";
import MortgageLoanPurpose from "./cummulative-risk/cummulative-mortageloan/mortgageloan-purpose/MortgageLoanPurpose";
import InheritanceTaxEstimated from "./cummulative-risk/cummulative-inheritancetax/inheritancetax-estimated/InheritanceTaxEstimated";
import InheritanceTaxLiability from "./cummulative-risk/cummulative-inheritancetax/inheritancetax-liability/InheritanceTaxLiability";
import InheritanceTaxLiabilityFall from "./cummulative-risk/cummulative-inheritancetax/inheritancetax-liabilityfall/InheritanceTaxLiabilityFall";
import InheritanceTaxNetWorth from "./cummulative-risk/cummulative-inheritancetax/inheritancetax-networth/InheritanceTaxNetWorth";
import InheritanceTaxOtherFactors from "./cummulative-risk/cummulative-inheritancetax/inheritancetax-other-factors/InheritanceTaxOtherFactors";
import InheritanceTaxRelief from "./cummulative-risk/cummulative-inheritancetax/inheritancetax-relief/InheritanceTaxRelief";
// import SweetAlert2 from "react-sweetalert2";

const Underwriting = () => {
  const { userData, setUserData } = useContext(UserContext);
  const userDataStorage = getUserDataFromLocalStorage();
  const axiosPrivate = useAxiosPrivate(userDataStorage);
  // const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [nextLoading, setNextLoading] = useState(false);
  const [authorized, setAuthorized] = useState();
  const params = useParams();
  const [quotationId, setQuotationId] = useState();
  const [loanPlan, setLoanPlan] = useState();
  const [requestMode, setRequestMode] = useState("create");
  // const [underwritingForQuotation, setUnderwritingForQuotation] = useState();
  const [processedMainLifeData, setProcessedMainLifeData] = useState();
  const [mainLifeUnderwritingCreated, setMainLifeUnderwritingCreated] =
    useState(false);
  const [updatingQuotationStatus, setUpdatingQuotationStatus] = useState(false);
  const [processedJointLifeData, setProcessedJointLifeData] = useState();
  const [jointLifeUnderwritingCreated, setJointLifeUnderwritingCreated] =
    useState(false);
  const [finalVerdict, setFinalVerdict] = useState();
  const [error, setError] = useState();
  const [quotation, setQuotation] = useState();
  const [consent, setConsent] = useState(false);
  const [state, setState] = useState({
    hasCovid: null,
    height: null,
    weight: null,
    pantSize: null,
    bmi: null,
    doctorTreatingYou: null,
    medicalCondition: {
      heartAttack: null,
      coronaryArteryBypassOrStenting: null,
      // chestPainThatRequiredInvestigationOrAngina: null,
      cardiomyopathy: null,
      // irregularHeartRate: null,
      heartFailure: null,
      peripheralVascularDisease: null,
      strokeOrTranscientIschaemicAttack: null,
      cancerAbnormalGrowthsOrAnyTumours: null,
      organFailureOrOrganTransplant: null,
      // diabetesOrRaisedBloodSugarOrSugarInUrine: null,
      chronicObstructivePulmonaryDiseaseInterstitialLungDiseaseFibrosisOfTheLungsOrEmphysema:
        null,
      chronicKidneyOrLiverDisease: null,
    },
    chronicCondition: null,
    conditionReceivingTreatment: [],
    diagnosedWith: [],
    medicationControl: {
      diabetes: null,
      hypertension: null,
      cholestrol: null,
    },
    conditionControlled: {
      diabetesWellControlled: null,
      hypertensionWellControlled: null,
      cholestrolBelowOrEqualToSeven: null,
    },
    hadCheckupRecently: null,
    seeingDocterInNextSixMonths: null,
    conditionsOrComplaintsToConsult: [],
    admitted: null,
    conditionOrComplaintHospitalizedFor: [],
    hasPhysicalOrSensoryLimitation: null,
    physicalOrSensoryCondition: null,
    consultedSpecialistInFiveYears: null,
    bookedOffWorkRecently: null,
    conditionOrComplaintBookedOffFor: [],
    tobaccoOrNicotineUse: null,
    howManyCigarettesPerDay: null,
    hiv: null,
    numberOfAlchoholicDrinksPerWeek: null,
    nameOfTreatingDoctorOrClinic: null,
    phoneOfTreatingDoctorOrClinic: null,
    hasPreviouslyDeclinedLifeOrDisabilityBecauseOfMedicalCondition: null,
    previouslyDeclinedLifeOrDisabilityCondition: [],
    conditionForSeeingSpecialist: null,
    musculoskeletalCategory: null,
    musculoskeletalConditionsSymptomsOrComplain: null,
    hasRecoveredCompletelyWithNoComplications: null,
    hadMultipleMusculoskeletalConditionOrSymptom: null,
    whenLastExperiencedMusculoskeletalSymptoms: null,
    backpainCondition: null,
    hasDoctorSuggestedSurgicalInterventionToBackpain: null,
    hadSymptomsOrTreatmentForBackpainInPastThreeYears: null,
    ableToPerformAllDutiesWithoutDiscomfortOrPain: null,
    mentalhealthCondition: null,
    suicidalThoughts: null,
    multipleSuicidalThoughts: null,
    sucidalSymptomsDuration: null,
    whenWasLastMentalHealthSymptoms: null,
  });

  const [pages, setPages] = useState([
    "underwriting-covid",
    "underwriting-height",
    "underwriting-medical-condition",
    "underwriting-chronic",
    "underwriting-checkup",
    "underwriting-admission",
    "underwriting-sensory",
    "underwriting-specialist",
    "underwriting-booked",
    "underwriting-tobacco",
    "summary",
  ]);

  // const [pages, setPages] = useState([
  //   "cummulative-purpose",
  //   "personal-applications",
  //   "personal-cover",
  //   "personal-income",
  //   "personal-dependents",
  //   "personal-policy-reason",
  //   "personal-sum-assured",
  //   "personal-assets",
  //   "mortgageloan-purpose",
  //   "inheritancetax-estimated",
  //   "inheritancetax-liability",
  //   "inheritancetax-liability-fall",
  //   "inheritancetax-networth",
  //   "inheritancetax-other-factors",
  //   "inheritancetax-relief",
  // ]);

  useEffect(() => {
    // console.log(state);
  }, [state]);

  useEffect(() => {
    // // debugger;
    getUnderwritingData();
    setQuotationId(params.quotationId);
    getQuotationDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  // const [terminalMessage, setTerminalMessage] = useState();

  const updateQuotationStatus = async (payload) => {
    setUpdatingQuotationStatus(true);
    const url =
      process.env.REACT_APP_API_URL +
      `/quotation/worker/status-update/${quotationId}`;

    try {
      payload = { ...payload, userId: quotation?.user?.id };
      const { data } = await axiosPrivate.patch(url, payload);

      setUpdatingQuotationStatus(false);

      if (!data.success) {
        // console.log(
        //   data.message
        //     ? data.message
        //     : "Quotation update was unsuccessful, please try again"
        // );
      } else {
        // navigate(`/review/${quotation.id}`);
      }
    } catch (error) {
      setUpdatingQuotationStatus(false);
      // console.log(error);
    }
  };

  const declarationsInvalidForLife = async (id) => {
    const url = process.env.REACT_APP_API_URL + `/user/declarations/${id}`;

    try {
      const response = await axiosPrivate.get(url);

      // console.log(response.data.data.Declarations);
      const declarations = response.data.data.Declarations;
      for (let i = 0; i < declarations.length; i++) {
        // debugger;
        if (declarations[i].UserDeclaration.consent) {
          return true;
          // eslint-disable-next-line no-unreachable
          break;
        }
      }
      return false;
    } catch (err) {
      // debugger;
      // console.log(err);
      return true;
    }
  };

  const getEightMillionRuleBoolean = async (
    amount,
    userId,
    userJointId = null
  ) => {
    const url = process.env.REACT_APP_API_URL + `/quotation/check/exceeded`;

    try {
      const response = await axiosPrivate.post(url, {
        amount,
        userId,
        quotationId: parseInt(quotationId),
        userJointId,
      });
      return response.data.success ? response.data.data : false;
    } catch (err) {
      // debugger;
      // console.log(err);
      return false;
    }
  };

  const getTenMillionRuleBoolean = async (
    amount,
    userId,
    userJointId = null
  ) => {
    const url =
      process.env.REACT_APP_API_URL + `/quotation/check/exceeded/tenmillion`;

    try {
      const response = await axiosPrivate.post(url, {
        amount,
        userId,
        quotationId: parseInt(quotationId),
        userJointId,
      });
      return response.data.success ? response.data.data : false;
    } catch (err) {
      // debugger;
      console.log(err);
      return false;
    }
  };

  const getDeclarationsStatusForUser = async (userId, policyId) => {
    const url = process.env.REACT_APP_API_URL + `/user/declarations/${userId}`;

    // debugger;
    try {
      const response = await axiosPrivate.get(url);

      const declarationsForQuotation = response.data.data.filter(
        (item) => item.quotationId === quotationId
      );

      // debugger;
      if (declarationsForQuotation.length === 0) {
        return false;
      }

      for (let i = 0; i < declarationsForQuotation.length; i++) {
        const item = declarationsForQuotation[i];
        if (item.consent) {
          return false;
        }
      }

      return true;
    } catch (err) {
      // console.log(err);
      return false;
    }
  };

  const proceed = async () => {
    await updateQuotationStatus({ status: "kyc" });
    navigate(`/client-kyc/${quotation?.user?.id}/${quotation?.id}/main`);

    // if (quotation?.quotationFormData?.join_loan) {
    // const userDeclarationStatus = await getDeclarationsStatusForUser(
    //   quotation?.user?.id
    // );

    // const userJointDeclarationStatus = await getDeclarationsStatusForUser(
    //   quotation?.userJoint?.id
    // );

    // if (
    //   (!quotation?.user?.kycVerification || !userDeclarationStatus) &&
    //   !(
    //     quotation?.user?.declarationsManuallyVerified &&
    //     quotation?.user?.shuftiManuallyVerified
    //   )
    // ) {
    // await updateQuotationStatus({ status: "kyc" });
    // navigate(`/client-kyc/${quotation?.user?.id}/${quotation?.id}/main`);
    // } else if (
    //   (!quotation?.userJoint?.kycVerification ||
    //     !userJointDeclarationStatus) &&
    //   !(
    //     quotation?.userJoint?.declarationsManuallyVerified &&
    //     quotation?.userJoint?.shuftiManuallyVerified
    //   )
    // ) {
    //   await updateQuotationStatus({ status: "kyc" });
    //   navigate(
    //     `/client-kyc/${quotation?.userJoint?.id}/${quotation?.id}/joint`
    //   );
    // } else {
    //   await updateQuotationStatus({ status: "review" });
    //   navigate(`/review/${quotation.id}`);
    // }
    //TODO: PEP check for joint loan
    //   } else if (!quotation?.quotationFormData?.join_loan) {
    //     const userDeclarationStatus = await getDeclarationsStatusForUser(
    //       quotation?.user?.id
    //     );

    //     if (
    //       (!quotation?.user?.kycVerification || !userDeclarationStatus) &&
    //       !(
    //         quotation?.user?.declarationsManuallyVerified &&
    //         quotation?.user?.shuftiManuallyVerified
    //       )
    //     ) {
    //       await updateQuotationStatus({ status: "kyc" });
    //       navigate(`/client-kyc/${quotation?.user?.id}/${quotation?.id}/main`);
    //     } else {
    //       await updateQuotationStatus({ status: "review" });
    //       navigate(`/review/${quotation.id}`);
    //     }
    //   } else {
    //     await updateQuotationStatus({ status: "review" });
    //     navigate(`/review/${quotation.id}`);
    //   }
  };

  const patchUnderwriting = async (isfinal = false) => {
    if (params.life === "main-life") {
      const url =
        process.env.REACT_APP_API_URL + `/underwriting/${quotationId}`;
      const getUnderwritingUrl =
        process.env.REACT_APP_API_URL + `/underwriting/${quotationId}`;

      const currentQuotationDetails = await axiosPrivate.get(
        getUnderwritingUrl
      );

      // console.log(currentQuotationDetails.data);
      currentQuotationDetails.data.data.underwritingJointData = JSON.parse(
        currentQuotationDetails.data.data.underwritingJointData,
        10
      );

      const payload = {
        ...currentQuotationDetails.data.data,
        underwritingData: { data: processUnderwritingData(state) },
      };

      setNextLoading(true);
      try {
        const response = await axiosPrivate.patch(url, payload);

        setNextLoading(false);
        // setMainLifeUnderwritingCreated(true);
        setProcessedMainLifeData(processUnderwritingData(state));

        if (quotation.quotationFormData.join_loan) {
          navigate(`/underwriting/${quotationId}/joint-life`, {
            replace: true,
          });
          navigate(0);
        } else {
          const eightMillionRuleBoolean = await getEightMillionRuleBoolean(
            quotation?.quotationFormData?.initial_sum_assured,
            quotation?.user?.id,
            quotation?.userJoint?.id || null
          );
          // debugger;
          const processedData = processUnderwritingData(state);
          if (
            ((processedData
              .map((item) => item.status)
              .includes("Refer to ILA") ||
              processedData
                .map((item) => item.status)
                .includes("Refer TPD to ILA") ||
              quotation?.quotationFormData?.initial_sum_assured > 8000000) &&
              !quotation.underwritingManuallyAccepted) ||
            eightMillionRuleBoolean.mainLifeAmountExceededEightMillion
          ) {
            // debugger;
            await generateQuotationPdf();
          }

          const tenMillionRuleBoolean = await getTenMillionRuleBoolean(
            quotation?.quotationFormData?.initial_sum_assured,
            quotation?.user?.id,
            quotation?.userJoint?.id || null
          );
          if (
            (tenMillionRuleBoolean?.mainLifeAmountExceededTenMillion == true ||
              tenMillionRuleBoolean?.jointLifeAmountExceededTenMillion ==
                true) &&
            quotation.isLtaResult == true &&
            quotation.isDtaResult == false &&
            (eightMillionRuleBoolean.mainLifeAmountExceededEightMillion ==
              true ||
              eightMillionRuleBoolean.jointLifeAmountExceededEightMillion ==
                true)
          ) {
            let answer = window.confirm(
              "Medical underwriting complete. Will now redirect to financial underwriting."
            );
            return navigate(`/financial-underwriting/${quotationId}/main-life`);
          } else {
            return navigate(
              `/underwriting-summary/${quotation?.user?.id}/${quotationId}`
            );
          }
          // await proceed();
        }
        setLoading(false);
        // console.log(response.data);
      } catch (err) {
        setLoading(false);

        if (err?.response?.data) {
          // debugger;
          setError(
            err?.response?.data
              ? err?.response?.data?.message.split(".")[0]
              : "Underwriting was unsuccessful, please try again"
          );
        } else {
          // debugger;
          setError("Underwriting was unsuccessful, please try again");
        }
        // console.log(err);
      }
    } else if (params.life === "joint-life") {
      const url =
        process.env.REACT_APP_API_URL + `/underwriting/${quotationId}`;
      const getUnderwritingUrl =
        process.env.REACT_APP_API_URL + `/underwriting/${quotationId}`;

      const currentQuotationDetails = await axiosPrivate.get(
        getUnderwritingUrl
      );

      // console.log(currentQuotationDetails.data);
      currentQuotationDetails.data.data.underwritingData = JSON.parse(
        currentQuotationDetails.data.data.underwritingData,
        10
      );

      const payload = {
        ...currentQuotationDetails.data.data,
        underwritingJointData: { data: processUnderwritingData(state), step },
      };

      try {
        const response = await axiosPrivate.patch(url, payload);
        setNextLoading(false);
        // setJointLifeUnderwritingCreated(true);
        setProcessedJointLifeData(processUnderwritingData(state));
        const processedData = processUnderwritingData(state);

        if (quotation.quotationFormData.join_loan) {
          navigate(`/underwriting/${quotationId}/joint-life`, {
            replace: true,
          });
          navigate(0);
        } else {
          const eightMillionRuleBoolean = await getEightMillionRuleBoolean(
            quotation?.quotationFormData?.initial_sum_assured,
            quotation?.user?.id,
            quotation?.userJoint?.id || null
          );
          // debugger;
          if (
            ((processedData
              .map((item) => item.status)
              .includes("Refer to ILA") ||
              processedData
                .map((item) => item.status)
                .includes("Refer TPD to ILA") ||
              quotation?.quotationFormData?.initial_sum_assured > 8000000 ||
              isUnderwritingReferred(quotation, false)) &&
              !quotation.underwritingManuallyAccepted) ||
            eightMillionRuleBoolean.mainLifeAmountExceededEightMillion
          ) {
            // debugger;
            await generateQuotationPdf();
          }

          const tenMillionRuleBoolean = await getTenMillionRuleBoolean(
            quotation?.quotationFormData?.initial_sum_assured,
            quotation?.user?.id,
            quotation?.userJoint?.id || null
          );
          if (
            (tenMillionRuleBoolean?.mainLifeAmountExceededTenMillion == true ||
              tenMillionRuleBoolean?.jointLifeAmountExceededTenMillion ==
                true) &&
            quotation.isLtaResult == true &&
            quotation.isDtaResult == false &&
            (eightMillionRuleBoolean.mainLifeAmountExceededEightMillion ==
              true ||
              eightMillionRuleBoolean.jointLifeAmountExceededEightMillion ==
                true)
          ) {
            let answer = window.confirm(
              "Medical underwriting complete. Will now redirect to financial underwriting."
            );
            return navigate(`/financial-underwriting/${quotationId}/main-life`);
          } else {
            return navigate(
              `/underwriting-summary/${quotation?.user?.id}/${quotationId}`
            );
          }
        }

        // }
        // }
        setLoading(false);
        setNextLoading(false);
        // console.log(response.data);
      } catch (err) {
        setLoading(false);
        setNextLoading(false);

        if (err?.response?.data) {
          // debugger;
          setError(
            err?.response?.data
              ? err?.response?.data?.message.split(".")[0]
              : "Underwriting was unsuccessful, please try again"
          );
        } else {
          // debugger;
          setError("Underwriting was unsuccessful, please try again");
        }
        // console.log(err);
      }
    }
  };
  const finalSubmit = async () => {
    // debugger;
    setNextLoading(true);

    if (params.life === "main-life") {
      const url = process.env.REACT_APP_API_URL + `/underwriting/worker/create`;
      const payload = {
        quotationId: parseInt(quotationId, 10),
        underwritingData: { data: processUnderwritingData(state), step },
        underwritingJointData: { data: [] },
      };
      try {
        const response = await axiosPrivate.post(url, payload);
        setNextLoading(false);
        // setMainLifeUnderwritingCreated(true);
        // await generateQuotationPdf();
        if (quotation.quotationFormData.join_loan) {
          // debugger;
          navigate(`/underwriting/${quotationId}/joint-life`);
          // navigate(`/underwriting/${quotationId}/joint-life`, {
          //   replace: true,
          // });
          // navigate(0);
        } else {
          // debugger;
          // await proceed();
          const eightMillionRuleBoolean = await getEightMillionRuleBoolean(
            quotation?.quotationFormData?.initial_sum_assured,
            quotation?.user?.id,
            quotation?.userJoint?.id || null
          );
          // debugger;
          const processedData = processUnderwritingData(state);
          if (
            ((processedData
              .map((item) => item.status)
              .includes("Refer to ILA") ||
              processedData
                .map((item) => item.status)
                .includes("Refer TPD to ILA") ||
              quotation?.quotationFormData?.initial_sum_assured > 8000000) &&
              !quotation.underwritingManuallyAccepted) ||
            eightMillionRuleBoolean.mainLifeAmountExceededEightMillion
          ) {
            // debugger;
            await generateQuotationPdf();
          }

          const tenMillionRuleBoolean = await getTenMillionRuleBoolean(
            quotation?.quotationFormData?.initial_sum_assured,
            quotation?.user?.id,
            quotation?.userJoint?.id || null
          );

          if (
            (tenMillionRuleBoolean?.mainLifeAmountExceededTenMillion == true ||
              tenMillionRuleBoolean?.jointLifeAmountExceededTenMillion ==
                true) &&
            quotation.isLtaResult == true &&
            quotation.isDtaResult == false &&
            (eightMillionRuleBoolean.mainLifeAmountExceededEightMillion ==
              true ||
              eightMillionRuleBoolean.jointLifeAmountExceededEightMillion ==
                true)
          ) {
            let answer = window.confirm(
              "Medical underwriting complete. Will now redirect to financial underwriting."
            );
            return navigate(`/financial-underwriting/${quotationId}/main-life`);
          } else {
            return navigate(
              `/underwriting-summary/${quotation?.user?.id}/${quotationId}`
            );
          }
        }
        // console.log(response.data);
      } catch (err) {
        //Handing Form Errors
        setNextLoading(false);
        setError(null);

        if (err?.response?.status === 409) {
          patchUnderwriting(true);
        } else if (err?.response?.data) {
          setLoading(false);
          setNextLoading(false);
          // debugger;
          setError(
            err?.response?.data
              ? err?.response?.data?.message.split(".")[0]
              : "Underwriting was unsuccessful, please try again"
          );
        } else {
          // debugger;
          setLoading(false);
          setNextLoading(false);
          // debugger;
          setError("Underwriting was unsuccessful, please try again");
        }
        // console.log(err);
      }
    } else if (params.life === "joint-life") {
      const url =
        process.env.REACT_APP_API_URL + `/underwriting/${quotationId}`;
      const getUnderwritingUrl =
        process.env.REACT_APP_API_URL + `/underwriting/${quotationId}`;

      const currentQuotationDetails = await axiosPrivate.get(
        getUnderwritingUrl
      );

      // console.log(currentQuotationDetails.data);
      currentQuotationDetails.data.data.underwritingData = JSON.parse(
        currentQuotationDetails.data.data.underwritingData,
        10
      );

      const payload = {
        ...currentQuotationDetails.data.data,
        underwritingJointData: { data: processUnderwritingData(state), step },
      };

      try {
        const response = await axiosPrivate.patch(url, payload);
        setNextLoading(false);
        // setJointLifeUnderwritingCreated(true);

        if (response.data.success) {
          setProcessedJointLifeData(processUnderwritingData(state));
          const processedData = processUnderwritingData(state);
          // debugger;
          const eightMillionRuleBoolean = await getEightMillionRuleBoolean(
            quotation?.quotationFormData?.initial_sum_assured,
            quotation?.user?.id,
            quotation?.userJoint?.id || null
          );
          // debugger;
          if (
            ((processedData
              .map((item) => item.status)
              .includes("Refer to ILA") ||
              processedData
                .map((item) => item.status)
                .includes("Refer TPD to ILA") ||
              quotation?.quotationFormData?.initial_sum_assured > 8000000 ||
              isUnderwritingReferred(quotation, false)) &&
              !quotation.underwritingManuallyAccepted) ||
            eightMillionRuleBoolean.mainLifeAmountExceededEightMillion
          ) {
            // debugger;
            await generateQuotationPdf();
          }

          const tenMillionRuleBoolean = await getTenMillionRuleBoolean(
            quotation?.quotationFormData?.initial_sum_assured,
            quotation?.user?.id,
            quotation?.userJoint?.id || null
          );

          if (
            (tenMillionRuleBoolean?.mainLifeAmountExceededTenMillion == true ||
              tenMillionRuleBoolean?.jointLifeAmountExceededTenMillion ==
                true) &&
            quotation.isLtaResult == true &&
            quotation.isDtaResult == false &&
            (eightMillionRuleBoolean.mainLifeAmountExceededEightMillion ==
              true ||
              eightMillionRuleBoolean.jointLifeAmountExceededEightMillion ==
                true)
          ) {
            let answer = window.confirm(
              "Medical underwriting complete. Will now redirect to financial underwriting."
            );
            return navigate(`/financial-underwriting/${quotationId}/main-life`);
          } else {
            return navigate(
              `/underwriting-summary/${quotation?.user?.id}/${quotationId}`
            );
          }
        }

        //   }
        // }
        setLoading(false);
        setNextLoading(false);
        // console.log(response.data);
      } catch (err) {
        setLoading(false);
        setNextLoading(false);

        if (err?.response?.data) {
          // debugger;
          setError(
            err?.response?.data
              ? err?.response?.data?.message.split(".")[0]
              : "Underwriting was unsuccessful, please try again"
          );
        } else {
          // debugger;
          setError("Underwriting was unsuccessful, please try again");
        }
        // console.log(err);
      }
    }

    // console.log(state);
    // processUnderwritingData(state);
  };

  const generateQuotationPdf = async () => {
    // debugger;
    const url =
      process.env.REACT_APP_API_URL +
      `/quotation/user/proposal-form-pdf/${quotationId}`;
    setNextLoading(true);

    try {
      const response = await axiosPrivate.get(url);
      // debugger;
      if (response.data.success === true) {
        // console.log("successfully generated quotation");
        sendEmailWithAttachment();
      } else {
        setNextLoading(false);
      }
    } catch (error) {
      // debugger;
      setNextLoading(false);
      // console.log(error);
    }
  };

  const sendEmailWithAttachment = async () => {
    setNextLoading(true);
    const url =
      process.env.REACT_APP_API_URL +
      `/utils/send-email/attachment/proposal-form/${quotationId}`;

    let message = `<p>Dear ILA Underwriter</p><p>You have received a referred underwriting request on client proposal with the details;</p><p><strong>Quotation ID : ${
      quotation.id
    }</strong></p><p><strong>Temporal Policy Number: ${
      quotation.temporaryId
    }</strong></p><p><strong>Name:${
      quotation?.joinLoan
        ? quotation?.user?.name + " | " + quotation?.userJoint?.name
        : quotation?.user?.name
    }</strong></p><p><strong>Date: ${new Moment(quotation.createdAt).format(
      "DD/MM/YYYY"
    )}</strong></p><p>The proposal form is attached for your review and attention.</p><p>Kind Regards</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p>`;
    const payload = {
      emails: ["underwriting@ila.mu"],
      subjectTitle: "Referred Quotation",
      messageHtml: message,
    };

    try {
      const response = await axiosPrivate.post(url, payload);
      setNextLoading(false);
      // debugger;
      if (response.data.success === true) {
        // console.log("successfully sent email");
      }
    } catch (error) {
      // debugger;
      setNextLoading(false);
      // console.log(error);
    }
  };
  const getQuotationDetails = async () => {
    const id = params.quotationId;
    const url = process.env.REACT_APP_API_URL + `/quotation/${id}`;
    const userData = getUserDataFromLocalStorage();
    const userId = userData?.data?.id;

    try {
      const response = await axiosPrivate.get(url);
      if (response.data.success === true) {
        const _quotation = response.data.data;
        _quotation.quotationFormData = JSON.parse(_quotation.quotationFormData);
        let _loanPlan =
          !_quotation.isLtaResult && _quotation.isDtaResult
            ? "dta"
            : _quotation.isLtaResult && !_quotation.isDtaResult
            ? "lta"
            : "dta-lta";
        setLoanPlan(_loanPlan);

        const totalPremium = getTotalPremium(_quotation);
        _quotation.totalPremium = totalPremium;
        if (!canModifyQuotation(_quotation, userId)) {
          setAuthorized(false);
          return;
        } else {
          setAuthorized(true);
        }
        setQuotation(_quotation);
        const newPages = [...pages];
        if (_quotation.quotationFormData.initial_sum_assured > 8000000) {
          // debugger;
          if (!newPages.includes("underwriting-hiv")) {
            newPages.splice(newPages.length - 1, 0, "underwriting-hiv");
          }
        }

        // debugger;
        // for single quotation
        if (
          params.life === "main-life" &&
          new Moment().diff(_quotation.user.birth, "years") >= 18 &&
          new Moment().diff(_quotation.user.birth, "years") <= 35
        ) {
          if (!newPages.includes("underwriting-alchohol")) {
            newPages.splice(newPages.length - 1, 0, "underwriting-alchohol");
          }
        }

        // for joint quotation
        if (
          params.life === "joint-life" &&
          new Moment().diff(_quotation.userJoint.birth, "years") >= 18 &&
          new Moment().diff(_quotation.userJoint.birth, "years") <= 35
        ) {
          if (!newPages.includes("underwriting-alchohol")) {
            newPages.splice(newPages.length - 1, 0, "underwriting-alchohol");
          }
        }

        setPages([...newPages]);
      }
    } catch (error) {
      // console.log(error);
    }
  };
  const saveUnderwritingState = async () => {
    const url = process.env.REACT_APP_API_URL + `/underwriting/${quotationId}`;
    setNextLoading(true);
    const getUnderwritingUrl =
      process.env.REACT_APP_API_URL + `/underwriting/${quotationId}`;

    const currentUnderwritingDetails = await axiosPrivate.get(
      getUnderwritingUrl
    );
    // console.log(currentUnderwritingDetails.data);

    let payload;
    if (params.life === "main-life") {
      currentUnderwritingDetails.data.data.underwritingJointData = JSON.parse(
        currentUnderwritingDetails.data.data.underwritingJointData,
        10
      );

      payload = {
        ...currentUnderwritingDetails.data.data,
        underwritingData: { data: processUnderwritingData(state), step },
      };
    } else {
      currentUnderwritingDetails.data.data.underwritingData = JSON.parse(
        currentUnderwritingDetails.data.data.underwritingData,
        10
      );

      payload = {
        ...currentUnderwritingDetails.data.data,
        underwritingJointData: { data: processUnderwritingData(state), step },
      };
    }

    try {
      const response = await axiosPrivate.patch(url, payload);
      if (response.data.success) {
        setStep(step + 1);
      }
      setNextLoading(false);
      // console.log(response.data);
    } catch (err) {
      setNextLoading(false);

      if (err?.response?.data) {
        // debugger;
        setError(
          err?.response?.data
            ? err?.response?.data?.message.split(".")[0]
            : "Underwriting was unsuccessful, please try again"
        );
      } else {
        // debugger;
        setError("Underwriting was unsuccessful, please try again");
      }
      // console.log(err);
    }
  };

  const getUnderwritingData = async () => {
    const getUnderwritingUrl =
      process.env.REACT_APP_API_URL + `/underwriting/${params.quotationId}`;

    const currentUnderwritingDetails = await axiosPrivate.get(
      getUnderwritingUrl
    );
    // // debugger;

    if (currentUnderwritingDetails.data.success) {
      if (params.life === "main-life") {
        const fieldsFromGET = JSON.parse(
          currentUnderwritingDetails.data.data.underwritingData
        ).data.map((item) => {
          return { key: item.key, response: item.response };
        });
        let fieldsAsObject = {};
        fieldsFromGET.forEach((field) => {
          fieldsAsObject[field.key] = field.response;
        });
        const readyState = {
          ...state,
          ...fieldsAsObject,
        };
        setState({
          ...state,
          ...fieldsAsObject,
        });

        // checkFieldsForPossibleReflexMutations(readyState);
        // setStep(
        //   JSON.parse(currentUnderwritingDetails.data.data.underwritingData).step
        // );
        if (fieldsFromGET.length > 0) {
          setRequestMode("update");
        }
      } else if (params.life === "joint-life") {
        const fieldsFromGET = JSON.parse(
          currentUnderwritingDetails.data.data.underwritingJointData
        ).data.map((item) => {
          return { key: item.key, response: item.response };
        });
        let fieldsAsObject = {};
        fieldsFromGET.forEach((field) => {
          fieldsAsObject[field.key] = field.response;
        });
        const readyState = {
          ...state,
          ...fieldsAsObject,
        };
        setState({
          ...state,
          ...fieldsAsObject,
        });

        // checkFieldsForPossibleReflexMutations(readyState);
        // setStep(
        //   JSON.parse(currentUnderwritingDetails.data.data.underwritingJointData)
        //     .step
        // );
        if (fieldsFromGET.length > 0) {
          setRequestMode("update");
        }
      }
    }
  };

  const getUnderwritingDataValue = async (life, qtnId) => {
    const getUnderwritingUrl =
      process.env.REACT_APP_API_URL + `/underwriting/${qtnId}`;

    const currentUnderwritingDetails = await axiosPrivate.get(
      getUnderwritingUrl
    );
    // // debugger;

    if (currentUnderwritingDetails.data.success) {
      if (life === "main-life") {
        const fieldsFromGET = JSON.parse(
          currentUnderwritingDetails.data.data.underwritingData
        ).data.map((item) => {
          return { key: item.key, response: item.response };
        });
        let fieldsAsObject = {};
        fieldsFromGET.forEach((field) => {
          fieldsAsObject[field.key] = field.response;
        });

        return fieldsAsObject;
      } else if (life === "joint-life") {
        const fieldsFromGET = JSON.parse(
          currentUnderwritingDetails.data.data.underwritingJointData
        ).data.map((item) => {
          return { key: item.key, response: item.response };
        });
        let fieldsAsObject = {};
        fieldsFromGET.forEach((field) => {
          fieldsAsObject[field.key] = field.response;
        });
        return fieldsAsObject;
      }
    } else {
      return {};
    }
  };

  const createNewUnderwriting = async () => {
    setNextLoading(true);
    if (params.life === "main-life") {
      const url = process.env.REACT_APP_API_URL + `/underwriting`;
      const payload = {
        quotationId: parseInt(quotationId, 10),
        underwritingData: { data: processUnderwritingData(state), step },
        underwritingJointData: { data: [] },
      };
      try {
        const response = await axiosPrivate.post(url, payload);
        setNextLoading(false);
        if (response.data.success) {
          setRequestMode("update");
          setStep(step + 1);
        }
      } catch (err) {
        //Handing Form Errors

        setError(null);

        if (err?.response?.data) {
          setNextLoading(false);
          setError(
            err?.response?.data
              ? err?.response?.data?.message.split(".")[0]
              : "Could not save state"
          );
        } else {
          setNextLoading(false);
          setError("Could not save state");
        }
        // console.log(err);
      }
    } else if (params.life === "joint-life") {
      const url =
        process.env.REACT_APP_API_URL + `/underwriting/${quotationId}`;
      const getUnderwritingUrl =
        process.env.REACT_APP_API_URL + `/underwriting/${quotationId}`;

      const currentUnderwriting = await axiosPrivate.get(getUnderwritingUrl);
      // console.log(currentUnderwriting.data);
      currentUnderwriting.data.data.underwritingData = JSON.parse(
        currentUnderwriting.data.data.underwritingData,
        10
      );

      const payload = {
        ...currentUnderwriting.data.data,
        underwritingJointData: { data: processUnderwritingData(state), step },
      };

      try {
        const response = await axiosPrivate.patch(url, payload);

        if (response.data.success) {
          setRequestMode("update");
          setStep(step + 1);
        }

        setNextLoading(false);
        // console.log(response.data);
      } catch (err) {
        setNextLoading(false);
        if (err?.response?.data) {
          // debugger;
          setError(
            err?.response?.data
              ? err?.response?.data?.message.split(".")[0]
              : "Underwriting was unsuccessful, please try again"
          );
        } else {
          // debugger;
          setError("Underwriting was unsuccessful, please try again");
        }
        // console.log(err);
      }
    }
  };

  const onNext = () => {
    if (step < pages.length) {
      const currentPage = pages[step - 1];

      //Validate steps

      if (currentPage === "underwriting-covid") {
        if (state.hasCovid == null) {
          setError("Please select covid status");
          return;
        }
      }
      if (currentPage === "underwriting-height") {
        if (lodash.isEmpty(state.height)) {
          setError("Height is required");
          return;
        } else if (lodash.isEmpty(state.weight)) {
          setError("Weight is required");
          return;
        } else if (lodash.isEmpty(state.pantSize)) {
          setError("Pant size is required");
          return;
        } else if (state.height < 0) {
          setError("Height cannot be negative!");
          return;
        } else if (state.height >= 2) {
          let confirmed = window.confirm(
            `Are you sure your height is ${state.height} metres?`
          );
          if (!confirmed) {
            return;
          }
        }
      }

      if (currentPage === "underwriting-medical-condition") {
        if (lodash.isEmpty(state.medicalCondition)) {
          setError("Please select option for all medical conditions");
          return;
        }

        const medicalConditionKeys = Object.keys(state.medicalCondition);
        for (let i = 0; i < medicalConditionKeys.length; i++) {
          if (lodash.isEmpty(state.medicalCondition[medicalConditionKeys[i]])) {
            setError("Please select option for all medical conditions");
            return;
          }
        }
      }

      if (currentPage === "underwriting-chronic") {
        if (lodash.isEmpty(state.chronicCondition)) {
          setError("Please select a statement");
          return;
        }
      }

      if (currentPage === "reflex-one-conditions") {
        if (
          state.conditionReceivingTreatment == null ||
          state.conditionReceivingTreatment.length === 0
        ) {
          setError("Please select a condition");
          return;
        }
        if (state.diagnosedWith.length === 0) {
          setError(
            "Please select atleast one condition you've been diagnosed with"
          );
          return;
        }
      }

      if (currentPage === "reflex-one-medication") {
        // const medicationControlKeys = Object.keys(state.medicationControl);
        // for (let i = 0; i < medicationControlKeys.length; i++) {
        //   if (state.medicationControl[medicationControlKeys[i]] == null) {
        //     setError("Please select all that apply");
        //     return;
        //   }
        // }
        // if (
        //   state.medicationControl.diabetes == null &&
        //   state.conditionReceivingTreatment.includes("diabetes")
        // ) {
        //   setError("Please select all that apply");
        //   return;
        // }
        // if (
        //   state.medicationControl.hypertension == null &&
        //   state.conditionReceivingTreatment.includes("hypertension")
        // ) {
        //   setError("Please select all that apply");
        //   return;
        // }
        // if (
        //   (state.medicationControl.cholestrol == null &&
        //     state.conditionReceivingTreatment.includes("cholestrol")) ||
        //   state.conditionReceivingTreatment.includes("hypercholesterolaemia")
        // ) {
        //   setError("Please select all that apply");
        //   return;
        // }
        //TODO: Valitdation for medicalControl
      }

      if (currentPage === "reflex-one-test") {
        if (state.hadCheckupRecently == null) {
          setError("Please select checkup status");
          return;
        }

        if (state.hadCheckupRecently) {
          const conditionControlledKeys = Object.keys(
            state.conditionControlled
          );
          // for (let i = 0; i < conditionControlledKeys.length; i++) {
          //   if (state.conditionControlled[conditionControlledKeys[i]] == null) {
          //     setError("Please select all that apply");
          //     return;
          //   }
          // }
          // TODO: Validation for conditionControlled Items
        }
      }

      if (currentPage === "underwriting-checkup") {
        if (state.seeingDocterInNextSixMonths == null) {
          setError("Please select an answer");
          return;
        }
        if (
          state.seeingDocterInNextSixMonths &&
          state.conditionsOrComplaintsToConsult.length === 0
        ) {
          setError("Select symptoms or complaints");
          return;
        }
      }

      if (currentPage === "underwriting-admission") {
        if (state.admitted == null) {
          setError("Please select admission status");
          return;
        }

        if (
          state.admitted &&
          state.conditionOrComplaintHospitalizedFor.length === 0
        ) {
          setError("Select symptoms or complaints");
          return;
        }
      }

      if (currentPage === "underwriting-sensory") {
        if (state.hasPhysicalOrSensoryLimitation == null) {
          setError("Please select physical or sensory limitation status");
          return;
        }
        if (
          state.hasPhysicalOrSensoryLimitation &&
          state.physicalOrSensoryCondition == null
        ) {
          setError("Please select a condition");
          return;
        }
      }
      if (currentPage === "underwriting-specialist") {
        if (state.consultedSpecialistInFiveYears == null) {
          setError("Please select an answer");
          return;
        }
      }

      if (currentPage === "underwriting-booked") {
        if (state.bookedOffWorkRecently == null) {
          setError("Please select answer");
          return;
        }
        if (
          state.bookedOffWorkRecently &&
          state.conditionOrComplaintBookedOffFor.length === 0
        ) {
          setError("Select symptoms or complaints");
          return;
        }
      }

      if (currentPage === "underwriting-tobacco") {
        if (state.tobaccoOrNicotineUse == null) {
          setError("Please select an answer");
          return;
        }
        if (
          (state.tobaccoOrNicotineUse === "this-week" ||
            state.tobaccoOrNicotineUse === "past-three-months" ||
            state.tobaccoOrNicotineUse === "past-twelve-months") &&
          state.howManyCigarettesPerDay == null
        ) {
          setError("Please enter number of cigarettes per day");
          return;
        }
      }

      setError(null);
      //End of validation

      if (pages[step - 1] === "underwriting-chronic") {
        if (state.chronicCondition === "yes") {
          const updatedPages = [...pages];
          const deletedArr = updatedPages.splice(
            step,
            0,
            "reflex-one-conditions",
            "reflex-one-medication",
            "reflex-one-test"
          );
          setPages(updatedPages);
          // console.log(updatedPages);
        }
      }

      if (pages[step - 1] === "underwriting-specialist") {
        if (!!state.consultedSpecialistInFiveYears) {
          const updatedPages = [...pages];
          const deletedArr = updatedPages.splice(
            step,
            0,
            "reflex-four-conditions"
          );
          setPages(updatedPages);
        }
      }
      if (requestMode === "create") {
        createNewUnderwriting();
      } else {
        saveUnderwritingState();
      }
    } else {
      let emptyFields = 0;

      if (
        state.nameOfTreatingDoctorOrClinic !== "" &&
        state.nameOfTreatingDoctorOrClinic != null
      ) {
        emptyFields++;
      }

      if (
        state.phoneOfTreatingDoctorOrClinic !== "" &&
        state.phoneOfTreatingDoctorOrClinic != null
      ) {
        emptyFields++;
      }

      if (
        emptyFields === 1 ||
        (!state.phoneOfTreatingDoctorOrClinic &&
          !state.nameOfTreatingDoctorOrClinic &&
          state.doctorTreatingYou)
      ) {
        setError("Both fields are required");
        return;
      }

      if (
        !consent &&
        step === pages.length &&
        ((state.nameOfTreatingDoctorOrClinic !== "" &&
          state.nameOfTreatingDoctorOrClinic != null) ||
          (state.phoneOfTreatingDoctorOrClinic !== "" &&
            state.phoneOfTreatingDoctorOrClinic != null) ||
          state.doctorTreatingYou)
      ) {
        setError("Please check the consent box");
        return;
      }

      if (
        state.hasPreviouslyDeclinedLifeOrDisabilityBecauseOfMedicalCondition ==
        null
      ) {
        setError(
          "Please specify whether you've had any life or disability declined"
        );
        return;
      }

      if (typeof state.doctorTreatingYou != "boolean") {
        setError("Please specify if you have a doctor treating you");
        return;
      }

      if (
        state.hasPreviouslyDeclinedLifeOrDisabilityBecauseOfMedicalCondition &&
        state.previouslyDeclinedLifeOrDisabilityCondition.length === 0
      ) {
        setError("Please select condition");
        return;
      }

      finalSubmit();
    }
  };
  const onPrev = () => {
    if (step > 1) {
      if (pages[step - 1] === "reflex-one-conditions") {
        const updatedPages = pages;
        const deletedArr = updatedPages.splice(step - 1, 3);

        setPages(updatedPages);
        // console.log(deletedArr);
      }
      if (pages[step - 1] === "reflex-four-conditions") {
        const updatedPages = pages;
        const deletedArr = updatedPages.splice(step - 1, 1);
        setPages(updatedPages);
        // console.log(deletedArr);
      }
      setStep(step - 1);
    }
  };

  return (
    <>
      <Navbar
        name={
          userData
            ? `${userData?.data?.firstname} ${userData?.data?.lastname}`
            : ""
        }
      />
      {authorized && (
        <div className="container">
          <h4 className="mt-4 ila-grey-text">
            {params.life === "joint-life"
              ? "Medical Underwriting-Joint Life"
              : "Medical Underwriting"}
          </h4>
          {quotation && (
            <>
              {params.life === "joint-life" ? (
                <div>
                  Questions to be answered by the joint life assured{" "}
                  <b>{quotation?.userJoint?.name}</b>
                </div>
              ) : (
                <div>
                  Questions to be answered by the main life assured{" "}
                  <b>{quotation?.user?.name}</b>
                </div>
              )}
            </>
          )}

          {/* <div className="my-3">
                Enter parameters to generate quotation
            </div> */}
          {((params.life === "main-life" && !mainLifeUnderwritingCreated) ||
            (params.life === "joint-life" &&
              !jointLifeUnderwritingCreated)) && (
            <div
              className="card p-5"
              style={{ background: "#C1C1C115", border: "none", marginTop: 30 }}
            >
              <div className="card-body">
                <h6 className="fw-bold w-100">
                  <div className="progress mb-5">
                    <div
                      className="progress-bar progress-bar-striped bg-success"
                      role="progressbar"
                      aria-label="Success striped example"
                      style={{ width: `${(step / pages.length) * 100}%` }}
                      aria-valuenow={step}
                      aria-valuemin="0"
                      aria-valuemax={pages.length}
                    ></div>
                  </div>
                </h6>
                <div className="alert alert-info my-4">
                  <i className="fa fa-info-circle me-3"></i>
                  Please answer all questions correctly and truthfully. Failure
                  to disclose any facts may lead to the insurance contract being
                  null and void and any future claim may as well be voided.
                </div>
                {error && (
                  <div className="alert alert-danger my-4">
                    <i className="fa fa-warning me-3"></i>
                    {error}
                  </div>
                )}
                {pages[step - 1] === "underwriting-covid" && (
                  <UnderwritingCovid
                    number={step}
                    state={state}
                    setState={setState}
                  />
                )}
                {pages[step - 1] === "underwriting-height" && (
                  <UnderwritingHeight
                    number={step}
                    state={state}
                    setState={setState}
                  />
                )}
                {pages[step - 1] === "underwriting-medical-condition" && (
                  <UnderwritingMedicalCondition
                    number={step}
                    state={state}
                    setState={setState}
                  />
                )}
                {pages[step - 1] === "underwriting-chronic" && (
                  <UnderwritingChronic
                    number={step}
                    state={state}
                    setState={setState}
                  />
                )}
                {pages[step - 1] === "underwriting-checkup" && (
                  <UnderwritingCheckup
                    number={step}
                    state={state}
                    setState={setState}
                  />
                )}
                {pages[step - 1] === "underwriting-admission" && (
                  <UnderwritingAdmission
                    number={step}
                    state={state}
                    setState={setState}
                  />
                )}
                {pages[step - 1] === "underwriting-sensory" && (
                  <UnderwritingSensory
                    number={step}
                    state={state}
                    setState={setState}
                  />
                )}
                {pages[step - 1] === "underwriting-specialist" && (
                  <UnderwritingSpecialist
                    number={step}
                    state={state}
                    setState={setState}
                  />
                )}
                {pages[step - 1] === "underwriting-booked" && (
                  <UnderwritingBooked
                    number={step}
                    state={state}
                    setState={setState}
                  />
                )}
                {pages[step - 1] === "underwriting-tobacco" && (
                  <UnderwritingTobacco
                    number={step}
                    state={state}
                    setState={setState}
                  />
                )}
                {pages[step - 1] === "underwriting-hiv" && (
                  <UnderwritingHiv
                    number={step}
                    state={state}
                    setState={setState}
                  />
                )}
                {pages[step - 1] === "underwriting-alchohol" && (
                  <UnderwritingAlcohol
                    number={step}
                    state={state}
                    setState={setState}
                  />
                )}
                {pages[step - 1] === "reflex-one-conditions" && (
                  <ReflexoneConditions
                    number={step}
                    state={state}
                    setState={setState}
                  />
                )}
                {pages[step - 1] === "reflex-one-control" && (
                  <ReflexoneControl
                    number={step}
                    state={state}
                    setState={setState}
                  />
                )}
                {pages[step - 1] === "reflex-one-diagnose" && (
                  <ReflexoneDiagnose
                    number={step}
                    state={state}
                    setState={setState}
                  />
                )}
                {pages[step - 1] === "reflex-one-medication" && (
                  <ReflexoneMedication
                    number={step}
                    state={state}
                    setState={setState}
                  />
                )}
                {pages[step - 1] === "reflex-one-test" && (
                  <ReflexoneTest
                    number={step}
                    state={state}
                    setState={setState}
                  />
                )}
                {pages[step - 1] === "reflex-four-conditions" && (
                  <ReflexFourConditions
                    number={step}
                    state={state}
                    setState={setState}
                  />
                )}

                {pages[step - 1] === "cummulative-purpose" && (
                  <CummulativePurpose
                    number={step}
                    state={state}
                    setState={setState}
                  />
                )}

                {pages[step - 1] === "personal-applications" && (
                  <PersonalApplications
                    number={step}
                    state={state}
                    setState={setState}
                  />
                )}

                {pages[step - 1] === "personal-cover" && (
                  <PersonalCover
                    number={step}
                    state={state}
                    setState={setState}
                  />
                )}

                {pages[step - 1] === "personal-income" && (
                  <PersonalIncome
                    number={step}
                    state={state}
                    setState={setState}
                  />
                )}

                {pages[step - 1] === "personal-dependents" && (
                  <PersonalDependents
                    number={step}
                    state={state}
                    setState={setState}
                  />
                )}

                {pages[step - 1] === "personal-policy-reason" && (
                  <PersonalPolicyReason
                    number={step}
                    state={state}
                    setState={setState}
                  />
                )}

                {pages[step - 1] === "personal-sum-assured" && (
                  <PersonalSumAssured
                    number={step}
                    state={state}
                    setState={setState}
                  />
                )}

                {pages[step - 1] === "personal-assets" && (
                  <PersonalAssets
                    number={step}
                    state={state}
                    setState={setState}
                  />
                )}

                {pages[step - 1] === "mortgageloan-purpose" && (
                  <MortgageLoanPurpose
                    number={step}
                    state={state}
                    setState={setState}
                  />
                )}

                {pages[step - 1] === "inheritancetax-estimated" && (
                  <InheritanceTaxEstimated
                    number={step}
                    state={state}
                    setState={setState}
                  />
                )}

                {pages[step - 1] === "inheritancetax-liability" && (
                  <InheritanceTaxLiability
                    number={step}
                    state={state}
                    setState={setState}
                  />
                )}

                {pages[step - 1] === "inheritancetax-liabilityfall" && (
                  <InheritanceTaxLiabilityFall
                    number={step}
                    state={state}
                    setState={setState}
                  />
                )}

                {pages[step - 1] === "inheritancetax-networth" && (
                  <InheritanceTaxNetWorth
                    number={step}
                    state={state}
                    setState={setState}
                  />
                )}

                {pages[step - 1] === "inheritancetax-other-factors" && (
                  <InheritanceTaxOtherFactors
                    number={step}
                    state={state}
                    setState={setState}
                  />
                )}
                {pages[step - 1] === "inheritancetax-relief" && (
                  <InheritanceTaxRelief
                    number={step}
                    state={state}
                    setState={setState}
                  />
                )}
                {pages[step - 1] === "summary" && (
                  <UnderwritingSummary
                    number={step}
                    state={state}
                    setState={setState}
                    setConsent={setConsent}
                    consent={consent}
                  />
                )}
                <div className="d-flex justify-content-between">
                  <a onClick={onPrev} className="btn ila-accent-btn">
                    <i className="fa fa-arrow-left me-2"></i>Prev
                  </a>
                  <a
                    onClick={onNext}
                    className="btn ila-accent-btn"
                    disabled={loading || nextLoading}
                  >
                    {loading ||
                      (nextLoading && (
                        <span
                          className="spinner-border spinner-border-sm me-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ))}
                    {step === pages.length - 1 && "Next"}
                    {step === pages.length && "Complete"}
                    {step < pages.length - 1 && "Next"}

                    <i className="fa fa-arrow-right ms-2"></i>
                  </a>
                </div>
              </div>
            </div>
          )}
          {finalVerdict && (
            <div>
              <h2>{finalVerdict}</h2>
            </div>
          )}
          {mainLifeUnderwritingCreated &&
            quotation.quotationFormData.join_loan &&
            params.life === "main-life" && (
              <>
                <a
                  href={`${process.env.REACT_APP_BASENAME}/underwriting/${quotationId}/joint-life`}
                  className="btn btn-light my-3"
                >
                  Create Underwriting for Joint Life
                </a>
              </>
            )}

          {((jointLifeUnderwritingCreated &&
            quotation.quotationFormData.join_loan &&
            params.life === "joint-life") ||
            (mainLifeUnderwritingCreated &&
              !quotation.quotationFormData.join_loan &&
              params.life === "main-life")) && (
            <div className="d-flex justify-content-end">
              {/* {finalVerdict !== "Referred" && ( */}
              <a
                onClick={() => {
                  proceed().then((res) => {});
                }}
                className="btn btn-secondary"
              >
                Proceed
              </a>
              {/* )} */}
              {/* {finalVerdict === "Referred" && (
              <div className="alert alert-info">
                ILA is reviewing your application. You will be notified to
                proceed when your application passes
              </div>
            )} */}
            </div>
          )}
          {mainLifeUnderwritingCreated && (
            <>
              <table className="table mt-4">
                <thead>
                  <tr>
                    <th scope="col">Question</th>
                    <th scope="col">Response</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {processedMainLifeData.map((_item) => {
                    return (
                      <tr>
                        <td>{_item.question}</td>
                        <td>
                          {_item.response !== ""
                            ? JSON.stringify(_item.response)
                            : "N/A"}
                        </td>
                        <td>{_item.status}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          )}
          {jointLifeUnderwritingCreated && (
            <>
              <table className="table mt-4">
                <thead>
                  <tr>
                    <th scope="col">Question</th>
                    <th scope="col">Response</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {processedJointLifeData.map((_item) => {
                    return (
                      <tr>
                        <td>{_item.question}</td>
                        <td>
                          {_item.response !== ""
                            ? JSON.stringify(_item.response)
                            : "N/A"}
                        </td>
                        <td>{_item.status}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          )}
        </div>
      )}

      {authorized === undefined && (
        <div>
          <div className="text-center">
            <span className="spinner-border  mt-5"></span>
          </div>
          <div className="text-center">Please wait ...</div>
        </div>
      )}
      {authorized === false && (
        <div className="container">
          {/* <h1 className="text-center">
            You are Unauthorized to modify this quotation
          </h1> */}

          <div
            style={{ marginTop: 50 }}
            className="alert alert-danger"
            role="alert"
          >
            You are Unauthorized to modify this quotation
          </div>
        </div>
      )}
    </>
  );
};

export default Underwriting;
