import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import useRefreshToken from "../../hooks/useRefreshToken";
import { getUserDataFromLocalStorage } from "../../core/utils";

function PersistLogin() {
  const userData = getUserDataFromLocalStorage();
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  // log("PersistLogin User----", userData?.access_token);

  useEffect(() => {
    let isMounted = true;

    const verifyRefreshToken = async () => {
      try {
        await refresh();
      } catch (err) {
        console.error(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    };

    // Avoids unwanted call to verifyRefreshToken
    !userData?.access_token ? verifyRefreshToken() : setIsLoading(false);

    return () => (isMounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   log(`isLoading: ${isLoading}`);
  //   log(`aT: ${JSON.stringify(userData?.access_token)}`);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isLoading]);

  return <>{isLoading ? <div>Loading...</div> : <Outlet />}</>;
}

export default PersistLogin;
