/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../core/components/NavBar";
import UserContext from "../core/UserContext";
import Moment from "moment";
import uniqid from "uniqid";
import CustomNumberInput from "../core/components/custom-number-input/CustomNumberInput";
// import lodash from "lodash";
// Axios
import {
  axios,
  saveUserDataInLocalStorage,
  getUserDataFromLocalStorage,
  getCountries,
  Buffer,
} from "../core/utils";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const KYC = () => {
  const countries = getCountries();
  const { userData, setUserData } = useContext(UserContext);
  const userDataStorage = getUserDataFromLocalStorage();
  const axiosPrivate = useAxiosPrivate(userDataStorage);
  const [jointLifeDetails, setJointLifeDetails] = useState();
  const [isVerifyingDetails, setIsVerifyingDetails] = useState(false);
  const [error, setError] = useState();
  const [occupations, setOccupations] = useState([]);
  const [salaryRanges, setSalaryRanges] = useState([]);
  const [declarationQuestions, setDeclarationQuestions] = useState([]);
  const [pepQuestions, setPepQuestions] = useState([]);
  const [fatcaQuestions, setFatcaQuestions] = useState([]);
  const [pepQuestionsJoint, setPepQuestionsJoint] = useState([]);
  const [fatcaQuestionsJoint, setFatcaQuestionsJoint] = useState([]);
  const [educationalQualifications, setEducationQualifications] = useState([]);
  const [quotationId, setQuotationId] = useState();
  const [quotation, setQuotation] = useState();
  const [declarationsForMainLife, setDeclarationsForMainLife] = useState();
  const [declarationsForMainLifeNotSaved, setDeclarationsForMainLifeNotSaved] =
    useState();
  const [verificationUrl, setVerificationUrl] = useState();
  const [declarationsForJointLife, setDeclarationsForJointLife] = useState();
  const [
    declarationsForJointLifeNotSaved,
    setDeclarationsForJointLifeNotSaved,
  ] = useState();
  const [verificationUrlForJointLife, setVerificationUrlForJointLife] =
    useState();
  const phoneNumberRef = useRef();
  // const [hasDeclaration ]
  const [updateSuccessful, setUpdateSuccessful] = useState();
  const [updatingQuotationStatus, setUpdatingQuotationStatus] = useState();

  const [mainLifeState, setMainLifeState] = useState({
    firstname: "",
    lastname: "",
    email: "",
    title: "",
    birth: "",
    occupationId: "",
    salaryrangeId: "",
    educationId: "",
    awardingInstitution: "",
    nic: "",
    phoneNumber: "",
  });
  const [jointLifeState, setJointLifeState] = useState({
    firstname: "",
    lastname: "",
    email: "",
    title: "",
    birth: "",
    occupationId: "",
    salaryrangeId: "",
    educationId: "",
    awardingInstitution: "",
    nic: "",
    phoneNumber: "",
  });

  const checkKycStatus = async () => {
    // const url = process.env.REACT_APP_API_URL + `/declaration/active`;
    // const data = getUserDataFromLocalStorage();
    // const token = data?.access_token;

    setTimeout(() => {}, 10000);
  };

  const validateKycDetailsMainLife = async () => {
    const quotationId = quotation?.id;
    const redirect_url = `${window.location.origin}/shufti-redirect?quotation=${quotationId}`;
    const callback_url = `https://ila-onlinesalesplatform-api.herokuapp.com/kyc/callback`;
    // const callback_url = process.env.REACT_APP_API_URL + `/kyc/callback`;
    // const callback_url = `https://webhook.site/3edfbe77-1fba-4c05-8e04-de4261eb602a`;

    const token = Buffer.from(process.env.REACT_APP_SHUFTI_KEY).toString(
      "base64"
    );
    const url = "https://api.shuftipro.com/";
    const userDetails = getUserDataFromLocalStorage();
    let reference = `${uniqid()}-${userDetails.data.id}`;

    setError(null);
    // // debugger;
    setIsVerifyingDetails(true);
    const payload = {
      reference,
      callback_url,
      email: mainLifeState.email,
      country: mainLifeState.country,
      language: "EN",
      redirect_url,
      verification_mode: "any",
      face: {},
      document: {
        proof: "",
        supported_types: ["id_card", "driving_license", "passport"],
        name: {
          first_name: mainLifeState.firstname,
          last_name: mainLifeState.lastname,
        },
        dob: new Moment(mainLifeState.birth).format("YYYY-MM-DD"),
        issue_date: "",
        expiry_date: "",
        document_number: "",
      },
      address: {
        proof: "",
        supported_types: ["utility_bill", "bank_statement"],
        name: {
          first_name: mainLifeState.firstname,
          last_name: mainLifeState.lastname,
        },
        issue_date: "",
        full_address: "",
        address_fuzzy_match: "1",
      },
      background_checks: {
        name: {
          full_name: `${mainLifeState.firstname} ${mainLifeState.lastname}`,
        },
        fuzzy_match: "1",
        dob: new Moment(mainLifeState.birth).format("YYYY-MM-DD"),
      },
    };
    try {
      const response = await axios.post(url, payload, {
        headers: {
          Authorization: `Basic ${token}`,
        },
      });

      if (response?.data?.verification_url) {
        // window.location.href = response.data.verification_url;
        setVerificationUrl(response.data.verification_url);
        setCurrentAction("shufti-validation-mainlife");
        updateQuotationStatus({ status: "review" });
        setError(null);
      } else {
        setError("Could not start verification process");
      }

      setIsVerifyingDetails(false);
      // console.log(response);
    } catch (err) {
      setIsVerifyingDetails(false);
      setError("Could not start verification process");
      // console.log(err);
    }
  };
  const validateKycDetailsJointLife = async () => {
    const quotationId = quotation?.id;
    const redirect_url = `${window.location.origin}/shufti-redirect?quotation=${quotationId}`;
    const callback_url = `https://ila-onlinesalesplatform-api.herokuapp.com/kyc/callback`;
    // const callback_url = process.env.REACT_APP_API_URL + `/kyc/callback`;
    // const callback_url = `https://webhook.site/3edfbe77-1fba-4c05-8e04-de4261eb602a`;

    const token = Buffer.from(process.env.REACT_APP_SHUFTI_KEY).toString(
      "base64"
    );
    const url = "https://api.shuftipro.com/";
    let reference = `${uniqid()}-${quotation?.userJoint?.id}`;

    setError(null);
    // // debugger;
    setIsVerifyingDetails(true);
    const payload = {
      reference,
      callback_url,
      email: jointLifeState.email,
      country: jointLifeState.country,
      language: "EN",
      redirect_url,
      verification_mode: "any",
      face: {},
      document: {
        proof: "",
        supported_types: ["id_card", "driving_license", "passport"],
        name: {
          first_name: jointLifeState.firstname,
          last_name: jointLifeState.lastname,
        },
        dob: new Moment(jointLifeState.birth).format("YYYY-MM-DD"),
        issue_date: "",
        expiry_date: "",
        document_number: "",
      },
      address: {
        proof: "",
        supported_types: ["utility_bill", "bank_statement"],
        name: {
          first_name: jointLifeState.firstname,
          last_name: jointLifeState.lastname,
        },
        issue_date: "",
        full_address: "",
        address_fuzzy_match: "1",
      },
      background_checks: {
        name: {
          first_name: jointLifeState.firstname,
          middle_name: "",
          last_name: jointLifeState.lastname,
        },
        fuzzy_match: "1",
        dob: new Moment(jointLifeState.birth).format("YYYY-MM-DD"),
      },
    };
    try {
      const response = await axios.post(url, payload, {
        headers: {
          Authorization: `Basic ${token}`,
        },
      });

      if (response?.data?.verification_url) {
        // window.location.href = response.data.verification_url;
        setVerificationUrlForJointLife(response.data.verification_url);
        setCurrentAction("shufti-validation-jointlife");

        updateQuotationStatus({ status: "review" });

        setError(null);
      } else {
        setError("Could not start verification process");
      }

      setIsVerifyingDetails(false);
      // console.log(response);
    } catch (err) {
      setIsVerifyingDetails(false);
      setError("Could not start verification process");
      // console.log(err);
    }
  };

  const getPaymentChannels = () => {};

  const getDeclarationQuestions = async () => {
    const url = process.env.REACT_APP_API_URL + `/declaration/active`;

    try {
      const response = await axiosPrivate.post(url, { active: true });

      setDeclarationQuestions(response.data.data);
      setPepQuestions(
        response.data.data
          .map((item) => {
            item.isAccepted = null;
            return item;
          })
          .filter((item) => item.type === "PEP")
      );
      setPepQuestionsJoint(
        response.data.data
          .map((item) => {
            item.isAccepted = null;
            return item;
          })
          .filter((item) => item.type === "PEP")
      );
      setFatcaQuestions(
        response.data.data
          .map((item) => {
            item.isAccepted = null;
            return item;
          })
          .filter((item) => item.type === "FATCA")
      );
      setFatcaQuestionsJoint(
        response.data.data
          .map((item) => {
            item.isAccepted = null;
            return item;
          })
          .filter((item) => item.type === "FATCA")
      );
    } catch (err) {
      // console.log(err);
    }
  };

  const getDeclarationsforMainLife = async () => {
    // /user/declarations/{userId}
    const data = getUserDataFromLocalStorage();
    const url =
      process.env.REACT_APP_API_URL + `/user/declarations/${data.data.id}`;

    try {
      const response = await axiosPrivate.get(url);

      // console.log(response.data.data.Declarations);

      setDeclarationsForMainLife(response.data.data.Declarations);
      setDeclarationsForMainLifeNotSaved(
        response.data.data.Declarations.length === 0
      );
    } catch (err) {
      // console.log(err);
    }
  };

  const getDeclarationsforJointLife = async () => {
    // /user/declarations/{userId}
    const userId = quotation?.userJoint;
    const url = process.env.REACT_APP_API_URL + `/user/declarations/${userId}`;

    try {
      const response = await axiosPrivate.get(url);

      // console.log(response.data.data.Declarations);

      setDeclarationsForJointLife(response.data.data.Declarations);
      setDeclarationsForJointLifeNotSaved(
        response.data.data.Declarations.length === 0
      );
    } catch (err) {
      // console.log(err);
    }
  };

  const saveDeclarationsMainLife = async () => {
    // /user/create/declaration
    // const id = params.quotationId;
    const url = process.env.REACT_APP_API_URL + `/user/create/declaration`;
    const allDeclarationQuestions = [...pepQuestions, ...fatcaQuestions];

    for (let i = 0; i < allDeclarationQuestions.length; i++) {
      try {
        const userDetails = getUserDataFromLocalStorage();
        const payload = {
          userId: userDetails.data.id,
          declarationId: allDeclarationQuestions[i].id,
          consent: allDeclarationQuestions[i].hasAccepted,
        };

        const response = await axiosPrivate.post(url, payload);

        if (response.data.success === true) {
        }
        // console.log(response);
      } catch (error) {
        // console.log(error);
      }
    }
  };

  const saveDeclarationsJointLife = async () => {
    // /user/create/declaration
    // const id = params.quotationId;
    const url = process.env.REACT_APP_API_URL + `/user/create/declaration`;
    const allDeclarationQuestions = [
      ...pepQuestionsJoint,
      ...fatcaQuestionsJoint,
    ];

    for (let i = 0; i < allDeclarationQuestions.length; i++) {
      try {
        const payload = {
          userId: quotation?.userJoint?.id,
          declarationId: allDeclarationQuestions[i].id,
          consent: allDeclarationQuestions[i].hasAccepted,
        };

        const response = await axiosPrivate.post(url, payload);

        if (response.data.success === true) {
        }
        // console.log(response);
      } catch (error) {
        // console.log(error);
      }
    }
  };

  const validateMainLifeDetailsAndProceed = async () => {
    const mainLifeUserKeys = Object.keys(mainLifeState);
    // console.log(mainLifeState);
    for (let i = 0; i < mainLifeUserKeys.length; i++) {
      if (
        mainLifeState[mainLifeUserKeys[i]] === "" ||
        mainLifeState[mainLifeUserKeys[i]] == null ||
        mainLifeState[mainLifeUserKeys[i]] === undefined
      ) {
        // // debugger;
        setError("Please fill all details");
        return;
      }
    }

    if (declarationsForMainLifeNotSaved) {
      for (let i = 0; i < pepQuestions.length; i++) {
        if (
          pepQuestions[i].hasAccepted == null ||
          pepQuestions[i].hasAccepted === undefined
        ) {
          setError("Please answer all PEP Questions");
          return;
        }
      }

      for (let i = 0; i < fatcaQuestions.length; i++) {
        if (
          fatcaQuestions[i].hasAccepted == null ||
          fatcaQuestions[i].hasAccepted === undefined
        ) {
          setError("Please answer all FATCA Questions");
          return;
        }
      }
    }

    setError(null);
    try {
      await validateKycDetailsMainLife();
    } catch (err) {
      // console.log(err);
    }

    updateProfileDetailsMainLife();

    // console.log(mainLifeState);
  };

  const validateJointLifeDetailsAndProceed = async () => {
    const jointLifeUserKeys = Object.keys(jointLifeState);
    // console.log(jointLifeState);
    for (let i = 0; i < jointLifeUserKeys.length; i++) {
      if (
        jointLifeState[jointLifeUserKeys[i]] === "" ||
        jointLifeState[jointLifeUserKeys[i]] == null ||
        jointLifeState[jointLifeUserKeys[i]] === undefined
      ) {
        // // debugger;
        setError("Please fill all details");
        return;
      }
    }

    if (declarationsForJointLifeNotSaved) {
      for (let i = 0; i < pepQuestionsJoint.length; i++) {
        if (
          pepQuestionsJoint[i].hasAccepted == null ||
          pepQuestionsJoint[i].hasAccepted === undefined
        ) {
          setError("Please answer all PEP Questions");
          return;
        }
      }

      for (let i = 0; i < fatcaQuestionsJoint.length; i++) {
        if (
          fatcaQuestionsJoint[i].hasAccepted == null ||
          fatcaQuestionsJoint[i].hasAccepted === undefined
        ) {
          setError("Please answer all FATCA Questions");
          return;
        }
      }
    }

    setError(null);
    try {
      await validateKycDetailsJointLife();
    } catch (err) {
      // console.log(err);
    }

    updateProfileDetailsJointLife();

    // console.log(jointLifeState);
  };
  const [currentAction, setCurrentAction] = useState("initial");
  let userDetails = getUserDataFromLocalStorage();
  const params = useParams();

  const getQuotationDetails = async () => {
    const id = params.quotationId;
    const url = process.env.REACT_APP_API_URL + `/quotation/${id}`;

    try {
      const response = await axiosPrivate.get(url);

      if (response.data.success === true) {
        const _quotation = response.data.data;
        _quotation.quotationFormData = JSON.parse(_quotation.quotationFormData);

        // getJointLifeDetails(_quotation.quotationFormData.userJointId);
        setQuotation(_quotation);

        // debugger;
        if (response.data.data.userJoint) {
          const userDetails = response.data.data.userJoint;
          const firstname = userDetails ? userDetails?.name?.split(" ")[0] : "";
          const lastname = userDetails ? userDetails?.name?.split(" ")[1] : "";
          const email = userDetails ? userDetails?.email : "";
          const title = userDetails ? userDetails?.title : "";
          const phoneNumber = userDetails ? userDetails?.phoneNumber : "";
          const gender = userDetails ? userDetails?.gender : "";
          const birth = userDetails ? new Moment(userDetails?.birth) : "";
          const country = userDetails ? userDetails?.country : "";
          const occupationId = userDetails ? userDetails?.occupationId : "";
          const salaryrangeId = userDetails ? userDetails?.salaryrangeId : "";
          const educationId = userDetails ? userDetails?.educationId : "";
          const nic = userDetails ? userDetails?.nic : "";

          const awardingInstitution = userDetails
            ? userDetails?.awardingInstitution
            : "";
          // setKycValidation(data.kycVerification);

          setJointLifeState({
            firstname,
            lastname,
            title,
            email,
            phoneNumber,
            gender,
            birth,
            country,
            occupationId,
            salaryrangeId,
            educationId,
            awardingInstitution,
            nic,
          });
        }
      }
      // console.log(response);
    } catch (error) {
      // console.log(error);
    }
  };

  const getOccupations = async () => {
    const url = process.env.REACT_APP_API_URL + `/occupation`;

    try {
      const response = await axiosPrivate.get(url);
      setOccupations(response.data.data);
    } catch (err) {
      // console.log(err);
    }
  };

  const getSalaryRanges = async () => {
    const url = process.env.REACT_APP_API_URL + `/salary-ranges`;

    try {
      const response = await axiosPrivate.get(url);
      setSalaryRanges(response.data.data);
    } catch (err) {
      // console.log(err);
    }
  };

  const getEducations = async () => {
    const url = process.env.REACT_APP_API_URL + `/education`;

    try {
      const response = await axiosPrivate.get(url);
      setEducationQualifications(response.data.data);
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    getDeclarationsforMainLife();
    setQuotationId(params.quotationId);
    getQuotationDetails();
    getSalaryRanges();
    getEducations();
    getOccupations();
    getDeclarationQuestions();
    const firstname = userDetails ? userDetails?.data?.name?.split(" ")[0] : "";
    const lastname = userDetails ? userDetails?.data?.name?.split(" ")[1] : "";
    const email = userDetails ? userDetails?.data?.email : "";
    const title = userDetails ? userDetails?.data?.title : "";
    const phoneNumber = userDetails ? userDetails?.data?.phoneNumber : "";
    const gender = userDetails ? userDetails?.data?.gender : "";
    const birth = userDetails ? new Moment(userDetails?.data?.birth) : "";
    const country = userDetails ? userDetails?.data?.country : "";
    const occupationId = userDetails ? userDetails?.data?.occupationId : "";
    const salaryrangeId = userDetails ? userDetails?.data?.salaryrangeId : "";
    const educationId = userDetails ? userDetails?.data?.educationId : "";
    const nic = userDetails ? userDetails?.data?.nic : "";

    const awardingInstitution = userDetails
      ? userDetails?.data?.awardingInstitution
      : "";
    // setKycValidation(data.kycVerification);

    setMainLifeState({
      firstname,
      lastname,
      title,
      email,
      phoneNumber,
      gender,
      birth,
      country,
      occupationId,
      salaryrangeId,
      educationId,
      awardingInstitution,
      nic,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateProfileDetailsMainLife = async () => {
    setError(null);
    const url = process.env.REACT_APP_API_URL + `/user/update-details`;
    const payload = {
      ...mainLifeState,
      name: `${mainLifeState.firstname} ${mainLifeState.lastname}`,
      occupationId: parseInt(mainLifeState.occupationId, 10),
      educationId: parseInt(mainLifeState.educationId, 10),
      salaryrangeId: parseInt(mainLifeState.salaryrangeId, 10),
      nic: mainLifeState.nic,
    };
    delete payload.firstname;
    delete payload.lastname;
    delete payload.email;
    delete payload.phoneNumber;

    setIsVerifyingDetails(true);

    try {
      const response = await axiosPrivate.patch(url, payload);
      if (declarationsForMainLife && declarationsForMainLifeNotSaved) {
        await saveDeclarationsMainLife();
      }
      setIsVerifyingDetails(false);
      saveDetailsLocally();
      setUpdateSuccessful(true);
      // console.log(response);
    } catch (err) {
      setIsVerifyingDetails(false);
      setError("Could update user details");
      // console.log(err);
    }
  };
  const updateProfileDetailsJointLife = async () => {
    setError(null);
    const url = process.env.REACT_APP_API_URL + `/user/update-details`;
    const payload = {
      ...jointLifeState,
      name: `${jointLifeState.firstname} ${jointLifeState.lastname}`,
      occupationId: parseInt(jointLifeState.occupationId, 10),
      educationId: parseInt(jointLifeState.educationId, 10),
      salaryrangeId: parseInt(jointLifeState.salaryrangeId, 10),
      nic: jointLifeState.nic,
    };
    delete payload.firstname;
    delete payload.lastname;
    delete payload.email;
    delete payload.phoneNumber;

    setIsVerifyingDetails(true);
    try {
      const response = await axiosPrivate.patch(url, payload);
      if (declarationsForJointLife && declarationsForJointLifeNotSaved) {
        await saveDeclarationsJointLife();
      }
      setIsVerifyingDetails(false);
      saveDetailsLocally();
      setUpdateSuccessful(true);
      // console.log(response);
    } catch (err) {
      setIsVerifyingDetails(false);
      setError("Could update joint user details");
      // console.log(err);
    }
  };

  const updateQuotationStatus = async (payload) => {
    setUpdatingQuotationStatus(true);
    const url =
      process.env.REACT_APP_API_URL +
      `/quotation/worker/status-update/${quotationId}`;

    try {
      payload = { ...payload, userId: quotation?.user?.id };
      const { data } = await axiosPrivate.patch(url, payload);

      setUpdatingQuotationStatus(false);

      if (!data.success) {
        // console.log(
        //   data.message
        //     ? data.message
        //     : "Quotation update was unsuccessful, please try again"
        // );
      }
    } catch (error) {
      setUpdatingQuotationStatus(false);
      // console.log(error);
    }
  };

  const saveDetailsLocally = () => {
    const data = getUserDataFromLocalStorage();
    data.data = {
      ...data.data,
      ...mainLifeState,
      name: `${mainLifeState.firstname} ${mainLifeState.lastname}`,
    };
    saveUserDataInLocalStorage(data);
  };

  const getJointLifeDetails = async (userId) => {
    // /user/{id}
    const url = process.env.REACT_APP_API_URL + `/user/${userId}`;

    try {
      const response = await axiosPrivate.get(url);
      if (response.data.success === true) {
        const jointLifeDetails = response.data.data;
        setJointLifeDetails(jointLifeDetails);
      }
      // console.log(response);
    } catch (error) {
      // console.log(error);
    }
  };

  // const simulateFailedCallbackRequest = async () => {
  //   const url = process.env.REACT_APP_API_URL + `/kyc/callback`;
  //   const userData = getUserDataFromLocalStorage();
  //   const token = userData?.access_token;
  //   const reference = `${uniqid()}-${userData.data.id}`;
  //   let payload = {
  //     reference,
  //     event: "verification.declined",
  //     email: mainLifeState.email,
  //     country: mainLifeState.country,
  //     verification_data: {
  //       document: {
  //         name: {
  //           first_name: mainLifeState.firstname,
  //           last_name: mainLifeState.lastname,
  //         },
  //         dob: "1991-12-27",
  //         expiry_date: "2031-01-01",
  //         issue_date: "2021-01-02",
  //         document_number: "O12233445",
  //         country: "GH",
  //         selected_type: ["passport"],
  //         supported_types: ["id_card", "driving_license", "passport"],
  //       },
  //       address: {
  //         name: {
  //           first_name: mainLifeState.firstname,
  //           last_name: mainLifeState.lastname,
  //         },
  //         full_address: "10 DOWNING ST, WESTMINSTER, LONDON SW1A 2AA, UK",
  //         issue_date: "2022-10-03",
  //         country: "GH",
  //         selected_type: ["utility_bill"],
  //         supported_types: ["utility_bill", "bank_statement"],
  //       },
  //       background_checks: {
  //         dob: "1991-12-27",
  //         name: {
  //           full_name: "undefined undefined",
  //         },
  //       },
  //     },
  //     verification_result: {
  //       face: null,
  //       document: {
  //         selected_type: 1,
  //         document: 1,
  //         document_must_not_be_expired: 1,
  //         document_visibility: 1,
  //         document_proof: null,
  //         face_on_document_matched: null,
  //         name: 0,
  //         dob: null,
  //         issue_date: 1,
  //         expiry_date: 1,
  //         document_number: 1,
  //       },
  //       address: {
  //         selected_type: null,
  //         address_document: 1,
  //         address_document_must_not_be_expired: null,
  //         address_document_visibility: null,
  //         address_document_proof: null,
  //         match_address_proofs_with_document_proofs: null,
  //         name: null,
  //         issue_date: 1,
  //         full_address: null,
  //       },
  //       background_checks: 1,
  //     },
  //     info: {
  //       agent: {
  //         is_desktop: true,
  //         is_phone: false,
  //         device_name: "Macintosh",
  //         browser_name: "Chrome 106.0.0.0",
  //         platform_name: "Mac OS 10.15.7",
  //       },
  //       geolocation: {
  //         host: "102-176-94-38-dedicated.vodafone.com.gh",
  //         ip: "102.176.94.38",
  //         rdns: "102.176.94.38",
  //         asn: "29614",
  //         isp: "Ghana Telecom",
  //         country_name: "Ghana",
  //         country_code: "GH",
  //         region_name: "Greater Accra",
  //         region_code: "AA",
  //         city: "Accra",
  //         postal_code: "",
  //         continent_name: "Africa",
  //         continent_code: "AF",
  //         latitude: "5.5444397926331",
  //         longitude: "-0.1958300024271",
  //         metro_code: "",
  //         timezone: "Africa/Accra",
  //         ip_type: "ipv4",
  //         capital: "Accra",
  //         currency: "GHS",
  //       },
  //     },
  //     declined_reason: "Name on the document doesn't match",
  //     declined_codes: ["SPDR07"],
  //     services_declined_codes: {
  //       document: ["SPDR07"],
  //     },
  //   };

  //   try {
  //     const response = await axios.post(url, payload, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     if (response.data.success == true) {
  //       const jointLifeDetails = response.data.data;
  //       setJointLifeDetails(jointLifeDetails);
  //     }
  //     console.log(response);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const simulateInitializationCallbackRequest = async () => {
  //   const url = process.env.REACT_APP_API_URL + `/kyc/callback`;
  //   const userData = getUserDataFromLocalStorage();
  //   const token = userData?.access_token;
  //   const reference = `${uniqid()}-${userData.data.id}`;
  //   let payload = {
  //     reference,
  //     event: "request.pending",
  //     verification_url:
  //       "https://app.shuftipro.com/verification/process/nSGwUkUr14GiO5KRgFxuxzZdhaGrKx1xqdtPsqzfqQ6fD6XvcGYjhfrXVa02N7Zl",
  //     email: mainLifeState.email,
  //     country: mainLifeState.country,
  //   };

  //   try {
  //     const response = await axios.post(url, payload, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     if (response.data.success == true) {
  //     }
  //     console.log(response);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const simulateSuccessCallbackRequest = async () => {
  //   const url = process.env.REACT_APP_API_URL + `/kyc/callback`;
  //   const userData = getUserDataFromLocalStorage();
  //   const token = userData?.access_token;
  //   const reference = `${uniqid()}-${userData.data.id}`;
  //   let payload = {
  //     reference,
  //     event: "verification.accepted",
  //     country: "GB",
  //     proofs: {
  //       face: {
  //         proof:
  //           "https://api.shuftipro.com/storage/aZa8mncOFLrbtxXk7Ka/face/proof.png?access_token=1a6c9985e88051092b31d62d043",
  //       },
  //       document: {
  //         proof:
  //           "https://api.shuftipro.com/storage/aZa8mncOFLrbtxXk7Ka/document/proof.png?access_token=1a6c9985e88051092b31d62d043",
  //       },
  //       documenttwo: {
  //         proof:
  //           "https://api.shuftipro.com/storage/aZa8mncOFLrbtxXk7Ka/documenttwo/proof.png?access_token=1a6c9985e88051092b31d62d043",
  //       },
  //       address: {
  //         proof:
  //           "https://api.shuftipro.com/storage/aZa8mncOFLrbtxXk7Ka/address/proof.png?access_token=1a6c9985e88051092b31d62d043",
  //       },
  //       consent: {
  //         proof:
  //           "https://api.shuftipro.com/storage/aZa8mncOFLrbtxXk7Ka/consent/proof.png?access_token=1a6c9985e88051092b31d62d043",
  //       },
  //       verification_video:
  //         "https://api.shuftipro.com/storage/aZa8mncOFLrbtxXk7Ka/video/video.mp4?access_token=3242f486e3346db90f442c9ac06a4ad1771ead3c",
  //     },
  //     verification_data: {
  //       document: {
  //         issue_date: "1990-01-01",
  //         selected_type: ["id_card"],
  //         supported_types: [
  //           "id_card",
  //           "passport",
  //           "driving_license",
  //           "credit_or_debit_card",
  //         ],
  //         face_match_confidence: 70,
  //       },
  //       document_two: {
  //         dob: "1990-01-01",
  //         selected_type: ["id_card"],
  //         supported_types: [
  //           "id_card",
  //           "passport",
  //           "driving_license",
  //           "credit_or_debit_card",
  //         ],
  //       },
  //       address: {
  //         full_address: "Candyland Avenue",
  //         selected_type: ["utility_bill"],
  //         supported_types: [
  //           "utility_bill",
  //           "driving_license",
  //           "bank_statement",
  //           "envelope",
  //           "id_card",
  //           "passport",
  //           "rent_agreement",
  //           "employer_letter",
  //           "insurance_agreement",
  //           "tax_bill",
  //         ],
  //       },
  //       consent: {
  //         text: "My name is John Doe and I authorise this transaction of $100/- Date: July 15, 2020",
  //         selected_type: ["handwritten"],
  //         supported_types: ["handwritten", "printed"],
  //       },
  //       background_checks: {
  //         dob: "1990-01-01",
  //         name: {
  //           last_name: "John",
  //           first_name: "Doe",
  //         },
  //       },
  //       aml_for_business: {
  //         business_name: "Company Name",
  //         business_incorporation_date: "1975-07-01",
  //         aml_data: {
  //           filters: [
  //             "sanction",
  //             "warning",
  //             "fitness-probity",
  //             "pep",
  //             "pep-class-1",
  //             "pep-class-2",
  //             "pep-class-3",
  //             "pep-class-4",
  //           ],
  //           hits: [
  //             {
  //               name: "Company Name",
  //               entity_type: "organisation",
  //               score: 24.911245,
  //               match_types: ["name_exact"],
  //               alternative_names: ["Company Name"],
  //               assets: [
  //                 {
  //                   public_url:
  //                     "http://example.s3.amazonaws.com/834refjadfkjhq4-ahfdkddfa8a.pdf",
  //                   source:
  //                     "us-securities-exchange-commission-litigation-releases",
  //                   type: "pdf",
  //                 },
  //               ],
  //               associates: [],
  //               fields: {
  //                 "Activation Date": [
  //                   {
  //                     value: "Jul. 17, 2019",
  //                     source:
  //                       "united-states-securities-and-exchange-commission-administrative-proceedings-organisation",
  //                     tag: "",
  //                   },
  //                 ],
  //                 "Adverse Media Subtypes": [
  //                   {
  //                     value:
  //                       "antitrust, corporate-fraud, corruption, fines, insider-trading, litigation, money-laundering, other-crime, security, tax-evasion",
  //                     source: "company-am",
  //                     tag: "",
  //                   },
  //                 ],
  //                 Date: [
  //                   {
  //                     value: "2002-08-03",
  //                     source:
  //                       "us-securities-exchange-commission-litigation-releases",
  //                     tag: "",
  //                   },
  //                 ],
  //                 "Enforcement Agency": [
  //                   {
  //                     value: "United States Securities and Exchange Commission",
  //                     source:
  //                       "united-states-securities-and-exchange-commission-administrative-proceedings-organisation",
  //                     tag: "",
  //                   },
  //                 ],
  //                 "Related URL": [
  //                   {
  //                     value: "http://www.sec.gov/litigation/link423.shtml",
  //                     source:
  //                       "us-securities-exchange-commission-litigation-releases",
  //                     tag: "related_url",
  //                   },
  //                   {
  //                     value:
  //                       "https://www.sec.gov/litigation/admin/2019/34-72891heq89.pdf",
  //                     source:
  //                       "united-states-securities-and-exchange-commission-administrative-proceedings-organisation",
  //                     tag: "related_url",
  //                   },
  //                 ],
  //                 Release: [
  //                   {
  //                     value: "Release information about company...",
  //                     source:
  //                       "us-securities-exchange-commission-litigation-releases",
  //                     tag: "",
  //                   },
  //                 ],
  //                 "Release Number": [
  //                   {
  //                     value: "34-46017",
  //                     source:
  //                       "us-securities-exchange-commission-litigation-releases",
  //                     tag: "",
  //                   },
  //                 ],
  //                 "Related Url": [
  //                   {
  //                     value:
  //                       "http://www.sec.gov/litigation/admin/34-234324.htm",
  //                     source:
  //                       "us-securities-exchange-commission-litigation-releases",
  //                     tag: "related_url",
  //                   },
  //                 ],
  //               },
  //               media: [
  //                 {
  //                   date: "2019-09-15T00:00:00Z",
  //                   snippet:
  //                     "The NYPD said 12 men and 75 women were charged with obstructing traffic after blocking cars outside the company's flagship shop on 5th Avenue between 53rd and 54th streets on Satur day",
  //                   title:
  //                     "Dozens of ICE Protesters Arrested in Midtown - American Renaissance",
  //                   url: "https://www.amren.com/news/2019/23/dozens-of-ice-protesters-arrtedaddsa-in-midtown/",
  //                 },
  //                 {
  //                   date: "2019-09-15T00:00:00Z",
  //                   snippet:
  //                     "The NYPD said 12 men and 75 women were charged with obstructing traffic after blocking cars outside the company's flagship shop on 5th Avenue between 53rd and 54th streets on Satur day",
  //                   title: "Dozens of ICE protesters arrested in Midtown",
  //                   url: "https://nypost.com/2019/10/23/dozens-of-ice-asdf-asdfd-in-midtown/",
  //                 },
  //               ],
  //               source_notes: {
  //                 "company-am": {
  //                   aml_types: [
  //                     "adverse-media",
  //                     "adverse-media-financial-crime",
  //                     "adverse-media-fraud",
  //                     "adverse-media-general",
  //                   ],
  //                   name: "company AM",
  //                 },
  //                 "united-states-securities-and-exchange-commission-administrative-proceedings-organisation":
  //                   {
  //                     aml_types: ["warning"],
  //                     listing_started_utc: "2019-07-25T00:00:00Z",
  //                     name: "United States Securities and Exchange Commission Administrative Proceedings - organisation",
  //                     url: "https://www.sec.gov/litigation/asddfa.shtml",
  //                   },
  //                 "us-securities-exchange-commission-litigation-releases": {
  //                   aml_types: ["warning"],
  //                   listing_ended_utc: "2016-12-22T00:00:00Z",
  //                   name: "Warnings USA SEC Litigation Releases",
  //                   url: "http://www.sec.gov/litigation/asdfasdf.shtml",
  //                 },
  //               },
  //               sources: [
  //                 "company-am",
  //                 "united-states-securities-and-exchange-commission-administrative-proceedings-organisation",
  //                 "us-securities-exchange-commission-litigation-releases",
  //               ],
  //               types: [
  //                 "adverse-media",
  //                 "adverse-media-financial-crime",
  //                 "adverse-media-fraud",
  //                 "adverse-media-general",
  //                 "warning",
  //               ],
  //             },
  //           ],
  //         },
  //       },
  //     },
  //     verification_result: {
  //       background_checks: 1,
  //       consent: {
  //         consent: 1,
  //         selected_type: 1,
  //       },
  //       address: {
  //         partial_address_match_with_id_and_utility_bill: 1,
  //         full_address: 1,
  //         address_document_visibility: 1,
  //         address_document_must_not_be_expired: 1,
  //         address_document: 1,
  //         address_document_country: 1,
  //         selected_type: 1,
  //       },
  //       document: {
  //         issue_date: 1,
  //         document_visibility: 1,
  //         document_must_not_be_expired: 1,
  //         document: 1,
  //         document_country: 1,
  //         selected_type: 1,
  //       },
  //       document_two: {
  //         dob: 1,
  //         document_visibility: 1,
  //         document_must_not_be_expired: 1,
  //         document: 1,
  //         document_country: 1,
  //         selected_type: 1,
  //       },
  //       face: 1,
  //     },
  //     info: {
  //       agent: {
  //         is_desktop: true,
  //         is_phone: false,
  //         useragent:
  //           "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/75.0.3770.100 Safari/537.36",
  //         device_name: "Macintosh",
  //         browser_name: "",
  //         platform_name: "OS X - 10_14_0",
  //       },
  //       geolocation: {
  //         host: "212.103.50.243",
  //         ip: "212.103.50.243",
  //         rdns: "212.103.50.243",
  //         asn: "9009",
  //         isp: "M247 Ltd",
  //         country_name: "Germany",
  //         country_code: "DE",
  //         region_name: "Hesse",
  //         region_code: "HE",
  //         city: "Frankfurt am Main",
  //         postal_code: "60326",
  //         continent_name: "Europe",
  //         continent_code: "EU",
  //         latitude: "50.1049",
  //         longitude: "8.6295",
  //         metro_code: "",
  //         timezone: "Europe/Berlin",
  //       },
  //     },
  //     additional_data: {
  //       document: {
  //         proof: {
  //           gender: "M",
  //           height: "183",
  //           country: "United Kingdom",
  //           authority: "HMPO",
  //           last_name: "Doe",
  //           first_name: "John",
  //           issue_date: "2018-01-31",
  //           expiry_date: "2028-01-30",
  //           nationality: "BRITSH CITIZEN",
  //           country_code: "GBR",
  //           document_type: "P",
  //           place_of_birth: "BRISTOL",
  //           document_number: "GB1234567",
  //           personal_number: "12345678910",
  //           dob: "1978-03-13",
  //           issue_date: "2015-10-10",
  //           expiry_date: "2025-12-31",
  //         },
  //       },
  //     },
  //   };

  //   try {
  //     const response = await axios.post(url, payload, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     if (response.data.success == true) {
  //       userData.data.kycVerification = true;
  //       saveUserDataInLocalStorage(userData);
  //       updateQuotationStatus({ status: "review" });
  //       // initial, accepted, underwriting, kyc, review, firstpayment, recurring-payment, expired
  //     }
  //     console.log(response);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getKycStatuses = () => {
    if (quotation?.quotationFormData?.join_loan) {
      return (
        <>
          {!quotation?.user?.kycVerification && (
            <div className="alert-danger alert d-flex align-items-center ">
              <i className="fa fa-warning me-2"></i>
              <div className="d-flex align-items-center justify-content-between w-100">
                <span>Main Life KYC has not been verified</span>
                <button
                  className="btn btn-dark"
                  onClick={() => {
                    setCurrentAction("show-form-for-main-life");
                  }}
                >
                  Verify <i className="fa fa-arrow-right"></i>
                </button>
              </div>
            </div>
          )}
          {!quotation?.jointUser?.kycVerification && (
            <div className="alert-danger alert d-flex align-items-center ">
              <i className="fa fa-warning me-2"></i>
              <div className="d-flex align-items-center justify-content-between w-100">
                <span>Joint Life KYC has not been verified</span>
                <button
                  className="btn btn-dark"
                  onClick={() => {
                    setCurrentAction("show-form-for-joint-life");
                  }}
                >
                  Verify <i className="fa fa-arrow-right"></i>
                </button>
              </div>
            </div>
          )}
          {quotation?.user?.kycVerification && (
            <div className="card mt-5 alert-success alert">
              <div className="card-body">Main Life KYC has been verified</div>
            </div>
          )}
          {quotation?.jointUser?.kycVerification && (
            <div className="card mt-5 alert-success alert">
              <div className="card-body">Joint Life KYC has been verified</div>
            </div>
          )}
        </>
      );
    } else if (quotation?.quotationFormData?.join_loan === false) {
      if (!userDetails?.data?.kycVerification) {
        return (
          <>
            <div className="alert-danger alert d-flex align-items-center ">
              <i className="fa fa-warning me-2"></i>
              <div className="d-flex align-items-center justify-content-between w-100">
                <span>Main Life KYC has not been verified</span>
                <button
                  className="btn btn-dark"
                  onClick={() => {
                    setCurrentAction("show-form-for-main-life");
                  }}
                >
                  Verify <i className="fa fa-arrow-right"></i>
                </button>
              </div>
            </div>
          </>
        );
      } else {
        return (
          <div className="card mt-5 alert-success alert">
            <div className="card-body">Main Life KYC has been verified</div>
          </div>
        );
      }
    }
    return (
      <div
        style={{ marginTop: 150 }}
        className="d-flex flex-column justify-content-center align-items-center"
      >
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div>Please wait...</div>
      </div>
    );
  };

  return (
    <>
      <Navbar
        name={
          userData
            ? `${userData?.data?.firstname} ${userData?.data?.lastname}`
            : ""
        }
      />
      <div className="container">
        <h4 className="mt-4">KYC Process</h4>
        {currentAction === "initial" && getKycStatuses()}
        {currentAction === "show-form-for-main-life" &&
          declarationsForMainLife && (
            <div>
              {error && <div className="alert alert-danger">{error}</div>}
              <h5 className="mt-4">
                <i className=""></i>Fill confirm form and proceed
              </h5>
              <div className="mb-3 row">
                <label for="firstname" className="col-sm-2 col-form-label">
                  Title{" "}
                </label>
                <div className="col-sm-10">
                  <select
                    value={mainLifeState.title}
                    onChange={(event) => {
                      setMainLifeState({
                        ...mainLifeState,
                        title: event.target.value,
                      });
                    }}
                    className="form-select"
                    aria-label="Title Select"
                  >
                    <option value="Mr">Mr</option>
                    <option value="Ms">Ms</option>
                    <option value="Mrs">Mrs</option>
                    <option value="Miss">Miss</option>
                    <option value="Dr">Dr</option>
                  </select>
                </div>
              </div>
              <div className="mb-3 row">
                <label for="firstname" className="col-sm-2 col-form-label">
                  First Names
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    value={mainLifeState.firstname}
                    onChange={(event) => {
                      setMainLifeState({
                        ...mainLifeState,
                        firstname: event.target.value,
                      });
                    }}
                    className="form-control"
                    id="firstname"
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label for="lastname" className="col-sm-2 col-form-label">
                  Last name
                </label>
                <div className="col-sm-10">
                  <input
                    value={mainLifeState.lastname}
                    onChange={(event) => {
                      setMainLifeState({
                        ...mainLifeState,
                        lastname: event.target.value,
                      });
                    }}
                    type="text"
                    className="form-control"
                    id="lastname"
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label for="email" className="col-sm-2 col-form-label">
                  Email
                </label>
                <div className="col-sm-10">
                  <input
                    value={mainLifeState.email}
                    type="text"
                    disabled={true}
                    className="form-control"
                    id="email"
                  />
                </div>
              </div>
              <div className="form-group row mb-3">
                <label htmlFor="gender" className="col-sm-2  col-form-label">
                  Gender{" "}
                </label>
                <div className="col-sm-10 ">
                  <select
                    onChange={(event) => {
                      setMainLifeState({
                        ...mainLifeState,
                        gender: event.target.value,
                      });
                    }}
                    value={mainLifeState.gender}
                    className={`form-select`}
                  >
                    <option value="">Select...</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </div>
              </div>
              <div className="mb-3 row">
                <label for="email" className="col-sm-2 col-form-label">
                  Phone Number
                </label>
                <div className="col-sm-10">
                  <CustomNumberInput
                    ref={phoneNumberRef}
                    disabled={true}
                    errors={{}}
                    state={mainLifeState}
                    setState={setMainLifeState}
                  />
                </div>
              </div>
              <div className="form-group row mb-3">
                <label htmlFor="country" className="col-sm-2  col-form-label">
                  Country{" "}
                </label>
                <div className="col-sm-10 ">
                  <select
                    onChange={(event) => {
                      setMainLifeState({
                        ...mainLifeState,
                        country: event.target.value,
                      });
                    }}
                    value={mainLifeState.country}
                    className={`form-select`}
                  >
                    <option value="">Select...</option>
                    {countries.map((country) => (
                      <option value={country.code}>{country.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-group row mb-3">
                <label htmlFor="country" className="col-sm-2  col-form-label">
                  Occupation{" "}
                </label>
                <div className="col-sm-10 ">
                  <select
                    onChange={(event) => {
                      setMainLifeState({
                        ...mainLifeState,
                        occupationId: event.target.value,
                      });
                    }}
                    value={mainLifeState.occupationId}
                    className={`form-select`}
                  >
                    <option value="">Select...</option>
                    {occupations.map((occupation) => (
                      <option value={occupation.id}>{occupation.title}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-group row mb-3">
                <label htmlFor="country" className="col-sm-2  col-form-label">
                  Salary Range{" "}
                </label>
                <div className="col-sm-10 ">
                  <select
                    onChange={(event) => {
                      setMainLifeState({
                        ...mainLifeState,
                        salaryrangeId: event.target.value,
                      });
                    }}
                    value={mainLifeState.salaryrangeId}
                    className={`form-select`}
                  >
                    <option value="">Select...</option>
                    {salaryRanges.map((salaryRange) => (
                      <option value={salaryRange.id}>
                        {salaryRange.range}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-group row mb-3">
                <label htmlFor="country" className="col-sm-2  col-form-label">
                  Highest Educational Qualification{" "}
                </label>
                <div className="col-sm-10 ">
                  <select
                    onChange={(event) => {
                      setMainLifeState({
                        ...mainLifeState,
                        educationId: event.target.value,
                      });
                    }}
                    value={mainLifeState.educationId}
                    className={`form-select`}
                  >
                    <option value="">Select...</option>
                    {educationalQualifications.map(
                      (educationalQualification) => (
                        <option value={educationalQualification.id}>
                          {educationalQualification.qualification}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>
              <div className="mb-3 row">
                <label for="lastname" className="col-sm-2 col-form-label">
                  Awarding Institution for Qualification
                </label>
                <div className="col-sm-10">
                  <input
                    value={mainLifeState.awardingInstitution}
                    onChange={(event) => {
                      setMainLifeState({
                        ...mainLifeState,
                        awardingInstitution: event.target.value,
                      });
                    }}
                    type="text"
                    className="form-control"
                    id="awarding institution"
                  />
                </div>
              </div>{" "}
              <div className="mb-3 row">
                <label for="nic" className="col-sm-2 col-form-label">
                  NIC
                </label>
                <div className="col-sm-10">
                  <input
                    value={mainLifeState.nic}
                    onChange={(event) => {
                      setMainLifeState({
                        ...mainLifeState,
                        nic: event.target.value,
                      });
                    }}
                    type="text"
                    className="form-control"
                    id="nic"
                  />
                </div>
              </div>
              {declarationsForMainLife && declarationsForMainLifeNotSaved && (
                <>
                  <div>
                    <h5 className="mt-5">
                      PEP (Politically Exposed Persons) Questions
                    </h5>
                    {pepQuestions && (
                      <>
                        {pepQuestions.map((item, index) => {
                          return (
                            <>
                              <div className="form-group row mb-3">
                                <label
                                  htmlFor="join_loan"
                                  className="col-sm-12  col-form-label"
                                >
                                  {item.content}
                                </label>
                                <div className="col-sm-8 ">
                                  <div
                                    className="form-check "
                                    onClick={() => {
                                      let updatedPepQuestions = [
                                        ...pepQuestions,
                                      ];
                                      updatedPepQuestions[
                                        index
                                      ].hasAccepted = false;
                                      setPepQuestions(updatedPepQuestions);
                                    }}
                                  >
                                    <input
                                      readOnly
                                      className="form-check-input"
                                      type="radio"
                                      checked={item.hasAccepted === false}
                                    />
                                    <label className="form-check-label">
                                      No
                                    </label>
                                  </div>
                                  <div
                                    className="form-check "
                                    onClick={() => {
                                      let updatedPepQuestions = [
                                        ...pepQuestions,
                                      ];
                                      updatedPepQuestions[
                                        index
                                      ].hasAccepted = true;
                                      setPepQuestions(updatedPepQuestions);
                                    }}
                                  >
                                    <input
                                      readOnly
                                      className="form-check-input"
                                      type="radio"
                                      checked={item.hasAccepted}
                                    />
                                    <label className="form-check-label">
                                      Yes
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </>
                    )}
                  </div>
                  <div>
                    <h5 className="mt-5">
                      FATCA (Foreign Account Tax Compliance Act) Questions
                    </h5>
                    {fatcaQuestions && (
                      <>
                        {fatcaQuestions.map((item, index) => {
                          return (
                            <>
                              <div className="form-group row mb-3">
                                <label className="col-sm-12  col-form-label">
                                  {item.content}
                                </label>
                                <div className="col-sm-8 ">
                                  <div
                                    className="form-check "
                                    onClick={() => {
                                      let updatedFatcaQuestions = [
                                        ...fatcaQuestions,
                                      ];
                                      updatedFatcaQuestions[
                                        index
                                      ].hasAccepted = false;
                                      setFatcaQuestions(updatedFatcaQuestions);
                                    }}
                                  >
                                    <input
                                      readOnly
                                      className="form-check-input"
                                      type="radio"
                                      checked={item.hasAccepted === false}
                                    />
                                    <label className="form-check-label">
                                      No
                                    </label>
                                  </div>
                                  <div
                                    className="form-check "
                                    onClick={() => {
                                      let updatedFatcaQuestions = [
                                        ...fatcaQuestions,
                                      ];
                                      updatedFatcaQuestions[
                                        index
                                      ].hasAccepted = true;
                                      setFatcaQuestions(updatedFatcaQuestions);
                                    }}
                                  >
                                    <input
                                      readOnly
                                      className="form-check-input"
                                      type="radio"
                                      checked={item.hasAccepted}
                                    />
                                    <label className="form-check-label">
                                      Yes
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </>
                    )}
                  </div>
                </>
              )}
              <button
                onClick={() => {
                  validateMainLifeDetailsAndProceed();
                }}
                className="btn btn-light"
              >
                {" "}
                {isVerifyingDetails && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                Save and proceed <i className="fa fa-save"></i>
              </button>
            </div>
          )}
        {currentAction === "show-form-for-main-life" &&
          declarationsForMainLifeNotSaved === undefined && (
            <>
              <div
                style={{ marginTop: 150 }}
                className="d-flex flex-column justify-content-center align-items-center"
              >
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                <div>Please wait...</div>
              </div>
            </>
          )}

        {currentAction === "show-form-for-joint-life" &&
          declarationsForJointLifeNotSaved === undefined && (
            <>
              <div>
                {error && <div className="alert alert-danger">{error}</div>}
                <h5 className="mt-4">
                  <i className=""></i>Fill confirm form and proceed (Joint Life)
                </h5>
                <div className="mb-3 row">
                  <label for="firstname" className="col-sm-2 col-form-label">
                    Title{" "}
                  </label>
                  <div className="col-sm-10">
                    <select
                      value={jointLifeState.title}
                      onChange={(event) => {
                        setMainLifeState({
                          ...jointLifeState,
                          title: event.target.value,
                        });
                      }}
                      className="form-select"
                      aria-label="Title Select"
                    >
                      <option value="Mr">Mr</option>
                      <option value="Ms">Ms</option>
                      <option value="Mrs">Mrs</option>
                      <option value="Miss">Miss</option>
                      <option value="Dr">Dr</option>
                    </select>
                  </div>
                </div>
                <div className="mb-3 row">
                  <label for="firstname" className="col-sm-2 col-form-label">
                    First Names
                  </label>
                  <div className="col-sm-10">
                    <input
                      type="text"
                      value={jointLifeState.firstname}
                      onChange={(event) => {
                        setJointLifeState({
                          ...jointLifeState,
                          firstname: event.target.value,
                        });
                      }}
                      className="form-control"
                      id="firstname"
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label for="lastname" className="col-sm-2 col-form-label">
                    Last name
                  </label>
                  <div className="col-sm-10">
                    <input
                      value={jointLifeState.lastname}
                      onChange={(event) => {
                        setJointLifeState({
                          ...mainLifeState,
                          lastname: event.target.value,
                        });
                      }}
                      type="text"
                      className="form-control"
                      id="lastname"
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label for="email" className="col-sm-2 col-form-label">
                    Email
                  </label>
                  <div className="col-sm-10">
                    <input
                      value={jointLifeState.email}
                      type="text"
                      disabled={true}
                      className="form-control"
                      id="email"
                    />
                  </div>
                </div>
                <div className="form-group row mb-3">
                  <label htmlFor="gender" className="col-sm-2  col-form-label">
                    Gender{" "}
                  </label>
                  <div className="col-sm-10 ">
                    <select
                      onChange={(event) => {
                        setJointLifeState({
                          ...jointLifeState,
                          gender: event.target.value,
                        });
                      }}
                      value={jointLifeState.gender}
                      className={`form-select`}
                    >
                      <option value="">Select...</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                </div>
                <div className="mb-3 row">
                  <label for="email" className="col-sm-2 col-form-label">
                    Phone Number
                  </label>
                  <div className="col-sm-10">
                    <CustomNumberInput
                      ref={phoneNumberRef}
                      disabled={true}
                      errors={{}}
                      state={jointLifeState}
                      setState={setJointLifeState}
                    />
                  </div>
                </div>
                <div className="form-group row mb-3">
                  <label htmlFor="country" className="col-sm-2  col-form-label">
                    Country{" "}
                  </label>
                  <div className="col-sm-10 ">
                    <select
                      onChange={(event) => {
                        setJointLifeState({
                          ...jointLifeState,
                          country: event.target.value,
                        });
                      }}
                      value={jointLifeState.country}
                      className={`form-select`}
                    >
                      <option value="">Select...</option>
                      {countries.map((country) => (
                        <option value={country.code}>{country.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="form-group row mb-3">
                  <label htmlFor="country" className="col-sm-2  col-form-label">
                    Occupation{" "}
                  </label>
                  <div className="col-sm-10 ">
                    <select
                      onChange={(event) => {
                        setMainLifeState({
                          ...jointLifeState,
                          occupationId: event.target.value,
                        });
                      }}
                      value={jointLifeState.occupationId}
                      className={`form-select`}
                    >
                      <option value="">Select...</option>
                      {occupations.map((occupation) => (
                        <option value={occupation.id}>
                          {occupation.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="form-group row mb-3">
                  <label htmlFor="country" className="col-sm-2  col-form-label">
                    Salary Range{" "}
                  </label>
                  <div className="col-sm-10 ">
                    <select
                      onChange={(event) => {
                        setMainLifeState({
                          ...jointLifeState,
                          salaryrangeId: event.target.value,
                        });
                      }}
                      value={jointLifeState.salaryrangeId}
                      className={`form-select`}
                    >
                      <option value="">Select...</option>
                      {salaryRanges.map((salaryRange) => (
                        <option value={salaryRange.id}>
                          {salaryRange.range}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="form-group row mb-3">
                  <label htmlFor="country" className="col-sm-2  col-form-label">
                    Highest Educational Qualification{" "}
                  </label>
                  <div className="col-sm-10 ">
                    <select
                      onChange={(event) => {
                        setJointLifeState({
                          ...jointLifeState,
                          educationId: event.target.value,
                        });
                      }}
                      value={jointLifeState.educationId}
                      className={`form-select`}
                    >
                      <option value="">Select...</option>
                      {educationalQualifications.map(
                        (educationalQualification) => (
                          <option value={educationalQualification.id}>
                            {educationalQualification.qualification}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
                <div className="mb-3 row">
                  <label for="lastname" className="col-sm-2 col-form-label">
                    Awarding Institution for Qualification
                  </label>
                  <div className="col-sm-10">
                    <input
                      value={jointLifeState.awardingInstitution}
                      onChange={(event) => {
                        setJointLifeState({
                          ...jointLifeState,
                          awardingInstitution: event.target.value,
                        });
                      }}
                      type="text"
                      className="form-control"
                      id="awarding institution"
                    />
                  </div>
                </div>{" "}
                <div className="mb-3 row">
                  <label for="nic" className="col-sm-2 col-form-label">
                    NIC
                  </label>
                  <div className="col-sm-10">
                    <input
                      value={jointLifeState.nic}
                      onChange={(event) => {
                        setJointLifeState({
                          ...jointLifeState,
                          nic: event.target.value,
                        });
                      }}
                      type="text"
                      className="form-control"
                      id="nic"
                    />
                  </div>
                </div>
                {declarationsForJointLife &&
                  declarationsForJointLifeNotSaved && (
                    <>
                      <div>
                        <h5 className="mt-5">
                          PEP (Politically Exposed Persons) Questions
                        </h5>
                        {pepQuestionsJoint && (
                          <>
                            {pepQuestionsJoint.map((item, index) => {
                              return (
                                <>
                                  <div className="form-group row mb-3">
                                    <label
                                      htmlFor="join_loan"
                                      className="col-sm-12  col-form-label"
                                    >
                                      {item.content}
                                    </label>
                                    <div className="col-sm-8 ">
                                      <div
                                        className="form-check "
                                        onClick={() => {
                                          let updatedPepQuestions = [
                                            ...pepQuestionsJoint,
                                          ];
                                          updatedPepQuestions[
                                            index
                                          ].hasAccepted = false;
                                          setPepQuestions(updatedPepQuestions);
                                        }}
                                      >
                                        <input
                                          readOnly
                                          className="form-check-input"
                                          type="radio"
                                          checked={item.hasAccepted === false}
                                        />
                                        <label className="form-check-label">
                                          No
                                        </label>
                                      </div>
                                      <div
                                        className="form-check "
                                        onClick={() => {
                                          let updatedPepQuestions = [
                                            ...pepQuestionsJoint,
                                          ];
                                          updatedPepQuestions[
                                            index
                                          ].hasAccepted = true;
                                          setPepQuestions(updatedPepQuestions);
                                        }}
                                      >
                                        <input
                                          readOnly
                                          className="form-check-input"
                                          type="radio"
                                          checked={item.hasAccepted}
                                        />
                                        <label className="form-check-label">
                                          Yes
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </>
                        )}
                      </div>
                      <div>
                        <h5 className="mt-5">
                          FATCA (Foreign Account Tax Compliance Act) Questions
                        </h5>
                        {fatcaQuestionsJoint && (
                          <>
                            {fatcaQuestionsJoint.map((item, index) => {
                              return (
                                <>
                                  <div className="form-group row mb-3">
                                    <label className="col-sm-12  col-form-label">
                                      {item.content}
                                    </label>
                                    <div className="col-sm-8 ">
                                      <div
                                        className="form-check "
                                        onClick={() => {
                                          let updatedFatcaQuestions = [
                                            ...fatcaQuestionsJoint,
                                          ];
                                          updatedFatcaQuestions[
                                            index
                                          ].hasAccepted = false;
                                          setFatcaQuestionsJoint(
                                            updatedFatcaQuestions
                                          );
                                        }}
                                      >
                                        <input
                                          readOnly
                                          className="form-check-input"
                                          type="radio"
                                          checked={item.hasAccepted === false}
                                        />
                                        <label className="form-check-label">
                                          No
                                        </label>
                                      </div>
                                      <div
                                        className="form-check "
                                        onClick={() => {
                                          let updatedFatcaQuestions = [
                                            ...fatcaQuestionsJoint,
                                          ];
                                          updatedFatcaQuestions[
                                            index
                                          ].hasAccepted = true;
                                          setFatcaQuestionsJoint(
                                            updatedFatcaQuestions
                                          );
                                        }}
                                      >
                                        <input
                                          readOnly
                                          className="form-check-input"
                                          type="radio"
                                          checked={item.hasAccepted}
                                        />
                                        <label className="form-check-label">
                                          Yes
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </>
                        )}
                      </div>
                    </>
                  )}
                <button
                  onClick={() => {
                    validateJointLifeDetailsAndProceed();
                  }}
                  className="btn btn-light"
                >
                  {" "}
                  {isVerifyingDetails && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  Save and proceed <i className="fa fa-save"></i>
                </button>
              </div>
            </>
          )}
        {currentAction === "shufti-validation-mainlife" && (
          <>
            <div className="text-center">
              {verificationUrl && (
                <div className="row justify-content-center">
                  <div className="col-lg-10">
                    <iframe
                      id="shuftipro-iframe"
                      allow="camera"
                      frameBorder="0"
                      src={verificationUrl}
                      style={{ width: "100%", height: 600 }}
                    />
                  </div>
                </div>
              )}
              {!verificationUrl && (
                <>
                  <div
                    style={{ marginTop: 150 }}
                    className="d-flex flex-column justify-content-center align-items-center"
                  >
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <div>Please wait...</div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
        {currentAction === "shufti-validation-jointlife" && (
          <>
            <div className="text-center">
              {verificationUrlForJointLife && (
                <div className="row justify-content-center">
                  <div className="col-lg-10">
                    <iframe
                      id="shuftipro-iframe"
                      allow="camera"
                      frameBorder="0"
                      src={verificationUrlForJointLife}
                      style={{ width: "100%", height: 600 }}
                    />
                  </div>
                </div>
              )}
              {!verificationUrlForJointLife && (
                <>
                  <div
                    style={{ marginTop: 150 }}
                    className="d-flex flex-column justify-content-center align-items-center"
                  >
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <div>Please wait...</div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default KYC;
