/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
// import axios from "axios";

const ShuftiRedirectPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const quotationId = searchParams.get("quotation");

  useEffect(() => {
    // console.log(window.parent.location.href);
    window.parent.location.href = `${window.location.origin}/application`;
  }, []);
  return;
};

export default ShuftiRedirectPage;
