import React, { useEffect } from "react";
import ReflexoneConditionControlledCheckBox from "../../../core/components/ReflexoneConditionControlledCheckBox";

const ReflexoneTest = ({ state, setState, number }) => {
  useEffect(() => {
    if (!state.conditionControlled) {
      setState({ ...state, conditionControlled: {} });
    }
  }, []);
  return (
    <>
      <div>
        <div className="mx-4">
          <div className="row my-3">
            <div className="col-sm-12 mb-2">
              <h6 className="fw-bold">
                {number}. Have you had a check up recently i.e. in the past 6
                month or tested your condition?
              </h6>{" "}
            </div>
            <div className="col-sm-12 mb-2">
              <div className="row mx-2">
                <div className="col-sm-4">
                  <div
                    className="col-sm-12"
                    onClick={() => {
                      setState({ ...state, hadCheckupRecently: true });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={state.hadCheckupRecently}
                    ></input>
                    <label className="form-check-label" htmlFor="refund1">
                      Yes
                    </label>
                  </div>
                  <div
                    className="col-sm-12"
                    onClick={() => {
                      setState({ ...state, hadCheckupRecently: false });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={state.hadCheckupRecently == false}
                    ></input>
                    <label className="form-check-label" htmlFor="refund1">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {state.hadCheckupRecently && (
        <div>
          <div className="mx-4">
            <div className="row my-3">
              <div className="col-sm-12 my-3">
                <div className="row mx-2">
                  <table className="table table-bordered ">
                    <thead>
                      <tr classNama="font-size-sm">
                        <th width="50%"></th>
                        <th className="table-title">Yes</th>
                        <th className="table-title">No</th>
                      </tr>
                    </thead>
                    <tbody>
                      {state.conditionReceivingTreatment.includes(
                        "diabetes"
                      ) && (
                        <tr>
                          <td>Was your Diabetes well controlled</td>

                          <ReflexoneConditionControlledCheckBox
                            state={state}
                            setState={setState}
                            conditionControlledKey="diabetesWellControlled"
                            conditionControlledValue={true}
                          />
                          <ReflexoneConditionControlledCheckBox
                            state={state}
                            setState={setState}
                            conditionControlledKey="diabetesWellControlled"
                            conditionControlledValue={false}
                          />
                        </tr>
                      )}
                      {state.conditionReceivingTreatment.includes(
                        "hypertension"
                      ) && (
                        <tr>
                          <td>Was your Hypertension well controlled</td>

                          <ReflexoneConditionControlledCheckBox
                            state={state}
                            setState={setState}
                            conditionControlledKey="hypertensionWellControlled"
                            conditionControlledValue={true}
                          />
                          <ReflexoneConditionControlledCheckBox
                            state={state}
                            setState={setState}
                            conditionControlledKey="hypertensionWellControlled"
                            conditionControlledValue={false}
                          />
                        </tr>
                      )}
                      {(state.conditionReceivingTreatment.includes(
                        "cholestrol"
                      ) ||
                        state.conditionReceivingTreatment.includes(
                          "hypercholesterolaemia"
                        )) && (
                        <tr>
                          <td>Was your Cholesterol below or equal to 7</td>

                          <ReflexoneConditionControlledCheckBox
                            state={state}
                            setState={setState}
                            conditionControlledKey="cholestrolBelowOrEqualToSeven"
                            conditionControlledValue={true}
                          />
                          <ReflexoneConditionControlledCheckBox
                            state={state}
                            setState={setState}
                            conditionControlledKey="cholestrolBelowOrEqualToSeven"
                            conditionControlledValue={false}
                          />
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReflexoneTest;
