import React, { useState } from "react";
import currencyFormatter from "currency-formatter";
import { useEffect } from "react";

const PaymentPlan = ({
  state,
  paymentInsurancePlan,
  loanPlan,
  name,
  updatePaymentInsuranceFn,
  amount,
  setState,
  refundAmount,
  isOnAccordion = false,
  withRefund,
}) => {
  let badge = null;

  if (paymentInsurancePlan === "yearly") {
    badge = (
      <span
        className={
          state.payment_insurance_plan === paymentInsurancePlan &&
          state.loan_plan === loanPlan
            ? `badge mt-3 bg-success p-2 px-4 text-white`
            : `badge mt-3 bg-white p-2 px-4 ila-grey-text`
        }
      >
        3% discount{" "}
      </span>
    );
  } else if (paymentInsurancePlan === "monthly") {
    badge = (
      <span
        className={
          state.payment_insurance_plan === paymentInsurancePlan &&
          state.loan_plan === loanPlan
            ? `badge mt-3 bg-success p-2 px-4 text-white`
            : `badge mt-3 bg-white p-2 px-4 ila-grey-text`
        }
      >
        No discount{" "}
      </span>
    );
  } else if (paymentInsurancePlan === "half-yearly") {
    badge = (
      <span
        className={
          state.payment_insurance_plan === paymentInsurancePlan &&
          state.loan_plan === loanPlan
            ? `badge mt-3 bg-success p-2 px-4 text-white`
            : `badge mt-3 bg-white p-2 px-4 ila-grey-text`
        }
      >
        1.5% discount{" "}
      </span>
    );
  } else if (paymentInsurancePlan === "quarterly") {
    badge = (
      <span
        className={
          state.payment_insurance_plan === paymentInsurancePlan &&
          state.loan_plan === loanPlan
            ? `badge mt-3 bg-success p-2 px-4 text-white`
            : `badge mt-3 bg-white p-2 px-4 ila-grey-text`
        }
      >
        0.75% discount{" "}
      </span>
    );
  } else {
    badge = <span className="badge mt-3 p-2 ila-accent-text">&nbsp;</span>;
  }

  let headerStyle = isOnAccordion ? { fontSize: "1rem" } : {};
  let amountStyle = isOnAccordion ? { fontSize: "1.4rem" } : {};

  return (
    <div
      style={{ cursor: "pointer" }}
      onClick={() => {
        setState({
          ...state,
          payment_insurance_plan: paymentInsurancePlan,
          loan_plan: loanPlan,
          hasChosenLoanPlan: true,
        });
        updatePaymentInsuranceFn({
          payment_insurance_plan: paymentInsurancePlan,
          loan_plan: loanPlan,
          with_refund: withRefund,
        });
      }}
      className={`card ila-accent-border mt-3`}
    >
      <div
        style={{ borderRadius: "0.18rem" }}
        className={`card-body   ${
          state.payment_insurance_plan === paymentInsurancePlan &&
          state.loan_plan === loanPlan
            ? "bg-white ila-accent-text"
            : "ila-accent-bg text-white"
        }`}
      >
        <h5 style={headerStyle}>{name}</h5>
        {!!withRefund && !!refundAmount ? (
          <span className="display-6" style={{ fontSize: "1.2rem" }}>
            {currencyFormatter.format(parseFloat(amount), { symbol: "Rs " })}
          </span>
        ) : (
          <div className="display-6" style={amountStyle}>
            {currencyFormatter.format(parseFloat(amount), { symbol: "Rs " })}
          </div>
        )}
        {!!withRefund && !!refundAmount && (
          <div className="mt-3">
            <span>with Refund </span>
            <div className="display-6 fw-bold" style={amountStyle}>
              {currencyFormatter.format(parseFloat(refundAmount), {
                symbol: "Rs ",
              })}
            </div>
          </div>
        )}
        <div>{badge}</div>
      </div>
    </div>
  );
};

export default PaymentPlan;
