import React from "react";

const ReflexoneDiagnose = ({ number }) => {
  return (
    <div>
      <div className="mx-4">
        <div className="row my-3">
          <div className="col-sm-12 mb-2">
            <h6 className="fw-bold">
              {number}. Do you suffer from or have you ever been diagnosed with
              any of the following conditions:
            </h6>{" "}
          </div>

          <div className="col-sm-12 mb-2">
            <div className="row mx-2">
              <div className="col-sm-4">
                <div className="fw-bold mb-2">If Diabetes Chosen</div>
                <div className="col-sm-12">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="refund"
                    id="refund1"
                    value="option1"
                  ></input>
                  <label className="form-check-label" htmlFor="refund1">
                    Hypertension
                  </label>
                </div>
                <div className="col-sm-12">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="refund"
                    id="refund1"
                    value="option1"
                  ></input>
                  <label className="form-check-label" htmlFor="refund1">
                    Blindness
                  </label>
                </div>
                <div className="col-sm-12">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="refund"
                    id="refund1"
                    value="option1"
                  ></input>
                  <label className="form-check-label" htmlFor="refund1">
                    Kidney Failure
                  </label>
                </div>
                <div className="col-sm-12">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="refund"
                    id="refund1"
                    value="option1"
                  ></input>
                  <label className="form-check-label" htmlFor="refund1">
                    Heart Attacks
                  </label>
                </div>
                <div className="col-sm-12">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="refund"
                    id="refund1"
                    value="option1"
                  ></input>
                  <label className="form-check-label" htmlFor="refund1">
                    Stroke
                  </label>
                </div>
                <div className="col-sm-12">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="refund"
                    id="refund1"
                    value="option1"
                  ></input>
                  <label className="form-check-label" htmlFor="refund1">
                    Lower Limb amputation
                  </label>
                </div>
              </div>

              <div className="col-sm-4">
                <div className="fw-bold mb-2">If Hypertension Chosen</div>
                <div className="col-sm-12">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="refund"
                    id="refund1"
                    value="option1"
                  ></input>
                  <label className="form-check-label" htmlFor="refund1">
                    Pulmonary Hypertension
                  </label>
                </div>
                <div className="col-sm-12">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="refund"
                    id="refund1"
                    value="option1"
                  ></input>
                  <label className="form-check-label" htmlFor="refund1">
                    Diabetes
                  </label>
                </div>
                <div className="col-sm-12">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="refund"
                    id="refund1"
                    value="option1"
                  ></input>
                  <label className="form-check-label" htmlFor="refund1">
                    Kidney disease
                  </label>
                </div>
                <div className="col-sm-12">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="refund"
                    id="refund1"
                    value="option1"
                  ></input>
                  <label className="form-check-label" htmlFor="refund1">
                    Heart Attacks
                  </label>
                </div>
                <div className="col-sm-12">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="refund"
                    id="refund1"
                    value="option1"
                  ></input>
                  <label className="form-check-label" htmlFor="refund1">
                    Heart Coronary Arteries
                  </label>
                </div>
              </div>

              <div className="col-sm-4">
                <div className="fw-bold mb-2">If Hypercholesterolaemia</div>
                <div className="col-sm-12">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="refund"
                    id="refund1"
                    value="option1"
                  ></input>
                  <label className="form-check-label" htmlFor="refund1">
                    Pulmonary Hypertension
                  </label>
                </div>
                <div className="col-sm-12">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="refund"
                    id="refund1"
                    value="option1"
                  ></input>
                  <label className="form-check-label" htmlFor="refund1">
                    Diabetes
                  </label>
                </div>
                <div className="col-sm-12">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="refund"
                    id="refund1"
                    value="option1"
                  ></input>
                  <label className="form-check-label" htmlFor="refund1">
                    Kidney disease
                  </label>
                </div>
                <div className="col-sm-12">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="refund"
                    id="refund1"
                    value="option1"
                  ></input>
                  <label className="form-check-label" htmlFor="refund1">
                    Heart Attacks
                  </label>
                </div>
                <div className="col-sm-12">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="refund"
                    id="refund1"
                    value="option1"
                  ></input>
                  <label className="form-check-label" htmlFor="refund1">
                    Heart Coronary Arteries
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReflexoneDiagnose;
