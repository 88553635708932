/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useNavigate } from "react-router-dom";
// import arrowDown from "../../core/assets/images/arrow-down.png";
import useLogout from "../../hooks/useLogout";

const Navbar = ({ name }) => {
  const navigate = useNavigate();
  const logout = useLogout();

  const signOut = async () => {
    await logout();
    navigate("/");
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg bg-white">
        <div className="container d-flex justify-content-end">
          <div className="dropdown">
            <a
              className="btn mt-1"
              href="#"
              style={{
                border: "none",
                cursor: "pointer",
                fontWeight: "bold",
                color: "#8E8E8E",
                fontSize: 18,
              }}
              role="button"
              id="dropdownMenuLink"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Hi, {name} <span className="fa fa-chevron-down"></span>
            </a>

            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <li>
                <a className="dropdown-item" href={`${process.env.REACT_APP_BASENAME}/profile`}>
                  Profile
                </a>
              </li>
              <li>
                <a className="dropdown-item" href={`${process.env.REACT_APP_BASENAME}/change-password`}>
                  Change Password
                </a>
              </li>
              <li>
                <a type="button" onClick={signOut} className="dropdown-item">
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
