/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState, useRef } from "react";
import Navbar from "../core/components/NavBar";
import UserContext from "../core/UserContext";
import { useNavigate } from "react-router-dom";
import CurrencyInput from "react-currency-input-field";
import "./SingleQuotationAuthenticated.css";
import Moment from "moment";
import CustomNumberInput from "../core/components/custom-number-input/CustomNumberInput";
import { isValidPhoneNumber } from "react-phone-number-input";
import WhatsappNumberInput from "../core/components/whatsapp-number-input/WhatsappNumberInput";
// Axios
import {
  getDate_ddmmyyyy,
  getUserDataFromLocalStorage,
  isValidAgeNextBirthdayWithTerm,
  isValidNic,
} from "../core/utils";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import uk from "date-fns/locale/en-GB";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

registerLocale("uk", uk);

const SingleQuotationAuthenticated = () => {
  const { userData, setUserData } = useContext(UserContext);
  const userDataStorage = getUserDataFromLocalStorage();
  const axiosPrivate = useAxiosPrivate(userDataStorage);
  const navigate = useNavigate();
  const jointLifephoneNumberRef = useRef();
  const whatsappNumberJointRef = useRef();
  const phoneNumberRef = useRef();
  const bankRepContactRef = useRef();
  const whatsappNumberRef = useRef();
  const [bankBranches, setBankBranches] = useState([]);
  // const [pepQuestions, setPepQuestions] = useState([]);
  // const [fatcaQuestions, setFatcaQuestions] = useState([]);
  // const [pepQuestionsJoint, setPepQuestionsJoint] = useState([]);
  // const [fatcaQuestionsJoint, setFatcaQuestionsJoint] = useState([]);
  const [state, setState] = useState({
    title: "",
    firstname: "",
    lastname: "",
    email: "",
    phoneNumber: "",
    birth: null,
    maiden_name: null,
    passport_number: null,
    nationality: null,
    nic: "",
    joint_user_title: "",
    joint_user_firstname: "",
    joint_user_lastname: "",
    joint_user_birth: null,
    joint_user_nic: "",
    joint_user_email: "",
    joint_user_phoneNumber: "",

    joint_user_maiden_name: "",
    joint_user_passport_number: "",
    joint_user_nationality: "",
    joint_user_relationship: "",

    intended_commencement_date: new Date(Moment().format("YYYY-MM-DD")),
    join_loan: false,
    term_number: null,
    initial_sum_assured: null,
    term_option: "years",
    gender: "",
    loan_type: "capital-interest",
    loan_plan: null,
    payment_insurance_plan: "yearly",
    with_refund: false,
    with_tpd: true,
    bank_name: "",
    bank_branch_name: "",
    joint_user_financial_institution_branch: "",
    bank_rep_name: null,
    bank_rep_email: null,
    bank_rep_contact: null,
    consent_bank_rep: false,
  });

  const intendedCommencementDateRef = useRef();
  const birthRef = useRef();
  const otherBirthRef = useRef();
  const [banks, setBanks] = useState([]);
  const [banksOptions, setBanksOptions] = useState([]);
  const [bankBranchesOptions, setBankBranchesOptions] = useState([]);
  const [jointLifeFieldsDisabled, setJointLifeFieldsDisabled] = useState(true);
  const eighteenYearsAgo = Moment().subtract(18, "years").format("YYYY-MM-DD");
  const sixtyFiveYearsAgo = Moment().subtract(65, "years").format("YYYY-MM-DD");
  const fifteenDaysFromToday = Moment().add(15, "days").format("YYYY-MM-DD");
  const today = Moment().format("YYYY-MM-DD");

  const [errors, setErrors] = useState({
    title: null,
    name: null,
    firstname: null,
    lastname: null,
    email: null,
    nic: null,
    phoneNumber: null,
    birth: null,
    joint_user_title: null,
    joint_user_name: null,
    joint_user_firstname: null,
    joint_user_lastname: null,
    joint_user_email: null,
    joint_user_nic: null,
    joint_user_maiden_name: null,
    joint_user_passport_number: null,
    joint_user_nationality: null,
    joint_user_relationship: null,
    term_number: null,
    gender: null,
    initial_sum_assured: null,
    term_option: null,
    loan_type: null,
    loan_plan: null,
    payment_insurance_plan: null,
  });

  const showErrorsForInput = (errs) => {
    // const errorsKeys = Object.keys(errors);
    let errorMessages = "";
    if (errs) {
      errs.forEach((errMsg) => {
        errorMessages += `${errMsg}\n`;
      });
      toast.error(errorMessages);
    }
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // const [quotationResult, setQuotationResult] = useState(null);
  const [validatingJointLife, setValidatingJointLife] = useState(false);

  useEffect(() => {
    getBanks();
    const userDetails = getUserDataFromLocalStorage();
    const name = userDetails?.data?.name ? userDetails?.data?.name : "";
    const nameAsArr = name.split(" ");
    let firstname = "";
    let lastname = "";
    if (nameAsArr.length === 3) {
      firstname = name.includes(" ")
        ? name.split(" ")[0] + " " + name.split(" ")[1]
        : "";
      lastname = name.includes(" ") ? name.split(" ")[2] : "";
    } else if (nameAsArr.length === 2) {
      firstname = name.includes(" ") ? name.split(" ")[0] : "";
      lastname = name.includes(" ") ? name.split(" ")[1] : "";
    }

    const maiden_name = userDetails?.data?.maiden_name
      ? userDetails?.data?.maiden_name
      : "";
    const passport_number = userDetails?.data?.passport_number
      ? userDetails?.data?.passport_number
      : "";
    const nationality = userDetails?.data?.nationality
      ? userDetails?.data?.nationality
      : "";

    setState({
      ...state,
      maiden_name,
      passport_number,
      nationality,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setJointLifeFieldsDisabled(!state.join_loan);
  }, [state.join_loan]);

  useEffect(() => {
    setState({ ...state, whatsappNumber: state.phoneNumber });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.phoneNumber]);

  useEffect(() => {
    setState({
      ...state,
      joint_user_whatsappNumber: state.joint_user_phoneNumber,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.joint_user_phoneNumber]);

  useEffect(() => {
    if (state.gender === "male") {
      setState({ ...state, joint_user_gender: "female" });
    } else if (state.gender === "female") {
      setState({ ...state, joint_user_gender: "male" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.gender]);

  useEffect(() => {
    // console.log(state);
  }, [state]);

  const getBanks = async () => {
    const url = process.env.REACT_APP_API_URL + `/bank`;

    const response = await axiosPrivate.get(url);
    if (response.data.success) {
      setBanks(response.data.data);
      setBanksOptions(
        response.data.data.map((bank) => {
          return { value: bank.name, label: bank.name };
        })
      );
    }
  };
  const validateJointLife = async () => {
    if (
      !state.joint_user_phoneNumber ||
      !isValidPhoneNumber(state.joint_user_phoneNumber)
    ) {
      toast.error("Please enter valid phone number for joint life");
      return;
    }

    setValidatingJointLife(true);
    setState({
      ...state,
      joint_user_firstname: "",
      joint_user_lastname: "",
      joint_user_nic: "",
      joint_user_birth: "",
      joint_user_email: "",
      joint_user_title: "",
      joint_user_gender: "",
      userJointId: null,
      joint_user_phoneNumber: "",
      joint_user_whatsappNumber: "",
    });
    // toast.error(null);
    const url =
      process.env.REACT_APP_API_URL +
      `/user/check-phone/${state.joint_user_phoneNumber}`;

    try {
      const response = await axiosPrivate.get(url);

      if (response.data.success) {
        // debugger;
        const jointLifeData = response.data.data;
        const title = jointLifeData?.title ? jointLifeData?.title : "";
        const name = jointLifeData?.name ? jointLifeData?.name : "";
        const firstname = name.includes(" ") ? name.split(" ")[0] : "";
        const lastname = name.includes(" ") ? name.split(" ")[1] : "";
        const birth = jointLifeData?.birth
          ? new Date(Moment(jointLifeData?.birth).format("YYYY-MM-DD"))
          : null;
        const email = jointLifeData?.email ? jointLifeData?.email : "";
        const gender = jointLifeData?.gender ? jointLifeData?.gender : "";
        const whatsappNumber = jointLifeData?.whatsappNumber
          ? jointLifeData?.whatsappNumber
          : "";
        const nic = jointLifeData?.nic ? jointLifeData?.nic : "";

        // debugger;
        setState({
          ...state,
          joint_user_firstname: firstname,
          joint_user_lastname: lastname,
          joint_user_nic: nic,
          joint_user_birth: birth,
          joint_user_email: email,
          joint_user_title: title,
          joint_user_gender: gender,
          joint_user_whatsappNumber: whatsappNumber,
          userJointId: jointLifeData?.id,
        });
      } else {
        // setJointLifeFieldsDisabled(false);
        setState({ ...state, userJointId: null });
      }
      setJointLifeFieldsDisabled(false);

      setValidatingJointLife(false);
      console.log(response);
    } catch (err) {
      setJointLifeFieldsDisabled(false);
      console.log(err);
      setValidatingJointLife(false);
    }
  };

  const getBranchesForBank = (bank) => {
    if (banks.length === 0) {
      return [];
    }
    const filteredBanks = banks.filter((item) => {
      return bank === item.name;
    });
    // debugger;
    if (!filteredBanks || filteredBanks.length === 0) {
      return [];
    }

    // debugger;
    const selectedBank = filteredBanks[0];
    if (!selectedBank.BranchBanks) {
      return [];
    }
    // debugger;
    return selectedBank.BranchBanks;
  };

  const submitData = async () => {
    setErrors({
      title: null,
      name: null,
      firstname: null,
      lastname: null,
      email: null,
      nic: null,
      phoneNumber: null,
      birth: null,
      maiden_name: null,
      passport_number: null,
      nationality: null,
      joint_user_title: null,
      joint_user_name: null,
      joint_user_firstname: null,
      joint_user_lastname: null,
      joint_user_email: null,
      joint_user_nic: null,

      joint_user_maiden_name: null,
      joint_user_passport_number: null,
      joint_user_nationality: null,
      joint_user_relationship: null,

      term_number: null,
      gender: null,
      initial_sum_assured: null,
      term_option: null,
      loan_type: null,
      loan_plan: null,
      payment_insurance_plan: null,
    });

    // Extra Frontend Validation
    if (state.title.trim().length === 0 || !state.title) {
      setErrors({ title: ["title is required"] });
      showErrorsForInput(["title is required"]);
      return;
    }

    if (state.firstname.trim().length === 0 || !state.firstname) {
      setErrors({ firstname: ["first names is required"] });
      showErrorsForInput(["first names is required"]);
      return;
    }

    if (state.lastname.trim().length === 0 || !state.lastname) {
      setErrors({ lastname: ["last name is required"] });
      showErrorsForInput(["last name is required"]);
      return;
    }

    if (
      !state.birth ||
      (typeof state.birth === "string" && state.birth.trim().length === 0)
    ) {
      setErrors({ birth: ["birthday is required"] });
      showErrorsForInput(["birthday is required"]);
      return;
    }

    if (
      !state.nationality ||
      (typeof state.nationality === "string" &&
        state.nationality.trim().length === 0)
    ) {
      setErrors({ nationality: ["Nationality is required"] });
      return;
    }

    // NEW VALIDATIONS

    if (
      !state.nic ||
      (typeof state.nic === "string" && state.nic.trim().length === 0)
    ) {
      setErrors({ nic: ["NIC is required"] });
      showErrorsForInput(["NIC is required"]);
      return;
    }

    if (state.nic.length === 0 || state.nic.length !== 14) {
      setErrors({ nic: ["The NIC number must be 14 characters"] });
      showErrorsForInput(["The NIC number must be 14 characters"]);
      return;
    }

    if (!state.gender) {
      setErrors({ gender: ["gender is required"] });
      showErrorsForInput(["gender is required"]);
      return;
    }

    if (!state.loan_plan) {
      setErrors({ loan_plan: ["Policy class is required"] });
      return;
    }

    if (state.loan_plan != "lta") {
      if (!state.bank_name) {
        setErrors({ bank_name: ["Financial institution is required"] });
        return;
      }
      if (!state.bank_branch_name) {
        setErrors({
          bank_branch_name: ["Financial institution branch is required"],
        });
        return;
      }
    }

    if (!state.join_loan) {
      const url = process.env.REACT_APP_API_URL + "/quotation/worker/single";

      //Term+ageNextBirthday Validation
      if (!isValidAgeNextBirthdayWithTerm(state.birth, state.term_number)) {
        toast.error(
          "Sum of age next birthday and term should not be more than 75!"
        );
        return;
      }

      if (state.nic.length === 0 || state.nic.length !== 14) {
        toast.error("The NIC number must be 14 characters");
        return;
      }

      if (!isValidNic(state.nic, state.birth)) {
        toast.error("The NIC number doesn't match with the birthday");
        return;
      }

      try {
        setLoading(true);
        toast.error(null);

        let payload = {
          ...state,
          name: state.firstname + " " + state.lastname,
          birth: getDate_ddmmyyyy(state.birth),
          userId: null,
          intended_commencement_date: getDate_ddmmyyyy(
            state.intended_commencement_date
          ),
        };

        delete payload.firstname;
        delete payload.lastname;
        delete payload.joint_user_title;
        delete payload.joint_user_firstname;
        delete payload.joint_user_lastname;
        delete payload.joint_user_birth;
        delete payload.joint_user_nic;
        delete payload.joint_user_email;
        delete payload.joint_user_phoneNumber;
        delete payload.joint_user_financial_institution_branch;
        delete payload.joint_user_gender;
        delete payload.joint_user_whatsappNumber;

        delete payload.joint_user_maiden_name;
        delete payload.joint_user_passport_number;
        delete payload.joint_user_nationality;
        delete payload.joint_user_relationship;

        if (payload.bank_branch_name === "Other") {
          payload.bank_branch_name =
            payload.joint_user_financial_institution_branch;
          delete payload.joint_user_financial_institution_branch;
        }

        try {
          payload.initial_sum_assured = parseFloat(
            payload.initial_sum_assured.toString()
          );
        } catch (error) {
          console.log(error);
        }

        // debugger;
        const { data } = await axiosPrivate.post(url, payload);

        // const createDeclarationUrl =
        //   process.env.REACT_APP_API_URL + "/user/create/declaration";

        // //for PEP
        // for (let i = 0; i < pepQuestions.length; i++) {
        //   await axiosPrivate.post(
        //     createDeclarationUrl,
        //     {
        //       userId: data.user.id,
        //       declarationId: pepQuestions[i].id,
        //       consent: pepQuestions[i].hasAccepted,
        //       quotationId: data.id,
        //       isMainLife: false,
        //     }
        //   );
        // }

        // // for Fatca
        // for (let j = 0; j < fatcaQuestions.length; j++) {
        //   await axiosPrivate.post(
        //     createDeclarationUrl,
        //     {
        //       userId: data.user.id,
        //       declarationId: fatcaQuestions[j].id,
        //       consent: fatcaQuestions[j].hasAccepted,
        //       quotationId: data.id,
        //       isMainLife: false,
        //     }
        //   );
        // }

        setLoading(false);
        setErrors({
          title: null,
          name: null,
          email: null,
          nic: null,
          phoneNumber: null,
          birth: null,
          maiden_name: null,
          passport_number: null,
          nationality: null,
          joint_user_title: null,
          joint_user_name: null,
          joint_user_firstname: null,
          joint_user_lastname: null,
          joint_user_email: null,
          joint_user_nic: null,
          term_number: null,
          gender: null,
          initial_sum_assured: null,
          term_option: null,
          loan_type: null,
          loan_plan: null,
          payment_insurance_plan: null,
        });
        if (data.success) {
          //show quotation
          navigate("/quotations");
        } else {
          toast.error(
            data.message
              ? data.message
              : "Quotation generation was unsuccessful, please try again"
          );
        }
      } catch (error) {
        //Handing Form Errors
        setLoading(false);
        toast.error(null);

        if (
          error?.response?.data?.errors &&
          Object.keys(error?.response?.data?.errors).length > 0
        ) {
          const errorsFromServer = error.response.data.errors;
          console.error(errorsFromServer);
          let errorData = {};
          if (errorsFromServer.length > 0) {
            errorsFromServer.forEach((err) => {
              if (!errorData[err.field]) {
                errorData[err.field] = [];
              }
              errorData[err.field].push(err.message);
            });
          }
          setErrors(errorData);
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          // debugger;
          toast.error(
            "Quotation generation was unsuccessful, please try again"
          );
        }
      }
    } else {
      // Extra Frontend Validation
      if (
        state.joint_user_title.trim().length === 0 ||
        !state.joint_user_title
      ) {
        setErrors({ joint_user_title: ["title is required"] });
        showErrorsForInput(["title for joint life is required"]);
        return;
      }

      if (
        state.joint_user_firstname.trim().length === 0 ||
        !state.joint_user_firstname
      ) {
        setErrors({
          joint_user_firstname: ["first names is required"],
        });
        showErrorsForInput(["first names for joint life is required "]);
        return;
      }

      if (
        state.joint_user_lastname.trim().length === 0 ||
        !state.joint_user_lastname
      ) {
        setErrors({ joint_user_lastname: ["last name is required"] });
        showErrorsForInput(["last name for joint life is required"]);
        return;
      }

      if (
        !state.joint_user_birth ||
        (typeof state.joint_user_birth === "string" &&
          state.joint_user_birth.trim().length === 0)
      ) {
        setErrors({ joint_user_birth: ["birthday is required"] });
        showErrorsForInput(["Birthday for joint life is required"]);
        return;
      }

      if (
        !state.joint_user_nic ||
        (typeof state.joint_user_nic === "string" &&
          state.joint_user_nic.trim().length === 0)
      ) {
        setErrors({ joint_user_nic: ["NIC is required"] });
        showErrorsForInput(["NIC for joint life is required"]);
        return;
      }

      if (
        state.joint_user_nic.length === 0 ||
        state.joint_user_nic.length !== 14
      ) {
        setErrors({
          joint_user_nic: ["The NIC number must be 14 characters"],
        });
        showErrorsForInput([
          "The NIC number for joint life must be 14 characters",
        ]);
        return;
      }

      //End of Extra Frontend Validation

      const url = process.env.REACT_APP_API_URL + "/quotation/worker/joint";
      try {
        //Term+ageNextBirthday Validation for main life
        if (!isValidAgeNextBirthdayWithTerm(state.birth, state.term_number)) {
          toast.error(
            "Sum of age next birthday for main life and term should not be more than 75!"
          );
          return;
        }
        //Term+ageNextBirthday Validation for joint life
        if (
          !isValidAgeNextBirthdayWithTerm(
            state.joint_user_birth,
            state.term_number
          )
        ) {
          toast.error(
            "Sum of age next birthday for joint life and term should not be more than 75!"
          );
          return;
        }

        if (state.nic.length === 0 || state.nic.length !== 14) {
          toast.error("The NIC number must be 14 characters");
          return;
        }
        if (!isValidNic(state.nic, state.birth)) {
          toast.error(
            "The Main Life NIC number doesn't match with the birthday"
          );
          return;
        }

        if (
          state.joint_user_nic.length === 0 ||
          state.joint_user_nic.length !== 14
        ) {
          toast.error("The joint life NIC number must be 14 characters");
          return;
        }
        if (!isValidNic(state.joint_user_nic, state.joint_user_birth)) {
          toast.error(
            "The Joint Life NIC number doesn't match with the birthday"
          );
          return;
        }

        if (!state.joint_user_whatsappNumber) {
          toast.error("The joint life whatsapp number is required");
          return;
        }

        // debugger;
        let payload = {
          ...state,
          intended_commencement_date: getDate_ddmmyyyy(
            state.intended_commencement_date
          ),
          main_user_name: state.firstname + " " + state.lastname,
          main_user_title: state.title,
          main_user_birth: getDate_ddmmyyyy(state.birth),
          main_user_nic: state.nic,
          main_user_phoneNumber: state.phoneNumber,
          main_user_whatsappNumber: state.whatsappNumber,
          main_user_email: state.email,
          main_user_gender: state.gender,
          joint_user_name:
            state.joint_user_firstname + " " + state.joint_user_lastname,
          joint_user_title: state.joint_user_title,
          joint_user_birth: getDate_ddmmyyyy(state.joint_user_birth),
          joint_user_nic: state.joint_user_nic,
          joint_user_phoneNumber: state.joint_user_phoneNumber,
          joint_user_email: state.joint_user_email,
          joint_user_whatsappNumber: state.joint_user_whatsappNumber,
        };

        // change field keys
        payload.main_user_maiden_name = payload.maiden_name;
        payload.main_user_passport_number = payload.passport_number;
        payload.main_user_nationality = payload.nationality;

        delete payload.maiden_name;
        delete payload.passport_number;
        delete payload.nationality;

        delete payload.title;
        delete payload.firstname;
        delete payload.lastname;
        delete payload.email;
        delete payload.phoneNumber;
        delete payload.birth;
        delete payload.nic;
        delete payload.gender;
        delete payload.whatsappNumber;

        payload.userId = null;

        if (!payload.userJointId) {
          payload.userJointId = null;
        }

        try {
          payload.initial_sum_assured = parseFloat(
            payload.initial_sum_assured.toString()
          );
        } catch (error) {
          console.log(error);
        }
        // delete payload.gender;

        if (payload.bank_branch_name === "Other") {
          payload.bank_branch_name =
            payload.joint_user_financial_institution_branch;
          delete payload.joint_user_financial_institution_branch;
        }

        setLoading(true);
        toast.error(null);
        const { data } = await axiosPrivate.post(url, payload);

        setLoading(false);
        setErrors({});
        if (data.success) {
          navigate("/quotations");
        } else {
          // debugger;
          toast.error(
            data.message
              ? data.message
              : "Quotation generation was unsuccessful, please try again"
          );
        }
      } catch (error) {
        //Handing Form Errors
        setLoading(false);
        toast.error(null);
        // debugger;
        if (
          error?.response?.data?.errors &&
          error?.response?.data?.errors.length > 0
        ) {
          const errorsFromServer = error.response.data.errors;
          console.error(errorsFromServer);
          let errorData = {};
          if (errorsFromServer.length > 0) {
            errorsFromServer.forEach((err) => {
              if (!errorData[err.field]) {
                errorData[err.field] = [];
              }
              //extra error handling
              errorData[err.field].push(err.message);
            });
          }
          setErrors(errorData);
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          // debugger;
          toast.error(
            "Quotation generation was unsuccessful, please try again"
          );
        }
      }
    }
  };

  const sendQuotationAsEmail = () => {};
  return (
    <>
      <Navbar
        name={
          userData
            ? `${userData?.data?.firstname} ${userData?.data?.lastname}`
            : ""
        }
      />
      <div className="container create-quotation-screen">
        <h4 className="mt-4 ila-grey-text">Create Quotation</h4>
        <div className="my-3" style={{ color: "#8E8E8E" }}>
          Enter parameters to generate quotation
        </div>
        <div className="text-danger small">* Required Fields</div>
        <div
          className="card mt-5 p-5"
          style={{ background: "#C1C1C115", border: "none" }}
        >
          <div className="card-body">
            <div className="row">
              <div className="col-lg-4">
                <div className="ila-grey-text" style={{ fontSize: 18 }}>
                  Personal Information
                </div>
              </div>
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group  mb-3">
                      <label htmlFor="title" className="ila-form-label">
                        Title <span className="text-danger">*</span>
                      </label>
                      <div>
                        <div
                          className="form-check form-check-inline"
                          onClick={() => setState({ ...state, title: "Mr" })}
                        >
                          <input
                            readOnly
                            className="form-check-input"
                            type="radio"
                            checked={state.title === "Mr"}
                          />
                          <label className="form-check-label">Mr</label>
                        </div>
                        <div
                          className="form-check form-check-inline"
                          onClick={() => setState({ ...state, title: "Ms" })}
                        >
                          <input
                            readOnly
                            className="form-check-input"
                            type="radio"
                            checked={state.title === "Ms"}
                          />
                          <label className="form-check-label">Ms</label>
                        </div>
                        <div
                          className="form-check form-check-inline"
                          onClick={() => setState({ ...state, title: "Mrs" })}
                        >
                          <input
                            readOnly
                            className="form-check-input"
                            type="radio"
                            checked={state.title === "Mrs"}
                          />
                          <label className="form-check-label">Mrs</label>
                        </div>
                        <div
                          className="form-check form-check-inline"
                          onClick={() => setState({ ...state, title: "Miss" })}
                        >
                          <input
                            readOnly
                            className="form-check-input"
                            type="radio"
                            checked={state.title === "Miss"}
                          />
                          <label className="form-check-label">Miss</label>
                        </div>
                        <div
                          className="form-check form-check-inline"
                          onClick={() => setState({ ...state, title: "Dr" })}
                        >
                          <input
                            readOnly
                            className="form-check-input"
                            type="radio"
                            checked={state.title === "Dr"}
                          />
                          <label className="form-check-label">Dr</label>
                        </div>
                      </div>
                    </div>
                    {errors.title && (
                      <div className="invalid-feedback d-block">
                        {errors.title.map((message) => {
                          return <div>{message}</div>;
                        })}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group  mb-3">
                      <label
                        htmlFor="firstname"
                        className="col-form-label ila-form-label"
                      >
                        First Names <span className="text-danger">*</span>
                      </label>
                      <div>
                        <input
                          onChange={(event) => {
                            setState({
                              ...state,
                              firstname: event.target.value,
                            });
                          }}
                          value={state.firstname}
                          type="text"
                          className={`form-control ${
                            errors.firstname && "is-invalid"
                          }`}
                          id="name"
                          placeholder="First Names"
                        />
                        {errors.firstname && (
                          <div className="invalid-feedback d-block">
                            {errors.firstname.map((message) => {
                              return <div>{message}</div>;
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group  mb-3">
                      <label
                        htmlFor="name"
                        className="col-form-label ila-form-label"
                      >
                        Last name <span className="text-danger">*</span>
                      </label>
                      <div>
                        <input
                          onChange={(event) => {
                            setState({
                              ...state,
                              lastname: event.target.value,
                            });
                          }}
                          value={state.lastname}
                          type="text"
                          className={`form-control ${
                            errors.lastname && "is-invalid"
                          }`}
                          id="name"
                          placeholder="Last Name"
                        />
                        {errors.lastname && (
                          <div className="invalid-feedback d-block">
                            {errors.lastname.map((message) => {
                              return <div>{message}</div>;
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group  mb-3">
                      <label
                        htmlFor="birth"
                        className="col-form-label ila-form-label"
                      >
                        Date of Birth <span className="text-danger">*</span>
                      </label>
                      <div>
                        <DatePicker
                          ref={birthRef}
                          dateFormat="dd/MM/yyyy"
                          className={`form-control ${
                            errors.birth && "is-invalid"
                          }`}
                          selected={state.birth ? state.birth : null}
                          maxDate={new Date(eighteenYearsAgo)}
                          minDate={new Date(sixtyFiveYearsAgo)}
                          id="birth"
                          locale="uk"
                          onChange={(date) =>
                            setState({ ...state, birth: date })
                          }
                        />
                        {errors.birth && (
                          <div className="invalid-feedback d-block">
                            {errors.birth.map((message) => {
                              return <div>{message}</div>;
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group  mb-3">
                      <label
                        htmlFor="nic"
                        className="ila-form-label  col-form-label"
                      >
                        NIC <span className="text-danger">*</span>
                      </label>
                      <div>
                        <input
                          onChange={(event) => {
                            setState({
                              ...state,
                              nic: event.target.value,
                            });
                          }}
                          value={state.nic}
                          type="text"
                          className={`form-control ${
                            errors.nic && "is-invalid"
                          }`}
                          id="nic"
                          placeholder=""
                        />
                        {errors.nic && (
                          <div className="invalid-feedback d-block">
                            {errors.nic.map((message) => {
                              return <div>{message.replace("nic", "NIC")}</div>;
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group  mb-3">
                      <label
                        htmlFor="gender"
                        className="ila-form-label col-form-label"
                      >
                        Gender <span className="text-danger">*</span>
                      </label>
                      <div>
                        <select
                          onChange={(event) => {
                            setState({
                              ...state,
                              gender: event.target.value,
                            });
                          }}
                          value={state.gender}
                          className={`form-select ila-inactive-input ${
                            errors.gender && "is-invalid"
                          }`}
                          aria-label="Default select example"
                        >
                          <option value="">Select...</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </select>
                        {errors.gender && (
                          <div className="invalid-feedback d-block">
                            {errors.gender.map((message) => {
                              return <div>{message}</div>;
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group  mb-3">
                      <label
                        htmlFor="email"
                        className="ila-form-label col-form-label"
                      >
                        Email <span className="text-danger">*</span>
                      </label>
                      <div>
                        <input
                          onChange={(event) => {
                            setState({ ...state, email: event.target.value });
                          }}
                          value={state.email}
                          type="text"
                          className={`form-control ${
                            errors.email && "is-invalid"
                          }`}
                          id="email"
                          placeholder="Email"
                        />
                        {errors.email && (
                          <div className="invalid-feedback d-block">
                            {errors.email.map((message) => {
                              return <div>{message}</div>;
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group  mb-3">
                      <label className="ila-form-label col-form-label">
                        Phone Number<span className="text-danger">*</span>
                      </label>
                      <div>
                        <CustomNumberInput
                          ref={phoneNumberRef}
                          errors={{}}
                          state={state}
                          setState={setState}
                          fieldName="phoneNumber"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group  mb-3">
                      <label
                        for="email"
                        className="ila-form-label col-form-label"
                      >
                        Whatsapp Number
                      </label>
                      <div>
                        <WhatsappNumberInput
                          ref={whatsappNumberRef}
                          errors={{}}
                          state={state}
                          setState={setState}
                        />
                      </div>
                    </div>
                  </div>

                  {/*NEW FIELDS*/}
                  <div className="form-group row mb-3">
                    <div className="col-12 col-lg-6">
                      <label
                        htmlFor="maiden_name"
                        className="col-sm-12  col-form-label ila-form-label"
                      >
                        Maiden Name
                      </label>

                      <input
                        onChange={(event) => {
                          setState({
                            ...state,
                            maiden_name: event.target.value,
                          });
                        }}
                        value={state.maiden_name}
                        type="text"
                        className={`col-sm-12 form-control ${
                          errors.maiden_name && "is-invalid"
                        }`}
                        id="maiden_name"
                        placeholder="Maiden Name"
                      />
                      {errors.maiden_name && (
                        <div className="invalid-feedback d-block">
                          {errors.maiden_name.map((message, index) => {
                            return <div key={index}>{message}</div>;
                          })}
                        </div>
                      )}
                    </div>
                    <div className="col-12 col-lg-6">
                      <label
                        htmlFor="passport_number"
                        className="col-sm-12  col-form-label ila-form-label"
                      >
                        Passport Number
                      </label>

                      <input
                        onChange={(event) => {
                          setState({
                            ...state,
                            passport_number: event.target.value,
                          });
                        }}
                        value={state.passport_number}
                        type="text"
                        className={`col-sm-12 form-control ${
                          errors.passport_number && "is-invalid"
                        }`}
                        id="passport_number"
                        placeholder="Passport Number"
                      />
                      {errors.passport_number && (
                        <div className="invalid-feedback d-block">
                          {errors.passport_number.map((message, index) => {
                            return <div key={index}>{message}</div>;
                          })}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="form-group row mb-3">
                    <div className="col-12 col-lg-6">
                      <label
                        htmlFor="nationality"
                        className="col-sm-12  col-form-label ila-form-label"
                      >
                        Nationality <span className="text-danger">*</span>
                      </label>

                      <input
                        onChange={(event) => {
                          setState({
                            ...state,
                            nationality: event.target.value,
                          });
                        }}
                        value={state.nationality}
                        type="text"
                        className={`col-sm-12 form-control ${
                          errors.nationality && "is-invalid"
                        }`}
                        id="nationality"
                        placeholder="Nationality"
                      />
                      {errors.nationality && (
                        <div className="invalid-feedback d-block">
                          {errors.nationality.map((message, index) => {
                            return <div key={index}>{message}</div>;
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* NEW FIELDS */}
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-lg-4">
                <div className="ila-grey-text" style={{ fontSize: 18 }}>
                  Loan Specifications
                </div>
              </div>
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group  mb-3">
                      <label
                        htmlFor="intended_commencement_date"
                        className="ila-form-label col-form-label"
                      >
                        Intended Commencement Date{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <div>
                        <DatePicker
                          ref={intendedCommencementDateRef}
                          dateFormat="dd/MM/yyyy"
                          className={`form-control ${
                            errors.intended_commencement_date && "is-invalid"
                          }`}
                          selected={
                            state.intended_commencement_date
                              ? state.intended_commencement_date
                              : null
                          }
                          maxDate={new Date(fifteenDaysFromToday)}
                          minDate={new Date(today)}
                          id="intended_commencement_date"
                          locale="uk"
                          onChange={(date) =>
                            setState({
                              ...state,
                              intended_commencement_date: date,
                            })
                          }
                        />
                        {errors.intended_commencement_date && (
                          <div className="invalid-feedback d-block">
                            {errors.intended_commencement_date.map(
                              (message) => {
                                return <div>{message}</div>;
                              }
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 col-lg-12 row pe-0">
                      <div className="col-12 col-lg-6">
                        <label
                          htmlFor="name"
                          className="col-sm-12  col-form-label ila-form-label"
                        >
                          Joint Loan <span className="text-danger">*</span>
                        </label>

                        <div className="col-sm-12">
                          <div
                            className="form-check form-check-inline"
                            onClick={() =>
                              setState({ ...state, join_loan: false })
                            }
                          >
                            <input
                              readOnly
                              className="form-check-input"
                              type="radio"
                              name="joinLoanRadio"
                              id="joinLoanRadioNo1"
                              checked={!state.join_loan}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="joinLoanRadioNo1"
                            >
                              No
                            </label>
                          </div>
                          <div
                            className="form-check form-check-inline"
                            onClick={() =>
                              setState({ ...state, join_loan: true })
                            }
                          >
                            <input
                              readOnly
                              className="form-check-input"
                              type="radio"
                              name="joinLoanRadio"
                              id="joinLoanRadioYes1"
                              checked={state.join_loan}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="joinLoanRadioYes1"
                            >
                              Yes
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-12 pe-0">
                        <label
                          htmlFor="name"
                          className="col-sm-12  col-form-label ila-form-label"
                        >
                          Policy Class <span className="text-danger">*</span>
                        </label>

                        <div className="col-sm-12">
                          <select
                            onChange={(event) => {
                              setState({
                                ...state,
                                loan_plan: event.target.value,
                              });
                            }}
                            value={state.loan_plan}
                            className={`form-select ${
                              errors.loan_plan && "is-invalid"
                            }`}
                            aria-label="Default select example"
                          >
                            <option value="">Select...</option>
                            <option value="dta">Loan Cover Plan</option>
                            <option value="lta">Protect Plan</option>
                            <option value="dta-lta">
                              Both Loan Cover and Protect Plan
                            </option>
                          </select>

                          {errors.loan_plan && (
                            <div className="invalid-feedback d-block">
                              {errors.loan_plan.map((message) => {
                                return <div>{message}</div>;
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group  mb-3">
                      <label
                        htmlFor="initial_sum_assured"
                        className=" col-form-label ila-form-label"
                      >
                        Sum Assured <span className="text-danger">*</span>
                      </label>
                      <div>
                        <CurrencyInput
                          className={`form-control ${
                            errors.initial_sum_assured && "is-invalid"
                          }`}
                          onValueChange={(event) => {
                            setState({
                              ...state,
                              initial_sum_assured: event,
                            });
                          }}
                          prefix="Rs "
                          value={state.initial_sum_assured}
                          placeholder="Sum Assured"
                        />

                        {errors.initial_sum_assured && (
                          <div className="invalid-feedback d-block">
                            {errors.initial_sum_assured.map((message) => {
                              return <div>{message}</div>;
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group  mb-3">
                      <label
                        htmlFor="term_number"
                        className="ila-form-label col-form-label"
                      >
                        Duration of Loan (Years){" "}
                        <span className="text-danger">*</span>
                      </label>
                      <div>
                        <input
                          onChange={(event) => {
                            setState({
                              ...state,
                              term_number: parseInt(event.target.value, 10),
                            });
                          }}
                          value={state.term_number}
                          type="number"
                          className={`form-control ${
                            errors.term_number && "is-invalid"
                          }`}
                          id="term_number"
                          placeholder="eg. 20"
                        />

                        {errors.term_number && (
                          <div className="invalid-feedback d-block">
                            {errors.term_number.map((message) => {
                              return <div>{message}</div>;
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group  mb-3">
                      <label
                        htmlFor="title"
                        className=" ila-form-label  col-form-label"
                      >
                        Financial Institution{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <div>
                        {banksOptions && (
                          <CreatableSelect
                            placeholder="Select..."
                            isClearable={true}
                            onChange={(val) => {
                              setState({
                                ...state,
                                bank_name: val.value,
                              });
                              setBankBranches(getBranchesForBank(val.value));
                              // debugger;
                              setBankBranchesOptions(
                                getBranchesForBank(val.value).map((item) => {
                                  return {
                                    label: `${item.name}-${item.town}`,
                                    value: `${item.name}-${item.town}`,
                                  };
                                })
                              );
                            }}
                            value={{
                              label: state.bank_name,
                              value: state.bank_name,
                            }}
                            options={banksOptions}
                          />
                        )}

                        {errors.bank_name && (
                          <div className="invalid-feedback d-block">
                            {errors.bank_name.map((message) => {
                              return <div>{message}</div>;
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group  mb-3">
                      <label
                        htmlFor="title"
                        className=" ila-form-label col-form-label"
                      >
                        Financial Institution Branch{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <div>
                        {banksOptions && (
                          <CreatableSelect
                            placeholder="Select..."
                            isClearable={true}
                            onChange={(val) => {
                              setState({
                                ...state,
                                bank_branch_name: val.value,
                              });
                            }}
                            value={{
                              label: state.bank_branch_name,
                              value: state.bank_branch_name,
                            }}
                            options={bankBranchesOptions}
                          />
                        )}

                        {errors.bank_branch_name && (
                          <div className="invalid-feedback d-block">
                            {errors.bank_branch_name.map((message) => {
                              return <div>{message}</div>;
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {state.bank_branch_name === "Other" && (
                    <div className="col-lg-6">
                      <div className="form-group r mb-3">
                        <label
                          htmlFor="joint_user_financial_institution_branch"
                          className="ila-form-label  col-form-label"
                        >
                          Please Specify Branch{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div>
                          <input
                            onChange={(event) => {
                              setState({
                                ...state,
                                joint_user_financial_institution_branch:
                                  event.target.value,
                              });
                            }}
                            value={
                              state.joint_user_financial_institution_branch
                            }
                            type="text"
                            className={`form-control `}
                            id="joint_user_financial_institution_branch"
                            placeholder="Financial Institution Branch"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-lg-6">
                    <div className="form-group  mb-3">
                      <label
                        htmlFor="bank_rep_name"
                        className="ila-form-label  col-form-label"
                      >
                        Bank Rep Name
                      </label>
                      <div>
                        <input
                          onChange={(event) => {
                            setState({
                              ...state,
                              bank_rep_name: event.target.value,
                            });
                          }}
                          value={state.bank_rep_name}
                          type="text"
                          className={`form-control `}
                          id="bank_rep_name"
                          placeholder="Bank Rep Name"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group  mb-3">
                      <label
                        htmlFor="bank_rep_email"
                        className="ila-form-label  col-form-label"
                      >
                        Bank Rep Email
                      </label>
                      <div className="">
                        <input
                          onChange={(event) => {
                            setState({
                              ...state,
                              bank_rep_email: event.target.value,
                            });
                          }}
                          value={state.bank_rep_email}
                          type="text"
                          className={`form-control `}
                          id="bank_rep_email"
                          placeholder="Bank Rep Email"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group  mb-3">
                      <label
                        for="bank_rep_contact"
                        className="ila-form-label col-form-label"
                      >
                        Bank Rep Contact
                      </label>
                      <div>
                        <CustomNumberInput
                          ref={bankRepContactRef}
                          errors={{}}
                          state={state}
                          setState={setState}
                          fieldName="bank_rep_contact"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group  mb-3">
                      <label
                        htmlFor="consent_bank_rep"
                        className="ila-form-label col-form-label"
                      >
                        Do you consent to ILA sending your Policy Documents to
                        your designated Bank Representative{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <div>
                        <div
                          className="form-check form-check-inline"
                          onClick={() =>
                            setState({ ...state, consent_bank_rep: false })
                          }
                        >
                          <input
                            readOnly
                            className="form-check-input"
                            type="radio"
                            checked={!state.consent_bank_rep}
                          />
                          <label className="form-check-label">No</label>
                        </div>
                        <div
                          className="form-check form-check-inline"
                          onClick={() =>
                            setState({ ...state, consent_bank_rep: true })
                          }
                        >
                          <input
                            readOnly
                            className="form-check-input"
                            type="radio"
                            checked={state.consent_bank_rep}
                          />
                          <label className="form-check-label">Yes</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-6">
                    <div className="form-group  mb-3">
                      <label
                        htmlFor="consent_bank_rep"
                        className="ila-form-label col-form-label"
                      >
                        Do you want physical delivery of documents?{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <div>
                        <div
                          className="form-check form-check-inline"
                          onClick={() =>
                            setState({ ...state, wantPhysicalDelivery: false })
                          }
                        >
                          <input
                            readOnly
                            className="form-check-input"
                            type="radio"
                            checked={!state.wantPhysicalDelivery}
                          />
                          <label className="form-check-label">No</label>
                        </div>
                        <div
                          className="form-check form-check-inline"
                          onClick={() =>
                            setState({ ...state, wantPhysicalDelivery: true })
                          }
                        >
                          <input
                            readOnly
                            className="form-check-input"
                            type="radio"
                            checked={state.wantPhysicalDelivery}
                          />
                          <label className="form-check-label">Yes</label>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            {state.join_loan && (
              <>
                <div className="row mt-5">
                  <div className="col-lg-4">
                    <div className="ila-grey-text" style={{ fontSize: 18 }}>
                      Joint Life Information
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group  mb-3">
                          <label className="ila-form-label col-form-label">
                            Phone Number for Joint Life
                            <span className="text-danger">*</span>
                          </label>
                          <div className="row">
                            <div className="col-sm-6">
                              <CustomNumberInput
                                ref={jointLifephoneNumberRef}
                                errors={{}}
                                state={state}
                                setState={setState}
                                fieldName="joint_user_phoneNumber"
                              />
                            </div>
                            <div className="col-sm-5">
                              <button
                                onClick={validateJointLife}
                                className="btn ila-primary-text "
                              >
                                {validatingJointLife && (
                                  <span className="spinner-border-sm spinner-border me-2"></span>
                                )}
                                Validate
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group  mb-3">
                          <label htmlFor="title" className="ila-form-label">
                            Title for Joint Life{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div>
                            <div
                              className="form-check form-check-inline"
                              onClick={() =>
                                setState({ ...state, joint_user_title: "Mr" })
                              }
                            >
                              <input
                                readOnly
                                className="form-check-input"
                                type="radio"
                                checked={state.joint_user_title === "Mr"}
                              />
                              <label className="form-check-label">Mr</label>
                            </div>
                            <div
                              className="form-check form-check-inline"
                              onClick={() =>
                                setState({ ...state, joint_user_title: "Ms" })
                              }
                            >
                              <input
                                readOnly
                                className="form-check-input"
                                type="radio"
                                checked={state.joint_user_title === "Ms"}
                              />
                              <label className="form-check-label">Ms</label>
                            </div>
                            <div
                              className="form-check form-check-inline"
                              onClick={() =>
                                setState({ ...state, joint_user_title: "Mrs" })
                              }
                            >
                              <input
                                readOnly
                                className="form-check-input"
                                type="radio"
                                checked={state.joint_user_title === "Mrs"}
                              />
                              <label className="form-check-label">Mrs</label>
                            </div>
                            <div
                              className="form-check form-check-inline"
                              onClick={() =>
                                setState({ ...state, joint_user_title: "Miss" })
                              }
                            >
                              <input
                                readOnly
                                className="form-check-input"
                                type="radio"
                                checked={state.joint_user_title === "Miss"}
                              />
                              <label className="form-check-label">Miss</label>
                            </div>
                            <div
                              className="form-check form-check-inline"
                              onClick={() =>
                                setState({ ...state, joint_user_title: "Dr" })
                              }
                            >
                              <input
                                readOnly
                                className="form-check-input"
                                type="radio"
                                checked={state.joint_user_title === "Dr"}
                              />
                              <label className="form-check-label">Dr</label>
                            </div>
                          </div>
                        </div>
                        {errors.joint_user_title && (
                          <div className="invalid-feedback d-block">
                            {errors.joint_user_title.map((message) => {
                              return <div>{message}</div>;
                            })}
                          </div>
                        )}
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group  mb-3">
                          <label
                            htmlFor="joint_user_firstname"
                            className="col-form-label ila-form-label"
                          >
                            First Names for Joint Life{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div>
                            <input
                              onChange={(event) => {
                                setState({
                                  ...state,
                                  joint_user_firstname: event.target.value,
                                });
                              }}
                              disabled={jointLifeFieldsDisabled}
                              value={state.joint_user_firstname}
                              type="text"
                              className={`form-control ${
                                errors.joint_user_firstname && "is-invalid"
                              }`}
                              id="name"
                              placeholder="First Names"
                            />
                            {errors.joint_user_firstname && (
                              <div className="invalid-feedback d-block">
                                {errors.joint_user_firstname.map((message) => {
                                  return <div>{message}</div>;
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group  mb-3">
                          <label
                            htmlFor="joint_user_name"
                            className="col-form-label ila-form-label"
                          >
                            Last name for Joint Life{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div>
                            <input
                              onChange={(event) => {
                                setState({
                                  ...state,
                                  joint_user_lastname: event.target.value,
                                });
                              }}
                              disabled={jointLifeFieldsDisabled}
                              value={state.joint_user_lastname}
                              type="text"
                              className={`form-control ${
                                errors.joint_user_lastname && "is-invalid"
                              }`}
                              id="name"
                              placeholder="Last Name"
                            />
                            {errors.joint_user_lastname && (
                              <div className="invalid-feedback d-block">
                                {errors.joint_user_lastname.map((message) => {
                                  return <div>{message}</div>;
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group  mb-3">
                          <label
                            htmlFor="joint_user_birth"
                            className="col-form-label ila-form-label"
                          >
                            Date of Birth for Joint Life{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div>
                            <DatePicker
                              ref={otherBirthRef}
                              dateFormat="dd/MM/yyyy"
                              className={`form-control ${
                                errors.joint_user_birth && "is-invalid"
                              }`}
                              selected={
                                state.joint_user_birth
                                  ? state.joint_user_birth
                                  : null
                              }
                              disabled={jointLifeFieldsDisabled}
                              maxDate={new Date(eighteenYearsAgo)}
                              minDate={new Date(sixtyFiveYearsAgo)}
                              id="joint_user_birth"
                              locale="uk"
                              onChange={(date) =>
                                setState({ ...state, joint_user_birth: date })
                              }
                            />
                            {errors.joint_user_birth && (
                              <div className="invalid-feedback d-block">
                                {errors.joint_user_birth.map((message) => {
                                  return <div>{message}</div>;
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group  mb-3">
                          <label
                            htmlFor="nic"
                            className="ila-form-label  col-form-label"
                          >
                            NIC for Joint Life{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div>
                            <input
                              onChange={(event) => {
                                setState({
                                  ...state,
                                  joint_user_nic: event.target.value,
                                });
                              }}
                              disabled={jointLifeFieldsDisabled}
                              value={state.joint_user_nic}
                              type="text"
                              className={`form-control ${
                                errors.joint_user_nic && "is-invalid"
                              }`}
                              id="joint_user_nic"
                              placeholder=""
                            />
                            {errors.joint_user_nic && (
                              <div className="invalid-feedback d-block">
                                {errors.joint_user_nic.map((message) => {
                                  return (
                                    <div>{message.replace("nic", "NIC")}</div>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group  mb-3">
                          <label
                            htmlFor="gender"
                            className="ila-form-label col-form-label"
                          >
                            Gender for Joint Life
                            <span className="text-danger">*</span>
                          </label>
                          <div>
                            <select
                              onChange={(event) => {
                                setState({
                                  ...state,
                                  joint_user_gender: event.target.value,
                                });
                              }}
                              disabled={jointLifeFieldsDisabled}
                              value={state.joint_user_gender}
                              className={`form-select ila-inactive-input ${
                                errors.joint_user_gender && "is-invalid"
                              }`}
                              aria-label="Default select example"
                            >
                              <option value="">Select...</option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                            </select>
                            {errors.joint_user_gender && (
                              <div className="invalid-feedback d-block">
                                {errors.joint_user_gender.map((message) => {
                                  return <div>{message}</div>;
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group  mb-3">
                          <label
                            htmlFor="email"
                            className="ila-form-label col-form-label"
                          >
                            Email for Joint Life{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div>
                            <input
                              onChange={(event) => {
                                setState({
                                  ...state,
                                  joint_user_email: event.target.value,
                                });
                              }}
                              disabled={jointLifeFieldsDisabled}
                              value={state.joint_user_email}
                              type="text"
                              className={`form-control ${
                                errors.joint_user_email && "is-invalid"
                              }`}
                              id="joint_user_email"
                              placeholder="Email"
                            />
                            {errors.joint_user_email && (
                              <div className="invalid-feedback d-block">
                                {errors.joint_user_email.map((message) => {
                                  return <div>{message}</div>;
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group  mb-3">
                          <label
                            for="email"
                            className="ila-form-label col-form-label"
                          >
                            Whatsapp Number for Joint Life
                          </label>
                          <div>
                            <WhatsappNumberInput
                              ref={whatsappNumberJointRef}
                              errors={{}}
                              state={state}
                              setState={setState}
                              field="joint_user_whatsappNumber"
                            />
                          </div>
                        </div>
                      </div>

                      {/*NEW FIELDS*/}
                      <div className="col-12 col-lg-6">
                        <label
                          htmlFor="joint_user_maiden_name"
                          className="col-sm-12 col-form-label ila-form-label"
                        >
                          Maiden Name{" "}
                        </label>

                        <div className="col-sm-12">
                          <input
                            disabled={jointLifeFieldsDisabled}
                            onChange={(event) => {
                              setState({
                                ...state,
                                joint_user_maiden_name: event.target.value,
                              });
                            }}
                            value={state.joint_user_maiden_name}
                            type="text"
                            className={`form-control ${
                              errors.joint_user_maiden_name && "is-invalid"
                            }`}
                            id="joint_user_maiden_name"
                            placeholder="Joint User Maiden Name"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <label
                          htmlFor="joint_user_passport_number"
                          className="col-sm-12 col-form-label ila-form-label"
                        >
                          Passport Number
                        </label>

                        <div className="col-sm-12">
                          <input
                            disabled={jointLifeFieldsDisabled}
                            onChange={(event) => {
                              setState({
                                ...state,
                                joint_user_passport_number: event.target.value,
                              });
                            }}
                            value={state.joint_user_passport_number}
                            type="text"
                            className={`form-control ${
                              errors.joint_user_passport_number && "is-invalid"
                            }`}
                            id="joint_user_passport_number"
                            placeholder="Joint User Passport Number"
                          />
                        </div>
                      </div>

                      <div className="col-12 col-lg-6">
                        <label
                          htmlFor="joint_user_nationality"
                          className="col-sm-12 col-form-label ila-form-label"
                        >
                          Nationality <span className="text-danger">*</span>
                        </label>

                        <div className="col-sm-12">
                          <input
                            disabled={jointLifeFieldsDisabled}
                            onChange={(event) => {
                              setState({
                                ...state,
                                joint_user_nationality: event.target.value,
                              });
                            }}
                            value={state.joint_user_nationality}
                            type="text"
                            className={`form-control ${
                              errors.joint_user_nationality && "is-invalid"
                            }`}
                            id="joint_user_nationality"
                            placeholder="Joint User Nationality"
                          />
                        </div>
                      </div>

                      <div className="col-12 col-lg-6 mt-3">
                        <label
                          htmlFor="joint_user_relationship"
                          className="col-sm-12 col-form-label ila-form-label"
                        >
                          Relationship <span className="text-danger">*</span>
                        </label>

                        <div className="col-sm-12">
                          <input
                            disabled={jointLifeFieldsDisabled}
                            onChange={(event) => {
                              setState({
                                ...state,
                                joint_user_relationship: event.target.value,
                              });
                            }}
                            value={state.joint_user_relationship}
                            type="text"
                            className={`form-control ${
                              errors.joint_user_relationship && "is-invalid"
                            }`}
                            id="joint_user_relationship"
                            placeholder="Joint User Relationship"
                          />
                        </div>
                      </div>
                      {/*NEW FIELDS*/}
                    </div>
                  </div>
                </div>
              </>
            )}

            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}
            <div className="d-flex justify-content-end mt-5">
              <button
                onClick={() => {
                  submitData();
                }}
                type="button"
                className="btn ila-primary-btn me-2 mb-3"
                disabled={loading}
              >
                {loading && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                Create Quotation
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleQuotationAuthenticated;
