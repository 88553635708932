/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../core/components/NavBar";
import UserContext from "../core/UserContext";
// Axios
import { getTotalPremium, getUserDataFromLocalStorage } from "../core/utils";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const Clients = () => {
  const { userData, setUserData } = useContext(UserContext);
  const userDataStorage = getUserDataFromLocalStorage();
  const axiosPrivate = useAxiosPrivate(userDataStorage);
  const params = useParams();
  const [clients, setClients] = useState();
  const [clientsLoading, setClientsLoading] = useState(false);
  const [quotation, setQuotation] = useState();

  useEffect(() => {
    getQuotationDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getQuotationDetails = async (fromShadowUpdate = false) => {
    const id = params.quotationId;
    const url = process.env.REACT_APP_API_URL + `/quotation/${id}`;
    setClientsLoading(true);

    try {
      const response = await axiosPrivate.get(url);
      setClientsLoading(false);
      if (response.data.success === true) {
        const _quotation = response.data.data;
        _quotation.quotationFormData = JSON.parse(_quotation.quotationFormData);
        // debugger;
        const totalPremium = getTotalPremium(_quotation);
        _quotation.totalPremium = totalPremium;

        setQuotation(_quotation);
        if (!_quotation.quotationFormData.join_loan && _quotation?.user) {
          setClients([{ userDetails: _quotation.user, isMainLife: true }]);
        } else if (
          _quotation.quotationFormData.join_loan &&
          _quotation?.user &&
          _quotation?.userJoint
        ) {
          setClients([
            { userDetails: _quotation.user, isMainLife: true },
            { userDetails: _quotation?.userJoint, isMainLife: false },
          ]);
        } else if (
          _quotation.quotationFormData.join_loan &&
          _quotation?.user &&
          !_quotation?.userJoint
        ) {
          setClients([{ userDetails: _quotation.user, isMainLife: true }]);
        } else {
          setClients([]);
        }
      }
      // console.log(response);
    } catch (error) {
      // console.log(error);
      setClientsLoading(false);
    }
  };

  const getClients = async () => {
    const url = process.env.REACT_APP_API_URL + `/user/worker/list/all`;

    try {
      setClientsLoading(true);
      const response = await axiosPrivate.get(url);
      setClientsLoading(false);
      if (response.data.success) {
        setClients(response.data.data);
      }
    } catch (err) {
      setClientsLoading(false);
      setClients([]);
      // console.log(err);
    }
  };
  return (
    <>
      <Navbar
        name={
          userData
            ? `${userData?.data?.firstname} ${userData?.data?.lastname}`
            : ""
        }
      />

      <div className="container">
        {!clientsLoading && clients && (
          <>
            <h4 className="mt-4 ila-grey-text">Clients</h4>
            <a
              className="ila-grey-link"
              href={`${process.env.REACT_APP_BASENAME}/quotations/${params.quotationId}`}
            >
              <span className="fa fa-chevron-left me-2 my-4"></span>
              Back to Quotation
            </a>
            <div className="alert alert-info">
              You cannot proceed to underwritting if all clients involved have
              not verified their kyc details. You can verify a client's kyc by
              clicking button beside client
            </div>
            <table className="table mt-5">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Gender</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Whatsapp</th>
                  <th scope="col">Birthday</th>
                  <th scope="col">NIC</th>
                  <th scope="col">KYC done</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {clients.map((client, index) => {
                  return (
                    <tr>
                      <td>{client.userDetails.name}</td>
                      <td>{client.userDetails.gender}</td>
                      <td>{client.userDetails.email}</td>
                      <td>{client.userDetails.phoneNumber}</td>
                      <td>{client.userDetails.whatsappNumber}</td>
                      <td>{client.userDetails.birth}</td>
                      <td>{client.userDetails.nic}</td>
                      <td className="text-center">
                        <span>
                          {!client.userDetails.kycVerification ? (
                            <div className="badge bg-danger">No</div>
                          ) : (
                            <div className="badge bg-success">Yes</div>
                          )}
                        </span>
                      </td>
                      <td>
                        {/* ${
                            client.userDetails.kycVerification ? "disabled" : ""
                          } */}
                        <a
                          className={`btn ila-action-link 
                         
                          `}
                          href={`${process.env.REACT_APP_BASENAME}/client-kyc/${
                            client.userDetails.id
                          }/${params.quotationId}/${
                            client.isMainLife ? "main" : "joint"
                          }`}
                        >
                          Perform KYC
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
        {clientsLoading && (
          <>
            <h4 className="mt-4">Clients</h4>
            <div
              style={{ marginTop: 150 }}
              className="d-flex flex-column justify-content-center align-items-center"
            >
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <div>Please wait...</div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Clients;
