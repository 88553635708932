import React from "react";

const RegistrationSuccess = () => {
  return (
    <div className="container-scroller my-5">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-md-6 grid-margin stretch-card mx-auto">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">You are almost there</h4>
                  <p className="card-description">
                    Check your email for and OTP code to verify your account
                  </p>
                  <div className="login_wrapper">
                    <div className="login_btn_wrapper">
                      <a href={`${process.env.REACT_APP_BASENAME}/login`} className="btn btn-danger login_btn">
                        Login
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationSuccess;
