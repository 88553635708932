import React from "react";
import { getAutoPassList } from "../../core/utils";
import TextInput from "react-autocomplete-input";
import "react-autocomplete-input/dist/bundle.css";
import { MultiSelect } from "react-multi-select-component";

const UnderwritingBooked = ({ state, setState, number }) => {
  const autoPassList = getAutoPassList();
  const autoPassListOptions = getAutoPassList().map((item) => {
    return { label: item, value: item };
  });

  return (
    <div>
      <div className="mx-4">
        <div className="row my-3">
          <div className="col-sm-12 mb-2">
            <h6 className="fw-bold">
              {number}. Have you been booked off work, or taken sick leave for
              more than 2 weeks in the past 12 months?{" "}
            </h6>{" "}
          </div>
          <div className="col-sm-12 mb-2">
            <div className="row mx-2">
              <div
                className="col-sm-2 form-check"
                onClick={() => {
                  setState({ ...state, bookedOffWorkRecently: true });
                }}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  checked={state.bookedOffWorkRecently}
                ></input>
                <label className="form-check-label" htmlFor="refund1">
                  Yes
                </label>
              </div>
              <div
                className="col-sm-2 form-check"
                onClick={() => {
                  setState({ ...state, bookedOffWorkRecently: false });
                }}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  checked={state.bookedOffWorkRecently == false}
                ></input>
                <label className="form-check-label" htmlFor="refund2">
                  No
                </label>
              </div>
            </div>
          </div>
          {!!state.bookedOffWorkRecently && (
            <div className="col-sm-12 mb-2">
              <div className="col-lg-8 mb-2">
                <div className="form-group row mb-3">
                  <div className="col-sm-12 mb-2">
                    <h6 className="fw-bold mt-4">
                      For which condition/symptom (s) or complaint where you
                      booked off for?
                    </h6>{" "}
                  </div>

                  <div className="col-sm-8 ">
                    <MultiSelect
                      options={autoPassListOptions}
                      value={state.conditionOrComplaintBookedOffFor}
                      onChange={(val) => {
                        setState({
                          ...state,
                          conditionOrComplaintBookedOffFor: val,
                        });
                      }}
                      isCreatable={true}
                      onCreateOption={(value) => ({
                        label: value.toUpperCase(),
                        value: value.toUpperCase(),
                      })}
                      labelledBy="Select"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UnderwritingBooked;
