/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// Axios
import { getUserDataFromLocalStorage } from "../../core/utils";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import "./UnderwritingSummaryPdfPage.css";
// eslint-disable-next-line no-unused-vars
import { Document, Outline, Page, pdfjs } from "react-pdf";
import { Dropdown, DropdownButton } from "react-bootstrap";

const UnderwritingSummaryPdfPage = () => {
  const params = useParams();
  const userDataStorage = getUserDataFromLocalStorage();
  const axiosPrivate = useAxiosPrivate(userDataStorage);
  const quotationId = params.quotationId;
  const [fileURL, setFileURL] = useState();
  const [loading, setLoading] = useState(false);
  const [hasFinancialUnderwriting, setHasFinancialUnderwriting] =
    useState(false);
  const [updatingQuotationStatus, setUpdatingQuotationStatus] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [userId, setUserId] = useState(null);
  // const [pageNumber, setPageNumber] = useState(1);
  const documentRef = useRef();

  // useEffect(() => {
  //   if (pageNumber) {
  //     getQuotationSummary();
  //   }
  // }, [pageNumber]);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const navigate = useNavigate();

  const getQuotationSummary = async () => {
    setLoading(true);
    setFileURL(null);
    const url =
      process.env.REACT_APP_API_URL +
      "/quotation/user/download/proposal-form-pdf/" +
      quotationId;

    axiosPrivate
      .get(url, {
        responseType: "blob",
      })
      //   .then((response) => response.blob())
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.download = "some.pdf";

        const fileUrl = URL.createObjectURL(blob);

        setFileURL(fileUrl);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
    // debugger;
  };

  const updateQuotationStatus = async (payload) => {
    setUpdatingQuotationStatus(true);
    const url =
      process.env.REACT_APP_API_URL +
      `/quotation/worker/status-update/${quotationId}`;

    try {
      payload = { ...payload, userId: params.userId };
      const { data } = await axiosPrivate.patch(url, payload);

      setUpdatingQuotationStatus(false);

      if (!data.success) {
        // console.log(
        //   data.message
        //     ? data.message
        //     : "Quotation update was unsuccessful, please try again"
        // );
      } else {
        // navigate(`/review/${quotation.id}`);
      }
    } catch (error) {
      setUpdatingQuotationStatus(false);
      // console.log(error);
    }
  };

  const getEightMillionRuleBoolean = async (
    amount,
    userId,
    userJointId = null
  ) => {
    const url = process.env.REACT_APP_API_URL + `/quotation/check/exceeded`;

    try {
      const response = await axiosPrivate.post(url, {
        amount,
        userId,
        quotationId: parseInt(quotationId),
        userJointId,
      });
      return response.data.success ? response.data.data : false;
    } catch (err) {
      // debugger;
      // console.log(err);
      return false;
    }
  };

  const getTenMillionRuleBoolean = async (
    amount,
    userId,
    userJointId = null
  ) => {
    const url =
      process.env.REACT_APP_API_URL + `/quotation/check/exceeded/tenmillion`;

    try {
      const response = await axiosPrivate.post(url, {
        amount,
        userId,
        quotationId: parseInt(quotationId),
        userJointId,
      });
      return response.data.success ? response.data.data : false;
    } catch (err) {
      // debugger;
      console.log(err);
      return false;
    }
  };

  const getQuotationDetails = async () => {
    const id = params.quotationId;
    const url = process.env.REACT_APP_API_URL + `/quotation/${quotationId}`;

    try {
      const response = await axiosPrivate.get(url);
      if (response.data.success === true) {
        const quotation = response.data.data;
        quotation.quotationFormData = JSON.parse(quotation.quotationFormData);

        // const eightMillionRuleBoolean = await getEightMillionRuleBoolean(
        //   quotation?.quotationFormData?.initial_sum_assured,
        //   quotation?.user?.id,
        //   quotation?.userJoint?.id || null
        // );

        // const tenMillionRuleBoolean = await getTenMillionRuleBoolean(
        //   quotation?.quotationFormData?.initial_sum_assured,
        //   quotation?.user?.id,
        //   quotation?.userJoint?.id || null
        // );

        const tenMillionRuleBoolean = quotation?.livesTenMillionData
          ? JSON.parse(quotation?.livesTenMillionData)
          : null;
        const eightMillionRuleBoolean = quotation?.livesEightMillionData
          ? JSON.parse(quotation?.livesEightMillionData)
          : null;

        console.log({
          tenMillionRuleBoolean,
          eightMillionRuleBoolean,
          quotation,
        });

        if (
          (tenMillionRuleBoolean?.mainLifeAmountExceededTenMillion == true ||
            tenMillionRuleBoolean?.jointLifeAmountExceededTenMillion == true) &&
          quotation.isLtaResult == true &&
          quotation.isDtaResult == false &&
          (eightMillionRuleBoolean?.mainLifeAmountExceededEightMillion ==
            true ||
            eightMillionRuleBoolean?.jointLifeAmountExceededEightMillion ==
              true)
        ) {
          setHasFinancialUnderwriting(true);
        } else {
          setHasFinancialUnderwriting(false);
        }
      }
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    getQuotationDetails();
    getQuotationSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="container"
      style={{ width: "100%", height: "100%", paddingTop: 40 }}
    >
      {/* <nav className="nav nav-pills nav-justified mt-5">
        <a className="nav-item nav-link active" href="#">Active</a>
        <a className="nav-item nav-link" href="#">Link</a>
        <a className="nav-item nav-link" href="#">Link</a>
        <a className="nav-item nav-link disabled" href="#">Disabled</a>
      </nav> */}
      <div
        className="mt-4 d-flex justify-content-between align-items-center p-4 px-5 text-white ila-bg"
        style={{ borderRadius: 2 }}
      >
        {hasFinancialUnderwriting ? (
          <DropdownButton
            id="dropdown-basic-button"
            title="Medical Underwriting Summary"
          >
            <Dropdown.Item
              href={`${process.env.REACT_APP_BASENAME}/underwriting-summary/${params.userId}/${quotationId}`}
            >
              Medical Underwriting Summary
            </Dropdown.Item>
            <Dropdown.Item
              href={`${process.env.REACT_APP_BASENAME}/financial-underwriting-summary/${params.userId}/${quotationId}`}
            >
              Financial Underwriting Summary
            </Dropdown.Item>
          </DropdownButton>
        ) : (
          <h4 className="mb-0">Medical Underwriting Summary</h4>
        )}

        <div className="d-flex flex-wrap">
          <a
            style={{ cursor: "pointer !important" }}
            className="btn ila-accent-btn login_btn text-white me-3 mt-3"
            onClick={() => {
              navigate(`/underwriting/${params.quotationId}/main-life`);
            }}
          >
            Back to Medical Underwriting
          </a>
          <a
            style={{ cursor: "pointer !important" }}
            className="btn ila-accent-btn login_btn text-white mt-3"
            onClick={() => {
              updateQuotationStatus({ status: "kyc" }).then((res) => {
                navigate(
                  `/client-kyc/${params.userId}/${params.quotationId}/main`
                );
              });
            }}
          >
            {updatingQuotationStatus && (
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            Proceed
          </a>
        </div>
      </div>

      {fileURL && (
        // <iframe style={{ width: "100%", height: "100%" }} src={fileURL} />
        <div
          style={{
            maxHeight: "600px",
            width: "100%",
            textAlign: "center",
            marginBottom: 100,
          }}
        >
          {/* <div
            style={{ background: "#363636" }}
            className="d-flex justify-content-center align-items-center py-3"
          >
            <button
              onClick={() => {
                if (pageNumber > 1) {
                  setPageNumber(pageNumber - 1);
                }
              }}
              className="btn btn-light"
            >
              Previous{" "}
            </button>
            <span className="px-4 text-white">
              Page {pageNumber} of {numPages}
            </span>
            <button
              onClick={() => {
                if (pageNumber < numPages) {
                  setPageNumber(pageNumber + 1);
                }
              }}
              className="btn btn-light"
            >
              Next{" "}
            </button>
          </div> */}
          <Document
            ref={documentRef}
            style={{ width: "100%" }}
            file={fileURL}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                scale={1.5}
              />
            ))}
          </Document>
        </div>
      )}
      {loading && (
        <div className="d-flex justify-content-center flex-column mt-5 align-items-center">
          <span
            className="spinner-border  me-2"
            role="status"
            aria-hidden="true"
          ></span>
          <div>Please wait ...</div>
        </div>
      )}
    </div>
  );
};

export default UnderwritingSummaryPdfPage;
