/* eslint-disable eqeqeq */
import React from "react";
import {
  getAutoPassList,
  getClavicleCollarboneHamstringToeFingerList,
  getHipList,
  getKneeList,
  getShoulderList,
  getBackpainList,
  getMentalHealthList,
} from "../../../core/utils";

const CummulativePurpose = ({ number, state, setState, updatePages }) => {
  // eslint-disable-next-line no-unused-vars
  const autoPassList = getAutoPassList();
  const clavicleCollarboneHamstringToeFingerList =
    getClavicleCollarboneHamstringToeFingerList();
  const kneeList = getKneeList();
  const hipList = getHipList();
  const shoulderList = getShoulderList();
  const backPainList = getBackpainList();
  const mentalHealthList = getMentalHealthList();

  return (
    <div>
      <div className="mx-4">
        <div className="row my-3">
          <div className="col-sm-12 mb-2">
            <h6 className="fw-bold">
              {number}. What is the purpose of this proposal for Life Assurance?
              (You can tick more than one box)
            </h6>{" "}
          </div>
          <div className="col-sm-12 mb-2">
            <div className="row mx-2">
              <div
                className="col-sm-12 form-check"
                onClick={() => {
                  setState({
                    ...state,
                    isPersonalProtection: !state.isPersonalProtection,
                  });
                }}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={false}
                  checked={state.isPersonalProtection == true}
                ></input>
                <label className="form-check-label">Personal Protection</label>
              </div>
              <div
                className="col-sm-12 form-check"
                onClick={() => {
                  setState({
                    ...state,
                    isMortgageLoanProtection: !state.isMortgageLoanProtection,
                  });
                }}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={false}
                  checked={state.isMortgageLoanProtection == true}
                ></input>
                <label className="form-check-label">
                  Mortgage/Loan Protection
                </label>
              </div>
              <div
                className="col-sm-12 form-check"
                onClick={() => {
                  setState({
                    ...state,
                    isInheritanceTaxCover: !state.isInheritanceTaxCover,
                  });
                }}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={false}
                  checked={state.isInheritanceTaxCover == true}
                ></input>
                <label className="form-check-label">
                  Inheritance Tax Cover
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CummulativePurpose;
