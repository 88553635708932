import React, { useEffect, useState, useMemo } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
// Pages
import Registration from "./registration/Registration";
import Login from "./login/Login";
import Verification from "./verification/Verification";
import RegistrationSuccess from "./registration-success/RegistrationSuccess";
import ChangePassword from "./change-password/ChangePassword";
import { redirect } from "react-router-dom";
// import ProtectedRoute from "./core/components/ProtectedRoute";
import JointQuotation from "./quote/JointQuotation";
import Onboarding from "./onboarding/Onboarding";
// import SingleQuotationUnthenticated from "./quote/SingleQuotationUnauthenticated";
import SingleQuotationAuthenticated from "./quote/SingleQuotationAuthenticated";
import Quotations from "./quotations/Quotations";
import QuotationView from "./quotation-view/QuotationView";
import ResetPasswordRequest from "./reset-password-request/ResetPasswordRequest";
import ResetPassword from "./reset-password/ResetPassword";
import Policies from "./policies/Policies";
import Application from "./application/Application";
import Profile from "./profile/Profile";
import Underwriting from "./underwriting/Underwriting";
import EditQuotation from "./quote/EditQuotation";
// import Reflexes from "./underwriting/Reflexes";
import KYC from "./kyc/Kyc";
import ReviewAcceptanceAndSigning from "./review-acceptance-and-signing/ReviewAcceptanceAndSigning";
import InsurancePolicy from "./insurance-policy/InsurancePolicy";
import FirstPayment from "./payment/first-payment/FirstPayment";
import ShuftiRedirectPage from "./ShuftiRedirectPage";
import HasCompletedInitialSetup from "./core/components/HasCompletedInitialSetup";
import Clients from "./clients/Clients";
import ClientKyc from "./client-kyc/ClientKyc";
import LoggedInLayout from "./core/components/loggedin-layout/LoggedInLayout";
import coupleImage from "./core/assets/images/couple.png";
import graduationImage from "./core/assets/images/graduation.png";
import handsImage from "./core/assets/images/hands.png";
import dreamHomeImage from "./core/assets/images/dream-home.png";
import TerminalDisplay from "./underwriting/TerminalDisplay";
import MipsRedirectPage from "./MipsRedirectPage";
import UnderwritingSummaryPdfPage from "./underwriting/underwriting-summary-pdf-page/UnderwritingSummaryPdfPage";
import { ToastContainer } from "react-toastify";
// Local Storage
import UserContext from "./core/UserContext";
import { getUserDataFromLocalStorage } from "./core/utils";
// Auth Components
import PersistLogin from "./layouts/auth/PersistLogin";
import RequireAuth from "./layouts/auth/RequireAuth";
import FinancialUnderwriting from "./underwriting/FinancialUnderwriting";
import FinancialUnderwritingSummaryPdfPage from "./underwriting/financial-underwriting-summary-pdf-page/FinancialUnderwritingSummaryPdfPage";

function App() {
  const [userData, setUserData] = useState(null);
  const providerValue = useMemo(
    () => ({ userData, setUserData }),
    [userData, setUserData]
  );

  useEffect(() => {
    const userDataFromLocalStorage = getUserDataFromLocalStorage();
    setUserData(userDataFromLocalStorage);
  }, []);

  return (
    <>
      <UserContext.Provider value={providerValue}>
        <Routes>
          <Route
            path="/request-password-update"
            element={<ResetPasswordRequest />}
          />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Registration />} />
          <Route
            path="/successful-registration"
            element={<RegistrationSuccess />}
          />
          <Route path="/verification" element={<Verification />} />
          <Route path="/onboarding" element={<Onboarding />} />
          {/* <Route path="/quotation" element={<SingleQuotationUnthenticated />} /> */}

          <Route path="/shufti-redirect" element={<ShuftiRedirectPage />} />

          {/* We want to protect these routes */}
          <Route element={<PersistLogin />}>
            <Route path="/" element={<RequireAuth />}>
              <Route index element={<Navigate replace to="/quotations" />} />
              <Route path="/insurance-policy" element={<InsurancePolicy />} />
              <Route
                path="/change-password"
                element={<LoggedInLayout component={<ChangePassword />} />}
              />
              <Route
                path="/quotations/:quotationId/first-payment"
                element={<LoggedInLayout component={<FirstPayment />} />}
              />
              <Route
                path="/underwriting/:quotationId/:life"
                element={
                  <LoggedInLayout
                    component={
                      <HasCompletedInitialSetup component={<Underwriting />} />
                    }
                  />
                }
              />
              <Route
                path="/financial-underwriting/:quotationId/:life"
                element={
                  <LoggedInLayout
                    component={
                      <HasCompletedInitialSetup
                        component={<FinancialUnderwriting />}
                      />
                    }
                  />
                }
              />
              <Route
                path="/underwriting-summary/:userId/:quotationId"
                element={
                  <LoggedInLayout
                    component={
                      <HasCompletedInitialSetup
                        component={<UnderwritingSummaryPdfPage />}
                      />
                    }
                  />
                }
              />
              <Route
                path="/financial-underwriting-summary/:userId/:quotationId"
                element={
                  <LoggedInLayout
                    component={
                      <HasCompletedInitialSetup
                        component={<FinancialUnderwritingSummaryPdfPage />}
                      />
                    }
                  />
                }
              />
              <Route
                path="/kyc/:quotationId"
                element={<HasCompletedInitialSetup component={<KYC />} />}
              />
              <Route
                path="/client-kyc/:id/:quotationId/:life"
                element={
                  <LoggedInLayout
                    component={
                      <HasCompletedInitialSetup component={<ClientKyc />} />
                    }
                  />
                }
              />
              <Route
                path="/clients/:quotationId"
                element={
                  <LoggedInLayout
                    component={
                      <HasCompletedInitialSetup component={<Clients />} />
                    }
                  />
                }
              />
              <Route
                path="/review/:quotationId"
                element={
                  <LoggedInLayout
                    component={
                      <HasCompletedInitialSetup
                        component={<ReviewAcceptanceAndSigning />}
                      />
                    }
                  />
                }
              />
              <Route
                path="/create-quotation"
                element={
                  <LoggedInLayout
                    component={
                      <HasCompletedInitialSetup
                        component={<SingleQuotationAuthenticated />}
                      />
                    }
                  />
                }
              />
              <Route
                path="/quotations"
                element={
                  <HasCompletedInitialSetup
                    component={
                      <LoggedInLayout
                        navImage={coupleImage}
                        component={<Quotations />}
                      />
                    }
                  />
                }
              />
              <Route
                path="/joint-quote"
                element={
                  <HasCompletedInitialSetup component={<JointQuotation />} />
                }
              />
              <Route
                path="/quotations/:id"
                element={
                  <LoggedInLayout
                    navImage={handsImage}
                    component={
                      <HasCompletedInitialSetup component={<QuotationView />} />
                    }
                  />
                }
              />
              <Route
                path="/policies"
                element={
                  <LoggedInLayout
                    navImage={coupleImage}
                    component={<Policies />}
                  />
                }
              />
              <Route
                path="/application"
                element={
                  <LoggedInLayout
                    navImage={graduationImage}
                    component={
                      <HasCompletedInitialSetup component={<Application />} />
                    }
                  />
                }
              />
              <Route
                path="/profile"
                element={
                  <LoggedInLayout
                    navImage={dreamHomeImage}
                    component={<Profile />}
                  />
                }
              />
              <Route
                path="/application-in-review"
                element={
                  <HasCompletedInitialSetup component={<TerminalDisplay />} />
                }
              />
              <Route path="/profile" element={<Profile />} />

              <Route
                path="/quotations/:id/edit"
                element={
                  <LoggedInLayout
                    navImage={dreamHomeImage}
                    component={
                      <HasCompletedInitialSetup component={<EditQuotation />} />
                    }
                  />
                }
              />
              <Route
                path="/mips-redirect/:quotationId/:operationType/:paymentChannelId"
                element={
                  <HasCompletedInitialSetup component={<MipsRedirectPage />} />
                }
              />
              <Route
                path="/mips-redirect/:quotationId/:operationType/:paymentChannelId/:userId/:policyNumber/:amountExpected/:amountPaid/:internalPaymentReference"
                element={
                  <HasCompletedInitialSetup component={<MipsRedirectPage />} />
                }
              />
            </Route>
          </Route>
        </Routes>
      </UserContext.Provider>
      <ToastContainer />
    </>
  );
}

export default App;
