/* eslint-disable eqeqeq */
import React from "react";
import {
  getAutoPassList,
  getClavicleCollarboneHamstringToeFingerList,
  getHipList,
  getKneeList,
  getShoulderList,
  getBackpainList,
  getMentalHealthList,
} from "../../../../core/utils";
import { MultiSelect } from "react-multi-select-component";

const PersonalDependents = ({ number, state, setState }) => {
  // eslint-disable-next-line no-unused-vars
  const autoPassList = getAutoPassList();
  const clavicleCollarboneHamstringToeFingerList =
    getClavicleCollarboneHamstringToeFingerList();
  const kneeList = getKneeList();
  const hipList = getHipList();
  const shoulderList = getShoulderList();
  const backPainList = getBackpainList();
  const mentalHealthList = getMentalHealthList();

  return (
    <div>
      <div className="mx-4">
        <div className="row my-3">
          <div className="col-sm-12 mb-2">
            <h6 className="fw-normal">Personal Protection</h6>
            <h6 className="fw-bold">
              {number}. How many dependants do you have and what is the
              relationship between you?
            </h6>
          </div>
          <div className="col-sm-12 mb-2">
            {/* First life assured */}
            {state.life == "main-life" && (
              <div className="row mx-2 mb-3">
                <div className="col-sm-5 d-flex">
                  <input
                    value={state.dependents_main_life}
                    onChange={(e) => {
                      setState({
                        ...state,
                        dependents_main_life: e.target.value,
                      });
                    }}
                    className="form-control w-100"
                  />
                </div>
              </div>
            )}

            {/* Second life assured */}
            {state.life == "joint-life" && (
              <div className="row mx-2 mb-3 mt-2">
                <div className="col-sm-5 d-flex">
                  <input
                    value={state.dependents_joint_life}
                    onChange={(e) => {
                      setState({
                        ...state,
                        dependents_joint_life: e.target.value,
                      });
                    }}
                    className="form-control w-100"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalDependents;
