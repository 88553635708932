import React from "react";
import { Navigate } from "react-router-dom";
// import Onboarding from "../../onboarding/Onboarding";
import { getUserDataFromLocalStorage } from "../utils";

const HasCompletedInitialSetup = ({
  component,
  skipPassword = false,
  skipUpdate = false,
}) => {
  const userData = getUserDataFromLocalStorage();
  const data = userData?.data;
  if (skipPassword) {
    if (!data.areInitialDetailsCompleted) {
      return <Navigate to="/profile" />;
    } else {
      return component;
    }
  } else if (skipUpdate) {
    if (!data.isFirstUpdatePasswordCompleted) {
      return <Navigate to="/change-password" />;
    } else {
      return component;
    }
  } else {
    if (!data.isFirstUpdatePasswordCompleted) {
      return <Navigate to="/change-password" />;
    } else if (!data.areInitialDetailsCompleted) {
      return <Navigate to="/profile" />;
    } else {
      return component;
    }
  }
};

export default HasCompletedInitialSetup;
