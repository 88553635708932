/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomNumberInput from "../core/components/custom-number-input/CustomNumberInput";
// Axios
import axios from "../api/axios";

const ResetPassword = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const phoneNumberRef = useRef();
  const [state, setState] = useState({
    username: "",
    provider: "email",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});
  const requestPasswordUpgrade = async () => {
    const url = process.env.REACT_APP_API_URL + `/user/reset-password`;

    try {
      setLoading(true);
      setError(null);

      const payload = { ...state };
      if (state.provider === "email") {
        payload.phoneNumber = null;
      } else {
        payload.email = null;
      }
      payload.otp = parseInt(payload.otp, 10);
      setSuccess(null);
      const { data } = await axios.post(url, payload);
      setErrors({});
      setLoading(false);

      if (data.success) {
        setSuccess(`Password have been Successfully reset. Please sign in`);
        setTimeout(() => {
          navigate("/login");
        }, 3000);
        setError(null);
      } else {
        setSuccess(null);
        setError(
          data.message
            ? data.message
            : "Password update request was unsuccessful"
        );
      }
    } catch (error) {
      setSuccess(null);
      setLoading(false);
      setError(null);
      if (
        error?.response?.data?.errors &&
        Object.keys(error?.response?.data?.errors).length > 0
      ) {
        const errorsFromServer = error.response.data.errors;
        console.error(errorsFromServer);
        let errorData = {};
        if (errorsFromServer.length > 0) {
          errorsFromServer.forEach((err) => {
            if (!errorData[err.field]) {
              errorData[err.field] = [];
            }
            errorData[err.field].push(err.message);
          });
        }
        setErrors(errorData);
      } else if (error?.response?.data?.message) {
        setError(error?.response?.data?.message);
      } else {
        setError("Password Change Unsuccessful");
      }
    }
  };
  const otp = searchParams.get("otp");
  const provider = searchParams.get("provider");
  const username = searchParams.get("username");

  useEffect(() => {
    setState({
      ...state,
      otp,
      provider,
      username,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <>
      <div className="container">
        <h4 className="mt-4">Reset Password</h4>
        <div className="container-scroller">
          <div className="container-fluid page-body-wrapper full-page-wrapper">
            <div className="content-wrapper d-flex align-items-center auth px-0">
              <div className="row w-100 mx-0">
                <div className="col-md-6 grid-margin stretch-card mx-auto">
                  <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                    {error && (
                      <div className="alert alert-danger" role="alert">
                        {error}
                      </div>
                    )}
                    {success && (
                      <div className="alert alert-success" role="alert">
                        {success}
                      </div>
                    )}
                    <form className="pt-1">
                      <div className="login_wrapper">
                        <div className="formsix-pos">
                          <div className="form-group i-email">
                            {state.provider === "email" && (
                              <input
                                type="text"
                                value={state.username}
                                onChange={(event) => {
                                  setState({
                                    ...state,
                                    username: event.target.value,
                                  });
                                }}
                                className="form-control"
                                required=""
                                id="email2"
                                placeholder="Email"
                              />
                            )}
                            {state.provider === "phone" && (
                              <CustomNumberInput
                                ref={phoneNumberRef}
                                errors={errors}
                                setState={setState}
                                state={state}
                              />
                            )}
                            {errors.username && (
                              <div className="text-danger small">
                                {errors.username.map((message) => {
                                  return <div>{message}</div>;
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="formsix-e">
                          <div className="form-group i-password">
                            <input
                              value={state.password}
                              onChange={(event) => {
                                setState({
                                  ...state,
                                  password: event.target.value,
                                });
                              }}
                              type="password"
                              className="form-control"
                              required=""
                              id="password1"
                              placeholder="New Password *"
                            />
                            {errors.password && (
                              <div className="text-danger small">
                                {errors.password.map((message) => {
                                  return <div>{message}</div>;
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="formsix-e">
                          <div className="form-group i-password">
                            <input
                              value={state.confirmPassword}
                              onChange={(event) => {
                                setState({
                                  ...state,
                                  confirmPassword: event.target.value,
                                });
                              }}
                              type="password"
                              className="form-control"
                              required=""
                              id="password2"
                              placeholder="Confirm Password *"
                            />
                            {errors.confirmPassword && (
                              <div className="text-danger small">
                                {errors.confirmPassword.map((message) => {
                                  return <div>{message}</div>;
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="login_btn_wrapper">
                          <a
                            className="btn btn-ila login_btn"
                            onClick={requestPasswordUpgrade}
                            disabled={loading}
                          >
                            {loading && (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            )}{" "}
                            Save{" "}
                          </a>
                        </div>
                        <div className="login_message">
                          <p>
                            <a href={`${process.env.REACT_APP_BASENAME}/login`}> Sign in </a>{" "}
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
