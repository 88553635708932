import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
// Axios
import axios from "../api/axios";
// import { saveUserDataInLocalStorage } from "../core/utils";
// Date plugins
import uk from "date-fns/locale/en-GB";
registerLocale("uk", uk);

const Registration = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    title: "Mr",
    name: "",
    email: "",
    phoneNumber: "",
    birth: null,
    gender: "male",
    provider: "email",
    password: "",
    confirmPassword: "",
  });
  const birthRef = useRef();
  const [errors, setErrors] = useState({
    title: null,
    name: null,
    email: null,
    phoneNumber: null,
    birth: null,
    gender: null,
    provider: null,
    password: null,
    confirmPassword: null,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const eighteenYearsAgo = Moment().subtract(18, "years").format("YYYY-MM-DD");
  const sixtyFiveYearsAgo = Moment().subtract(65, "years").format("YYYY-MM-DD");
  // const fifteenDaysFromToday = Moment().add(15, "days").format("YYYY-MM-DD");
  // const today = Moment().format("YYYY-MM-DD");

  const submitData = async () => {
    //validate data
    const url = process.env.REACT_APP_API_URL + "/user/sign-up";
    try {
      setLoading(true);
      setError(null);
      const { data } = await axios.post(url, state);
      setLoading(false);

      if (data.success) {
        // saveUserDataInLocalStorage(data);
        const redirectUrl = "/verification?email=" + state.email;
        navigate(redirectUrl);
      } else {
        setError(
          data.message
            ? data.message
            : "Registration was unsuccessful, please try again"
        );
      }
    } catch (error) {
      //Handing Form Errors
      setLoading(false);
      setError(null);
      if (error?.response?.data?.errors) {
        const errorsFromServer = error.response.data.errors;
        console.error(errorsFromServer);
        let errorData = {};
        if (errorsFromServer.length > 0) {
          errorsFromServer.forEach((err) => {
            if (!errorData[err.field]) {
              errorData[err.field] = [];
            }
            errorData[err.field].push(err.message);
          });
        }
        setErrors(errorData);
      } else {
        setError("Registration was unsuccessful, please try again");
      }
    }
  };

  return (
    <div className="container-scroller my-5">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-md-6 grid-margin stretch-card mx-auto">
              <div className="card">
                <div className="card-body">
                  {error && (
                    <div className="alert alert-danger" role="alert">
                      {error}
                    </div>
                  )}
                  <h4 className="card-title">Registration Form</h4>
                  <p className="card-description">
                    Register here and start a Policy with Us, Thank You
                  </p>
                  <form className="forms-sample">
                    <div className="form-group row mb-3">
                      <label
                        htmlFor="title"
                        className="col-sm-4 col-form-label"
                      >
                        Title
                      </label>
                      <div className="col-sm-8">
                        <select
                          onChange={(event) => {
                            setState({ ...state, title: event.target.value });
                          }}
                          value={state.title}
                          className={`form-select ${
                            errors.title && "is-invalid"
                          }`}
                          aria-label="Default select example"
                        >
                          <option value="Mr">Mr</option>
                          <option value="Mrs">Mrs</option>
                          <option value="Miss">Miss</option>
                        </select>
                        {errors.title && (
                          <div className="invalid-feedback">
                            {errors.title.map((message) => {
                              return <div>{message}</div>;
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group row mb-3">
                      <label htmlFor="name" className="col-sm-4 col-form-label">
                        Name
                      </label>
                      <div className="col-sm-8">
                        <input
                          onChange={(event) => {
                            setState({ ...state, name: event.target.value });
                          }}
                          value={state.name}
                          type="text"
                          className={`form-control ${
                            errors.name && "is-invalid"
                          }`}
                          id="name"
                          placeholder="Full Name"
                        />
                        {errors.name && (
                          <div className="invalid-feedback">
                            {errors.name.map((message) => {
                              return <div>{message}</div>;
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group row mb-3">
                      <label
                        htmlFor="email"
                        className="col-sm-4 col-form-label"
                      >
                        Email
                      </label>
                      <div className="col-sm-8">
                        <input
                          onChange={(event) => {
                            setState({ ...state, email: event.target.value });
                          }}
                          value={state.email}
                          type="email"
                          className={`form-control ${
                            errors.email && "is-invalid"
                          }`}
                          id="email"
                          placeholder="Email"
                        />
                        {errors.email && (
                          <div className="invalid-feedback">
                            {errors.email.map((message) => {
                              return <div>{message}</div>;
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group row mb-3">
                      <label
                        htmlFor="phoneNumber"
                        className="col-sm-4 col-form-label"
                      >
                        Mobile
                      </label>
                      <div className="col-sm-8">
                        <input
                          onChange={(event) => {
                            setState({
                              ...state,
                              phoneNumber: event.target.value,
                            });
                          }}
                          value={state.phoneNumber}
                          type="text"
                          className={`form-control ${
                            errors.phoneNumber && "is-invalid"
                          }`}
                          id="phoneNumber"
                          placeholder="Mobile number"
                        />
                        {errors.phoneNumber && (
                          <div className="invalid-feedback">
                            {errors.phoneNumber.map((message) => {
                              return <div>{message}</div>;
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group row mb-3">
                      <label
                        htmlFor="birthday"
                        className="col-sm-4 col-form-label"
                      >
                        Birthday
                      </label>
                      <div className="col-sm-8">
                        <DatePicker
                          ref={birthRef}
                          dateFormat="dd/MM/yyyy"
                          className={`form-control ${
                            errors.birth && "is-invalid"
                          }`}
                          selected={state.birth ? state.birth : null}
                          maxDate={new Date(eighteenYearsAgo)}
                          minDate={new Date(sixtyFiveYearsAgo)}
                          id="birth"
                          locale="uk"
                          onChange={(date) =>
                            setState({ ...state, birth: date })
                          }
                        />
                        {errors.birth && (
                          <div className="invalid-feedback">
                            {errors.birth.map((message) => {
                              return (
                                <div>
                                  {message.replace("birth", "birthday")}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group row mb-3">
                      <label
                        htmlFor="gender"
                        className="col-sm-4 col-form-label"
                      >
                        Gender
                      </label>
                      <div className="col-sm-8">
                        <select
                          onChange={(event) => {
                            setState({ ...state, gender: event.target.value });
                          }}
                          value={state.gender}
                          className={`form-select ${
                            errors.gender && "is-invalid"
                          }`}
                          aria-label="Default select example"
                        >
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </select>
                        {errors.gender && (
                          <div className="invalid-feedback">
                            {errors.gender.map((message) => {
                              return <div>{message}</div>;
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group row mb-3">
                      <label
                        htmlFor="password"
                        className="col-sm-4 col-form-label"
                      >
                        Password
                      </label>
                      <div className="col-sm-8">
                        <input
                          onChange={(event) => {
                            setState({
                              ...state,
                              password: event.target.value,
                            });
                          }}
                          value={state.password}
                          type="password"
                          className={`form-control ${
                            errors.password && "is-invalid"
                          }`}
                          id="password"
                          placeholder="Password"
                        />
                        {errors.password && (
                          <div className="invalid-feedback">
                            {errors.password.map((message) => {
                              return <div>{message}</div>;
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group row mb-3">
                      <label
                        htmlFor="confirmPassword"
                        className="col-sm-4 col-form-label"
                      >
                        Re Password
                      </label>
                      <div className="col-sm-8">
                        <input
                          onChange={(event) => {
                            setState({
                              ...state,
                              confirmPassword: event.target.value,
                            });
                          }}
                          value={state.confirmPassword}
                          type="password"
                          className={`form-control ${
                            errors.confirmPassword && "is-invalid"
                          }`}
                          id="confirmPassword"
                          placeholder="Confirm Password"
                        />
                        {errors.confirmPassword && (
                          <div className="invalid-feedback">
                            {errors.confirmPassword.map((message) => {
                              return <div>{message}</div>;
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-check form-check-flat form-check-primary mb-3">
                      <label className="form-check-label">
                        <input type="checkbox" className="form-check-input" />
                        Remember me
                      </label>
                    </div>

                    <button
                      onClick={() => {
                        submitData();
                      }}
                      type="button"
                      className="btn btn-ila me-2 mb-3"
                      disabled={loading}
                    >
                      {loading && (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}{" "}
                      Submit
                    </button>
                    <button className="btn btn-light mb-3">Cancel</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
