/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from "react";
import CustomNumberInput from "../core/components/custom-number-input/CustomNumberInput";
// Axios
import axios from "../api/axios";

const ResetPasswordRequest = () => {
  const phoneNumberRef = useRef();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [state, setState] = useState({
    phoneNumber: "",
    email: "",
    provider: "email",
  });
  const [errors, setErrors] = useState({});
  const requestPasswordUpgrade = async () => {
    const url = process.env.REACT_APP_API_URL + `/user/request-update-password`;

    try {
      setLoading(true);
      setError(null);

      const payload = { ...state };
      if (state.provider === "email") {
        payload.phoneNumber = null;
      } else {
        payload.email = null;
      }
      setSuccess(null);
      const { data } = await axios.post(url, payload);
      setErrors({});
      setLoading(false);

      if (data.success) {
        setSuccess(`Please check your phone/email for password update link`);
        setError(null);
      } else {
        setSuccess(null);
        setError(
          data.message
            ? data.message
            : "Password update request was unsuccessful"
        );
      }
    } catch (error) {
      setSuccess(null);
      setLoading(false);
      setError(null);
      if (
        error?.response?.data?.errors &&
        Object.keys(error?.response?.data?.errors).length > 0
      ) {
        const errorsFromServer = error.response.data.errors;
        console.error(errorsFromServer);
        let errorData = {};
        if (errorsFromServer.length > 0) {
          errorsFromServer.forEach((err) => {
            if (!errorData[err.field]) {
              errorData[err.field] = [];
            }
            errorData[err.field].push(err.message);
          });
        }
        setErrors(errorData);
      } else if (error?.response?.data?.message) {
        setError(error?.response?.data?.message);
      } else {
        setError("Password Change Unsuccessful");
      }
    }
  };

  return (
    <>
      <div className="container">
        <h4 className="mt-4">Request Password Update</h4>
        <div className="container-scroller">
          <div className="container-fluid page-body-wrapper full-page-wrapper">
            <div className="content-wrapper d-flex align-items-center auth px-0">
              <div className="row w-100 mx-0">
                <div className="col-md-6 grid-margin stretch-card mx-auto">
                  <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                    {error && (
                      <div className="alert alert-danger" role="alert">
                        {error}
                      </div>
                    )}
                    {success && (
                      <div className="alert alert-success" role="alert">
                        {success}
                      </div>
                    )}
                    <form className="pt-1">
                      <div className="login_wrapper">
                        <div className="form-group row mb-3">
                          <label
                            htmlFor="with_refund"
                            className="col-sm-12  col-form-label"
                          >
                            Where should we send password reset link?
                          </label>
                          <div className="col-sm-8 mt-2">
                            <div
                              className="form-check form-check-inline"
                              onClick={() =>
                                setState({ ...state, provider: "email" })
                              }
                            >
                              <input
                                className="form-check-input"
                                type="radio"
                                name="providerRadio"
                                id="providerRadioEmail"
                                checked={state.provider === "email"}
                              />
                              <label
                                className="form-check-label"
                                for="providerRadioEmail"
                              >
                                Email
                              </label>
                            </div>
                            <div
                              className="form-check form-check-inline"
                              onClick={() =>
                                setState({ ...state, provider: "phone" })
                              }
                            >
                              <input
                                className="form-check-input"
                                type="radio"
                                name="providerRadio"
                                id="providerRadioPhone"
                                checked={state.provider === "phone"}
                              />
                              <label
                                className="form-check-label"
                                for="providerRadioPhone"
                              >
                                Phone
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="formsix-pos">
                          <div className="form-group i-email">
                            {state.provider === "email" && (
                              <input
                                type="text"
                                value={state.email}
                                onChange={(event) => {
                                  setState({
                                    ...state,
                                    email: event.target.value,
                                  });
                                }}
                                className="form-control"
                                required=""
                                id="email2"
                                placeholder="Email"
                              />
                            )}
                            {state.provider === "phone" && (
                              <CustomNumberInput
                                errors={errors}
                                ref={phoneNumberRef}
                                setState={setState}
                                state={state}
                              />
                            )}
                            {((state.provider === "phone" &&
                              errors.phoneNumber) ||
                              (state.provider === "email" && errors.email)) && (
                              <div className="text-danger small">
                                {state.provider === "phone" &&
                                  errors.phoneNumber &&
                                  errors.phoneNumber.map((message) => {
                                    return <div>{message}</div>;
                                  })}
                                {state.provider === "email" &&
                                  errors.email &&
                                  errors.email.map((message) => {
                                    return <div>{message}</div>;
                                  })}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="login_btn_wrapper">
                          <a
                            className="btn btn-ila login_btn"
                            onClick={requestPasswordUpgrade}
                            disabled={loading}
                          >
                            {loading && (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            )}{" "}
                            Submit{" "}
                          </a>
                        </div>
                        <div className="login_message">
                          <p>
                            <a href={`${process.env.REACT_APP_BASENAME}/login`}> Back to login</a>{" "}
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordRequest;
