/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import Moment from "moment";
// import lodash from "lodash";
import "./Quotations.css";
import Navbar from "../core/components/NavBar";
import UserContext from "../core/UserContext";
import currencyFormatter from "currency-formatter";
import NoData from "../core/components/no-data/NoData";
// Axios
import { getPolicyClassName, getTotalPremiumWithAddOnValue, getUserDataFromLocalStorage } from "../core/utils";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

import { CDBDataTable } from "cdbreact";
import { getLives } from "../quotation-view/QuotationView";

const Quotations = () => {
  const [quotations, setQuotations] = useState();
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([
    {
      label: "Sum Assured",
      field: "initial_sum_assured",
    },
    {
      label: "Premium",
      field: "premium",
    },
    {
      label: "Payment Frequency",
      field: "payment_frequency",
    },
    {
      label: "Lives",
      field: "lives",
    },
    {
      label: "Term",
      field: "term",
    },
    {
      label: "Class of Policy",
      field: "class_of_policy",
    },
    {
      label: "Type",
      field: "type",
    },
    {
      label: "Created At",
      field: "created_at",
    },
    {
      label: "Is Forwarded",
      field: "is_forwarded",
    },
    {
      label: "Status",
      field: "expired_status",
    },
    {
      label: "",
      field: "view_details",
    },
  ]);
  const [rows, setRows] = useState([]);
  const { userData, setUserData } = useContext(UserContext);
  const userDataStorage = getUserDataFromLocalStorage();
  const axiosPrivate = useAxiosPrivate(userDataStorage);

  const data = () => {
    return {
      columns: [
        {
          label: "Name",
          field: "name",
          width: 150,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Name",
          },
        },
        {
          label: "Position",
          field: "position",
          width: 270,
        },
        {
          label: "Office",
          field: "office",
          width: 200,
        },
        {
          label: "Age",
          field: "age",
          sort: "asc",
          width: 100,
        },
        {
          label: "Start date",
          field: "date",
          sort: "disabled",
          width: 150,
        },
        {
          label: "Salary",
          field: "salary",
          sort: "disabled",
          width: 100,
        },
      ],
      rows: [
        {
          name: "Tiger Nixon",
          position: "System Architect",
          office: "Edinburgh",
          age: "61",
          date: "2011/04/25",
          salary: "$320",
        },
        {
          name: "Garrett Winters",
          position: "Accountant",
          office: "Tokyo",
          age: "63",
          date: "2011/07/25",
          salary: "$170",
        },
        {
          name: "Ashton Cox",
          position: "Junior Technical Author",
          office: "San Francisco",
          age: "66",
          date: "2009/01/12",
          salary: "$86",
        },
      ],
    };
  };

  const fetchQuotations = async () => {
    console.log("FETCH QUOTATONS");
    try {
      setLoading(true);
      const url = process.env.REACT_APP_API_URL + "/quotation/worker/all";
      const response = await axiosPrivate.get(url);


      const sortedData = response.data.data
        .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
        .filter((item) => item.status === "initial");


      const sortedDataWithExpireFields = sortedData.map((_item) => {
        _item.quotationFormData = JSON.parse(_item.quotationFormData);
        const expiryDate = Moment(_item.createdAt).add(15, "days");
        _item.expired = expiryDate.diff(Moment(0, "HH")) <= 0;
        return _item;
      });

      setRows(
        sortedDataWithExpireFields.map((_quotation) => {
          // console.log(_quotation.quotationFormData.whatsappNumber);
          _quotation.initial_sum_assured = currencyFormatter.format(
            parseFloat(_quotation.quotationFormData.initial_sum_assured),
            { symbol: "Rs " }
          );
          _quotation.premium = currencyFormatter.format(
            parseFloat(getTotalPremiumWithAddOnValue(_quotation)),
            { symbol: "Rs " }
          );
          _quotation.payment_frequency =
            _quotation.quotationFormData.payment_insurance_plan;
          _quotation.term =
            _quotation.quotationFormData.term_number +
            " " +
            _quotation.quotationFormData.term_option;

          _quotation.class_of_policy = getPolicyClassName(
            _quotation,
            _quotation.quotationFormData.loan_plan
          );
          _quotation.type = _quotation.quotationFormData.join_loan
            ? "joint"
            : "single";
          _quotation.created_at = Moment(_quotation.createdAt).format("LLL");
          _quotation.is_forwarded = _quotation.isForwardedToCustomer ? (
            <div className="badge bg-success">Yes</div>
          ) : (
            <div className="badge bg-danger">No</div>
          );

          _quotation.expired_status = _quotation.expired ? (
            <div className="badge bg-danger">Expired</div>
          ) : (
            <div className="badge bg-success">Active</div>
          );

          _quotation.lives = getLives(_quotation);

          _quotation.view_details =
            !_quotation.expired || _quotation.status !== "accepted" ? (
              <a
                href={`${process.env.REACT_APP_BASENAME}/quotations/${_quotation.id}`}
                className="btn btn-sm ila-action-link"
              >
                View
              </a>
            ) : (
              ""
            );

          return _quotation;
        })
      );

      setQuotations(sortedDataWithExpireFields);
      setLoading(false);
    } catch (error) {
      console.log({error});
    }
  };

  useEffect(() => {
    fetchQuotations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Navbar
        name={
          userData
            ? `${userData?.data?.firstname} ${userData?.data?.lastname}`
            : ""
        }
      />
      <div className="container">
        {loading && (
          <>
            {/* <h4 className="mt-4">Quotations</h4> */}
            <div
              style={{ marginTop: 150 }}
              className="d-flex flex-column justify-content-center align-items-center"
            >
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <div>Please wait...</div>
            </div>
          </>
        )}
        {quotations && quotations.length > 0 && (
          <div>
            <div
              className="mt-4 d-flex justify-content-between align-items-center p-4 px-5 text-white ila-bg"
              style={{ borderRadius: 2 }}
            >
              <h4 className="mb-0">My Quotations</h4>
              <a
                href={`${process.env.REACT_APP_BASENAME}/create-quotation`}
                className="btn ila-accent-btn text-white"
              >
                {" "}
                Create New Quotation
              </a>
            </div>

            <CDBDataTable
              entriesOptions={[5, 10, 20, 25]}
              entries={10}
              pagesAmount={4}
              data={{ columns, rows }}
              materialSearch={true}
            />
          </div>
        )}

        {!loading && quotations && quotations.length === 0 && (
          <>
            <div
              className="mt-4 d-flex justify-content-between align-items-center p-4 px-5 text-white ila-bg"
              style={{ borderRadius: 2 }}
            >
              <h4 className="mb-0">My Quotations</h4>
              <a
                href={`${process.env.REACT_APP_BASENAME}/create-quotation`}
                className="btn ila-accent-btn text-white"
              >
                Create New Quotation
              </a>
            </div>
            <NoData />
          </>
        )}
      </div>
    </>
  );
};

export default Quotations;
