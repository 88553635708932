import React, { useEffect } from "react";
import MedicalConditionCheckBox from "../../core/components/MedicalConditionCheckBox";

const UnderwritingMedicalCondition = ({ state, setState, number }) => {
  useEffect(() => {
    if (!state.medicalCondition) {
      setState({ ...state, medicalCondition: {} });
    }
  }, []);

  return (
    <div>
      <div className="mx-4">
        <div className="row my-3">
          <div className="col-sm-12 mb-2">
            <h6 className="fw-bold">
              {number}. Have you ever had any of the following conditions?{" "}
            </h6>
          </div>
          <div className="col-sm-12 my-3">
            <div className="row mx-2">
              <table className="table table-bordered table-hover">
                <thead>
                  <tr classNama="font-size-sm">
                    <th width="50%"></th>
                    <th className="table-title">
                      Diagnosed & Receiving Treatment
                    </th>
                    {/* <th className="table-title">Diagnosed and Treated</th>
                    <th className="table-title">
                      Diagnosed but not following treatment
                    </th>
                    <th className="table-title">
                      Never Been Diagnosed, received advices
                    </th> */}
                    <th className="table-title">
                      Never been diagnosed, no advice and no treatment
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Heart Attack</td>

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="heartAttack"
                      medicalConditionValue="diagnosed-and-receiving-treatment"
                    />
                    {/* <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="heartAttack"
                      medicalConditionValue="diagnosed-and-treated"
                    />

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="heartAttack"
                      medicalConditionValue="diagnosed-but-not-following-treatment"
                    />

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="heartAttack"
                      medicalConditionValue="never-been-diagnosed-received-advices"
                    />
 */}
                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="heartAttack"
                      medicalConditionValue="never-been-diagnosed-no-advice-and-no-treatment"
                    />
                  </tr>

                  <tr>
                    <td>Coronary artery bypass or stenting</td>

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="coronaryArteryBypassOrStenting"
                      medicalConditionValue="diagnosed-and-receiving-treatment"
                    />

                    {/* <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="coronaryArteryBypassOrStenting"
                      medicalConditionValue="diagnosed-and-treated"
                    />

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="coronaryArteryBypassOrStenting"
                      medicalConditionValue="diagnosed-but-not-following-treatment"
                    />

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="coronaryArteryBypassOrStenting"
                      medicalConditionValue="never-been-diagnosed-received-advices"
                    /> */}

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="coronaryArteryBypassOrStenting"
                      medicalConditionValue="never-been-diagnosed-no-advice-and-no-treatment"
                    />
                  </tr>

                  <tr>
                    <td>Cardiomyopathy</td>

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="cardiomyopathy"
                      medicalConditionValue="diagnosed-and-receiving-treatment"
                    />

                    {/* <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="cardiomyopathy"
                      medicalConditionValue="diagnosed-and-treated"
                    />

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="cardiomyopathy"
                      medicalConditionValue="diagnosed-but-not-following-treatment"
                    />

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="cardiomyopathy"
                      medicalConditionValue="never-been-diagnosed-received-advices"
                    /> */}

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="cardiomyopathy"
                      medicalConditionValue="never-been-diagnosed-no-advice-and-no-treatment"
                    />
                  </tr>

                  <tr>
                    <td>Heart Failure</td>

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="heartFailure"
                      medicalConditionValue="diagnosed-and-receiving-treatment"
                    />

                    {/* <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="heartFailure"
                      medicalConditionValue="diagnosed-and-treated"
                    />

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="heartFailure"
                      medicalConditionValue="diagnosed-but-not-following-treatment"
                    />

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="heartFailure"
                      medicalConditionValue="never-been-diagnosed-received-advices"
                    /> */}

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="heartFailure"
                      medicalConditionValue="never-been-diagnosed-no-advice-and-no-treatment"
                    />
                  </tr>

                  <tr>
                    <td>Peripheral Vascular Disease</td>

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="peripheralVascularDisease"
                      medicalConditionValue="diagnosed-and-receiving-treatment"
                    />

                    {/* <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="peripheralVascularDisease"
                      medicalConditionValue="diagnosed-and-treated"
                    />

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="peripheralVascularDisease"
                      medicalConditionValue="diagnosed-but-not-following-treatment"
                    />

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="peripheralVascularDisease"
                      medicalConditionValue="never-been-diagnosed-received-advices"
                    /> */}

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="peripheralVascularDisease"
                      medicalConditionValue="never-been-diagnosed-no-advice-and-no-treatment"
                    />
                  </tr>

                  <tr>
                    <td>Stroke or Transcient Ischaemic Attack (TIA)</td>

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="strokeOrTranscientIschaemicAttack"
                      medicalConditionValue="diagnosed-and-receiving-treatment"
                    />

                    {/* <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="strokeOrTranscientIschaemicAttack"
                      medicalConditionValue="diagnosed-and-treated"
                    />

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="strokeOrTranscientIschaemicAttack"
                      medicalConditionValue="diagnosed-but-not-following-treatment"
                    />

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="strokeOrTranscientIschaemicAttack"
                      medicalConditionValue="never-been-diagnosed-received-advices"
                    /> */}

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="strokeOrTranscientIschaemicAttack"
                      medicalConditionValue="never-been-diagnosed-no-advice-and-no-treatment"
                    />
                  </tr>

                  <tr>
                    <td>Cancer, abnormal growths or any tumours</td>

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="cancerAbnormalGrowthsOrAnyTumours"
                      medicalConditionValue="diagnosed-and-receiving-treatment"
                    />

                    {/* <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="cancerAbnormalGrowthsOrAnyTumours"
                      medicalConditionValue="diagnosed-and-treated"
                    />

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="cancerAbnormalGrowthsOrAnyTumours"
                      medicalConditionValue="diagnosed-but-not-following-treatment"
                    />

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="cancerAbnormalGrowthsOrAnyTumours"
                      medicalConditionValue="never-been-diagnosed-received-advices"
                    /> */}

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="cancerAbnormalGrowthsOrAnyTumours"
                      medicalConditionValue="never-been-diagnosed-no-advice-and-no-treatment"
                    />
                  </tr>

                  <tr>
                    <td>Organ failure or Organ Transplant</td>

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="organFailureOrOrganTransplant"
                      medicalConditionValue="diagnosed-and-receiving-treatment"
                    />

                    {/* <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="organFailureOrOrganTransplant"
                      medicalConditionValue="diagnosed-and-treated"
                    />

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="organFailureOrOrganTransplant"
                      medicalConditionValue="diagnosed-but-not-following-treatment"
                    />

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="organFailureOrOrganTransplant"
                      medicalConditionValue="never-been-diagnosed-received-advices"
                    /> */}

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="organFailureOrOrganTransplant"
                      medicalConditionValue="never-been-diagnosed-no-advice-and-no-treatment"
                    />
                  </tr>

                  {/* <tr>
                    <td>Diabetes or Raised blood Sugar or sugar in urine</td>

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="diabetesOrRaisedBloodSugarOrSugarInUrine"
                      medicalConditionValue="diagnosed-and-receiving-treatment"
                    />

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="diabetesOrRaisedBloodSugarOrSugarInUrine"
                      medicalConditionValue="never-been-diagnosed-no-advice-and-no-treatment"
                    />
                  </tr> */}

                  <tr>
                    <td>
                      Chronic Obstructive pulmonary disease, interstitial lung
                      disease, fibrosis of the lungs or Emphysema
                    </td>

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="chronicObstructivePulmonaryDiseaseInterstitialLungDiseaseFibrosisOfTheLungsOrEmphysema"
                      medicalConditionValue="diagnosed-and-receiving-treatment"
                    />
                    {/* 
                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="chronicObstructivePulmonaryDiseaseInterstitialLungDiseaseFibrosisOfTheLungsOrEmphysema"
                      medicalConditionValue="diagnosed-and-treated"
                    />

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="chronicObstructivePulmonaryDiseaseInterstitialLungDiseaseFibrosisOfTheLungsOrEmphysema"
                      medicalConditionValue="diagnosed-but-not-following-treatment"
                    />

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="chronicObstructivePulmonaryDiseaseInterstitialLungDiseaseFibrosisOfTheLungsOrEmphysema"
                      medicalConditionValue="never-been-diagnosed-received-advices"
                    /> */}

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="chronicObstructivePulmonaryDiseaseInterstitialLungDiseaseFibrosisOfTheLungsOrEmphysema"
                      medicalConditionValue="never-been-diagnosed-no-advice-and-no-treatment"
                    />
                  </tr>

                  <tr>
                    <td>Chronic Kidney or Liver disease</td>

                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="chronicKidneyOrLiverDisease"
                      medicalConditionValue="diagnosed-and-receiving-treatment"
                    />
                    {/* <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="chronicKidneyOrLiverDisease"
                      medicalConditionValue="diagnosed-and-treated"
                    />
                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="chronicKidneyOrLiverDisease"
                      medicalConditionValue="diagnosed-but-not-following-treatment"
                    />
                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="chronicKidneyOrLiverDisease"
                      medicalConditionValue="never-been-diagnosed-received-advices"
                    /> */}
                    <MedicalConditionCheckBox
                      state={state}
                      setState={setState}
                      medicalConditionKey="chronicKidneyOrLiverDisease"
                      medicalConditionValue="never-been-diagnosed-no-advice-and-no-treatment"
                    />
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnderwritingMedicalCondition;
