/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../core/components/NavBar";
import UserContext from "../core/UserContext";
import CurrencyInput from "react-currency-input-field";
import "./SingleQuotationAuthenticated.css";
import Moment from "moment";
import CustomNumberInput from "../core/components/custom-number-input/CustomNumberInput";
import { isValidPhoneNumber } from "react-phone-number-input";
import WhatsappNumberInput from "../core/components/whatsapp-number-input/WhatsappNumberInput";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import _ from "lodash";
// Axios
import {
  canModifyQuotation,
  splitNameToFirstNamesAndSurname,
  getUserDataFromLocalStorage,
  isValidAgeNextBirthdayWithTerm,
  isValidNic,
  getDate_ddmmyyyy,
  convertCamelCaseFieldNamesToSnakeCase,
} from "../core/utils";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import uk from "date-fns/locale/en-GB";
registerLocale("uk", uk);

const SingleQuotationAuthenticated = () => {
  const { userData, setUserData } = useContext(UserContext);
  const userDataStorage = getUserDataFromLocalStorage();
  const axiosPrivate = useAxiosPrivate(userDataStorage);
  const navigate = useNavigate();
  const params = useParams();

  const [quotation, setQuotation] = useState();
  const [banks, setBanks] = useState([]);
  const [bankBranches, setBankBranches] = useState([]);
  const [authorized, setAuthorized] = useState();
  // const [isNewJoint, setIsNewJoint] = useState(false);
  const whatsappNumberRef = useRef();
  const whatsappNumberJointRef = useRef();
  const bankRepContactRef = useRef();
  const eighteenYearsAgo = Moment().subtract(18, "years").format("YYYY-MM-DD");
  const sixtyFiveYearsAgo = Moment().subtract(65, "years").format("YYYY-MM-DD");
  const fifteenDaysFromToday = Moment().add(15, "days").format("YYYY-MM-DD");
  const today = Moment().format("YYYY-MM-DD");

  const [state, setState] = useState({
    title: "",
    firstname: "",
    lastname: "",
    email: "",
    phoneNumber: "",
    birth: "",
    maiden_name: "",
    passport_number: "",
    nationality: "",
    nic: "",
    joint_user_title: "",
    joint_user_firstname: "",
    joint_user_lastname: "",
    joint_user_birth: "",
    joint_user_nic: "",

    joint_user_maiden_name: "",
    joint_user_passport_number: "",
    joint_user_nationality: "",
    joint_user_relationship: "",

    joint_user_email: "",
    joint_user_phoneNumber: "",
    intended_commencement_date: new Date(Moment().format("YYYY-MM-DD")),
    join_loan: false,
    term_number: null,
    initial_sum_assured: null,
    term_option: "years",
    gender: "",
    loan_plan: "",
    loan_type: "capital-interest",
    payment_insurance_plan: "yearly",
    with_refund: false,
    with_tpd: true,
    bank_name: "",
    bank_branch_name: "",
    joint_user_financial_institution_branch: "",
    bank_rep_name: null,
    bank_rep_email: null,
    bank_rep_contact: null,
    consent_bank_rep: false,
    // wantPhysicalDelivery: false,
  });
  console.log({state});

  const getBranchesForBank = (bank) => {
    
    if (banks.length === 0) {
      return [];
    }
    const filteredBanks = banks.filter((item) => {
      return bank === item.name;
    });
    // 
    if (!filteredBanks || filteredBanks.length === 0) {
      return [];
    }

    // 
    const selectedBank = filteredBanks[0];
    if (!selectedBank.BranchBanks) {
      return [];
    }
    // 
    return selectedBank.BranchBanks ? selectedBank.BranchBanks : [];
  };

  const getBanks = async () => {
    const url = process.env.REACT_APP_API_URL + `/bank`;

    const response = await axiosPrivate.get(url);

    // 
    if (response.data.success) {
      setBanks(response.data.data);
    }
  };

  useEffect(() => {
    //Pre-populating from userData
    getBanks();
    const userDetails = getUserDataFromLocalStorage();
    getQuotationDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showErrorsForInput = (errs) => {
    // const errorsKeys = Object.keys(errors);
    let errorMessages = "";
    if (errs) {
      errs.forEach((errMsg) => {
        errorMessages += `${errMsg}\n`;
      });
      toast.error(errorMessages);
    }
  };

  const getQuotationDetails = async () => {
    const id = params.id;
    const url = process.env.REACT_APP_API_URL + `/quotation/${id}`;
    const userData = getUserDataFromLocalStorage();
    const userId = userData?.data?.id;


    try {
      const response = await axiosPrivate.get(url);
      if (response.data.success === true) {
        const _quotation = response.data.data;
        _quotation.quotationFormData = JSON.parse(_quotation.quotationFormData);

        setQuotation(_quotation);
        setAuthorized(canModifyQuotation(_quotation, userId));

        let savedQuotation = null;

        if (_quotation.userId) {
          if (!_quotation.joinLoan) {
            console.log({qt: _quotation.quotationFormData});
            const { firstnames: firstname, lastname } = splitNameToFirstNamesAndSurname(_quotation.user.name);

            setQuotation(_quotation);
            //
            let email = _quotation.user.email;
            let phoneNumber = _quotation.user.phoneNumber;
            let loan_plan = _quotation.quotationFormData.loan_plan;
            let savedQuotation = null;
            delete _quotation.quotationFormData;

            const quotationInSnakeCaseFields = convertCamelCaseFieldNamesToSnakeCase(_quotation);

            savedQuotation = {
              ...quotationInSnakeCaseFields,
              firstname,
              lastname,
              email,
              loan_plan,
              phoneNumber,
              title: _quotation.user.title,
              gender: _quotation.user.gender,
              nic: _quotation.user.nic,
              maiden_name: _quotation.user.maidenName,
              passport_number: _quotation.user.passportNumber,
              nationality: _quotation.user.nationality,
              whatsappNumber: _quotation.user.whatsappNumber,
              birth: new Date(_quotation.user.birth),
              intended_commencement_date: new Date(Moment(_quotation.intendedCommencementDate).format("YYYY-MM-DD")),
            };
            setState({ ...state, ...savedQuotation });

          }
          else {
            const {
              joint_user_gender,
              joint_user_maiden_name,
              joint_user_passport_number,
              joint_user_nationality,
              joint_user_birth,
              joint_user_title,
              joint_user_nic,
              joint_user_phoneNumber,
              joint_user_email,
              joint_user_whatsappNumber,
              joint_user_name
            } = _quotation.quotationFormData;

            const { firstnames: joint_user_firstname, lastname: joint_user_lastname } = splitNameToFirstNamesAndSurname(joint_user_name);

            let userJointDetails;
            if(_quotation.userJointId){
              const { firstnames: joint_user_firstname, lastname: joint_user_lastname } = splitNameToFirstNamesAndSurname(_quotation.userJoint.name);
              userJointDetails = {
                joint_user_firstname,
                joint_user_lastname,
                joint_user_birth: new Date(_quotation.userJoint.birth),
                joint_user_relationship: _quotation.jointLifeRelationship,
                joint_user_gender: _quotation.userJoint.gender,
                joint_user_maiden_name: _quotation.userJoint.maidenName,
                joint_user_passport_number: _quotation.userJoint.passportNumber,
                joint_user_nationality: _quotation.userJoint.nationality,
                joint_user_title: _quotation.userJoint.title,
                joint_user_nic: _quotation.userJoint.nic,
                joint_user_phoneNumber: _quotation.userJoint.phoneNumber,
                joint_user_email: _quotation.userJoint.email,
                joint_user_whatsappNumber: _quotation.userJoint.whatsappNumber,
              }
            }
            else {
              userJointDetails = {
                joint_user_firstname,
                joint_user_lastname,
                joint_user_birth: new Date(joint_user_birth),
                joint_user_relationship: _quotation.jointLifeRelationship,
                joint_user_gender,
                joint_user_maiden_name,
                joint_user_passport_number,
                joint_user_nationality,
                joint_user_title,
                joint_user_nic,
                joint_user_phoneNumber,
                joint_user_email,
                joint_user_whatsappNumber,
              }
            }


            const { firstnames: firstname, lastname } = splitNameToFirstNamesAndSurname(_quotation.user.name);
            const {birth, title, nic, email, gender, phoneNumber, whatsappNumber,
              maidenName: maiden_name, passportNumber: passport_number, nationality} = _quotation.user;

            const userMainDetails = {firstname, lastname, birth: new Date(birth), title, nic, email, gender,
              phoneNumber, whatsappNumber, maiden_name, passport_number, nationality};


            let loan_plan = _quotation.quotationFormData.loan_plan;

            delete _quotation.quotationFormData;
            const quotationInSnakeCaseFields =
              convertCamelCaseFieldNamesToSnakeCase(_quotation);
            savedQuotation = {
              ...quotationInSnakeCaseFields,
              loan_plan,
              intended_commencement_date: new Date(_quotation.intendedCommencementDate),
              ...userMainDetails,
              ...userJointDetails
            };

            setState({ ...state, ...savedQuotation });
          }
        }
        else {
          if (!_quotation.quotationFormData.join_loan) {
            const { firstnames: firstname, lastname } =
              splitNameToFirstNamesAndSurname(
                _quotation.quotationFormData.name
              );
            
            setQuotation(_quotation);
            // 
            let email = _quotation.quotationFormData.email;
            let phoneNumber = _quotation.quotationFormData.phoneNumber;
            let savedQuotation = null;
            savedQuotation = {
              ..._quotation.quotationFormData,
              firstname,
              lastname,
              email,
              phoneNumber,
              birth: new Date(
                Moment(_quotation.quotationFormData.birth).format("YYYY-MM-DD")
              ),
              intended_commencement_date: new Date(
                Moment(
                  _quotation.quotationFormData.intended_commencement_date
                ).format("YYYY-MM-DD")
              ),
            };

            setState({ ...state, ...savedQuotation });
            //
          }
          else {

            let firstname = "";
            let lastname = "";
            let joint_user_firstname = "";
            let joint_user_lastname = "";
            let birth = null;
            let joint_user_birth = null;
            let title = "";
            let joint_user_title = "";
            let nic = "";
            let joint_user_nic = "";
            let loan_plan = _quotation.quotationFormData.loan_plan;
            let email = "";
            let phoneNumber = "";
            let joint_user_phoneNumber = "";
            let whatsappNumber = "";
            let joint_user_whatsappNumber = "";
            let joint_user_email = "";



            birth = new Date(_quotation.quotationFormData.main_user_birth);
            joint_user_birth = new Date(_quotation.quotationFormData.joint_user_birth);
            title = _quotation.quotationFormData.main_user_title;
            joint_user_title = _quotation.quotationFormData.joint_user_title;
            nic = _quotation.quotationFormData.main_user_nic;
            joint_user_nic = _quotation.quotationFormData.joint_user_nic;
            phoneNumber = _quotation.quotationFormData.main_user_phoneNumber;
            joint_user_phoneNumber =
              _quotation.quotationFormData.joint_user_phoneNumber;
            email = _quotation.quotationFormData.main_user_email;
            joint_user_email = _quotation.quotationFormData.joint_user_email;
            whatsappNumber = _quotation.quotationFormData.main_user_whatsappNumber;
            joint_user_whatsappNumber =
              _quotation.quotationFormData.joint_user_whatsappNumber;


            

            const { firstnames: fn, lastname: ln } =
              splitNameToFirstNamesAndSurname(
                _quotation.quotationFormData.main_user_name
              );

            firstname = fn;
            lastname = ln;

            const { firstnames: joint_user_fn, lastname: joint_user_ln } =
              splitNameToFirstNamesAndSurname(
                _quotation.quotationFormData.joint_user_name
              );
            joint_user_firstname = joint_user_fn;
            joint_user_lastname = joint_user_ln;
          
            savedQuotation = {
              ..._quotation.quotationFormData,
              firstname,
              lastname,
              title,
              maiden_name: _quotation.quotationFormData?.main_user_maiden_name,
              passport_number: _quotation.quotationFormData?.main_user_passport_number,
              nationality: _quotation.quotationFormData?.main_user_nationality,
              joint_user_title,
              joint_user_email,
              joint_user_phoneNumber,
              whatsappNumber,
              joint_user_whatsappNumber,
              joint_user_lastname,
              joint_user_firstname,
              joint_user_nic,
              email,
              gender: _quotation.quotationFormData.main_user_gender,
              phoneNumber,
              loan_plan,
              nic,
              birth: new Date(Moment(birth).format("YYYY-MM-DD")),
              joint_user_birth: new Date(Moment(joint_user_birth).format("YYYY-MM-DD")),
              intended_commencement_date: new Date(
                Moment(
                  _quotation.quotationFormData.intended_commencement_date
                ).format("YYYY-MM-DD")
              ),
            };

            //
            setState({ ...state, ...savedQuotation });
          }
        }

        // setQuotation(_quotation);
      }
      // console.log(response);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(()=>{
    console.log({stateChanged:  state});
  },[state])



  useEffect(() => {
    setState({ ...state, whatsappNumber: state.phoneNumber });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.phoneNumber]);

  useEffect(() => {
    setState({ ...state, joint_user_whatsappNumber: state.joint_user_phoneNumber });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.joint_user_phoneNumber]);
  const intendedCommencementDateRef = useRef();
  const birthRef = useRef();
  const otherBirthRef = useRef();
  const [jointLifeFieldsDisabled, setJointLifeFieldsDisabled] = useState(true);

  const [errors, setErrors] = useState({
    title: null,
    name: null,
    email: null,
    nic: null,
    maiden_name: null,
    passport_number: null,
    nationality: null,
    phoneNumber: null,
    birth: null,
    term_number: null,
    gender: null,
    initial_sum_assured: null,
    term_option: null,
    loan_type: null,
    loan_plan: null,
    payment_insurance_plan: null,
    joint_user_maiden_name: null,
    joint_user_passport_number: null,
    joint_user_nationality: null,
    joint_user_relationship: null,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // const [quotationResult, setQuotationResult] = useState(null);
  const [validatingJointLife, setValidatingJointLife] = useState(false);

  useEffect(() => {
    
    if (state.bank_name) {
      setBankBranches(getBranchesForBank(state.bank_name));
    }
  }, [state]);

  useEffect(() => {
    setJointLifeFieldsDisabled(!state.join_loan);
  }, [state.join_loan]);

  const validateJointLife = async () => {
    if (
      !state.joint_user_phoneNumber ||
      !isValidPhoneNumber(state.joint_user_phoneNumber)
    ) {
      toast.error("Please enter valid phone number for joint life");
      return;
    }

    setState({
      ...state,
      joint_user_firstname: "",
      joint_user_lastname: "",
      joint_user_nic: "",
      joint_user_birth: "",
      joint_user_email: "",
      joint_user_title: "",
      userJointId: null,
      joint_user_whatsappNumber: "",
    });

    setValidatingJointLife(true);

    toast.error(null);

    const url =
      process.env.REACT_APP_API_URL +
      `/user/check-phone/${state.joint_user_phoneNumber}`;

    try {
      const response = await axiosPrivate.get(url);
      if (response.data.success) {
        const jointLifeData = response.data.data;
        const title = jointLifeData?.title ? jointLifeData?.title : "";
        const name = jointLifeData?.name ? jointLifeData?.name : "";
        const { firstnames: firstname, lastname } =
          splitNameToFirstNamesAndSurname(jointLifeData?.name);

        const birth = jointLifeData?.birth
          ? new Date(Moment(jointLifeData?.birth).format("YYYY-MM-DD"))
          : null;
        const email = jointLifeData?.email ? jointLifeData?.email : "";
        const nic = jointLifeData?.nic ? jointLifeData?.nic : "";
        const gender = jointLifeData?.gender ? jointLifeData?.gender : "";
        const whatsappNumber = jointLifeData?.whatsappNumber
          ? jointLifeData?.whatsappNumber
          : "";

        const {maidenName, passportNumber, nationality} = jointLifeData;
        console.log({joint_user_relationship: state.joint_user_relationship});


        setState({
          ...state,
          joint_user_firstname: firstname,
          joint_user_lastname: lastname,
          joint_user_nic: nic,
          joint_user_gender: gender,
          joint_user_birth: birth,
          joint_user_email: email,
          joint_user_title: title,
          userJointId: jointLifeData?.id,
          joint_user_whatsappNumber: whatsappNumber,
          joint_user_maiden_name: maidenName || "",
          joint_user_passport_number: passportNumber || "",
          joint_user_nationality: nationality || "",
          joint_user_relationship: state.joint_user_relationship
        });
      } else {
        // setJointLifeFieldsDisabled(false);
        setState({ ...state, userJointId: null });
      }
      setJointLifeFieldsDisabled(false);

      setValidatingJointLife(false);
      console.log(response);
    } catch (err) {
      setJointLifeFieldsDisabled(false);
      console.log(err);
      setValidatingJointLife(false);
    }
  };

  const submitData = async () => {
    if (!state.join_loan) {
      const url =
        process.env.REACT_APP_API_URL +
        "/quotation/worker/single-update/" +
        params.id;

      if (!state.title) {
        setErrors({ title: ["Title is required!"] });
      }
      //Term+ageNextBirthday Validation
      if (!isValidAgeNextBirthdayWithTerm(state.birth, state.term_number)) {
        toast.error(
          "Sum of age next birthday and term should not be more than 75!"
        );
        return;
      }

      if (state.nic.length === 0 || state.nic.length !== 14) {
        toast.error("The NIC number must be 14 characters");
        return;
      }

      if (!isValidNic(state.nic, state.birth)) {
        toast.error("The NIC number doesn't match with the birthday");
        return;
      }

      try {
        setLoading(true);
        toast.error(null);
        let payload = {
          ...state,
          name: state.firstname + " " + state.lastname,
        };
        delete payload.firstname;
        delete payload.lastname;
        delete payload.joint_user_title;
        delete payload.joint_user_firstname;
        delete payload.joint_user_lastname;
        delete payload.joint_user_birth;
        delete payload.joint_user_nic;
        delete payload.joint_user_email;
        delete payload.joint_user_phoneNumber;

        delete payload.joint_user_maiden_name;
        delete payload.joint_user_passport_number;
        delete payload.joint_user_nationality;
        delete payload.joint_user_relationship;

        if (payload.bank_branch_name === "Other") {
          payload.bank_branch_name = payload.joint_user_financial_institution_branch;
          delete payload.joint_user_financial_institution_branch;
        }
        delete payload.joint_user_financial_institution_branch;
        delete payload.with_tpd;

        try {
          payload.initial_sum_assured = parseFloat(
            payload.initial_sum_assured.toString()
          );
        } catch (error) {
          console.log(error);
        }

        payload = {
          ...payload,
          intended_commencement_date: getDate_ddmmyyyy(
            payload.intended_commencement_date
          ),
          birth: getDate_ddmmyyyy(payload.birth),
        };

        // 
        const { data } = await axiosPrivate.patch(url, payload);

        setLoading(false);
        setErrors({
          title: null,
          name: null,
          email: null,
          nic: null,
          phoneNumber: null,
          birth: null,
          term_number: null,
          gender: null,
          initial_sum_assured: null,
          term_option: null,
          loan_type: null,
          payment_insurance_plan: null,
          maiden_name: null,
          passport_number: null,
          nationality: null,
          joint_user_maiden_name: null,
          joint_user_passport_number: null,
          joint_user_nationality: null,
          joint_user_relationship: null,
        });
        if (data.success) {
          //show quotation
          navigate("/quotations");
        } else {
          toast.error(
            data.message
              ? data.message
              : "Quotation generation was unsuccessful, please try again"
          );
        }
      } catch (error) {
        //Handing Form Errors
        setLoading(false);
        toast.error(null);

        if (
          error?.response?.data?.errors &&
          Object.keys(error?.response?.data?.errors).length > 0
        ) {
          const errorsFromServer = error.response.data.errors;
          console.error(errorsFromServer);
          let errorData = {};
          if (errorsFromServer.length > 0) {
            errorsFromServer.forEach((err) => {
              if (!errorData[err.field]) {
                errorData[err.field] = [];
              }
              errorData[err.field].push(err.message);
            });
          }
          setErrors(errorData);
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(
            "Quotation generation was unsuccessful, please try again"
          );
        }
      }
    }
    else {
      const url =
        process.env.REACT_APP_API_URL +
        "/quotation/worker/joint-update/" +
        params.id;
      try {
        //Term+ageNextBirthday Validation for main life
        if (!isValidAgeNextBirthdayWithTerm(state.birth, state.term_number)) {
          toast.error(
            "Sum of age next birthday for main life and term should not be more than 75!"
          );
          return;
        }
        //Term+ageNextBirthday Validation for joint life
        if (
          !isValidAgeNextBirthdayWithTerm(state.joint_user_birth, state.term_number)
        ) {
          toast.error(
            "Sum of age next birthday for joint life and term should not be more than 75!"
          );
          return;
        }
        if (state.nic.length === 0 || state.nic.length !== 14) {
          toast.error("The NIC number must be 14 characters");
          return;
        }
        if (!isValidNic(state.nic, state.birth)) {
          toast.error(
            "The Main Life NIC number doesn't match with the birthday"
          );
          return;
        }

        if (
          state.joint_user_nic.length === 0 ||
          state.joint_user_nic.length !== 14
        ) {
          toast.error("The joint life NIC number must be 14 characters");
          return;
        }
        if (!isValidNic(state.joint_user_nic, state.joint_user_birth)) {
          toast.error(
            "The Joint Life NIC number doesn't match with the birthday"
          );
          return;
        }

        

        let payload = {
          ...state,
          main_user_name: state.firstname + " " + state.lastname,
          main_user_title: state.title,
          main_user_birth: getDate_ddmmyyyy(state.birth),
          main_user_nic: state.nic,
          main_user_phoneNumber: state.phoneNumber,
          main_user_whatsappNumber: state.whatsappNumber,
          main_user_email: state.email,
          main_user_gender: state.gender,
          joint_user_name: state.joint_user_firstname + " " + state.joint_user_lastname,
          joint_user_title: state.joint_user_title,
          joint_user_birth: getDate_ddmmyyyy(state.joint_user_birth),
          joint_user_nic: state.joint_user_nic,
          joint_user_gender: state.joint_user_gender,
          joint_user_phoneNumber: state.joint_user_phoneNumber,
          joint_user_email: state.joint_user_email,
          joint_user_whatsappNumber: state.joint_user_whatsappNumber,
        };

        payload.userId = null;

        // change field keys
        payload.main_user_maiden_name = payload.maiden_name;
        payload.main_user_passport_number = payload.passport_number;
        payload.main_user_nationality = payload.nationality;

        delete payload.maiden_name;
        delete payload.passport_number;
        delete payload.nationality;

        delete payload.title;
        delete payload.firstname;
        delete payload.lastname;
        delete payload.email;
        delete payload.phoneNumber;
        delete payload.birth;
        delete payload.nic;
        delete payload.gender;
        delete payload.whatsappNumber;

        payload = {
          ...payload,
          intended_commencement_date: getDate_ddmmyyyy(
            payload.intended_commencement_date
          ),
        };

        if (state.join_loan) {
          payload.userId = state.userId ? state.userId : null;
          payload.userJointId = state.userJointId ? state.userJointId : null;
        } else {
          payload.userId = state.userId ? state.userId : null;
        }

        if (!quotation?.quotationFormData?.join_loan && !state.userJointId) {
          payload.isNewJoint = true;
          payload.userJointId = null;
        } else {
          payload.isNewJoint = false;
          if (state?.userJointId) {
            payload.userJointId = state?.userJointId;
          } else {
            payload.userJointId = null;
          }
        }

        

        try {
          payload.initial_sum_assured = parseFloat(
            payload.initial_sum_assured.toString()
          );
        } catch (error) {
          console.log(error);
        }
        // delete payload.gender;

        if (payload.bank_branch_name === "Other") {
          payload.bank_branch_name = payload.joint_user_financial_institution_branch;
          delete payload.joint_user_financial_institution_branch;
        }
        delete payload.joint_user_financial_institution_branch;
        delete payload.with_tpd;

        setLoading(true);
        toast.error(null);

        
        const { data } = await axiosPrivate.patch(url, payload);
        
        setLoading(false);
        setErrors({});
        if (data.success) {
          navigate("/quotations");
        } else {
          toast.error(
            data.message
              ? data.message
              : "Quotation update was unsuccessful, please try again"
          );
        }
      } catch (error) {
        //Handing Form Errors
        console.log(error);
        setLoading(false);
        toast.error(null);

        if (
          error?.response?.data?.errors &&
          error?.response?.data?.errors.length > 0
        ) {
          const errorsFromServer = error.response.data.errors;
          console.error(errorsFromServer);
          let errorData = {};
          if (errorsFromServer.length > 0) {
            errorsFromServer.forEach((err) => {
              if (!errorData[err.field]) {
                errorData[err.field] = [];
              }
              //extra error handling
              errorData[err.field].push(err.message);
            });
          }
          setErrors(errorData);
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Quotation update was unsuccessful, please try again");
        }
      }
    }
  };

  return (
    <>
      <Navbar
        name={
          userData
            ? `${userData?.data?.firstname} ${userData?.data?.lastname}`
            : ""
        }
      />
      {authorized && (
        <div className="container create-quotation-screen">
          <h4 className="mt-4 ila-grey-text">Edit Quotation</h4>
          <div className="my-3" style={{ color: "#8E8E8E" }}>
            Enter parameters to generate quotation
          </div>
          <div className="text-danger small">* Required Fields</div>
          <div
            className="card mt-5 p-5"
            style={{ background: "#C1C1C115", border: "none" }}
          >
            <div className="card-body">
              <div className="row">
                <div className="col-lg-4">
                  <div className="ila-grey-text" style={{ fontSize: 18 }}>
                    Personal Information
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group  mb-3">
                        <label htmlFor="title" className="ila-form-label">
                          Title <span className="text-danger">*</span>
                        </label>
                        <div>
                          <div
                            className="form-check form-check-inline"
                            onClick={() => {
                              if (!quotation.userId) {
                                setState({ ...state, title: "Mr" });
                              }
                            }}
                          >
                            <input
                              readOnly
                              className="form-check-input"
                              type="radio"
                              checked={state.title === "Mr"}
                            />
                            <label className="form-check-label">Mr</label>
                          </div>
                          <div
                            className="form-check form-check-inline"
                            onClick={() => {
                              if (!quotation.userId) {
                                setState({ ...state, title: "Ms" });
                              }
                            }}
                          >
                            <input
                              readOnly
                              className="form-check-input"
                              type="radio"
                              checked={state.title === "Ms"}
                            />
                            <label className="form-check-label">Ms</label>
                          </div>
                          <div
                            className="form-check form-check-inline"
                            onClick={() => {
                              if (!quotation.userId) {
                                setState({ ...state, title: "Mrs" });
                              }
                            }}
                          >
                            <input
                              readOnly
                              className="form-check-input"
                              type="radio"
                              checked={state.title === "Mrs"}
                            />
                            <label className="form-check-label">Mrs</label>
                          </div>
                          <div
                            className="form-check form-check-inline"
                            onClick={() => {
                              if (!quotation.userId) {
                                setState({ ...state, title: "Miss" });
                              }
                            }}
                          >
                            <input
                              readOnly
                              className="form-check-input"
                              type="radio"
                              checked={state.title === "Miss"}
                            />
                            <label className="form-check-label">Miss</label>
                          </div>
                          <div
                            className="form-check form-check-inline"
                            onClick={() => {
                              if (!quotation.userId) {
                                setState({ ...state, title: "Dr" });
                              }
                            }}
                          >
                            <input
                              readOnly
                              className="form-check-input"
                              type="radio"
                              checked={state.title === "Dr"}
                            />
                            <label className="form-check-label">Dr</label>
                          </div>
                        </div>
                        {errors.title && (
                          <div className="invalid-feedback d-block">
                            {errors.title.map((message, index) => {
                              return <div key={index}>{message}</div>;
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group  mb-3">
                        <label
                          htmlFor="firstname"
                          className="col-form-label ila-form-label"
                        >
                          First Names <span className="text-danger">*</span>
                        </label>
                        <div>
                          <input
                            onChange={(event) => {
                              if (!quotation.userId) {
                                setState({
                                  ...state,
                                  firstname: event.target.value,
                                });
                              }
                            }}
                            value={state.firstname}
                            type="text"
                            className={`form-control ${errors.firstname && "is-invalid"
                              }`}
                            id="name"
                            placeholder="First Names"
                          />
                          {errors.firstname && (
                            <div className="invalid-feedback d-block">
                              {errors.firstname.map((message, index) => {
                                return <div key={index}>{message}</div>;
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group  mb-3">
                        <label
                          htmlFor="name"
                          className="col-form-label ila-form-label"
                        >
                          Last name <span className="text-danger">*</span>
                        </label>
                        <div>
                          <input
                            onChange={(event) => {
                              if (!quotation.userId) {
                                setState({
                                  ...state,
                                  lastname: event.target.value,
                                });
                              }
                            }}
                            value={state.lastname}
                            type="text"
                            className={`form-control ${errors.lastname && "is-invalid"
                              }`}
                            id="name"
                            placeholder="Last Name"
                          />
                          {errors.lastname && (
                            <div className="invalid-feedback d-block">
                              {errors.lastname.map((message, index) => {
                                return <div key={index}>{message}</div>;
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group  mb-3">
                        <label
                          htmlFor="birth"
                          className="col-form-label ila-form-label"
                        >
                          Date of Birth <span className="text-danger">*</span>
                        </label>
                        <div>
                          <DatePicker
                            ref={birthRef}
                            dateFormat="dd/MM/yyyy"
                            className={`form-control ${errors.birth && "is-invalid"
                              }`}
                            selected={state.birth ? state.birth : null}
                            maxDate={new Date(eighteenYearsAgo)}
                            minDate={new Date(sixtyFiveYearsAgo)}
                            id="birth"
                            locale="uk"
                            onChange={(date) => {
                              if (!quotation.userId) {
                                setState({ ...state, birth: date });
                              }
                            }}
                          />
                          {errors.birth && (
                            <div className="invalid-feedback d-block">
                              {errors.birth.map((message, index) => {
                                return <div key={index}>{message}</div>;
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group  mb-3">
                        <label
                          htmlFor="nic"
                          className="ila-form-label  col-form-label"
                        >
                          NIC <span className="text-danger">*</span>
                        </label>
                        <div>
                          <input
                            onChange={(event) => {
                              if (!quotation.userId) {
                                setState({
                                  ...state,
                                  nic: event.target.value,
                                });
                              }
                            }}
                            value={state.nic}
                            type="text"
                            className={`form-control ${errors.nic && "is-invalid"
                              }`}
                            id="nic"
                            placeholder=""
                          />
                          {errors.nic && (
                            <div className="invalid-feedback d-block">
                              {errors.nic.map((message, index) => {
                                return (
                                  <div key={index}>
                                    {message.replace("nic", "NIC")}
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group  mb-3">
                        <label
                          htmlFor="gender"
                          className="ila-form-label col-form-label"
                        >
                          Gender <span className="text-danger">*</span>
                        </label>
                        <div>
                          <select
                            onChange={(event) => {
                              if (!quotation.userId) {
                                setState({
                                  ...state,
                                  gender: event.target.value,
                                });
                              }
                            }}
                            value={state.gender}
                            className={`form-select ila-inactive-input ${errors.gender && "is-invalid"
                              }`}
                            aria-label="Default select example">
                            <option value="">Select...</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                          </select>
                          {errors.gender && (
                            <div className="invalid-feedback d-block">
                              {errors.gender.map((message, index) => {
                                return <div key={index}>{message}</div>;
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group  mb-3">
                        <label
                          htmlFor="email"
                          className="ila-form-label col-form-label"
                        >
                          Email <span className="text-danger">*</span>
                        </label>
                        <div>
                          <input
                            onChange={(event) => {
                              if (!quotation.userId) {
                                setState({
                                  ...state,
                                  email: event.target.value,
                                });
                              }
                            }}
                            value={state.email}
                            type="text"
                            className={`form-control ${errors.email && "is-invalid"
                              }`}
                            id="email"
                            placeholder="Email"
                          />
                          {errors.email && (
                            <div className="invalid-feedback d-block">
                              {errors.email.map((message, index) => {
                                return <div key={index}>{message}</div>;
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group  mb-3">
                        <label className="ila-form-label col-form-label">
                          Phone Number<span className="text-danger">*</span>
                        </label>
                        <div>
                          <CustomNumberInput
                            errors={{}}
                            state={state}
                            setState={setState}
                            quotation={quotation}
                            fieldName="phoneNumber"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group  mb-3">
                        <label
                          htmlFor="email"
                          className="ila-form-label col-form-label"
                        >
                          Whatsapp Number
                        </label>
                        <div>
                          <WhatsappNumberInput
                            errors={{}}
                            state={state}
                            quotation={quotation}
                            setState={setState}
                          />
                        </div>
                      </div>
                    </div>

                    {/*NEW FIELDS*/}
                    <div className="form-group row mb-3">
                      <div className="col-12 col-lg-6">
                        <label
                          htmlFor="maiden_name"
                          className="col-sm-12  col-form-label ila-form-label"
                        >
                          Maiden Name <span className="text-danger">*</span>
                        </label>

                        <input
                          onChange={(event) => {
                            setState({
                              ...state,
                              maiden_name: event.target.value,
                            });
                          }}
                          value={state.maiden_name}
                          type="text"
                          className={`col-sm-12 form-control ${
                            errors.maiden_name && "is-invalid"
                          }`}
                          id="maiden_name"
                          placeholder="Maiden Name"
                        />
                        {errors.maiden_name && (
                          <div className="invalid-feedback d-block">
                            {errors.maiden_name.map((message, index) => {
                              return <div key={index}>{message}</div>;
                            })}
                          </div>
                        )}
                      </div>
                      <div className="col-12 col-lg-6">
                        <label
                          htmlFor="passport_number"
                          className="col-sm-12  col-form-label ila-form-label"
                        >
                          Passport Number <span className="text-danger">*</span>
                        </label>

                        <input
                          onChange={(event) => {
                            setState({
                              ...state,
                              passport_number: event.target.value,
                            });
                          }}
                          value={state.passport_number}
                          type="text"
                          className={`col-sm-12 form-control ${
                            errors.passport_number && "is-invalid"
                          }`}
                          id="passport_number"
                          placeholder="Passport Number"
                        />
                        {errors.passport_number && (
                          <div className="invalid-feedback d-block">
                            {errors.passport_number.map((message, index) => {
                              return <div key={index}>{message}</div>;
                            })}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group row mb-3">
                      <div className="col-12 col-lg-6">
                        <label
                          htmlFor="nationality"
                          className="col-sm-12  col-form-label ila-form-label"
                        >
                          Nationality <span className="text-danger">*</span>
                        </label>

                        <input
                          onChange={(event) => {
                            setState({
                              ...state,
                              nationality: event.target.value,
                            });
                          }}
                          value={state.nationality}
                          type="text"
                          className={`col-sm-12 form-control ${
                            errors.nationality && "is-invalid"
                          }`}
                          id="nationality"
                          placeholder="Nationality"
                        />
                        {errors.nationality && (
                          <div className="invalid-feedback d-block">
                            {errors.nationality.map((message, index) => {
                              return <div key={index}>{message}</div>;
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                    {/* NEW FIELDS */}

                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-lg-4">
                  <div className="ila-grey-text" style={{ fontSize: 18 }}>
                    Loan Specifications
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group  mb-3">
                        <label
                          htmlFor="intended_commencement_date"
                          className="ila-form-label col-form-label"
                        >
                          Intended Commencement Date{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div>
                          <DatePicker
                            ref={intendedCommencementDateRef}
                            dateFormat="dd/MM/yyyy"
                            className={`form-control ${errors.intended_commencement_date && "is-invalid"
                              }`}
                            selected={
                              state.intended_commencement_date
                                ? state.intended_commencement_date
                                : null
                            }
                            maxDate={new Date(fifteenDaysFromToday)}
                            minDate={new Date(today)}
                            id="intended_commencement_date"
                            locale="uk"
                            onChange={(date) =>
                              setState({
                                ...state,
                                intended_commencement_date: date,
                              })
                            }
                          />
                          {errors.intended_commencement_date && (
                            <div className="invalid-feedback d-block">
                              {errors.intended_commencement_date.map(
                                (message, index) => {
                                  return <div key={index}>{message}</div>;
                                }
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">



                      <div className="col-12 col-lg-12 row pe-0">

                        <div className="col-12 col-lg-6">
                          <label
                            htmlFor="name"
                            className="col-sm-12  col-form-label ila-form-label"
                          >
                            Joint Loan <span className="text-danger">*</span>
                          </label>

                          <div className="col-sm-12">
                            <div
                              className="form-check form-check-inline"
                              onClick={() =>
                                setState({ ...state, join_loan: false })
                              }
                            >
                              <input
                                readOnly
                                className="form-check-input"
                                type="radio"
                                name="joinLoanRadio"
                                id="joinLoanRadioNo1"
                                checked={!state.join_loan}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="joinLoanRadioNo1"
                              >
                                No
                              </label>
                            </div>
                            <div
                              className="form-check form-check-inline"
                              onClick={() =>
                                setState({ ...state, join_loan: true })
                              }
                            >
                              <input
                                readOnly
                                className="form-check-input"
                                type="radio"
                                name="joinLoanRadio"
                                id="joinLoanRadioYes1"
                                checked={state.join_loan}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="joinLoanRadioYes1"
                              >
                                Yes
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 col-12 pe-0">
                          <label
                            htmlFor="name"
                            className="col-sm-12  col-form-label ila-form-label"
                          >
                            Policy Class <span className="text-danger">*</span>
                          </label>

                          <div className="col-sm-12">
                            <select
                              onChange={(event) => {
                                setState({
                                  ...state,
                                  loan_plan: event.target.value,
                                });
                              }}
                              value={state.loan_plan}
                              className={`form-select ${errors.loan_plan && "is-invalid"
                                }`}
                              aria-label="Default select example">
                              <option value="">Select...</option>
                              <option value="dta">Loan Cover Plan</option>
                              <option value="lta">Protect Plan</option>
                              <option value="dta-lta">Both Loan Cover and Protect Plan</option>
                            </select>

                            {errors.loan_plan && (
                              <div className="invalid-feedback d-block">
                                {errors.loan_plan.map(
                                  (message, index) => {
                                    return <div key={index}>{message}</div>;
                                  }
                                )}
                              </div>
                            )}
                          </div>
                        </div>

                      </div>


                    </div>
                    <div className="col-lg-6">
                      <div className="form-group  mb-3">
                        <label
                          htmlFor="initial_sum_assured"
                          className=" col-form-label ila-form-label"
                        >
                          Sum Assured <span className="text-danger">*</span>
                        </label>
                        <div>
                          <CurrencyInput
                            className={`form-control ${errors.initial_sum_assured && "is-invalid"
                              }`}
                            onValueChange={(event) => {
                              setState({
                                ...state,
                                initial_sum_assured: event,
                              });
                            }}
                            prefix="Rs "
                            value={state.initial_sum_assured}
                            placeholder="Sum Assured"
                          />

                          {errors.initial_sum_assured && (
                            <div className="invalid-feedback d-block">
                              {errors.initial_sum_assured.map((message, index) => {
                                return <div key={index}>{message}</div>;
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group  mb-3">
                        <label
                          htmlFor="term_number"
                          className="ila-form-label col-form-label"
                        >
                          Duration of Loan (Years){" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div>
                          <input
                            onChange={(event) => {
                              setState({
                                ...state,
                                term_number: parseInt(event.target.value, 10),
                              });
                            }}
                            value={state.term_number}
                            type="number"
                            className={`form-control ${errors.term_number && "is-invalid"
                              }`}
                            id="term_number"
                            placeholder="eg. 20"
                          />

                          {errors.term_number && (
                            <div className="invalid-feedback d-block">
                              {errors.term_number.map((message, index) => {
                                return <div key={index}>{message}</div>;
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group  mb-3">
                        <label
                          htmlFor="title"
                          className=" ila-form-label  col-form-label"
                        >
                          Financial Institution{" "}
                        </label>
                        <div>
                          <select
                            onChange={(event) => {
                              setState({
                                ...state,
                                bank_name: event.target.value,
                              });
                              setBankBranches(
                                getBranchesForBank(event.target.value)
                              );
                            }}
                            value={state.bank_name}
                            className={`form-select ila-inactive-input ${errors.bank_name && "is-invalid"
                              }`}
                            aria-label="Default select example"
                          >
                            <option value="">Select...</option>
                            {banks &&
                              banks.map((bank, index) => {
                                return (
                                  <option key={index} value={bank.name}>{bank.name}</option>
                                );
                              })}
                          </select>
                          {errors.bank_name && (
                            <div className="invalid-feedback d-block">
                              {errors.bank_name.map((message, index) => {
                                return <div key={index}>{message}</div>;
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group  mb-3">
                        <label
                          htmlFor="title"
                          className=" ila-form-label col-form-label"
                        >
                          Financial Institution Branch
                        </label>
                        <div>
                          <select
                            onChange={(event) => {
                              setState({
                                ...state,
                                bank_branch_name: event.target.value,
                              });
                            }}
                            value={state.bank_branch_name}
                            className={`form-select ila-inactive-input ${errors.bank_branch_name && "is-invalid"
                              }`}
                            aria-label="Default select example"
                          >
                            <option value="">Select...</option>
                            {banks && (
                              <>
                                {bankBranches.map((branch, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={branch.name + "-" + branch.town}
                                    >
                                      {branch.name} - {branch.town}
                                    </option>
                                  );
                                })}
                              </>
                            )}
                            <option value="Other">Other</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    {state.bank_branch_name === "Other" && (
                      <div className="col-lg-6">
                        <div className="form-group r mb-3">
                          <label
                            htmlFor="joint_user_financial_institution_branch"
                            className="ila-form-label  col-form-label"
                          >
                            Please Specify Branch{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div>
                            <input
                              onChange={(event) => {
                                setState({
                                  ...state,
                                  bank_branch_name:
                                    event.target.value,
                                });
                              }}
                              value={state.bank_branch_name}
                              type="text"
                              className={`form-control `}
                              id="financial_institution_branch"
                              placeholder="Financial Institution Branch"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="col-lg-6">
                      <div className="form-group  mb-3">
                        <label
                          htmlFor="bank_rep_name"
                          className="ila-form-label  col-form-label"
                        >
                          Bank Rep Name
                        </label>
                        <div>
                          <input
                            onChange={(event) => {
                              setState({
                                ...state,
                                bank_rep_name: event.target.value,
                              });
                            }}
                            value={state.bank_rep_name}
                            type="text"
                            className={`form-control `}
                            id="bank_rep_name"
                            placeholder="Bank Rep Name"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group  mb-3">
                        <label
                          htmlFor="bank_rep_email"
                          className="ila-form-label  col-form-label"
                        >
                          Bank Rep Email
                        </label>
                        <div className="">
                          <input
                            onChange={(event) => {
                              setState({
                                ...state,
                                bank_rep_email: event.target.value,
                              });
                            }}
                            value={state.bank_rep_email}
                            type="text"
                            className={`form-control `}
                            id="bank_rep_email"
                            placeholder="Bank Rep Email"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group  mb-3">
                        <label
                          htmlFor="bank_rep_contact"
                          className="ila-form-label col-form-label"
                        >
                          Bank Rep Contact
                        </label>
                        <div>
                          <CustomNumberInput
                            errors={{}}
                            state={state}
                            setState={setState}
                            fieldName="bank_rep_contact"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group  mb-3">
                        <label
                          htmlFor="consent_bank_rep"
                          className="ila-form-label col-form-label"
                        >
                          Do you consent to sharing quotation changes to the
                          bank rep specified{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div>
                          <div
                            className="form-check form-check-inline"
                            onClick={() =>
                              setState({ ...state, consent_bank_rep: false })
                            }
                          >
                            <input
                              readOnly
                              className="form-check-input"
                              type="radio"
                              checked={!state.consent_bank_rep}
                            />
                            <label className="form-check-label">No</label>
                          </div>
                          <div
                            className="form-check form-check-inline"
                            onClick={() =>
                              setState({ ...state, consent_bank_rep: true })
                            }
                          >
                            <input
                              readOnly
                              className="form-check-input"
                              type="radio"
                              checked={state.consent_bank_rep}
                            />
                            <label className="form-check-label">Yes</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-lg-6">
                    <div className="form-group  mb-3">
                      <label
                        htmlFor="consent_bank_rep"
                        className="ila-form-label col-form-label"
                      >
                        Do you want physical delivery of documents?{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <div>
                        <div
                          className="form-check form-check-inline"
                          onClick={() =>
                            setState({ ...state, wantPhysicalDelivery: false })
                          }
                        >
                          <input
                            readOnly
                            className="form-check-input"
                            type="radio"
                            checked={!state.wantPhysicalDelivery}
                          />
                          <label className="form-check-label">No</label>
                        </div>
                        <div
                          className="form-check form-check-inline"
                          onClick={() =>
                            setState({ ...state, wantPhysicalDelivery: true })
                          }
                        >
                          <input
                            readOnly
                            className="form-check-input"
                            type="radio"
                            checked={state.wantPhysicalDelivery}
                          />
                          <label className="form-check-label">Yes</label>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>
              {state.join_loan && (
                <>
                  <div className="row mt-5">
                    <div className="col-lg-4">
                      <div className="ila-grey-text" style={{ fontSize: 18 }}>
                        Joint Life Information
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group  mb-3">
                            <label className="ila-form-label col-form-label">
                              Phone Number for Joint Life
                              <span className="text-danger">*</span>
                            </label>
                            <div className="row">
                              <div className="col-sm-6">
                                <CustomNumberInput
                                  errors={{}}
                                  state={state}
                                  setState={setState}
                                  fieldName="joint_user_phoneNumber"
                                />
                              </div>
                              <div className="col-sm-5">
                                <button
                                  onClick={validateJointLife}
                                  className="btn ila-primary-text "
                                >
                                  {validatingJointLife && (
                                    <span className="spinner-border-sm spinner-border me-2"></span>
                                  )}
                                  Validate
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group  mb-3">
                            <label htmlFor="title" className="ila-form-label">
                              Title for Joint Life{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div>
                              <div
                                className="form-check form-check-inline"
                                onClick={() => {
                                  if (!quotation.userId) {
                                    setState({ ...state, joint_user_title: "Mr" });
                                  }
                                }}
                              >
                                <input
                                  readOnly
                                  className="form-check-input"
                                  type="radio"
                                  checked={state.joint_user_title === "Mr"}
                                />
                                <label className="form-check-label">Mr</label>
                              </div>
                              <div
                                className="form-check form-check-inline"
                                onClick={() => {
                                  if (!quotation.userId) {
                                    setState({ ...state, joint_user_title: "Ms" });
                                  }
                                }}
                              >
                                <input
                                  readOnly
                                  className="form-check-input"
                                  type="radio"
                                  checked={state.joint_user_title === "Ms"}
                                />
                                <label className="form-check-label">Ms</label>
                              </div>
                              <div
                                className="form-check form-check-inline"
                                onClick={() => {
                                  if (!quotation.userId) {
                                    setState({ ...state, joint_user_title: "Mrs" });
                                  }
                                }}
                              >
                                <input
                                  readOnly
                                  className="form-check-input"
                                  type="radio"
                                  checked={state.joint_user_title === "Mrs"}
                                />
                                <label className="form-check-label">Mrs</label>
                              </div>
                              <div
                                className="form-check form-check-inline"
                                onClick={() => {
                                  if (!quotation.userId) {
                                    setState({ ...state, joint_user_title: "Miss" });
                                  }
                                }}
                              >
                                <input
                                  readOnly
                                  className="form-check-input"
                                  type="radio"
                                  checked={state.joint_user_title === "Miss"}
                                />
                                <label className="form-check-label">Miss</label>
                              </div>
                              <div
                                className="form-check form-check-inline"
                                onClick={() => {
                                  if (!quotation.userId) {
                                    setState({ ...state, joint_user_title: "Dr" });
                                  }
                                }}
                              >
                                <input
                                  readOnly
                                  className="form-check-input"
                                  type="radio"
                                  checked={state.joint_user_title === "Dr"}
                                />
                                <label className="form-check-label">Dr</label>
                              </div>
                            </div>
                          </div>
                          {errors.joint_user_title && (
                            <div className="invalid-feedback d-block">
                              {errors.joint_user_title.map((message, index) => {
                                return <div key={index}>{message}</div>;
                              })}
                            </div>
                          )}
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group  mb-3">
                            <label
                              htmlFor="joint_user_firstname"
                              className="col-form-label ila-form-label"
                            >
                              First Names for Joint Life{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div>
                              <input
                                onChange={(event) => {
                                  if (!quotation.userId) {
                                    setState({
                                      ...state,
                                      joint_user_firstname: event.target.value,
                                    });
                                  }
                                }}
                                disabled={jointLifeFieldsDisabled}
                                value={state.joint_user_firstname}
                                type="text"
                                className={`form-control ${errors.joint_user_firstname && "is-invalid"
                                  }`}
                                id="name"
                                placeholder="First Names"
                              />
                              {errors.joint_user_firstname && (
                                <div className="invalid-feedback d-block">
                                  {errors.joint_user_firstname.map((message, index) => {
                                    return <div key={index}>{message}</div>;
                                  })}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group  mb-3">
                            <label
                              htmlFor="joint_user_name"
                              className="col-form-label ila-form-label"
                            >
                              Last name for Joint Life{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div>
                              <input
                                onChange={(event) => {
                                  if (!quotation.userId) {
                                    setState({
                                      ...state,
                                      joint_user_lastname: event.target.value,
                                    });
                                  }
                                }}
                                disabled={jointLifeFieldsDisabled}
                                value={state.joint_user_lastname}
                                type="text"
                                className={`form-control ${errors.joint_user_lastname && "is-invalid"
                                  }`}
                                id="name"
                                placeholder="Last Name"
                              />
                              {errors.joint_user_lastname && (
                                <div className="invalid-feedback d-block">
                                  {errors.joint_user_lastname.map((message, index) => {
                                    return <div key={index}>{message}</div>;
                                  })}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group  mb-3">
                            <label
                              htmlFor="joint_user_birth"
                              className="col-form-label ila-form-label"
                            >
                              Date of Birth for Joint Life{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div>
                              <DatePicker
                                ref={otherBirthRef}
                                dateFormat="dd/MM/yyyy"
                                className={`form-control ${errors.joint_user_birth && "is-invalid"
                                  }`}
                                selected={
                                  state.joint_user_birth ? state.joint_user_birth : null
                                }
                                disabled={jointLifeFieldsDisabled}
                                maxDate={new Date(eighteenYearsAgo)}
                                minDate={new Date(sixtyFiveYearsAgo)}
                                id="joint_user_birth"
                                locale="uk"
                                onChange={(date) => {
                                  if (!quotation.userId) {
                                    setState({ ...state, joint_user_birth: date });
                                  }
                                }}
                              />
                              {errors.joint_user_birth && (
                                <div className="invalid-feedback d-block">
                                  {errors.joint_user_birth.map((message, index) => {
                                    return <div key={index}>{message}</div>;
                                  })}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group  mb-3">
                            <label
                              htmlFor="nic"
                              className="ila-form-label  col-form-label"
                            >
                              NIC for Joint Life{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div>
                              <input
                                onChange={(event) => {
                                  if (!quotation.userId) {
                                    setState({
                                      ...state,
                                      joint_user_nic: event.target.value,
                                    });
                                  }
                                }}
                                disabled={jointLifeFieldsDisabled}
                                value={state.joint_user_nic}
                                type="text"
                                className={`form-control ${errors.joint_user_nic && "is-invalid"
                                  }`}
                                id="joint_user_nic"
                                placeholder=""
                              />
                              {errors.joint_user_nic && (
                                <div className="invalid-feedback d-block">
                                  {errors.joint_user_nic.map((message, index) => {
                                    return (
                                      <div key={index}>
                                        {message.replace("nic", "NIC")}
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group  mb-3">
                            <label
                              htmlFor="gender"
                              className="ila-form-label col-form-label"
                            >
                              Gender for Joint Life
                              <span className="text-danger">*</span>
                            </label>
                            <div>
                              <select
                                onChange={(event) => {
                                  if (!quotation.userId) {
                                    setState({
                                      ...state,
                                      joint_user_gender: event.target.value,
                                    });
                                  }
                                }}
                                disabled={jointLifeFieldsDisabled}
                                value={state.joint_user_gender}
                                className={`form-select ila-inactive-input ${errors.joint_user_gender && "is-invalid"
                                  }`}
                                aria-label="Default select example"
                              >
                                <option value="">Select...</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                              </select>
                              {errors.joint_user_gender && (
                                <div className="invalid-feedback d-block">
                                  {errors.joint_user_gender.map((message, index) => {
                                    return <div key={index}>{message}</div>;
                                  })}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group  mb-3">
                            <label
                              htmlFor="email"
                              className="ila-form-label col-form-label"
                            >
                              Email for Joint Life{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div>
                              <input
                                onChange={(event) => {
                                  if (!quotation.userId) {
                                    setState({
                                      ...state,
                                      joint_user_email: event.target.value,
                                    });
                                  }
                                }}
                                disabled={jointLifeFieldsDisabled}
                                value={state.joint_user_email}
                                type="text"
                                className={`form-control ${errors.joint_user_email && "is-invalid"
                                  }`}
                                id="joint_user_email"
                                placeholder="Email"
                              />
                              {errors.joint_user_email && (
                                <div className="invalid-feedback d-block">
                                  {errors.joint_user_email.map((message, index) => {
                                    return <div key={index}>{message}</div>;
                                  })}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group  mb-3">
                            <label
                              htmlFor="email"
                              className="ila-form-label col-form-label"
                            >
                              Whatsapp Number for Joint Life
                            </label>
                            <div>
                              <WhatsappNumberInput
                                errors={{}}
                                state={state}
                                disabled={jointLifeFieldsDisabled}
                                setState={setState}
                                field="joint_user_whatsappNumber"
                              />
                            </div>
                          </div>
                        </div>

                        {/*NEW FIELDS*/}
                        <div className="col-12 col-lg-6">
                          <label
                            htmlFor="joint_user_maiden_name"
                            className="col-sm-12 col-form-label ila-form-label"
                          >
                            Maiden Name{" "}
                            <span className="text-danger">*</span>
                          </label>

                          <div className="col-sm-12">
                            <input
                              disabled={jointLifeFieldsDisabled}
                              onChange={(event) => {
                                setState({
                                  ...state,
                                  joint_user_maiden_name: event.target.value,
                                });
                              }}
                              value={state.joint_user_maiden_name}
                              type="text"
                              className={`form-control ${
                                errors.joint_user_maiden_name && "is-invalid"
                              }`}
                              id="joint_user_maiden_name"
                              placeholder="Joint User Maiden Name"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-lg-6">
                          <label
                            htmlFor="joint_user_passport_number"
                            className="col-sm-12 col-form-label ila-form-label"
                          >
                            Passport Number{" "}
                            <span className="text-danger">*</span>
                          </label>

                          <div className="col-sm-12">
                            <input
                              disabled={jointLifeFieldsDisabled}
                              onChange={(event) => {
                                setState({
                                  ...state,
                                  joint_user_passport_number: event.target.value,
                                });
                              }}
                              value={state.joint_user_passport_number}
                              type="text"
                              className={`form-control ${
                                errors.joint_user_passport_number && "is-invalid"
                              }`}
                              id="joint_user_passport_number"
                              placeholder="Joint User Passport Number"
                            />
                          </div>
                        </div>

                        <div className="col-12 col-lg-6">
                          <label
                            htmlFor="joint_user_nationality"
                            className="col-sm-12 col-form-label ila-form-label"
                          >
                            Nationality{" "}
                            <span className="text-danger">*</span>
                          </label>

                          <div className="col-sm-12">
                            <input
                              disabled={jointLifeFieldsDisabled}
                              onChange={(event) => {
                                setState({
                                  ...state,
                                  joint_user_nationality: event.target.value,
                                });
                              }}
                              value={state.joint_user_nationality}
                              type="text"
                              className={`form-control ${
                                errors.joint_user_nationality && "is-invalid"
                              }`}
                              id="joint_user_nationality"
                              placeholder="Joint User Nationality"
                            />
                          </div>
                        </div>

                        <div className="col-12 col-lg-6 mt-3">
                          <label
                            htmlFor="joint_user_relationship"
                            className="col-sm-12 col-form-label ila-form-label"
                          >
                            Relationship{" "}
                            <span className="text-danger">*</span>
                          </label>

                          <div className="col-sm-12">
                            <input
                              disabled={jointLifeFieldsDisabled}
                              onChange={(event) => {
                                setState({
                                  ...state,
                                  joint_user_relationship: event.target.value,
                                });
                              }}
                              value={state.joint_user_relationship}
                              type="text"
                              className={`form-control ${
                                errors.joint_user_relationship && "is-invalid"
                              }`}
                              id="joint_user_relationship"
                              placeholder="Joint User Relationship"
                            />
                          </div>
                        </div>
                        {/*NEW FIELDS*/}

                      </div>
                    </div>
                  </div>
                </>
              )}

              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}
              <div className="d-flex justify-content-end mt-5">
                <button
                  onClick={() => {
                    submitData();
                  }}
                  type="button"
                  className="btn ila-primary-btn me-2 mb-3"
                  disabled={loading}
                >
                  {loading && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  Edit Quotation
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {authorized === undefined && (
        <div>
          <div className="text-center">
            <span className="spinner-border  mt-5"></span>
          </div>
          <div className="text-center">Please wait ...</div>
        </div>
      )}
      {authorized === false && (
        <div className="container">
          {/* <h1 className="text-center">
            You are Unauthorized to modify this quotation
          </h1> */}

          <div
            style={{ marginTop: 50 }}
            className="alert alert-danger"
            role="alert"
          >
            You are Unauthorized to modify this quotation
          </div>
        </div>
      )}
    </>
  );
};

export default SingleQuotationAuthenticated;
