/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/scope */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import CustomNumberInput from "../core/components/custom-number-input/CustomNumberInput";
import Navbar from "../core/components/NavBar";
import UserContext from "../core/UserContext";
import currencyFormatter from "currency-formatter";
import "./Application.css";
import Moment from "moment";
// Axios
import axios from "../api/axios";
import {
  getTotalPremiumWithAddOnValue,
  updateObjectInArray,
  getUserDataFromLocalStorage,
  getPolicyClassName,
  log,
} from "../core/utils";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { getLives } from "../quotation-view/QuotationView";

const Application = () => {
  const { userData, setUserData } = useContext(UserContext);
  const userDataStorage = getUserDataFromLocalStorage();
  const axiosPrivate = useAxiosPrivate(userDataStorage);
  const navigate = useNavigate();
  // const [editMode, setEditMode] = useState(false);
  const [applications, setApplications] = useState();
  const [loading, setLoading] = useState(false);
  const [kycStatuses, setKycStatuses] = useState([]);
  const [downloadingQuotation, setDownloadingQuotation] = useState([]);
  const [kycJointStatuses, setKycJointStatuses] = useState([]);
  const [state, setState] = useState({
    firstname: "",
    lastname: "",
    email: "",
    title: "",
    birth: "",
    phoneNumber: "",
  });

  const getDeclarationsStatusForUser = async (userId) => {
    const url = process.env.REACT_APP_API_URL + `/user/declarations/${userId}`;

    try {
      const response = await axiosPrivate.get(url);

      if (response.data.data.Declarations.length === 0) {
        return false;
      }

      for (let i = 0; i < response.data.data.Declarations.length; i++) {
        const item = response.data.data.Declarations[i];
        if (item.UserDeclaration.consent) {
          return false;
        }
      }

      return true;
    } catch (err) {
      log(err);
      return false;
    }
  };
  const primaryAction = async (application) => {
    // // debugger;
    if (application.status === "underwriting") {
      navigate(`/underwriting/${application.id}/main-life`);
    } else if (application.status === "kyc") {
      navigate(`/client-kyc/${application?.user?.id}/${application.id}/main`);
    } else if (application.status === "review") {
      navigate(`/review/${application.id}`);
    } else if (application.status === "firstpayment") {
      navigate(`/quotations/${application.id}/first-payment`);
    }
  };

  const getApplications = async () => {
    const url = process.env.REACT_APP_API_URL + `/quotation/worker/all`;
    setLoading(true);

    try {
      const response = await axiosPrivate.get(url);
      setLoading(false);
      if (response.data.success === true) {
        let applications = response.data.data
          .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
          .filter(
            (item) => item.status !== "initial" && item.status !== "accepted"
          );

        log(applications);

        let downloadingSpinners = [];
        applications = applications.map((application, index) => {
          downloadingSpinners.push({ index, status: false });
          application.quotationFormData = JSON.parse(
            application.quotationFormData
          );

          application.lives = getLives(application);
          return application;
        });
        setDownloadingQuotation(downloadingSpinners);
        setApplications(applications);
        // { type: "main", verified: kycVerification, name, userId },
        const copyOfKycStatuses = [...kycJointStatuses];
        applications.forEach((application) => {
          if (application.quotationFormData.join_loan) {
            if (
              !copyOfKycStatuses.find(
                (item) => item.userId === application.userJoint.id
              )
            ) {
              copyOfKycStatuses.push({
                type: "joint",
                verified: application.userJoint.kycVerification,
                name: application.userJoint.name,
                userId: application.userJoint.id,
              });
            }
          }
        });
        setKycJointStatuses(copyOfKycStatuses);
      }
    } catch (error) {
      setLoading(false);
      log(error);
    }
  };

  const getQuotationLinkAndDownload = async (_quotation, index) => {
    const url =
      process.env.REACT_APP_API_URL +
      `/quotation/user/generate-pdf/${_quotation.id}`;

    try {
      setDownloadingQuotation(
        updateObjectInArray(downloadingQuotation, "index", index, {
          index,
          status: true,
        })
      );
      const response = await axiosPrivate.get(url);

      if (response.data.success === true) {
        await new Promise((r) => setTimeout(r, 3000));
        const fileResponse = await axios({
          responseType: "blob",
          url: response.data.data.pdfLink,
          method: "GET",
        });
        setDownloadingQuotation(
          updateObjectInArray(downloadingQuotation, "index", index, {
            index,
            status: false,
          })
        );
        const url = window.URL.createObjectURL(fileResponse.data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "quotation.pdf");
        link.click();
      } else {
        setDownloadingQuotation(
          updateObjectInArray(downloadingQuotation, "index", index, {
            index,
            status: false,
          })
        );
      }

      log(response);
    } catch (error) {
      setDownloadingQuotation(
        updateObjectInArray(downloadingQuotation, "index", index, {
          index,
          status: false,
        })
      );
      log(error);
    }
  };

  const getKycStatusOfMainLife = async () => {
    const url =
      process.env.REACT_APP_API_URL + `/user/${userDataStorage.data.id}`;

    // setLoading(true);
    try {
      const response = await axiosPrivate.get(url);
      // setLoading(false);
      if (response.data.success === true) {
        const kycVerification = response.data.data.kycVerification;
        const name = response.data.data.name;
        const userId = response.data.data.id;
        // // debugger;
        let kycStatusesCopy = [...kycStatuses].filter(
          (item) => item.userId !== userId
        );
        kycStatusesCopy.push({
          type: "main",
          verified: kycVerification,
          name,
          userId,
        });

        setKycStatuses(kycStatusesCopy);
      }
    } catch (error) {
      log(error);
    }
  };

  const kycStatusProcesses = async () => {
    getKycStatusOfMainLife();

    // need a list of kyc statuses {fields userType, verified, name }
    //get mainlife kyc status and push to list
    //get all applications and loop through
    //if joint_loan and joint_loan userId not already added, get kyc status of joint life and append details to list,
    //On click navigate to profile to go through process
  };

  useEffect(() => {
    const data = getUserDataFromLocalStorage();
    getApplications();
    kycStatusProcesses();
    const firstname = data ? data?.data?.name?.split(" ")[0] : "";
    const lastname = data ? data?.data?.name?.split(" ")[1] : "";
    const email = data ? data?.data?.email : "";
    const title = data ? data?.data?.title : "";
    const phoneNumber = data ? data?.data?.phoneNumber : "";
    const gender = data ? data?.data?.gender : "";
    const birth = data ? new Moment(data?.data?.birth) : "";
    const country = data ? data?.data?.country : "";

    setState({
      firstname,
      lastname,
      title,
      email,
      phoneNumber,
      gender,
      birth,
      country,
    });

    // setInterval(() => {
    //   kycStatusProcesses();
    // }, 10000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Navbar
        name={
          userData
            ? `${userData?.data?.firstname} ${userData?.data?.lastname}`
            : ""
        }
      />
      <div className="container">
        <div
          className="mt-4 d-flex justify-content-between align-items-center p-4 px-5 text-white ila-bg"
          style={{ borderRadius: 2 }}
        >
          <h4 className="mb-0">Applications</h4>
        </div>

        {loading && (
          <>
            <div
              style={{ marginTop: 150 }}
              className="d-flex flex-column justify-content-center align-items-center"
            >
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <div>Please wait...</div>
            </div>
          </>
        )}
        {applications && (
          <div className="application-table">
            <table style={{ padding: "20px !important" }} className="table ">
              <thead>
                <tr>
                  <th scope="col">Temporal ID</th>
                  <th scope="col">Contract Parties</th>
                  <th scope="col">Product</th>
                  <th scope="col">Sum Assured</th>
                  <th scope="col">Premium</th>
                  <th scope="col">Status</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {applications.map((application, index) => {
                  const premium = getTotalPremiumWithAddOnValue(application);
                  return (
                    <tr key={index}>
                      <td scope="row">{application.temporaryId}</td>
                      <td>{application.lives}</td>
                      <td>
                        {getPolicyClassName(
                          application,
                          application.quotationFormData.loan_plan
                        )}
                      </td>

                      <td>
                        {currencyFormatter.format(
                          parseFloat(
                            application.quotationFormData.initial_sum_assured
                          ),
                          { symbol: "Rs " }
                        )}
                      </td>
                      <td>
                        {currencyFormatter.format(parseFloat(premium), {
                          symbol: "Rs ",
                        })}
                      </td>
                      <td>{application.status}</td>
                      <td>
                        <a
                          className="btn btn-sm ila-action-link"
                          onClick={() => {
                            getQuotationLinkAndDownload(application, index);
                          }}
                        >
                          {downloadingQuotation[index].status && (
                            <span
                              className="spinner-border spinner-border-sm me-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                          Download
                        </a>
                        &nbsp;
                        <a
                          className="btn btn-sm ila-action-link"
                          onClick={() => {
                            if (!application.paymentStatus) {
                              primaryAction(application);
                            } else {
                              navigate("/application-in-review");
                            }
                          }}
                        >
                          Continue
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export default Application;
