/* eslint-disable eqeqeq */
import React from "react";
import {
  getAutoPassList,
  getClavicleCollarboneHamstringToeFingerList,
  getHipList,
  getKneeList,
  getShoulderList,
  getBackpainList,
  getMentalHealthList,
} from "../../../../core/utils";
import { MultiSelect } from "react-multi-select-component";

const MortgageLoanPurpose = ({ number, state, setState }) => {
  // eslint-disable-next-line no-unused-vars
  const autoPassList = getAutoPassList();
  const clavicleCollarboneHamstringToeFingerList =
    getClavicleCollarboneHamstringToeFingerList();
  const kneeList = getKneeList();
  const hipList = getHipList();
  const shoulderList = getShoulderList();
  const backPainList = getBackpainList();
  const mentalHealthList = getMentalHealthList();

  return (
    <div>
      <div className="mx-4">
        <div className="row my-3">
          <div className="col-sm-12 mb-2">
            <h6 className="fw-normal">Mortgage/Loan Protection</h6>
            <h6 className="fw-bold">
              {number}. What is the purpose of the loan?{" "}
              <font className="fw-normal">
                Please provide details and notify us of:
              </font>
            </h6>
          </div>

          {/* First life assured */}
          {state.life == "main-life" && (
            <div className="col-sm-12 mb-2 mt-2 px-2">
              <div className="col-sm-8">
                <textarea
                  onChange={(e) => {
                    setState({
                      ...state,
                      mortgageloan_purpose_main_life: e.target.value,
                    });
                  }}
                  rows={4}
                  className="form-control w-100 mx-3"
                >
                  {state.mortgageloan_purpose_main_life}
                </textarea>
              </div>
            </div>
          )}

          {/* Second life assured */}
          {state.life == "joint-life" && (
            <div className="col-sm-12 mb-2 mt-2 px-2">
              <div className="col-sm-8">
                <textarea
                  onChange={(e) => {
                    setState({
                      ...state,
                      mortgageloan_purpose_joint_life: e.target.value,
                    });
                  }}
                  className="form-control w-100 mx-3"
                  rows={4}
                >
                  {state.mortgageloan_purpose_joint_life}
                </textarea>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MortgageLoanPurpose;
