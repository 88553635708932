/* eslint-disable eqeqeq */
import React from "react";
import {
  getAutoPassList,
  getClavicleCollarboneHamstringToeFingerList,
  getHipList,
  getKneeList,
  getShoulderList,
  getBackpainList,
  getMentalHealthList,
} from "../../../core/utils";

const ReflexFourConditions = ({ number, state, setState }) => {
  // eslint-disable-next-line no-unused-vars
  const autoPassList = getAutoPassList();
  const clavicleCollarboneHamstringToeFingerList =
    getClavicleCollarboneHamstringToeFingerList();
  const kneeList = getKneeList();
  const hipList = getHipList();
  const shoulderList = getShoulderList();
  const backPainList = getBackpainList();
  const mentalHealthList = getMentalHealthList();

  return (
    <div>
      <div className="mx-4">
        <div className="row my-3">
          <div className="col-sm-12 mb-2">
            <h6 className="fw-bold">
              {number}. For which condition/symptom (s) or complaint where you
              treated for?
            </h6>{" "}
          </div>
          <div className="col-sm-12 mb-2">
            <div className="row mx-2">
              <div
                className="col-sm-12 form-check"
                onClick={() => {
                  setState({
                    ...state,
                    conditionForSeeingSpecialist: "musculoskeletal",
                  });
                }}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  checked={
                    state.conditionForSeeingSpecialist == "musculoskeletal"
                  }
                ></input>
                <label className="form-check-label">Musculoskeletal</label>
              </div>
              <div
                className="col-sm-12 form-check"
                onClick={() => {
                  setState({
                    ...state,
                    conditionForSeeingSpecialist: "backpain",
                  });
                }}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  checked={state.conditionForSeeingSpecialist == "backpain"}
                ></input>
                <label className="form-check-label">Backpain</label>
              </div>
              <div
                className="col-sm-12 form-check"
                onClick={() => {
                  setState({
                    ...state,
                    conditionForSeeingSpecialist: "mental-health-concerns",
                  });
                }}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  checked={
                    state.conditionForSeeingSpecialist ==
                    "mental-health-concerns"
                  }
                ></input>
                <label className="form-check-label">
                  Mental Health Concerns
                </label>
              </div>
              <div
                className="col-sm-12 form-check"
                onClick={() => {
                  setState({
                    ...state,
                    conditionForSeeingSpecialist: "other",
                  });
                }}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  checked={state.conditionForSeeingSpecialist == "other"}
                ></input>
                <label className="form-check-label">Other</label>
              </div>
            </div>
          </div>

          {!!(state.conditionForSeeingSpecialist == "backpain") && (
            <>
              <h6 className="fw-bold mt-3">Select condition for backpain</h6>
              <div className="col-sm-8 mb-4">
                <select
                  onChange={(event) => {
                    setState({
                      ...state,
                      backpainCondition: event.target.value,
                    });
                  }}
                  value={state.backpainCondition}
                  className={`form-select`}
                  aria-label="Default select example"
                >
                  <option value="">Select...</option>

                  {backPainList.map((_item) => {
                    return <option value={_item.toLowerCase()}>{_item}</option>;
                  })}
                  <option value="other">OTHER</option>
                </select>
              </div>

              <div className="col-sm-12 mb-2">
                <h6 className="fw-bold">
                  Have you had or has your doctor suggested any surgical
                  intervention to treat your back condition?
                </h6>{" "}
              </div>
              <div className="col-sm-12 mb-2">
                <div className="row mx-2">
                  <div
                    className="col-sm-12 form-check"
                    onClick={() => {
                      setState({
                        ...state,
                        hasDoctorSuggestedSurgicalInterventionToBackpain: true,
                      });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={
                        state.hasDoctorSuggestedSurgicalInterventionToBackpain ==
                        true
                      }
                    ></input>

                    <label className="form-check-label">Yes</label>
                  </div>
                  <div
                    className="col-sm-12 form-check"
                    onClick={() => {
                      setState({
                        ...state,
                        hasDoctorSuggestedSurgicalInterventionToBackpain: false,
                      });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={
                        state.hasDoctorSuggestedSurgicalInterventionToBackpain ==
                        false
                      }
                    ></input>

                    <label className="form-check-label">No</label>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 mb-2">
                <h6 className="fw-bold">
                  Have you had any symptoms or treatment for this condition in
                  the past 3 yrs?
                </h6>{" "}
              </div>
              <div className="col-sm-12 mb-2">
                <div className="row mx-2">
                  <div
                    className="col-sm-12 form-check"
                    onClick={() => {
                      setState({
                        ...state,
                        hadSymptomsOrTreatmentForBackpainInPastThreeYears: true,
                      });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={
                        state.hadSymptomsOrTreatmentForBackpainInPastThreeYears ==
                        true
                      }
                    ></input>

                    <label className="form-check-label">Yes</label>
                  </div>
                  <div
                    className="col-sm-12 form-check"
                    onClick={() => {
                      setState({
                        ...state,
                        hadSymptomsOrTreatmentForBackpainInPastThreeYears: false,
                      });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={
                        state.hadSymptomsOrTreatmentForBackpainInPastThreeYears ==
                        false
                      }
                    ></input>

                    <label className="form-check-label">No</label>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 mb-2">
                <h6 className="fw-bold">
                  Are you currently able to perform all the duties of your
                  occupation without experiencing and discomfort or pain?
                </h6>{" "}
              </div>
              <div className="col-sm-12 mb-2">
                <div className="row mx-2">
                  <div
                    className="col-sm-12 form-check"
                    onClick={() => {
                      setState({
                        ...state,
                        ableToPerformAllDutiesWithoutDiscomfortOrPain: true,
                      });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={
                        state.ableToPerformAllDutiesWithoutDiscomfortOrPain ==
                        true
                      }
                    ></input>

                    <label className="form-check-label">Yes</label>
                  </div>
                  <div
                    className="col-sm-12 form-check"
                    onClick={() => {
                      setState({
                        ...state,
                        ableToPerformAllDutiesWithoutDiscomfortOrPain: false,
                      });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={
                        state.ableToPerformAllDutiesWithoutDiscomfortOrPain ==
                        false
                      }
                    ></input>

                    <label className="form-check-label">No</label>
                  </div>
                </div>
              </div>
            </>
          )}
          {!!(state.conditionForSeeingSpecialist == "musculoskeletal") && (
            <>
              <div className="col-sm-12 mb-2">
                <h6 className="fw-bold">Choose musculoskeletal category?</h6>{" "}
              </div>
              <div className="col-sm-12 mb-2">
                <div className="row mx-2">
                  <div
                    className="col-sm-12 form-check"
                    onClick={() => {
                      setState({
                        ...state,
                        musculoskeletalCategory:
                          "clavicle-collarbone-hamstring-toe-finger",
                      });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={
                        state.musculoskeletalCategory ==
                        "clavicle-collarbone-hamstring-toe-finger"
                      }
                    ></input>

                    <label className="form-check-label">
                      Clavicle/collarbone/hamstring/toe/finger
                    </label>
                  </div>
                  <div
                    className="col-sm-12 form-check"
                    onClick={() => {
                      setState({
                        ...state,
                        musculoskeletalCategory: "shoulder",
                      });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={state.musculoskeletalCategory == "shoulder"}
                    ></input>

                    <label className="form-check-label">Shoulder</label>
                  </div>
                  <div
                    className="col-sm-12 form-check"
                    onClick={() => {
                      setState({
                        ...state,
                        musculoskeletalCategory: "knee",
                      });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={state.musculoskeletalCategory == "knee"}
                    ></input>

                    <label className="form-check-label">Knee</label>
                  </div>
                  <div
                    className="col-sm-12 form-check"
                    onClick={() => {
                      setState({
                        ...state,
                        musculoskeletalCategory: "hip",
                      });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={state.musculoskeletalCategory == "hip"}
                    ></input>

                    <label className="form-check-label">Hip</label>
                  </div>
                </div>
              </div>

              {!!(
                state.musculoskeletalCategory ==
                "clavicle-collarbone-hamstring-toe-finger"
              ) && (
                <>
                  <h6 className="fw-bold mt-3">
                    Select musculoskeletal condition?
                  </h6>
                  <div className="col-sm-8 mb-4">
                    <select
                      onChange={(event) => {
                        setState({
                          ...state,
                          musculoskeletalConditionsSymptomsOrComplain:
                            event.target.value,
                        });
                      }}
                      value={state.musculoskeletalConditionsSymptomsOrComplain}
                      className={`form-select`}
                      aria-label="Default select example"
                    >
                      <option value="">Select...</option>

                      {clavicleCollarboneHamstringToeFingerList.map((_item) => {
                        return (
                          <option value={_item.toLowerCase()}>{_item}</option>
                        );
                      })}
                      <option value="other">OTHER</option>
                    </select>
                  </div>
                </>
              )}

              {!!(state.musculoskeletalCategory == "knee") && (
                <>
                  <h6 className="fw-bold mt-3">
                    Select musculoskeletal condition?
                  </h6>
                  <div className="col-sm-8 mb-4">
                    <select
                      onChange={(event) => {
                        setState({
                          ...state,
                          musculoskeletalConditionsSymptomsOrComplain:
                            event.target.value,
                        });
                      }}
                      value={state.musculoskeletalConditionsSymptomsOrComplain}
                      className={`form-select`}
                      aria-label="Default select example"
                    >
                      <option value="">Select...</option>

                      {kneeList.map((_item) => {
                        return (
                          <option value={_item.toLowerCase()}>{_item}</option>
                        );
                      })}
                      <option value="other">OTHER</option>
                    </select>
                  </div>
                </>
              )}

              {!!(state.musculoskeletalCategory == "shoulder") && (
                <>
                  <h6 className="fw-bold mt-3">
                    Select musculoskeletal condition?
                  </h6>
                  <div className="col-sm-8 mb-4">
                    <select
                      onChange={(event) => {
                        setState({
                          ...state,
                          musculoskeletalConditionsSymptomsOrComplain:
                            event.target.value,
                        });
                      }}
                      value={state.musculoskeletalConditionsSymptomsOrComplain}
                      className={`form-select`}
                      aria-label="Default select example"
                    >
                      <option value="">Select...</option>

                      {shoulderList.map((_item) => {
                        return (
                          <option value={_item.toLowerCase()}>{_item}</option>
                        );
                      })}
                      <option value="other">OTHER</option>
                    </select>
                  </div>
                </>
              )}

              {!!(state.musculoskeletalCategory == "hip") && (
                <>
                  <h6 className="fw-bold mt-3">
                    Select musculoskeletal condition?
                  </h6>
                  <div className="col-sm-8 mb-4">
                    <select
                      onChange={(event) => {
                        setState({
                          ...state,
                          musculoskeletalConditionsSymptomsOrComplain:
                            event.target.value,
                        });
                      }}
                      value={state.musculoskeletalConditionsSymptomsOrComplain}
                      className={`form-select`}
                      aria-label="Default select example"
                    >
                      <option value="">Select...</option>

                      {hipList.map((_item) => {
                        return (
                          <option value={_item.toLowerCase()}>{_item}</option>
                        );
                      })}
                      <option value="other">OTHER</option>
                    </select>
                  </div>
                </>
              )}

              <div className="col-sm-12 mb-2">
                <h6 className="fw-bold">
                  Have you recovered Completely with no complications or further
                  symptoms?
                </h6>{" "}
              </div>
              <div className="col-sm-12 mb-2">
                <div className="row mx-2">
                  <div
                    className="col-sm-12 form-check"
                    onClick={() => {
                      setState({
                        ...state,
                        hasRecoveredCompletelyWithNoComplications: true,
                      });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={
                        state.hasRecoveredCompletelyWithNoComplications == true
                      }
                    ></input>

                    <label className="form-check-label">Yes</label>
                  </div>
                  <div
                    className="col-sm-12 form-check"
                    onClick={() => {
                      setState({
                        ...state,
                        hasRecoveredCompletelyWithNoComplications: false,
                      });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={
                        state.hasRecoveredCompletelyWithNoComplications == false
                      }
                    ></input>

                    <label className="form-check-label">No</label>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 mb-2">
                <h6 className="fw-bold">
                  Have you had more than one episode/event?
                </h6>{" "}
              </div>
              <div className="col-sm-12 mb-2">
                <div className="row mx-2">
                  <div
                    className="col-sm-12 form-check"
                    onClick={() => {
                      setState({
                        ...state,
                        hadMultipleMusculoskeletalConditionOrSymptom: true,
                      });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={
                        state.hadMultipleMusculoskeletalConditionOrSymptom ==
                        true
                      }
                    ></input>

                    <label className="form-check-label">Yes</label>
                  </div>
                  <div
                    className="col-sm-12 form-check"
                    onClick={() => {
                      setState({
                        ...state,
                        hadMultipleMusculoskeletalConditionOrSymptom: false,
                      });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={
                        state.hadMultipleMusculoskeletalConditionOrSymptom ==
                        false
                      }
                    ></input>

                    <label className="form-check-label">No</label>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 mb-2">
                <h6 className="fw-bold">
                  When did you last experience musculoskeletal symptoms?
                </h6>{" "}
              </div>
              <div className="col-sm-12 mb-2">
                <div className="row mx-2">
                  <div
                    className="col-sm-12 form-check"
                    onClick={() => {
                      setState({
                        ...state,
                        whenLastExperiencedMusculoskeletalSymptoms:
                          "less-than-24-months",
                      });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={
                        state.whenLastExperiencedMusculoskeletalSymptoms ==
                        "less-than-24-months"
                      }
                    ></input>

                    <label className="form-check-label">
                      Less than 24 months
                    </label>
                  </div>
                  <div
                    className="col-sm-12 form-check"
                    onClick={() => {
                      setState({
                        ...state,
                        whenLastExperiencedMusculoskeletalSymptoms:
                          "more-than-24-months-and-less-than-36-months",
                      });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={
                        state.whenLastExperiencedMusculoskeletalSymptoms ==
                        "more-than-24-months-and-less-than-36-months"
                      }
                    ></input>

                    <label className="form-check-label">
                      more than 24 months & less than 36 months
                    </label>
                  </div>
                  <div
                    className="col-sm-12 form-check"
                    onClick={() => {
                      setState({
                        ...state,
                        whenLastExperiencedMusculoskeletalSymptoms:
                          "more-than-36-months",
                      });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={
                        state.whenLastExperiencedMusculoskeletalSymptoms ==
                        "more-than-36-months"
                      }
                    ></input>

                    <label className="form-check-label">
                      More than 36 months
                    </label>
                  </div>
                </div>
              </div>
            </>
          )}
          {!!(
            state.conditionForSeeingSpecialist == "mental-health-concerns"
          ) && (
            <>
              <div className="col-sm-12 mb-2">
                <h6 className="fw-bold">
                  Have you ever thought about taking your own life or attempted
                  to take your life?
                </h6>{" "}
              </div>
              <div className="col-sm-12 mb-2">
                <div className="row mx-2">
                  <div
                    className="col-sm-12 form-check"
                    onClick={() => {
                      setState({
                        ...state,
                        suicidalThoughts: true,
                      });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={state.suicidalThoughts == true}
                    ></input>

                    <label className="form-check-label">Yes</label>
                  </div>
                  <div
                    className="col-sm-12 form-check"
                    onClick={() => {
                      setState({
                        ...state,
                        suicidalThoughts: false,
                      });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={state.suicidalThoughts == false}
                    ></input>

                    <label className="form-check-label">No</label>
                  </div>
                </div>
              </div>

              <h6 className="fw-bold mt-3">Select Mental health condition</h6>
              <div className="col-sm-8 mb-4">
                <select
                  onChange={(event) => {
                    setState({
                      ...state,
                      mentalhealthCondition: event.target.value,
                    });
                  }}
                  value={state.mentalhealthCondition}
                  className={`form-select`}
                  aria-label="Default select example"
                >
                  <option value="">Select...</option>

                  {mentalHealthList.map((_item) => {
                    return <option value={_item.toLowerCase()}>{_item}</option>;
                  })}
                  <option value="other">OTHER</option>
                </select>
              </div>

              <div className="col-sm-12 mb-2">
                <h6 className="fw-bold">
                  Have you experienced these symptoms over more than one
                  episode?
                </h6>{" "}
              </div>
              <div className="col-sm-12 mb-2">
                <div className="row mx-2">
                  <div
                    className="col-sm-12 form-check"
                    onClick={() => {
                      setState({
                        ...state,
                        multipleSuicidalThoughts: true,
                      });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={state.multipleSuicidalThoughts == true}
                    ></input>

                    <label className="form-check-label">Yes</label>
                  </div>
                  <div
                    className="col-sm-12 form-check"
                    onClick={() => {
                      setState({
                        ...state,
                        multipleSuicidalThoughts: false,
                      });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={state.multipleSuicidalThoughts == false}
                    ></input>

                    <label className="form-check-label">No</label>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 mb-2">
                <h6 className="fw-bold">
                  Did the symptoms last less than 4 weeks and are you now back
                  at work full time?
                </h6>{" "}
              </div>
              <div className="col-sm-12 mb-2">
                <div className="row mx-2">
                  <div
                    className="col-sm-12 form-check"
                    onClick={() => {
                      setState({
                        ...state,
                        sucidalSymptomsDuration:
                          "symptoms-less-than-4-weeks-and-back-to-work",
                      });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={
                        state.sucidalSymptomsDuration ==
                        "symptoms-less-than-4-weeks-and-back-to-work"
                      }
                    ></input>

                    <label className="form-check-label">
                      Yes, symptoms less than 4 weeks and back to work
                    </label>
                  </div>
                  <div
                    className="col-sm-12 form-check"
                    onClick={() => {
                      setState({
                        ...state,
                        sucidalSymptomsDuration:
                          "no-symptoms-more-than-4-weeks",
                      });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={
                        state.sucidalSymptomsDuration ==
                        "no-symptoms-more-than-4-weeks"
                      }
                    ></input>
                    <label className="form-check-label">
                      No symptoms more than 4 weeks
                    </label>
                  </div>
                  <div
                    className="col-sm-12 form-check"
                    onClick={() => {
                      setState({
                        ...state,
                        sucidalSymptomsDuration:
                          "no-not-working-full-time-due-to-symptoms",
                      });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={
                        state.sucidalSymptomsDuration ==
                        "no-not-working-full-time-due-to-symptoms"
                      }
                    ></input>
                    <label className="form-check-label">
                      No I am not working full time due to the symptoms
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 mb-2">
                <h6 className="fw-bold">When was the last symptoms?</h6>{" "}
              </div>
              <div className="col-sm-12 mb-2">
                <div className="row mx-2">
                  <div
                    className="col-sm-12 form-check"
                    onClick={() => {
                      setState({
                        ...state,
                        whenWasLastMentalHealthSymptoms: "6-months-ago-or-less",
                      });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={
                        state.whenWasLastMentalHealthSymptoms ==
                        "6-months-ago-or-less"
                      }
                    ></input>

                    <label className="form-check-label">
                      6 months ago or less
                    </label>
                  </div>
                  <div
                    className="col-sm-12 form-check"
                    onClick={() => {
                      setState({
                        ...state,
                        whenWasLastMentalHealthSymptoms:
                          "6-months-to-2-years-ago",
                      });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={
                        state.whenWasLastMentalHealthSymptoms ==
                        "6-months-to-2-years-ago"
                      }
                    ></input>
                    <label className="form-check-label">
                      6 months to 2 years ago
                    </label>
                  </div>
                  <div
                    className="col-sm-12 form-check"
                    onClick={() => {
                      setState({
                        ...state,
                        whenWasLastMentalHealthSymptoms: "more-than-two-years",
                      });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={
                        state.whenWasLastMentalHealthSymptoms ==
                        "more-than-two-years"
                      }
                    ></input>
                    <label className="form-check-label">
                      More than 2 years
                    </label>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReflexFourConditions;
