import React from "react";

const UnderwritingSensory = ({ state, setState, number }) => {
  return (
    <div>
      <div className="mx-4">
        <div className="row my-3">
          <div className="col-sm-12 mb-2">
            <h6 className="fw-bold">
              {number}. Do you have any physical or sensory limitation that
              impacts on your ability to perform your activities of daily living
              independently?
            </h6>{" "}
          </div>
          <div className="col-sm-12 text-danger mb-2">
            <em>
              (Activity of daily living include the skills you require to manage
              your basic physical needs including personal hygiene, dressing,
              eating, maintaining your home and managing your finances)
            </em>
          </div>
          <div className="col-sm-12 mb-2">
            <div className="row mx-2">
              <div
                className="col-sm-2 form-check"
                onClick={() => {
                  setState({ ...state, hasPhysicalOrSensoryLimitation: true });
                }}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  checked={state.hasPhysicalOrSensoryLimitation}
                ></input>
                <label className="form-check-label" htmlFor="refund1">
                  Yes
                </label>
              </div>
              <div
                className="col-sm-2 form-check"
                onClick={() => {
                  setState({ ...state, hasPhysicalOrSensoryLimitation: false });
                }}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  checked={state.hasPhysicalOrSensoryLimitation == false}
                ></input>
                <label className="form-check-label" htmlFor="refund2">
                  No
                </label>
              </div>
            </div>
          </div>

          {state.hasPhysicalOrSensoryLimitation && (
            <>
              <div className="col-sm-12 mb-2 mt-4">
                <h6 className="fw-bold">Following which condition:</h6>{" "}
              </div>

              <div className="col-sm-12 mb-2">
                <div className="row mx-2">
                  <div
                    className="col-sm-12 form-check"
                    onClick={() => {
                      setState({
                        ...state,
                        physicalOrSensoryCondition: "deafness-or-hearing",
                      });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={
                        state.physicalOrSensoryCondition ==
                        "deafness-or-hearing"
                      }
                    ></input>
                    <label className="form-check-label" htmlFor="refund1">
                      Deafness or hearing impairment
                    </label>
                  </div>

                  <div
                    className="col-sm-12 form-check"
                    onClick={() => {
                      setState({
                        ...state,
                        physicalOrSensoryCondition: "blindness-and-low-vision",
                      });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={
                        state.physicalOrSensoryCondition ==
                        "blindness-and-low-vision"
                      }
                    ></input>
                    <label className="form-check-label" htmlFor="refund2">
                      Blindness and low vision
                    </label>
                  </div>

                  <div
                    className="col-sm-12 form-check"
                    onClick={() => {
                      setState({
                        ...state,
                        physicalOrSensoryCondition: "hearing-loss",
                      });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={
                        state.physicalOrSensoryCondition == "hearing-loss"
                      }
                    ></input>
                    <label className="form-check-label" htmlFor="refund2">
                      Hearing loss
                    </label>
                  </div>

                  <div
                    className="col-sm-12 form-check"
                    onClick={() => {
                      setState({
                        ...state,
                        physicalOrSensoryCondition: "brain-injury",
                      });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={
                        state.physicalOrSensoryCondition == "brain-injury"
                      }
                    ></input>
                    <label className="form-check-label" htmlFor="refund2">
                      Brain injury
                    </label>
                  </div>

                  <div
                    className="col-sm-12 form-check"
                    onClick={() => {
                      setState({
                        ...state,
                        physicalOrSensoryCondition: "spinal-cord-injury",
                      });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={
                        state.physicalOrSensoryCondition == "spinal-cord-injury"
                      }
                    ></input>
                    <label className="form-check-label" htmlFor="refund2">
                      Spinal cord injury
                    </label>
                  </div>

                  <div
                    className="col-sm-12 form-check"
                    onClick={() => {
                      setState({
                        ...state,
                        physicalOrSensoryCondition: "cerebral-palsy",
                      });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={
                        state.physicalOrSensoryCondition == "cerebral-palsy"
                      }
                    ></input>
                    <label className="form-check-label" htmlFor="refund2">
                      Cerebral palsy
                    </label>
                  </div>

                  <div
                    className="col-sm-12 form-check"
                    onClick={() => {
                      setState({
                        ...state,
                        physicalOrSensoryCondition: "other",
                      });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={state.physicalOrSensoryCondition == "other"}
                    ></input>
                    <label className="form-check-label" htmlFor="refund2">
                      Other
                    </label>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UnderwritingSensory;
