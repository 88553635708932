/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../core/components/NavBar";
import UserContext from "../core/UserContext";
// import Modal from "react-modal";
import SignatureCanvas from "react-signature-canvas";
import currencyFormatter from "currency-formatter";
import moment from "moment";
import CashLogo from "./cash.png";
import PosLogo from "./pos.png";
import BankLogo from "./bank.png";
import CardLogo from "./card.png";
import MinistryCompany from "./company.png";
import BlinkLogo from "../payment/first-payment/blink.png";
import JuiceLogo from "../payment/first-payment/juice.png";
import GoogleIcon from "../payment/first-payment/google-play.png";
import IosLogo from "../payment/first-payment/ios.png";
import HwuaweiLogo from "../payment/first-payment/hwuawei.svg";
import "./ReviewAcceptanceAndSigning.css";
import uniqid from "uniqid";
// import { Buffer } from "buffer";
import MipsLogo from "./mips.png";

import {
  axios,
  getMipsMembershipBlock,
  getTotalPremiumWithAddOnValue,
  getTotalPremiumWithAddOnValueAndServiceCharge,
  isUnderwritingReferred,
  processUnderwritingData,
  getUserDataFromLocalStorage,
} from "../core/utils";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAxiosPrivateMultipart from "../hooks/useAxiosPrivateMultipart";
import { Nav, NavDropdown } from "react-bootstrap";

const ReviewAcceptanceAndSigning = () => {
  const { userData, setUserData } = useContext(UserContext);
  const userDataStorage = getUserDataFromLocalStorage();
  const axiosPrivate = useAxiosPrivate(userDataStorage);
  const axiosPrivateMultipart = useAxiosPrivateMultipart(userDataStorage);
  const navigate = useNavigate();
  const mainLifeSignatureRef = useRef();
  const userMandateSignatureRef = useRef();
  const userJointMandateSignatureRef = useRef();

  const [userMandateError, setUserMandateError] = useState();
  const [userJointMandateSignatureError, setUserJointMandateSignatureError] =
    useState();

  const iframeRef = useRef();
  const jointLifeSignatureRef = useRef();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [quotation, setQuotation] = useState();
  const [savingBankSuccess, setSavingBankSuccess] = useState(false);
  const [savingDetails, setSavingDetails] = useState(false);
  const [banks, setBanks] = useState([]);
  const [posChannels, setPosChannels] = useState([]);
  const [policyNumber, setPolicyNumber] = useState();
  const [activePaymentTab, setActivePaymentTab] = useState("instant");
  const [amount, setAmount] = useState();
  const [paymentChannels, setPaymentChannels] = useState([]);
  const [manualUploadAgreement, setManualUploadAgreement] = useState(false);
  // const [submitting, setSubmitting] = useState();
  const [savingBankDetails, setSavingBankDetails] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState();
  const [error, setError] = useState();
  const [selectedSignedProposalDocument, setSelectedSignedProposalDocument] =
    useState();
  const [
    selectedSignedDataConsentDocument,
    setSelectedSignedDataConsentDocument,
  ] = useState();
  const [
    selectedSignedFinancialQuestionnaire,
    setSelectedSignedFinancialQuestionnaire,
  ] = useState();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [showSignPads, setShowSignPads] = useState();
  const [jointLifeDetails, setJointLifeDetails] = useState();
  const [quotationId, setQuotationId] = useState();
  const [completedIB, setCompletedIB] = useState(false);
  const [mainLifeAgreement, setMainLifeAgreement] = useState(false);
  const [userMandateSignatureAgreement, setUserMandateSignatureAgreement] =
    useState(false);
  const [
    userJointMandateSignatureAgreement,
    setUserJointMandateSignatureAgreement,
  ] = useState(false);
  const [jointLifeAgreement, setJointLifeAgreement] = useState(false);
  const [downloadProposalForm, setDownloadProposalForm] = useState(false);
  const [modeOfPayment, setModeOfPayment] = useState();
  const [internetBankingUrl, setInternetBankingUrl] = useState();
  const [uploadedManuallySignedAgreement, setUploadedManuallySignedAgreement] =
    useState(false);
  const [
    uploadingManuallySignedAgreement,
    setUploadingManuallySignedAgreement,
  ] = useState(false);
  const [kycAndUnderwritingPassed, setKycAndUnderwritingPassed] =
    useState(false);
  const [updatingSigningAndAgreementData, setUpdatingSigningAndAgreementData] =
    useState(false);
  const [
    updatingSigningAndAgreementDataForJointLife,
    setUpdatingSigningAndAgreementDataForJointLife,
  ] = useState(false);
  const [signatureError, setSignatureError] = useState();
  const [signatureErrorForJointLife, setSignatureErrorForJointLife] =
    useState();
  const [signatureSuccess, setSignatureSuccess] = useState();
  const [signatureSuccessForJointLife, setSignatureSuccessForJointLife] =
    useState();
  const [manuallySignatureSuccess, setManuallySignatureSuccess] =
    useState(null);
  const [manuallySignatureError, setManuallySignatureError] = useState(null);
  const [savedBankDetails, setSavedBankDetails] = useState(false);
  const [state, setState] = useState({
    mode_of_payment: null,
    bank: null,
    premiumDueDay: "",
    nameOnAccount: "",
    mandateReference: "",
    accountType: "",
    accountNumber: "",
    signatureRight: "",
    lives: "",
  });

  const [ministryCompanyChannels, setMinistryCompanyChannels] = useState([]);
  const [savingMinistryBankDetails, setSavingMinistryBankDetails] =
    useState(false);
  const [isManuallySignedUpload, setIsManuallySignedUpload] = useState(false);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      maxHeight: "100%",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const [octList, setOctList] = useState([]);
  const [octCode, setOctCode] = useState();
  const [ostList, setOstList] = useState([]);
  const [ostCode, setOstCode] = useState();

  const livesMap = {
    mainlife: "main",
    jointlife: "joint",
    bothlives: "both",
  };

  useEffect(() => {
    const life = livesMap[state.nameOnAccount];
    setState({
      ...state,
      lives: life,
      signatureRight:
        state.nameOnAccount === "mainlife" ||
        state.nameOnAccount === "jointlife"
          ? "either"
          : "both",
    });
  }, [state.nameOnAccount]);

  const getOct = () => {
    axiosPrivate
      .get(
        `/oct/user/${quotation.user.id}/${
          quotation?.quotationFormData?.payment_insurance_plan || "monthly"
        }`,
        { withCredentials: true }
      )
      // axiosPrivate.get(`/oct/${"monthly"}`, { withCredentials: true, })
      .then((response) => setOctList(response.data.data));
  };

  const getOst = () => {
    axiosPrivate
      .get(
        `/ost/user/${quotation.user.id}/${
          quotation?.quotationFormData?.payment_insurance_plan || "monthly"
        }`,
        { withCredentials: true }
      )
      .then((response) => setOstList(response.data.data));
  };

  useEffect(() => {
    if (quotation) {
      getOct();
      getOst();
    }
  }, [quotation]);

  function openModal() {
    setIsOpen(true);
  }

  useEffect(() => {
    getAllPaymentChannels();
    getQuotationDetails();
  }, []);

  useEffect(() => {
    setSelectedPayment(modeOfPayment);
  }, [modeOfPayment]);

  const checkForSuccessfulMipsPayment = () => {
    setInterval(() => {
      // window.location.href = `${window.location.origin}/${process.env.REACT_APP_BASENAME}/policies`;
      const iframeEl = document.querySelector("iframe");
      // console.log(iframeEl.contentWindow.document);
    }, 6000);
  };
  const checkUnderwriting = async (qtn) => {
    setLoading(true);
    const getUnderwritingUrl =
      process.env.REACT_APP_API_URL + `/underwriting/${params.quotationId}`;

    const currentUnderwritingDetails = await axiosPrivate.get(
      getUnderwritingUrl
    );

    if (currentUnderwritingDetails.data.success) {
      if (!qtn.quotationFormData.join_loan) {
        const fieldsFromGET = JSON.parse(
          currentUnderwritingDetails.data.data.underwritingData
        ).data.map((item) => {
          return { key: item.key, response: item.response };
        });
        let fieldsAsObject = {};
        fieldsFromGET.forEach((field) => {
          fieldsAsObject[field.key] = field.response;
        });

        const processedJointData = processUnderwritingData(fieldsAsObject);
        if (
          (processedJointData
            .map((item) => item.status)
            .includes("Refer to ILA") ||
            processedJointData
              .map((item) => item.status)
              .includes("Refer TPD to ILA") ||
            qtn?.quotationFormData?.initial_sum_assured > 8000000) &&
          !qtn.underwritingManuallyAccepted
        ) {
          navigate("/application-in-review");
        }
      } else if (qtn.quotationFormData.join_loan) {
        const fieldsFromGETMain = JSON.parse(
          currentUnderwritingDetails.data.data.underwritingData
        ).data.map((item) => {
          return { key: item.key, response: item.response };
        });
        let fieldsAsObjectMain = {};
        fieldsFromGETMain.forEach((field) => {
          fieldsAsObjectMain[field.key] = field.response;
        });

        const processedJointDataMain =
          processUnderwritingData(fieldsAsObjectMain);

        // Joint
        const fieldsFromGET = JSON.parse(
          currentUnderwritingDetails.data.data.underwritingJointData
        ).data.map((item) => {
          return { key: item.key, response: item.response };
        });
        let fieldsAsObject = {};
        fieldsFromGET.forEach((field) => {
          fieldsAsObject[field.key] = field.response;
        });

        const processedJointData = processUnderwritingData(fieldsAsObject);

        if (
          ((processedJointDataMain
            .map((item) => item.status)
            .includes("Refer to ILA") ||
            processedJointDataMain
              .map((item) => item.status)
              .includes("Refer TPD to ILA") ||
            qtn?.quotationFormData?.initial_sum_assured > 8000000) &&
            !qtn.underwritingManuallyAccepted) ||
          ((processedJointData
            .map((item) => item.status)
            .includes("Refer to ILA") ||
            processedJointData
              .map((item) => item.status)
              .includes("Refer TPD to ILA") ||
            qtn?.quotationFormData?.initial_sum_assured > 8000000) &&
            !qtn.underwritingManuallyAccepted)
        ) {
          navigate("/application-in-review");
        }
      }
    }
  };

  const selectPaymentTab = (tab) => {
    setActivePaymentTab(tab);
  };

  const getDeclarationsStatusForUser = async (userId, _quotationId) => {
    const url = process.env.REACT_APP_API_URL + `/user/declarations/${userId}`;

    try {
      const response = await axiosPrivate.get(url);

      const declarationsForQuotation = response.data.data.filter(
        (item) => item.quotationId === _quotationId
      );

      if (declarationsForQuotation.length === 0) {
        return false;
      }

      for (let i = 0; i < declarationsForQuotation.length; i++) {
        const item = declarationsForQuotation[i];
        if (item.consent) {
          return false;
        }
      }
      return true;
    } catch (err) {
      return false;
    }
  };
  const checkKyc = async (qtn) => {
    setLoading(true);
    if (qtn?.quotationFormData?.join_loan) {
      const userDeclarationStatus = await getDeclarationsStatusForUser(
        qtn?.user?.id
      );

      const userJointDeclarationStatus = await getDeclarationsStatusForUser(
        qtn?.userJoint?.id
      );

      if (
        (!qtn?.user?.kycVerification || !userDeclarationStatus) &&
        !(
          qtn?.user?.declarationsManuallyVerified &&
          qtn?.user?.shuftiManuallyVerified
        )
      ) {
        navigate("/application-in-review");
      } else if (
        (!qtn?.userJoint?.kycVerification || !userJointDeclarationStatus) &&
        !(
          qtn?.userJoint?.declarationsManuallyVerified &&
          qtn?.userJoint?.shuftiManuallyVerified
        )
      ) {
        navigate("/application-in-review");
      }

      // setLoading(false);
    } else if (!qtn?.quotationFormData?.join_loan) {
      const userDeclarationStatus = await getDeclarationsStatusForUser(
        qtn?.user?.id
      );

      if (
        (!qtn?.user?.kycVerification || !userDeclarationStatus) &&
        !(
          qtn?.user?.declarationsManuallyVerified &&
          qtn?.user?.shuftiManuallyVerified
        )
      ) {
        await updateQuotationStatus({ status: "kyc" });
        navigate(`/client-kyc/${qtn?.user?.id}/${qtn?.id}/main`);
      }
      // setLoading(false);
    }
  };

  const createMembershipPaymentAndPopulateIframe = async () => {
    const startDate = new moment(
      quotation.quotationFormData.intended_commencement_date
    ).format("YYYY-MM-DD");
    const endDate = new moment(
      quotation.quotationFormData.intended_commencement_date
    )
      .add(parseInt(quotation.quotationFormData.term_number, 10), "years")
      .format("YYYY-MM-DD");
    // "order": {
    //   "id_order": "INV502123213133421",
    //   "currency": "MUR",
    //   "amount": 5332
    // },
    // "membership": {
    //   "interval": 1,
    //   "start_date": "2023-01-19",
    //   "frequency": "month",
    //   "end_date": "2040-01-19",
    //   "day_to_process": 5,
    //   "membership_amount": 5332
    // },
    //  "additional_params": [
    //   {
    //     "param_name": "temporary_id",
    //     "param_value": "PR/2023/NP2020/D00039"
    //   }
    // ],
    // "iframe_behavior": {
    //   "height": 400,
    //   "width": 350,
    //   "custom_redirection_url": "www.example.com",
    //   "language": "EN"
    // },
    // "request_mode": "membership",
    // "touchpoint": "native_app"

    const orderId = `${uniqid()}-${quotation.id}`;
    const amountPaid = getTotalPremiumWithAddOnValueAndServiceCharge(quotation);
    const paymentChannelId = paymentChannels.find(
      (item) => item.name === "Mips Gateway"
    ).id;
    let payload = {
      loadPayment: {
        order: {
          id_order: orderId,
          currency: "MUR",
          amount: getTotalPremiumWithAddOnValueAndServiceCharge(quotation),
        },
        iframe_behavior: {
          height: 400,
          width: 350,
          custom_redirection_url: `${window.location.hostname}/mips-redirect/${
            quotation.id
          }/choice/${paymentChannelId}/${
            quotation.user.id
          }/${quotation.temporaryId.replaceAll(
            "/",
            "-"
          )}/${amountPaid}/${amountPaid}/${orderId}`,
          language: "EN",
        },
        request_mode: "membership",
        touchpoint: "native_app",
        membership: getMipsMembershipBlock(quotation),
        additional_params: [
          {
            param_name: "temporaryId",
            param_value: quotation.temporaryId,
          },
          {
            param_name: "quotationId",
            param_value: quotation.id,
          },
          {
            param_name: "paymentType",
            param_value: "recurring",
          },
        ],
      },
    };
    try {
      const url =
        process.env.REACT_APP_API_URL + `/utils/mips/load-payment-zone`;

      const response = await axiosPrivate.post(url, payload);

      const iframeUrl = response.data.data.payment_zone_data;
      iframeRef.current.setAttribute("src", iframeUrl);
      setInterval(() => {}, 6000);
    } catch (err) {
      // console.log(err);
    }
  };

  const uploadManuallySignedAgreement = async () => {
    const id = params.quotationId;
    const url =
      process.env.REACT_APP_API_URL + `/quotation/worker/signed/${id}`;
    const isDtaPolicy = quotation && quotation?.isDtaResult ? true : false;
    // const isLtaPolicy = quotation && quotation?.isLtaResult;

    // File validation base on quotation status
    if (
      (isDtaPolicy && !selectedSignedProposalDocument) ||
      (isDtaPolicy && !selectedSignedDataConsentDocument) ||
      (!isDtaPolicy && !selectedSignedProposalDocument) ||
      (!isDtaPolicy && !selectedSignedDataConsentDocument) ||
      (!isDtaPolicy && !selectedSignedFinancialQuestionnaire)
    ) {
      setManuallySignatureError("Please all documents to sign are required");
      return;
    }
    // Send file base on quotation status
    let formData = new FormData();
    if (isDtaPolicy) {
      formData.append(
        "fileSignedProposalDocument",
        selectedSignedProposalDocument
      );
      formData.append(
        "fileSignedDataConsentDocument",
        selectedSignedDataConsentDocument
      );
    } else {
      formData.append(
        "fileSignedProposalDocument",
        selectedSignedProposalDocument
      );
      formData.append(
        "fileSignedDataConsentDocument",
        selectedSignedDataConsentDocument
      );
      formData.append(
        "fileSignedFinancialQuestionnaire",
        selectedSignedFinancialQuestionnaire
      );
    }
    debugger;

    try {
      setUploadingManuallySignedAgreement(true);
      const response = await axiosPrivateMultipart.patch(url, formData);
      debugger;
      if (response.data.success) {
        setUploadedManuallySignedAgreement(true);
        setManuallySignatureSuccess("Documents signed successfully uploaded");
        setManuallySignatureError(null);
        setIsManuallySignedUpload(true);
      } else {
        setManuallySignatureError("Could not upload signed documents");
        setManuallySignatureSuccess(null);
        setIsManuallySignedUpload(false);
      }
      setUploadingManuallySignedAgreement(false);
    } catch (err) {
      // console.log(err);
      setUploadingManuallySignedAgreement(false);
    }
  };

  const saveAndProceed = async () => {
    const url = process.env.REACT_APP_API_URL + `/payment-choice`;
    const isDtaPolicy = quotation && quotation?.isDtaResult ? true : false;

    if (
      (!isManuallySignedUpload && mainLifeSignatureRef.current.isEmpty()) ||
      (isManuallySignedUpload &&
        isDtaPolicy &&
        !selectedSignedProposalDocument) ||
      (isManuallySignedUpload &&
        isDtaPolicy &&
        !selectedSignedDataConsentDocument) ||
      (isManuallySignedUpload &&
        !isDtaPolicy &&
        !selectedSignedProposalDocument) ||
      (isManuallySignedUpload &&
        !isDtaPolicy &&
        !selectedSignedDataConsentDocument) ||
      (isManuallySignedUpload &&
        !isDtaPolicy &&
        !selectedSignedFinancialQuestionnaire)
    ) {
      setError("Please sign before you can proceed");
      return;
    }
    if (
      (!isManuallySignedUpload && !mainLifeAgreement) ||
      (isManuallySignedUpload && !manualUploadAgreement)
    ) {
      setError("Please agree to terms before you can proceed");
      return;
    }
    if (quotation.quotationFormData.join_loan) {
      if (!isManuallySignedUpload && jointLifeSignatureRef.current.isEmpty()) {
        setError("Please sign for Joint Life before you can proceed");
        return;
      }
      if (
        (!isManuallySignedUpload && !jointLifeAgreement) ||
        (isManuallySignedUpload && !manualUploadAgreement)
      ) {
        setError("Please agree to terms for joint before you can proceed");
        return;
      }
    }

    if (modeOfPayment === "cash") {
      const paymentChannelId = paymentChannels.find(
        (item) => item.type === "cash"
      ).id;
      const payload = {
        paymentChannelId,
        premiumDueDay: parseInt(state.premiumDueDay, 10),
        quotationId: quotation.id,
        paymentType: "cash",
      };

      try {
        setSavingDetails(true);
        setError(null);
        const response = await axiosPrivate.post(url, payload);
        // setSavingDetails(false);
        if (response.data.success) {
          // updateQuotationStatus({
          //   status: "review",
          //   paymentStatus: "depositpayment",
          // });
          navigate(`/quotations/${quotation.id}/first-payment`);
        } else {
          setError("Could not save payment details");
        }
        return;
      } catch (err) {
        setSavingDetails(false);
        // console.log(err);
        setError("Could not save payment  details");
        return;
      }
    } else if (
      !selectedPayment &&
      modeOfPayment !== "bank" &&
      quotation.quotationFormData.payment_insurance_plan !== "single"
    ) {
      setError("Please select a payment type");
      return;
    }

    if (selectedPayment === "blink") {
      const paymentChannelId = paymentChannels.find(
        (item) => item.name === "Blink"
      ).id;

      const payload = {
        paymentChannelId,
        premiumDueDay: parseInt(state.premiumDueDay, 10),
        quotationId: quotation.id,
        paymentType: "pos",
      };
      try {
        setSavingDetails(true);
        setError(null);
        const response = await axiosPrivate.post(url, payload);
        // setSavingDetails(false);
        if (response.data.success) {
          // updateQuotationStatus({
          //   status: "review",
          //   paymentStatus: "depositpayment",
          // });
          navigate(`/quotations/${quotation.id}/first-payment`);
        } else {
          setError("Could not save payment details");
        }
      } catch (err) {
        setSavingDetails(false);
        // console.log(err);
        setError("Could not save payment  details");
      }
    } else if (selectedPayment === "juice") {
      const paymentChannelId = paymentChannels.find(
        (item) => item.name === "Juice"
      ).id;

      const payload = {
        paymentChannelId,
        premiumDueDay: parseInt(state.premiumDueDay, 10),
        quotationId: quotation.id,
        paymentType: "pos",
      };

      try {
        setSavingDetails(true);
        setError(null);
        const response = await axiosPrivate.post(url, payload);
        setSavingDetails(false);
        if (response.data.success) {
          navigate(`/quotations/${quotation.id}/first-payment`);
        } else {
          setError("Could not save payment details");
        }
      } catch (err) {
        setSavingDetails(false);
        // console.log(err);
        setError("Could not save payment  details");
      }
    } else if (
      quotation.quotationFormData.payment_insurance_plan === "single"
    ) {
      navigate(`/quotations/${quotation.id}/first-payment`);
    } else if (
      selectedPayment === "ministry" ||
      selectedPayment === "company"
    ) {
      let osttableId = null;
      setError(null);

      let paymentChannelId = paymentChannels.find(
        (item) => item.name === state.ministryCompany
      )?.id;
      let oct = null;
      if (octCode) {
        oct = octList.find((item) => item.code === octCode);
        paymentChannelId = oct.paymentChannelId;
        osttableId = oct?.id;
      }

      if (!octCode && !userMandateSignatureAgreement) {
        setError("Please check the agreement before signing");
        return;
      }

      let userMandateSignature = null;
      if (!oct) {
        userMandateSignature = userMandateSignatureRef.current
          .getTrimmedCanvas()
          .toDataURL("image/png");
      } else {
        userMandateSignature = oct?.userMandateSignature;
      }

      if (!octCode && userMandateSignatureRef.current.isEmpty()) {
        setError("Please sign before you can proceed");
        return;
      }

      const payload = {
        currentUserId: quotation.userId,
        paymentChannelId,
        osttableId,
        octOstType: "ministry",
        premiumDueDay: parseInt(state.premiumDueDay, 10),
        quotationId: quotation.id,
        paymentType: modeOfPayment,
        signatureRight: "either",
        userMandateSignature: userMandateSignature,
        lives: oct?.lives || state.lives,
      };

      // return console.log({payload});

      try {
        setSavingDetails(true);
        setError(null);
        const response = await axiosPrivate.post(url, payload);
        if (response.data.success) {
          navigate(`/quotations/${quotation.id}/first-payment`);
        } else {
          setError("Could not save payment details");
        }
        setSavingDetails(false);
      } catch (err) {
        setError("Could not save payment  details");
        setSavingDetails(false);
      }
    } else if (selectedPayment === "bank") {
      setSavingDetails(true);
      await submitBankDetails();
      setSavingDetails(false);
    }
    // else {
    //   updateQuotationStatus({ status: "firstpayment" });
    // }
  };

  const updateQuotationStatus = async (payload) => {
    setSavingDetails(true);
    const url =
      process.env.REACT_APP_API_URL +
      `/quotation/worker/status-update/${quotationId}`;

    try {
      payload = { ...payload, userId: quotation?.user?.id };
      const { data } = await axiosPrivate.patch(url, payload);

      setSavingDetails(false);

      if (!data.success) {
        // console.log(
        //   data.message
        //     ? data.message
        //     : "Quotation update was unsuccessful, please try again"
        // );
      } else {
        navigate(`/quotations/${quotation.id}/first-payment`);
      }
    } catch (error) {
      setSavingDetails(false);
      // console.log(error);
    }
  };

  const updateSiginingAndAgreementData = async () => {
    const id = params.quotationId;
    const signatureImage = mainLifeSignatureRef.current
      .getTrimmedCanvas()
      .toDataURL("image/png");

    if (mainLifeSignatureRef.current.isEmpty()) {
      setSignatureError("Please sign before you can proceed");
      return;
    }
    if (!mainLifeAgreement) {
      setSignatureError("Please agree to terms before you can proceed");
      return;
    }
    setSignatureError(null);

    setUpdatingSigningAndAgreementData(true);
    const payload = {
      signatureMainLife: signatureImage,
      consentSignatureMainLife: mainLifeAgreement,
    };
    const url =
      process.env.REACT_APP_API_URL +
      `/quotation/worker/main-life/signature/${id}`;

    try {
      const response = await axiosPrivate.patch(url, payload);
      setUpdatingSigningAndAgreementData(false);

      if (response.data.success === true) {
        setSignatureSuccess("Signature successfully uploaded");
      } else {
        setSignatureError("Could not upload signature");
      }
      // console.log(response);
    } catch (error) {
      setUpdatingSigningAndAgreementData(false);
      setSignatureError("Could not upload signature");
      // console.log(error);
    }
  };

  const updateSiginingAndAgreementDataForJointLife = async () => {
    const id = params.quotationId;
    const signatureImage = jointLifeSignatureRef.current
      .getTrimmedCanvas()
      .toDataURL("image/png");

    if (jointLifeSignatureRef.current.isEmpty()) {
      setSignatureErrorForJointLife(
        "Joint Life must sign before you can proceed"
      );
      return;
    }
    if (!jointLifeAgreement) {
      setSignatureErrorForJointLife(
        "Please agree to terms before you can proceed"
      );
      return;
    }
    setSignatureErrorForJointLife(null);

    setUpdatingSigningAndAgreementDataForJointLife(true);
    const payload = {
      signatureJointLife: signatureImage,
      consentSignatureJointLife: jointLifeAgreement,
    };
    const url =
      process.env.REACT_APP_API_URL +
      `/quotation/worker/joint-life/signature/${id}`;

    try {
      const response = await axiosPrivate.patch(url, payload);
      setUpdatingSigningAndAgreementDataForJointLife(false);

      if (response.data.success === true) {
        setSignatureSuccessForJointLife("Signature successfully uploaded");
      } else {
        setSignatureErrorForJointLife("Could not upload signature");
      }
      // console.log(response);
    } catch (error) {
      setUpdatingSigningAndAgreementDataForJointLife(false);
      setSignatureErrorForJointLife("Could not upload signature");
      // console.log(error);
    }
  };

  const getAllPaymentChannels = async () => {
    const url = "/payment-channel";

    try {
      const response = await axiosPrivate.get(url);
      setLoading(false);
      if (response.data.success === true) {
        setPaymentChannels(response.data.data);
        const bankPaymentChannels = response.data.data
          .filter((item) => item.type === "bank")
          .map((item) => {
            item.supportInternetBanking = !!item.internetBankingUrl;
            return item;
          });

        setMinistryCompanyChannels(
          response.data.data.filter(
            (item) => "ministry" === item.type || item.type === "company"
          )
        );

        setBanks(bankPaymentChannels);

        const posChannels = response.data.data.filter(
          (item) => item.type === "pos"
        );
        setPosChannels(posChannels);
      }
      console.log(response);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getDeclarationsforLife = async (userId) => {
    const url = process.env.REACT_APP_API_URL + `/user/declarations/${userId}`;

    try {
      const response = await axiosPrivate.get(url);

      return response.data.data.Declarations;
    } catch (e) {
      // console.log(e);
      return [];
    }
  };

  const getProposalFormLinkAndDownload = async () => {
    const _quotation = quotation;
    // const url =
    //   process.env.REACT_APP_API_URL +
    //   `/quotation/user/proposal-form-pdf/${_quotation.id}`;
    const url =
      process.env.REACT_APP_API_URL +
      `/quotation/user/manual-signature-zip/${_quotation.id}`;

    try {
      setDownloadProposalForm(true);
      const response = await axiosPrivate.get(url);

      if (response.data.success === true) {
        await new Promise((r) => setTimeout(r, 6000));
        const fileResponse = await axios({
          responseType: "blob",
          url: response.data.data.pdfLink,
          method: "GET",
        });
        setDownloadProposalForm(false);
        const url = window.URL.createObjectURL(fileResponse.data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `manual_signatures.zip`);
        link.click();
      } else {
        setDownloadProposalForm(false);
      }

      // console.log(response);
    } catch (error) {
      setDownloadProposalForm(false);
      // console.log(error);
    }
  };

  const getJointLifeDetails = async (userId) => {
    // /user/{id}
    const url = process.env.REACT_APP_API_URL + `/user/${userId}`;

    try {
      const response = await axiosPrivate.get(url);
      if (response.data.success === true) {
        const jointLifeDetails = response.data.data;
        setJointLifeDetails(jointLifeDetails);
      }
      // console.log(response);
    } catch (error) {
      // console.log(error);
    }
  };
  const getQuotationDetails = async () => {
    setLoading(true);
    const id = params.quotationId;
    const url = process.env.REACT_APP_API_URL + `/quotation/${id}`;

    try {
      const response = await axiosPrivate.get(url);

      if (response.data.success === true) {
        let _quotation = response.data.data;
        _quotation.quotationFormData = JSON.parse(_quotation.quotationFormData);
        // const premium = getTotalPremiumWithAddOnValue(_quotation);
        _quotation.premium = _quotation.premiumAmount;
        if (_quotation.quotationFormData.join_loan) {
          getJointLifeDetails(_quotation.quotationFormData.userJointId);
        }

        // await checkUnderwriting(_quotation);
        // await checkKyc(_quotation);
        setLoading(false);
        setAmount(_quotation.premiumAmount);
        setPolicyNumber(_quotation.temporaryId);
        setQuotation(_quotation);
        setQuotationId(_quotation.id);

        // getDeclarationsStatusForUser
        if (!_quotation.joinLoan) {
          const mainLifeDeclarationsPassed = await getDeclarationsStatusForUser(
            _quotation.user.id,
            _quotation.id
          );

          let mainKycVerificationCheck =
            _quotation.kycVerificationMain ||
            _quotation.shuftiManuallyVerifiedMainLife;

          let mainDeclarationCheck =
            mainLifeDeclarationsPassed ||
            _quotation.declarationsManuallyVerifiedMainLife;

          let isReferred = await isUnderwritingReferred(_quotation);

          let underwritingCheck =
            !isReferred || _quotation.underwritingManuallyAccepted;

          if (
            mainKycVerificationCheck &&
            mainDeclarationCheck &&
            underwritingCheck
          ) {
            setKycAndUnderwritingPassed(true);
          } else {
            setKycAndUnderwritingPassed(false);
          }
        } else {
          const mainLifeDeclarationsPassed = await getDeclarationsStatusForUser(
            _quotation.user.id,
            _quotation.id
          );
          const jointLifeDeclarationsPassed =
            await getDeclarationsStatusForUser(
              _quotation.userJoint.id,
              _quotation.id
            );

          let mainKycVerificationCheck =
            _quotation.kycVerificationMain ||
            _quotation.shuftiManuallyVerifiedMainLife;

          let mainDeclarationCheck =
            mainLifeDeclarationsPassed ||
            _quotation.declarationsManuallyVerifiedMainLife;

          let isReferred = await isUnderwritingReferred(_quotation);

          let underwritingCheck =
            !isReferred || _quotation.underwritingManuallyAccepted;

          let jointKycVerificationCheck =
            _quotation.kycVerificationJoint ||
            _quotation.shuftiManuallyVerifiedJointLife;

          let jointDeclarationCheck =
            jointLifeDeclarationsPassed ||
            _quotation.declarationsManuallyVerifiedJointLife;

          if (
            mainKycVerificationCheck &&
            mainDeclarationCheck &&
            underwritingCheck &&
            jointKycVerificationCheck &&
            jointDeclarationCheck &&
            underwritingCheck
          ) {
            setKycAndUnderwritingPassed(true);
          } else {
            setKycAndUnderwritingPassed(false);
          }
        }
      }
      // console.log(response);
    } catch (error) {
      setLoading(false);
      // console.log(error);
    }
  };

  const submitBankDetails = async () => {
    setUserMandateError(null);
    setUserJointMandateSignatureError(null);

    const ost = ostCode ? ostList.find((item) => item.code === ostCode) : null;

    if ((!state.nameOnAccount || !state.accountNumber || !state.bank) && !ost) {
      setUserMandateError("Please fill in all details");
      return;
    }

    if (!internetBankingUrl) {
      if (
        !userMandateSignatureAgreement &&
        (state.nameOnAccount === "mainlife" ||
          state.nameOnAccount === "bothlives") &&
        !ost
      ) {
        setUserMandateError("Please check the agreement before signing");
        return;
      }

      if (
        !userJointMandateSignatureAgreement &&
        (state.nameOnAccount === "jointlife" ||
          state.nameOnAccount === "bothlives") &&
        !ost
      ) {
        setUserJointMandateSignatureError(
          "Please check the agreement before signing"
        );
        return;
      }
    }

    const url = "/payment-choice/worker/create";

    if (modeOfPayment === "bank") {
      const paymentChannelId = paymentChannels.find(
        (item) => item.name === state.bank
      ).id;
      const bankName = state.bank;
      const { nameOnAccount, mandateReference, accountType } = state;

      let nameOfAccountObj = {};
      if (nameOnAccount === "mainlife") {
        nameOfAccountObj = { life: "mainlife" };
      }

      if (nameOnAccount === "jointlife") {
        nameOfAccountObj = { life: "jointlife" };
      }

      if (nameOnAccount === "bothlives") {
        nameOfAccountObj = { life: "bothlives" };
      }

      let osttableId = null;
      if (ostCode && ost) {
        osttableId = ostList.find((item) => item.code === ostCode)?.id;
      }

      let userMandateSignature = null;
      if (!ost) {
        if (userMandateSignatureRef.current?.isEmpty()) {
          setUserMandateError("Please sign before you can proceed");
          return;
        }
        userMandateSignature = userMandateSignatureRef.current
          ?.getTrimmedCanvas()
          .toDataURL("image/png");
      } else {
        userMandateSignature = ost.userMandateSignature;
      }

      let userJointMandateSignature = null;

      if (!ost && quotation?.joinLoan) {
        if (userJointMandateSignatureRef.current?.isEmpty()) {
          setUserMandateError("Please sign before you can proceed");
          return;
        }
        userJointMandateSignature = userJointMandateSignatureRef.current
          ?.getTrimmedCanvas()
          .toDataURL("image/png");
      }

      let payload = {
        osttableId,
        paymentChannelId,
        premiumDueDay: parseInt(state.premiumDueDay, 10),
        quotationId: quotation.id,
        paymentType: "bank",
        nameOnAccount: nameOfAccountObj,
        accountType,
        accountNumber: state.accountNumber || ost?.accountNumber,
        mandateReference,
        bankName: ostCode ? null : bankName,
        lives: ost?.lives || state.lives,
        signatureRight: state.signatureRight,
        userMandateSignature,
        userJointMandateSignature,
      };

      if (ost) {
        const {
          id,
          paymentChannelId,
          nameOnAccount,
          accountType,
          accountNumber,
          mandateReference,
          bankName,
          lives,
          signatureRight,
          userMandateSignature,
          userJointMandateSignature,
        } = ost;

        payload = {
          currentUserId: quotation.userId,
          osttableId: id,
          paymentChannelId,
          premiumDueDay: parseInt(state.premiumDueDay, 10),
          quotationId: quotation.id,
          paymentType: "bank",
          nameOnAccount: JSON.parse(nameOnAccount),
          accountType,
          accountNumber,
          mandateReference,
          bankName: ostCode ? null : bankName,
          lives,
          signatureRight,
          userMandateSignature,
          userJointMandateSignature,
        };
      }

      try {
        setSavingBankDetails(true);
        setError(null);
        const response = await axiosPrivate.post(url, payload);
        setSavingBankDetails(false);
        if (response.data.success) {
          setSavingBankSuccess("Bank Details saved");
          setSavedBankDetails(true);
          setTimeout(() => {
            navigate(`/quotations/${quotation.id}/first-payment`);
          }, 1000);
        } else {
          setError("Could not save payment details");
        }
      } catch (err) {
        setSavingBankDetails(false);
        console.log({ err });
        setError("Could not save payment  details");
      }
    }
  };

  // const getPaymentFrequency = (quotation) => {
  //   if()
  // }
  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }

  // MARKUP *********
  const markupManualSignInput =
    quotation && quotation?.isLtaResult ? (
      <React.Fragment>
        <div className="col-lg-6">
          <div className="mb-2 mt-2">
            <label htmlFor="formFile" className="form-label">
              Upload signed{" "}
              <span style={{ fontWeight: 500 }}>proposal document</span>
            </label>
            <input
              onChange={(event) => {
                // console.log(event.target.files[0]);
                setSelectedSignedProposalDocument(event.target.files[0]);
              }}
              className="form-control"
              type="file"
              accept="application/pdf,image/*"
              id="formFile"
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="mb-2 mt-2">
            <label htmlFor="formFile" className="form-label">
              Upload signed{" "}
              <span style={{ fontWeight: 500 }}>data consent document</span>
            </label>
            <input
              onChange={(event) => {
                // console.log(event.target.files[0]);
                setSelectedSignedDataConsentDocument(event.target.files[0]);
              }}
              className="form-control"
              type="file"
              accept="application/pdf,image/*"
              id="formFile"
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="mb-2 mt-2">
            <label htmlFor="formFile" className="form-label">
              Upload signed{" "}
              <span style={{ fontWeight: 500 }}>financial questionnaire</span>
            </label>
            <input
              onChange={(event) => {
                // console.log(event.target.files[0]);
                setSelectedSignedFinancialQuestionnaire(event.target.files[0]);
              }}
              className="form-control"
              type="file"
              accept="application/pdf,image/*"
              id="formFile"
            />
          </div>
        </div>
      </React.Fragment>
    ) : quotation && quotation.isDtaResult ? (
      <React.Fragment>
        <div className="col-lg-6">
          <div className="mb-2 mt-2">
            <label htmlFor="formFile" className="form-label">
              Upload signed{" "}
              <span style={{ fontWeight: 500 }}>proposal document</span>
            </label>
            <input
              onChange={(event) => {
                // console.log(event.target.files[0]);
                setSelectedSignedProposalDocument(event.target.files[0]);
              }}
              className="form-control"
              type="file"
              accept="application/pdf,image/*"
              id="formFile"
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="mb-2 mt-2">
            <label htmlFor="formFile" className="form-label">
              Upload signed{" "}
              <span style={{ fontWeight: 500 }}>data consent document</span>
            </label>
            <input
              onChange={(event) => {
                // console.log(event.target.files[0]);
                setSelectedSignedDataConsentDocument(event.target.files[0]);
              }}
              className="form-control"
              type="file"
              accept="application/pdf,image/*"
              id="formFile"
            />
          </div>
        </div>
      </React.Fragment>
    ) : null;

  return (
    <>
      <Navbar
        name={
          userData
            ? `${userData?.data?.firstname} ${userData?.data?.lastname}`
            : ""
        }
      />
      <div className="container review">
        <h4 className="mt-4">Review Approval and Payment</h4>

        {loading && (
          <>
            <div
              style={{ marginTop: 150 }}
              className="d-flex flex-column justify-content-center align-items-center"
            >
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <div>Please wait...</div>
            </div>
          </>
        )}
        {quotation && (
          <>
            <div className="alert alert-info">
              I accept that all information provided are correct, truthful and
              to the best of my knowledge. I acknowledge being aware that any
              failure to fully disclose any facts may lead to the insurance
              being null and void.
            </div>
            <div className="d-flex mt-5 justify-content-between align-items-end flex-wrap">
              <h5>1. Brief Application Details</h5>
              <div className="d-flex flex-column align-items-start">
                {/* <a
                  style={{ color: "#BEC663", fontSize: 18, fontWeight: "bold" }}
                  className="btn btn-text"
                  href={`${process.env.REACT_APP_BASENAME}/quotations/${quotation.id}/edit`}
                >
                  <span className="fa fa-edit me-2"></span>Edit
                </a> */}
                <a
                  style={{ color: "#707070", fontSize: 18, fontWeight: "bold" }}
                  className={`btn btn-text ${
                    !quotation.isForwardedToCustomer ? "disabled" : ""
                  }`}
                  onClick={() => {
                    getProposalFormLinkAndDownload(quotation);
                  }}
                >
                  {downloadProposalForm && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}{" "}
                  <span className="fa fa-file-pdf me-2"></span>Download Proposal
                  Form
                </a>
              </div>
            </div>
            <table className="table mt-2 table-bordered">
              <thead>
                <tr className="ila-bg text-white">
                  <th colSpan="5">
                    Temporal Policy ID :{quotation.temporaryId}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>Plan Name</td>
                  <td>
                    <input
                      disabled={true}
                      className="form-control"
                      type="text"
                      value={
                        quotation.isDtaResult
                          ? "DTA"
                          : quotation.isLtaResult
                          ? "LTA"
                          : "DTA"
                      }
                      readOnly="readonly"
                    />
                  </td>
                  <td>
                    <label className="me-3">Refund</label>
                  </td>
                  <td colSpan="3" className=" pl-5 mt-2 ml-0 pl-0">
                    <div className="form-check form-check-inline">
                      <input
                        disabled
                        readOnly
                        className="form-check-input"
                        type="radio"
                        checked={!quotation.quotationFormData.with_refund}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="joinLoanRadioNo"
                      >
                        No
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        disabled
                        readOnly
                        className="form-check-input"
                        type="radio"
                        checked={quotation.quotationFormData.with_refund}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="joinLoanRadioYes"
                      >
                        Yes
                      </label>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>Sum Assured</td>
                  <td>
                    <input
                      disabled={true}
                      className="form-control"
                      type="text"
                      value={currencyFormatter.format(
                        parseFloat(
                          quotation?.quotationFormData?.initial_sum_assured ||
                            ""
                        ),
                        { symbol: "Rs " }
                      )}
                      readOnly="readonly"
                    />
                  </td>

                  <td>Term</td>
                  <td>
                    <input
                      disabled={true}
                      className="form-control"
                      type="text"
                      value={`${quotation?.quotationFormData?.term_number} ${quotation?.quotationFormData?.term_option}`}
                    ></input>
                  </td>
                </tr>

                <tr>
                  <td>Premium</td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      disabled={true}
                      value={currencyFormatter.format(
                        parseFloat(quotation?.premiumAmount || ""),
                        { symbol: "Rs " }
                      )}
                      readOnly="readonly"
                    />
                  </td>
                  <td>
                    <label className="me-3">Joint Loan</label>
                  </td>
                  <td colSpan="3" className=" pl-5 mt-2 ml-0 pl-0">
                    <div className="form-check form-check-inline">
                      <input
                        disabled
                        readOnly
                        className="form-check-input"
                        type="radio"
                        name="joinLoanRadio"
                        id="joinLoanRadioNo"
                        checked={!quotation.quotationFormData.join_loan}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="joinLoanRadioNo"
                      >
                        No
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        disabled
                        readOnly
                        className="form-check-input"
                        type="radio"
                        checked={quotation.quotationFormData.join_loan}
                      />
                      <label className="form-check-label">Yes</label>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>Payment Frequency</td>
                  <td>
                    <select
                      readOnly
                      disabled={true}
                      className="form-select"
                      aria-label="Default select example"
                      value={
                        quotation?.quotationFormData?.payment_insurance_plan ||
                        ""
                      }
                    >
                      <option value="">Select Payment Frequency</option>
                      <option value="yearly">Yearly</option>
                      <option value="half-yearly">Half Yearly</option>
                      <option value="monthly">Monthly</option>
                      <option value="single">single</option>
                      <option value="quarterly">Quarterly</option>
                    </select>
                  </td>
                  <td colSpan="3"></td>
                </tr>
              </tbody>
            </table>

            <div className=" mt-5">
              {error && <div className="alert alert-danger">{error}</div>}
              <h5 className="mt-3">
                2. How do you want to sign for your policy?
              </h5>
              <ul
                className="nav nav-pills mb-3 mt-2"
                id="pills-tab"
                role="tablist"
              >
                <li
                  className="nav-item"
                  role="presentation"
                  onClick={() => setShowSignPads(true)}
                >
                  <button
                    className="payment nav-link"
                    id="pills-home-tab"
                    data-coreui-toggle="pill"
                    data-coreui-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Digitally Sign
                  </button>
                </li>
                <li
                  className="nav-item"
                  role="presentation"
                  onClick={() => setShowSignPads(true)}
                >
                  <button
                    className="payment nav-link"
                    id="pills-profile-tab"
                    data-coreui-toggle="pill"
                    data-coreui-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    Manually Sign Document
                  </button>
                </li>
              </ul>

              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane  border padding rounded fade  pb-3"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                  tabIndex="0"
                >
                  {showSignPads && (
                    <div style={{ marginTop: 40 }} className="container">
                      <div className="row">
                        <div className="col-lg-6">
                          <h2>eSignature Pad [1st Life]</h2>
                          <h5>{quotation?.user?.name}</h5>
                          {signatureError && (
                            <div className="alert alert-danger">
                              {signatureError}
                            </div>
                          )}
                          {signatureSuccess && (
                            <div className="alert alert-success">
                              {signatureSuccess}
                            </div>
                          )}
                          <div className="mb-2">
                            <label className="form-check-label">
                              <input
                                className="me-3"
                                type="checkbox"
                                onClick={() => {
                                  setMainLifeAgreement(!mainLifeAgreement);
                                }}
                                checked={mainLifeAgreement}
                              />
                              <div className="d-inline-block">
                                By checking this you authorize ILA to use your
                                signature on relevant documents
                              </div>
                            </label>
                          </div>

                          <SignatureCanvas
                            ref={mainLifeSignatureRef}
                            penColor="black"
                            style={{ background: "blue" }}
                            canvasProps={{
                              width: 500,
                              height: 200,
                              className: "sigCanvas",
                            }}
                          />

                          <div className="d-flex justify-content-start">
                            <a
                              className="btn btn-light"
                              onClick={() => {
                                mainLifeSignatureRef.current.clear();
                              }}
                            >
                              Clear <i className="fa fa-refresh"></i>
                            </a>
                          </div>
                          <div className="d-flex justify-content-start mt-5">
                            <button
                              className={`btn ila-primary-btn me-3 text-white ${
                                signatureSuccess && "disabled"
                              }`}
                              onClick={() => {
                                updateSiginingAndAgreementData();
                              }}
                            >
                              {updatingSigningAndAgreementData && (
                                <span className="spinner-border spinner-border-sm me-3"></span>
                              )}
                              Submit signature for Main Life
                            </button>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          {quotation.quotationFormData.join_loan && (
                            <>
                              <h2>eSignature Pad [2nd Life]</h2>
                              <h5>
                                {jointLifeDetails ? jointLifeDetails?.name : ""}
                              </h5>
                              {signatureErrorForJointLife && (
                                <div className="alert alert-danger">
                                  {signatureErrorForJointLife}
                                </div>
                              )}

                              {signatureSuccessForJointLife && (
                                <div className="alert alert-success">
                                  {signatureSuccessForJointLife}
                                </div>
                              )}
                              <div className="mb-2">
                                <label className="form-check-label">
                                  <input
                                    className="me-3"
                                    type="checkbox"
                                    onClick={() => {
                                      setJointLifeAgreement(
                                        !jointLifeAgreement
                                      );
                                    }}
                                    checked={jointLifeAgreement}
                                  />
                                  <div className="d-inline-block">
                                    By checking this you authorize ILA to use
                                    your signature on relevant documents
                                  </div>
                                </label>
                              </div>

                              <SignatureCanvas
                                ref={jointLifeSignatureRef}
                                penColor="black"
                                canvasProps={{
                                  width: 500,
                                  height: 200,
                                  className: "sigCanvas",
                                }}
                              />

                              <div className="d-flex justify-content-start">
                                <a
                                  className="btn btn-light"
                                  onClick={() => {
                                    jointLifeSignatureRef.current.clear();
                                  }}
                                >
                                  Clear <i className="fa fa-refresh"></i>
                                </a>
                              </div>
                            </>
                          )}
                          {jointLifeDetails && (
                            <div className="d-flex justify-content-start mt-5">
                              <button
                                className={`btn ila-primary-btn me-3 text-white ${
                                  signatureSuccessForJointLife && "disabled"
                                }`}
                                onClick={() => {
                                  updateSiginingAndAgreementDataForJointLife();
                                }}
                              >
                                {updatingSigningAndAgreementDataForJointLife && (
                                  <span className="spinner-border spinner-border-sm me-3"></span>
                                )}
                                Submit signature for Joint Life
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className="tab-pane  border padding rounded fade mb-4"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                  tabIndex="0"
                >
                  {showSignPads && (
                    <div style={{ marginTop: 40 }} className="container">
                      <button
                        disabled={downloadProposalForm}
                        style={{}}
                        className="btn btn-light me-2 mb-3"
                        onClick={() => {
                          getProposalFormLinkAndDownload();
                        }}
                      >
                        {downloadProposalForm && (
                          <span
                            className="spinner-border spinner-border-sm me-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}{" "}
                        <span className="fa fa-download me-2"></span>Download
                        Document to Sign
                      </button>

                      <div className="row">
                        {markupManualSignInput}

                        {/* Notification upload */}
                        {manuallySignatureError && (
                          <div className="alert alert-danger">
                            {manuallySignatureError}
                          </div>
                        )}

                        {manuallySignatureSuccess && (
                          <div className="alert alert-success">
                            {manuallySignatureSuccess}
                          </div>
                        )}
                      </div>
                      <div className="mb-2 mt-3">
                        <label className="form-check-label">
                          <input
                            className="me-3"
                            type="checkbox"
                            onClick={() => {
                              setManualUploadAgreement(!manualUploadAgreement);
                            }}
                            checked={manualUploadAgreement}
                          />
                          <div className="d-inline-block">
                            By checking this you authorize ILA to use your
                            signature on relevant documents
                          </div>
                        </label>
                      </div>
                      {manualUploadAgreement && (
                        <button
                          className={`btn ila-primary-btn text-white me-2 my-4 ${
                            (uploadedManuallySignedAgreement ||
                              uploadingManuallySignedAgreement) &&
                            "disabled"
                          }`}
                          onClick={() => {
                            uploadManuallySignedAgreement();
                          }}
                        >
                          {uploadingManuallySignedAgreement && (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}{" "}
                          Submit
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {quotation &&
              quotation?.quotationFormData?.payment_insurance_plan !==
                "single" &&
              kycAndUnderwritingPassed && (
                <div className="mt-5">
                  <div style={{ marginTop: 20 }} className="row">
                    <h5>
                      3. How do you want to pay for your subsequent premiums?
                    </h5>

                    <div className="px-3 mb-3 pb-2">
                      <Nav
                        variant="tabs"
                        onSelect={selectPaymentTab}
                        activeKey={activePaymentTab}
                        defaultActiveKey={activePaymentTab}
                      >
                        <Nav.Item>
                          <Nav.Link eventKey="instant" title="instant">
                            Instant Payment
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="non-instant" title="non-instant">
                            Non-Instant Payment
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>

                      {activePaymentTab == "instant" && (
                        <div className={`col-lg-12 d-flex mb-4`}>
                          <div className={`col-lg-2 col-md-3 col-xs-4 mt-3 `}>
                            <div
                              onClick={() => {
                                createMembershipPaymentAndPopulateIframe();
                                setModeOfPayment("card");
                              }}
                              className={`payment-option p-3 px-4 border d-flex tw-items-center flex-column ${
                                modeOfPayment === "card" ? "selected" : ""
                              }`}
                            >
                              <div className="text-center">
                                <img style={{ height: 80 }} src={MipsLogo} />
                              </div>
                              <div className="fw-bold small text-center">
                                Mips Gateway
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {activePaymentTab == "non-instant" && (
                        <div className={`col-lg-12 tw-flex tw-gap-3 mb-4`}>
                          <div className={`col-lg-2 col-md-3 col-xs-4 mt-3 `}>
                            <div
                              onClick={() => {
                                setModeOfPayment("pos");
                              }}
                              className={`payment-option p-3 px-4  border tw-flex tw-flex-col tw-items-center tw-justify-center ${
                                modeOfPayment === "pos" ? "selected" : ""
                              }`}
                            >
                              <div className="text-center">
                                <img style={{ height: 80 }} src={PosLogo} />
                              </div>
                              <div className="fw-bold small text-center">
                                Point of Sale
                              </div>
                            </div>
                          </div>
                          <div className={`col-lg-2 col-md-3 col-xs-4 mt-3 `}>
                            <div
                              onClick={() => {
                                setState({
                                  ...state,
                                  mandateReference: uniqid(
                                    "ost",
                                    `${quotationId}`
                                  ).toUpperCase(),
                                });
                                setModeOfPayment("bank");
                              }}
                              className={`payment-option p-3 px-4  border tw-flex tw-flex-col tw-items-center tw-justify-center  ${
                                modeOfPayment === "bank" ? "selected" : ""
                              }`}
                            >
                              <div className="text-center">
                                <img style={{ height: 80 }} src={BankLogo} />
                              </div>
                              <div className="fw-bold small text-center">
                                Bank
                              </div>
                            </div>
                          </div>
                          <div className={`col-lg-2 col-md-3 col-xs-4 mt-3 `}>
                            <div
                              onClick={() => {
                                setModeOfPayment("cash");
                              }}
                              className={`payment-option p-3 px-4  border tw-flex tw-flex-col tw-items-center tw-justify-center ${
                                modeOfPayment === "cash" ? "selected" : ""
                              }`}
                            >
                              <div className="text-center">
                                <img style={{ height: 80 }} src={CashLogo} />
                              </div>
                              <div className="fw-bold small text-center">
                                Cash
                              </div>
                            </div>
                          </div>

                          <div
                            className={`col-lg-2 col-md-3 col-xs-4 mt-3 px-2`}
                          >
                            <div
                              onClick={() => {
                                setModeOfPayment("company");
                              }}
                              className={`payment-option p-3 px-4  border tw-flex tw-flex-col tw-items-center tw-justify-center ${
                                modeOfPayment === "company" ? "selected" : ""
                              }`}
                            >
                              <div className="text-center">
                                <img
                                  style={{ height: 80 }}
                                  src={MinistryCompany}
                                />
                              </div>
                              <div className="fw-bold small text-center">
                                Ministry/Company
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {modeOfPayment && (
                        <>
                          <div
                            className="p-3 mt-4"
                            style={{ background: "#efefef", borderRadius: 10 }}
                          >
                            {modeOfPayment === "cash" && (
                              <div className="row mt-4 mb-5">
                                <div className="col-lg-6">
                                  <label>
                                    Enter Premium Payment day of the Month (1st
                                    to 27th)
                                  </label>
                                  <input
                                    onChange={(event) => {
                                      setState({
                                        ...state,
                                        premiumDueDay: event.target.value,
                                      });
                                    }}
                                    className="form-control"
                                    type="number"
                                    value={state.premiumDueDay}
                                    min="1"
                                    maxLength={2}
                                    max="27"
                                  />
                                </div>
                              </div>
                            )}
                            {modeOfPayment === "pos" && (
                              <div className="row mt-4 mb-5">
                                <div className="col-lg-6">
                                  <label>
                                    Enter Premium Payment day of the Month (1st
                                    to 27th)
                                  </label>
                                  <input
                                    className="form-control"
                                    type="number"
                                    onChange={(event) => {
                                      setState({
                                        ...state,
                                        premiumDueDay: event.target.value,
                                      });
                                    }}
                                    value={state.premiumDueDay}
                                    maxLength={2}
                                    min="1"
                                    max="27"
                                  />
                                </div>
                                <div style={{ marginTop: 20 }} className="row">
                                  <div
                                    className={`col-lg-1 col-md-3 col-xs-4 mt-3 `}
                                  >
                                    <div
                                      onClick={() => {
                                        setSelectedPayment("blink");
                                      }}
                                      className={`payment-option p-3  border d-flex justify-content-center ${
                                        selectedPayment === "blink"
                                          ? "selected"
                                          : ""
                                      }`}
                                    >
                                      <img className="w-75" src={BlinkLogo} />
                                    </div>
                                  </div>
                                  <div
                                    className={`col-lg-1 col-md-3 col-xs-4 mt-3 `}
                                  >
                                    <div
                                      onClick={() => {
                                        setSelectedPayment("juice");
                                      }}
                                      className={`payment-option p-3  border d-flex justify-content-center h-100 ${
                                        selectedPayment === "juice"
                                          ? "selected"
                                          : ""
                                      }`}
                                    >
                                      <img
                                        className="w-100 my-auto"
                                        src={JuiceLogo}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex  mt-4 align-items-center">
                                  <div className="row">
                                    <div className="col-lg-5">
                                      {selectedPayment === "blink" && (
                                        <>
                                          <div className="alert alert-info">
                                            <ol>
                                              <li>
                                                Ensure you have your blink app
                                                downloaded and linked to your
                                                bank account
                                              </li>
                                              <li>
                                                If you don't, click on the link
                                                relevant to your device.
                                                <br />
                                                <a
                                                  href="https://play.google.com/store/apps/details?id=com.emtel.payment&hl=en&gl=US"
                                                  target="_blank"
                                                  className="me-3"
                                                  rel="noreferrer"
                                                >
                                                  <img
                                                    style={{ width: "100px" }}
                                                    src={GoogleIcon}
                                                  />
                                                </a>
                                                <a
                                                  href="https://apps.apple.com/us/app/blink-by-emtel/id1607506713"
                                                  target="_blank"
                                                  className="me-3"
                                                  rel="noreferrer"
                                                >
                                                  <img
                                                    style={{ height: "30px" }}
                                                    src={IosLogo}
                                                  />
                                                </a>
                                                <a
                                                  href="https://appgallery.huawei.com/app/C104485991"
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  <img
                                                    style={{ height: "30px" }}
                                                    src={HwuaweiLogo}
                                                  />
                                                </a>
                                              </li>
                                              <li>
                                                Scan merchant QR code and make
                                                payment. Please quote your
                                                policy number as{" "}
                                                <span className="badge mx-1 badge-lg bg-info">
                                                  <h5>
                                                    {policyNumber ||
                                                      "Policy Number"}
                                                  </h5>
                                                </span>{" "}
                                                in your transaction reference
                                                and amount as{" "}
                                                <span className="badge mx-1 badge-lg mt-1 bg-info">
                                                  <h5>
                                                    {currencyFormatter.format(
                                                      parseFloat(amount || ""),
                                                      { symbol: "Rs " }
                                                    ) || "Amount"}
                                                  </h5>
                                                </span>
                                              </li>
                                              <li>
                                                Enter the transaction id in the
                                                space provided
                                              </li>
                                              <li>
                                                ILA will send you a notification
                                                upon receipt of your premium you
                                                are done
                                              </li>
                                            </ol>
                                          </div>
                                        </>
                                      )}
                                      {selectedPayment === "juice" && (
                                        <>
                                          <div className="alert alert-info">
                                            <ol>
                                              <li>
                                                Ensure you have your juice app
                                                installed and linked to your
                                                bank account
                                              </li>
                                              <li>
                                                If you don't have it installed,
                                                click on the link relevant to
                                                your device.
                                                <br />
                                                <a
                                                  href="https://play.google.com/store/apps/details?id=com.mcbmu.juice"
                                                  target="_blank"
                                                  className="me-3"
                                                  rel="noreferrer"
                                                >
                                                  <img
                                                    style={{ width: "100px" }}
                                                    src={GoogleIcon}
                                                  />
                                                </a>
                                                <a
                                                  href="https://apps.apple.com/us/app/mcb-juice/id1525428458"
                                                  target="_blank"
                                                  className="me-3"
                                                  rel="noreferrer"
                                                >
                                                  <img
                                                    style={{ height: "30px" }}
                                                    src={IosLogo}
                                                  />
                                                </a>
                                                <a
                                                  href="https://appgallery.huawei.com/app/C104285497"
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  <img
                                                    style={{ height: "30px" }}
                                                    src={HwuaweiLogo}
                                                  />
                                                </a>
                                              </li>
                                              <li>
                                                Go to payment page and enter our
                                                account number,
                                                <span className=" me-2 badge badge-lg bg-info">
                                                  <h5>000010217975</h5>
                                                </span>
                                                Please quote your policy number
                                                as{" "}
                                                <span className="mx-1 mt-1 badge badge-lg bg-info">
                                                  <h5>
                                                    {policyNumber ||
                                                      "Policy Number"}
                                                  </h5>
                                                </span>{" "}
                                                in your transaction reference
                                                and amount as{" "}
                                                <span className="badge mx-1 badge-lg mt-1 bg-info">
                                                  <h5>
                                                    {" "}
                                                    {currencyFormatter.format(
                                                      parseFloat(amount || ""),
                                                      { symbol: "Rs " }
                                                    ) || "Amount"}
                                                  </h5>
                                                </span>
                                              </li>
                                              <li>
                                                Enter the transaction id in the
                                                space provided
                                              </li>
                                              <li>
                                                ILA will send you a notification
                                                upon receipt of your premium you
                                                are done
                                              </li>
                                            </ol>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {modeOfPayment === "bank" && (
                              <>
                                <div className="row  mb-2">
                                  {ostList.length > 0 && (
                                    <div className={"tw-my-5"}>
                                      <h2
                                        className={
                                          "tw-text-black tw-text-xl font-bold"
                                        }
                                      >
                                        {" "}
                                        List of existing payment references
                                      </h2>
                                      <p
                                        className={
                                          "tw-text-orange-500 tw-text-xs"
                                        }
                                      >
                                        You can select any of these to reuse as
                                        your payment reference
                                      </p>
                                      <div
                                        className={
                                          "tw-grid tw-grid-cols-4 tw-grid-flow-row tw-gap-3 tw-flex-wrap tw-mt-3"
                                        }
                                      >
                                        {ostList
                                          .filter((ost) => ost.isActive)
                                          .map((ost, index) => (
                                            <div
                                              key={index}
                                              className={`${
                                                ostCode === ost.code
                                                  ? "tw-border-2 tw-border-dotted tw-border-[#bdc666] !tw-bg-[#bdc666]"
                                                  : ""
                                              } tw-flex tw-flex-col tw-justify-between tw-bg-white tw-rounded-lg tw-p-2 tw-px-3 tw-font-bold`}
                                            >
                                              <div>
                                                <div className="tw-border-b tw-border-gray-200 tw-mb-2">
                                                  <p
                                                    className={`tw-text-md ${
                                                      ostCode === ost.code
                                                        ? "tw-text-white"
                                                        : ""
                                                    }`}
                                                  >
                                                    {ost.code}
                                                  </p>
                                                </div>

                                                {ost.Quotations.map(
                                                  (quotation, index) => (
                                                    <div
                                                      key={index}
                                                      className="tw-flex tw-flex-col tw-gap-0 tw-items-start tw-mt-2 tw-border-b tw-border-gray-200 tw-pb-2"
                                                    >
                                                      <p
                                                        className={`tw-text-md ${
                                                          ostCode === ost.code
                                                            ? "tw-text-white"
                                                            : ""
                                                        }`}
                                                      >
                                                        Bank Name:{" "}
                                                        <span className="tw-font-bold">
                                                          {
                                                            ost.PaymentChannel
                                                              .name
                                                          }
                                                        </span>
                                                      </p>
                                                      <p
                                                        className={`tw-text-md ${
                                                          ostCode === ost.code
                                                            ? "tw-text-white"
                                                            : ""
                                                        }`}
                                                      >
                                                        Policy Number:{" "}
                                                        <span className="tw-font-bold">
                                                          {
                                                            quotation.policyNumber
                                                          }
                                                        </span>
                                                      </p>
                                                      <p
                                                        className={`tw-text-md ${
                                                          ostCode === ost.code
                                                            ? "tw-text-white"
                                                            : ""
                                                        }`}
                                                      >
                                                        Premium{" "}
                                                        {currencyFormatter.format(
                                                          parseFloat(
                                                            quotation.premium
                                                          ) || "",
                                                          { symbol: "Rs " }
                                                        )}
                                                      </p>
                                                      <p
                                                        className={`tw-text-md ${
                                                          ostCode === ost.code
                                                            ? "tw-text-white"
                                                            : ""
                                                        }`}
                                                      >
                                                        Payment Frequency:{" "}
                                                        <span className="tw-font-bold">
                                                          {
                                                            quotation.paymentFrequency
                                                          }
                                                        </span>
                                                      </p>
                                                    </div>
                                                  )
                                                )}
                                              </div>

                                              <button
                                                onClick={() => {
                                                  const filteredBank =
                                                    banks.find(
                                                      (item) =>
                                                        item.name ===
                                                        ost.PaymentChannel.name
                                                    );
                                                  setInternetBankingUrl(
                                                    filteredBank.internetBankingUrl
                                                  );
                                                  setState({
                                                    ...state,
                                                    bank: ost.PaymentChannel
                                                      .name,
                                                  });
                                                  if (ostCode === ost.code) {
                                                    setOstCode(null);
                                                  } else {
                                                    setOstCode(ost.code);
                                                  }
                                                }}
                                                className={`tw-p-2 tw-px-3 ${
                                                  ostCode === ost.code
                                                    ? "tw-bg-white tw-text-[#bdc666]"
                                                    : "tw-bg-[#bdc666] tw-text-white"
                                                }  tw-text-xs tw-mt-2 tw-cursor-pointer tw-rounded-md`}
                                              >
                                                {ostCode === ost.code
                                                  ? "DESELECT"
                                                  : "SELECT"}
                                              </button>
                                            </div>
                                          ))}
                                      </div>
                                    </div>
                                  )}

                                  <div className="col-lg-6">
                                    {!ostCode && (
                                      <div className="row mb-3">
                                        <label
                                          htmlFor="term"
                                          className="col-sm-12 col-form-label"
                                        >
                                          Bank
                                        </label>
                                        <div>
                                          <select
                                            className="form-select"
                                            onChange={(event) => {
                                              if (ostCode) {
                                                setOstCode(null);
                                              }
                                              const filteredBank = banks.find(
                                                (item) =>
                                                  item.name ===
                                                  event.target.value
                                              );
                                              setInternetBankingUrl(
                                                filteredBank.internetBankingUrl
                                              );
                                              setState({
                                                ...state,
                                                bank: event.target.value,
                                              });
                                            }}
                                            value={state.bank}
                                          >
                                            <option selected="">
                                              Select...
                                            </option>
                                            {banks.map((item) => (
                                              <option value={item.name}>
                                                {item.name}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                        {internetBankingUrl && (
                                          <div>
                                            <a
                                              href={internetBankingUrl}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              Click here to setup internet
                                              banking
                                            </a>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>

                                {internetBankingUrl && (
                                  <div className="mb-2">
                                    <label className="form-check-label">
                                      <input
                                        className="me-3"
                                        type="checkbox"
                                        onClick={() => {
                                          setCompletedIB(!completedIB);
                                        }}
                                        checked={completedIB}
                                      />
                                      <div className="d-inline-block">
                                        Have completed setting up mandate on
                                        Internet Banking
                                      </div>
                                    </label>
                                  </div>
                                )}

                                {!ostCode &&
                                  internetBankingUrl &&
                                  completedIB && (
                                    <>
                                      <div className="row  mb-2">
                                        <div className="col-lg-6">
                                          <label>Name on Account</label>
                                          <select
                                            onChange={(event) => {
                                              setState({
                                                ...state,
                                                nameOnAccount:
                                                  event.target.value,
                                              });
                                            }}
                                            value={state.nameOnAccount}
                                            className="form-select"
                                            id="term"
                                          >
                                            <option selected="">
                                              Select...
                                            </option>
                                            <option value="mainlife">
                                              Main life
                                            </option>
                                            {quotation.joinLoan == true && (
                                              <>
                                                <option value="jointlife">
                                                  Joint life
                                                </option>
                                                <option value="bothlives">
                                                  Both lives
                                                </option>
                                              </>
                                            )}
                                          </select>
                                        </div>
                                      </div>

                                      <div className="row  mb-2">
                                        <div className="col-lg-6">
                                          <div className="row mb-3">
                                            <label
                                              htmlFor="term"
                                              className="col-sm-12 col-form-label"
                                            >
                                              Account Type
                                            </label>
                                            <div>
                                              <select
                                                onChange={(event) => {
                                                  setState({
                                                    ...state,
                                                    accountType:
                                                      event.target.value,
                                                  });
                                                }}
                                                value={state.accountType}
                                                className="form-select"
                                                id="term"
                                              >
                                                <option value="">
                                                  Select...
                                                </option>
                                                <option value="current">
                                                  Current
                                                </option>
                                                <option value="savings">
                                                  Savings
                                                </option>
                                              </select>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="row  mb-2">
                                        <div className="col-lg-6">
                                          <label>Account Number</label>
                                          <input
                                            onChange={(event) => {
                                              setState({
                                                ...state,
                                                accountNumber:
                                                  event.target.value,
                                              });
                                            }}
                                            value={state.accountNumber}
                                            className="form-control"
                                            type="text"
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}

                                {!ostCode &&
                                  state.bank &&
                                  !internetBankingUrl && (
                                    <>
                                      <div className="row  mb-2">
                                        <div className="col-lg-6">
                                          <label>Name on Account</label>
                                          <select
                                            onChange={(event) => {
                                              setState({
                                                ...state,
                                                nameOnAccount:
                                                  event.target.value,
                                              });
                                            }}
                                            value={state.nameOnAccount}
                                            className="form-control"
                                          >
                                            <option selected="">
                                              Select...
                                            </option>
                                            <option value="mainlife">
                                              Main life
                                            </option>
                                            {quotation?.joinLoan == true && (
                                              <>
                                                <option value="jointlife">
                                                  Joint life
                                                </option>
                                                <option value="bothlives">
                                                  Both lives
                                                </option>
                                              </>
                                            )}
                                          </select>
                                        </div>
                                      </div>

                                      <div className="row  mb-2">
                                        <div className="col-lg-6">
                                          <div className="row mb-3">
                                            <label
                                              htmlFor="term"
                                              className="col-sm-12 col-form-label"
                                            >
                                              Account Type
                                            </label>
                                            <div>
                                              <select
                                                onChange={(event) => {
                                                  setState({
                                                    ...state,
                                                    accountType:
                                                      event.target.value,
                                                  });
                                                }}
                                                value={state.accountType}
                                                className="form-select"
                                                id="term"
                                              >
                                                <option selected="">
                                                  Select...
                                                </option>
                                                <option value="current">
                                                  Current
                                                </option>
                                                <option value="savings">
                                                  Savings
                                                </option>
                                              </select>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="row  mb-2">
                                        <div className="col-lg-6">
                                          <label>Account Number</label>
                                          <input
                                            onChange={(event) => {
                                              setState({
                                                ...state,
                                                accountNumber:
                                                  event.target.value,
                                              });
                                            }}
                                            value={state.accountNumber}
                                            className="form-control"
                                            type="text"
                                          />
                                        </div>
                                      </div>

                                      <div className="row mb-5">
                                        <div className="col-lg-6">
                                          <label>Signature Right</label>
                                          <select
                                            disabled={
                                              state.nameOnAccount ===
                                                "mainlife" ||
                                              state.nameOnAccount ===
                                                "jointlife"
                                            }
                                            onChange={(event) => {
                                              setState({
                                                ...state,
                                                signatureRight:
                                                  event.target.value,
                                              });
                                            }}
                                            value={state.signatureRight}
                                            className="form-control mt-2"
                                          >
                                            <option selected="">
                                              Select...
                                            </option>
                                            <option value="either">
                                              Either
                                            </option>
                                            {quotation?.joinLoan && (
                                              <option value="both">
                                                Both Lives
                                              </option>
                                            )}
                                          </select>
                                        </div>

                                        <div className="tw-grid tw-gap-5 tw-grid-cols-2 mt-5">
                                          {(state.nameOnAccount ===
                                            "mainlife" ||
                                            state.nameOnAccount ===
                                              "bothlives") && (
                                            <div>
                                              <p className="tw-text-lg">
                                                User Mandate Signature
                                              </p>
                                              <div className="mb-2">
                                                <label className="form-check-label">
                                                  <input
                                                    className="me-3"
                                                    type="checkbox"
                                                    onClick={() => {
                                                      setUserMandateSignatureAgreement(
                                                        !userMandateSignatureAgreement
                                                      );
                                                    }}
                                                    checked={
                                                      userMandateSignatureAgreement
                                                    }
                                                  />
                                                  <div className="d-inline-block">
                                                    By checking this you
                                                    authorize ILA to use your
                                                    signature on relevant
                                                    documents
                                                  </div>
                                                </label>
                                              </div>

                                              <SignatureCanvas
                                                ref={userMandateSignatureRef}
                                                penColor="black"
                                                style={{ background: "blue" }}
                                                canvasProps={{
                                                  width: 500,
                                                  height: 200,
                                                  className: "sigCanvas",
                                                }}
                                              />
                                              <button
                                                className="btn btn-light tw-mt-5 tw-bg-accent"
                                                onClick={() => {
                                                  userMandateSignatureRef.current.clear();
                                                }}
                                              >
                                                Clear{" "}
                                                <i className="fa fa-refresh"></i>
                                              </button>
                                            </div>
                                          )}

                                          {quotation?.joinLoan &&
                                            (state.nameOnAccount ===
                                              "jointlife" ||
                                              (state.nameOnAccount ===
                                                "bothlives" &&
                                                state.signatureRight ===
                                                  "both")) && (
                                              <div>
                                                <p className="tw-text-lg">
                                                  User Joint Mandate Signature
                                                </p>

                                                <div className="mb-2">
                                                  <label className="form-check-label">
                                                    <input
                                                      className="me-3"
                                                      type="checkbox"
                                                      onClick={() => {
                                                        setUserJointMandateSignatureAgreement(
                                                          !userJointMandateSignatureAgreement
                                                        );
                                                      }}
                                                      checked={
                                                        userJointMandateSignatureAgreement
                                                      }
                                                    />
                                                    <div className="d-inline-block">
                                                      By checking this you
                                                      authorize ILA to use your
                                                      signature on relevant
                                                      documents
                                                    </div>
                                                  </label>
                                                </div>

                                                {userJointMandateSignatureError && (
                                                  <div className="alert alert-danger tw-mt-3">
                                                    {
                                                      userJointMandateSignatureError
                                                    }
                                                  </div>
                                                )}
                                                <SignatureCanvas
                                                  ref={
                                                    userJointMandateSignatureRef
                                                  }
                                                  penColor="black"
                                                  style={{ background: "blue" }}
                                                  canvasProps={{
                                                    width: 500,
                                                    height: 200,
                                                    className: "sigCanvas",
                                                  }}
                                                />
                                                <button
                                                  className="btn btn-light tw-mt-5 tw-bg-accent"
                                                  onClick={() => {
                                                    userJointMandateSignatureRef.current.clear();
                                                  }}
                                                >
                                                  Clear{" "}
                                                  <i className="fa fa-refresh"></i>
                                                </button>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </>
                                  )}
                                {savingBankSuccess && (
                                  <div className="alert alert-success">
                                    {savingBankSuccess}
                                  </div>
                                )}
                              </>
                            )}

                            {modeOfPayment === "card" && (
                              <div className="mt-3">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="d-flex justify-content-center align-items-center h-100 w-100">
                                      <div className=" d-flex  h-100 flex-column justify-content-center  align-items-start">
                                        <div className="mb-2">
                                          <span className=" me-2">
                                            First Premium
                                          </span>
                                          <h5>
                                            {quotation &&
                                              currencyFormatter.format(
                                                parseFloat(
                                                  getTotalPremiumWithAddOnValue(
                                                    quotation
                                                  ) || ""
                                                ),
                                                { symbol: "Rs " }
                                              )}
                                          </h5>
                                        </div>
                                        <div className="mb-2">
                                          <span className=" me-2">
                                            Payment Frequency
                                          </span>
                                          <h5>
                                            {
                                              quotation?.quotationFormData
                                                ?.payment_insurance_plan
                                            }
                                          </h5>
                                        </div>
                                        <div className="mb-2">
                                          <span className=" me-2">
                                            Start Date
                                          </span>
                                          <h5>
                                            {new moment(
                                              quotation?.quotationFormData?.intended_commencement_date
                                            ).format("DD-MM-YYYY")}
                                          </h5>
                                        </div>
                                        <div className="mb-2">
                                          <span className="">End Date </span>
                                          <h5>
                                            {new moment(
                                              quotation?.quotationFormData?.intended_commencement_date
                                            )
                                              .add(
                                                parseInt(
                                                  quotation?.quotationFormData
                                                    ?.term_number,
                                                  10
                                                ),
                                                quotation?.quotationFormData
                                                  ?.term_option
                                              )
                                              .format("DD-MM-YYYY")}
                                          </h5>
                                        </div>
                                        <div className="mb-2">
                                          <span className="">
                                            Service Charge
                                          </span>
                                          <h5>{0}</h5>
                                        </div>
                                        <div className="mb-2">
                                          <span className="">Total </span>
                                          <h5>
                                            {quotation &&
                                              currencyFormatter.format(
                                                parseFloat(
                                                  getTotalPremiumWithAddOnValueAndServiceCharge(
                                                    quotation
                                                  )
                                                ) || "",
                                                { symbol: "Rs " }
                                              )}
                                          </h5>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <iframe
                                      ref={iframeRef}
                                      src=""
                                      style={{
                                        width: "100%",
                                        height: 500,
                                      }}
                                      sandbox="allow-top-navigation allow-scripts allow-forms"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}

                            {
                              // channel
                              (modeOfPayment === "ministry" ||
                                modeOfPayment === "company") && (
                                <div className="mt-3">
                                  {octList.length > 0 && (
                                    <div className={"tw-my-5"}>
                                      <p
                                        className={
                                          "tw-text-black !tw-m-0  tw-text-xl font-bold"
                                        }
                                      >
                                        {" "}
                                        List of existing payment references
                                      </p>
                                      <p
                                        className={
                                          "tw-text-orange-500 tw-text-xs !tw-m-0"
                                        }
                                      >
                                        You can select any of these to reuse as
                                        your payment reference
                                      </p>
                                      <div
                                        className={
                                          "tw-grid tw-grid-cols-4 tw-grid-flow-row tw-gap-2 tw-flex-wrap tw-mt-3"
                                        }
                                      >
                                        {octList
                                          .filter((oct) => oct.isActive)
                                          .map((oct, index) => (
                                            <div
                                              key={index}
                                              className={`${
                                                octCode === oct.code
                                                  ? "tw-border-2 tw-border-dotted tw-border-[#bdc666] !tw-bg-[#bdc666]"
                                                  : ""
                                              } tw-flex tw-flex-col tw-bg-white tw-rounded-lg tw-p-2 tw-px-3 tw-font-bold`}
                                            >
                                              <div className="tw-border-b tw-border-gray-200 tw-mb-2">
                                                <p
                                                  className={`tw-text-md ${
                                                    octCode === oct.code
                                                      ? "tw-text-white"
                                                      : ""
                                                  }`}
                                                >
                                                  {oct.code}
                                                </p>
                                              </div>

                                              {oct.Quotations.map(
                                                (quotation, index) => (
                                                  <div
                                                    key={index}
                                                    className="tw-flex tw-flex-col tw-gap-0 tw-items-start tw-mt-2 tw-border-b tw-border-gray-200 tw-pb-2"
                                                  >
                                                    <p
                                                      className={`tw-text-md ${
                                                        octCode === oct.code
                                                          ? "tw-text-white"
                                                          : ""
                                                      } !tw-m-0`}
                                                    >
                                                      Policy Number:{" "}
                                                      <span className="tw-font-bold">
                                                        {quotation.policyNumber}
                                                      </span>
                                                    </p>
                                                    <p
                                                      className={`tw-text-md ${
                                                        octCode === oct.code
                                                          ? "tw-text-white"
                                                          : ""
                                                      } !tw-m-0`}
                                                    >
                                                      Premium{" "}
                                                      {currencyFormatter.format(
                                                        parseFloat(
                                                          quotation.premium
                                                        ) || "",
                                                        { symbol: "Rs " }
                                                      )}
                                                    </p>
                                                    <p
                                                      className={`tw-text-md ${
                                                        octCode === oct.code
                                                          ? "tw-text-white"
                                                          : ""
                                                      } !tw-m-0`}
                                                    >
                                                      Payment Frequency:{" "}
                                                      <span className="tw-font-bold">
                                                        {
                                                          quotation.paymentFrequency
                                                        }
                                                      </span>
                                                    </p>
                                                  </div>
                                                )
                                              )}

                                              <button
                                                onClick={() => {
                                                  if (octCode === oct.code) {
                                                    setOctCode(null);
                                                  } else {
                                                    setOctCode(oct.code);
                                                  }
                                                }}
                                                className={`tw-p-2 tw-px-3 ${
                                                  octCode === oct.code
                                                    ? "tw-bg-white tw-text-[#bdc666]"
                                                    : "tw-bg-[#bdc666] tw-text-white"
                                                }  tw-text-xs tw-mt-2 tw-cursor-pointer tw-rounded-md`}
                                              >
                                                {octCode === oct.code
                                                  ? "DESELECT"
                                                  : "SELECT"}
                                              </button>
                                            </div>
                                          ))}
                                      </div>
                                    </div>
                                  )}
                                  <div className={"row"}>
                                    <div className={"form-group col-lg-6"}>
                                      <label>Ministry/Company List </label>
                                      <div className={"mt-2"}>
                                        <select
                                          className="form-select"
                                          onChange={(event) => {
                                            setState({
                                              ...state,
                                              ministryCompany:
                                                event.target.value,
                                            });
                                          }}
                                          value={state.ministryCompany}
                                        >
                                          <option selected="">Select...</option>

                                          {ministryCompanyChannels.map(
                                            (item) => (
                                              <option value={item.name}>
                                                {item.name}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            }

                            {!octCode &&
                              (modeOfPayment === "ministry" ||
                                modeOfPayment === "company") && (
                                <div className="mt-3">
                                  <div className="row  mb-3">
                                    <div className="col-lg-6">
                                      <label>Mandate Owner</label>
                                      <select
                                        onChange={(event) => {
                                          setState({
                                            ...state,
                                            lives: event.target.value,
                                          });
                                        }}
                                        value={state.lives}
                                        className="form-control"
                                      >
                                        <option selected="">Select...</option>
                                        <option value="main">Main life</option>
                                        {quotation?.joinLoan && (
                                          <>
                                            <option value="joint">
                                              Joint life
                                            </option>
                                          </>
                                        )}
                                      </select>
                                    </div>
                                  </div>

                                  <p className="tw-text-lg mb-2">
                                    User Mandate Signature
                                  </p>

                                  <div className="mb-2">
                                    <label className="form-check-label">
                                      <input
                                        className="me-3"
                                        type="checkbox"
                                        onClick={() => {
                                          setUserMandateSignatureAgreement(
                                            !userMandateSignatureAgreement
                                          );
                                        }}
                                        checked={userMandateSignatureAgreement}
                                      />
                                      <div className="d-inline-block">
                                        By checking this you authorize ILA to
                                        use your signature on relevant documents
                                      </div>
                                    </label>
                                  </div>

                                  <SignatureCanvas
                                    ref={userMandateSignatureRef}
                                    penColor="black"
                                    style={{ background: "blue" }}
                                    canvasProps={{
                                      width: 500,
                                      height: 200,
                                      className: "sigCanvas",
                                    }}
                                  />
                                  <button
                                    className="btn btn-light tw-mt-5 tw-bg-accent"
                                    onClick={() => {
                                      userMandateSignatureRef.current.clear();
                                    }}
                                  >
                                    Clear <i className="fa fa-refresh"></i>
                                  </button>
                                </div>
                              )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
            {kycAndUnderwritingPassed && (
              <div className="tw-flex tw-flex-col tw-max-w-[500px] ">
                {userMandateError && (
                  <div className="alert alert-danger tw-mt-3">
                    {userMandateError}
                  </div>
                )}
                {error && (
                  <div className="alert alert-danger tw-mt-3 tw-mb-2 tw-w-full">
                    {error}
                  </div>
                )}
                {showSignPads && modeOfPayment !== "card" && (
                  <a
                    onClick={() => {
                      saveAndProceed();
                    }}
                    className="btn ila-primary-btn text-white my-5"
                  >
                    {savingDetails && (
                      <span className="spinner-border-sm spinner-border"></span>
                    )}{" "}
                    Save and Proceed to First Payment
                  </a>
                )}
              </div>
            )}
            {!kycAndUnderwritingPassed && (
              <div className="d-flex justify-content-start ">
                {showSignPads && modeOfPayment !== "card" && (
                  <a
                    onClick={() => {
                      navigate("/application-in-review");
                    }}
                    className="btn ila-primary-btn text-white my-5"
                  >
                    {savingDetails && (
                      <span className="spinner-border-sm spinner-border"></span>
                    )}{" "}
                    Proceed
                  </a>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ReviewAcceptanceAndSigning;
