import { useContext } from "react";
import axios from "../api/axios";
import { clearLocalStorageData } from "../core/utils";
import UserContext from "../core/UserContext";
import { useNavigate } from "react-router-dom";

const useLogout = () => {
  // eslint-disable-next-line no-unused-vars
  const { userData, setUserData } = useContext(UserContext);
  const navigate = useNavigate();
  const logout = async () => {
    clearLocalStorageData();
    setUserData(null);
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await axios("/auth/worker/logout", {
        withCredentials: true,
      });
      navigate("/login");
    } catch (err) {
      console.error(err);
    }
  };

  return logout;
};

export default useLogout;
