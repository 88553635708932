/* eslint-disable jsx-a11y/scope */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
// import CustomNumberInput from "../core/components/custom-number-input/CustomNumberInput";
import Navbar from "../core/components/NavBar";
import UserContext from "../core/UserContext";
import currencyFormatter from "currency-formatter";
import Moment from "moment";
// Axios
import {
  getPolicyClassName,
  getTotalPremiumWithAddOnValue,
  getUserDataFromLocalStorage,
  isUnderwritingReferred,
  updateObjectInArray,
} from "../core/utils";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { CDBDataTable } from "cdbreact";
import NoData from "../core/components/no-data/NoData";

const Policies = () => {
  const { userData, setUserData } = useContext(UserContext);
  const userDataStorage = getUserDataFromLocalStorage();
  const axiosPrivate = useAxiosPrivate(userDataStorage);
  // const [editMode, setEditMode] = useState(false);
  const [applications, setApplications] = useState([]);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sendingContractsToEmail, setSendingContractsToEmail] = useState([]);
  const [sendingContractsToAgent, setSendingContractsToAgent] = useState([]);
  const [state, setState] = useState({
    firstname: "",
    lastname: "",
    email: "",
    title: "",
    birth: "",
    phoneNumber: "",
  });

  const columns = [
    {
      name: "Policy Number",
      selector: (application) => application.permanentId,
    },
    {
      name: "Product",
      selector: (application) =>
        getPolicyClassName(
          application,
          application.quotationFormData.loan_plan
        ),
    },
    {
      name: "Lives",
      selector: (application) => application.lives,
    },
    {
      name: "Sum Assured",
      selector: (application) =>
        currencyFormatter.format(
          parseFloat(application.quotationFormData.initial_sum_assured),
          { symbol: "Rs " }
        ),
    },
    {
      name: "Premium",
      selector: (application) =>
        currencyFormatter.format(
          parseFloat(getTotalPremiumWithAddOnValue(application)),
          {
            symbol: "Rs ",
          }
        ),
    },
    {
      name: "State",
      selector: (application) => application.status,
    },
    {
      name: "Actions",
      selector: (application, index) => <></>,
    },
  ];

  const canSendContracts = async (_quotation, index) => {
    if (_quotation?.status?.toString().toLowerCase() == "accepted") {
      return true;
    } else {
      return false;
    }

    // if (!_quotation.joinLoan) {
    //   const mainLifeDeclarationsPassed = await getDeclarationsStatusForUser(
    //     _quotation.user.id,
    //     _quotation.id
    //   );
    //   // debugger;
    //   if (
    //     (_quotation.kycVerificationMain ||
    //       _quotation.shuftiManuallyVerifiedMainLife) &&
    //     (mainLifeDeclarationsPassed ||
    //       _quotation.declarationsManuallyVerifiedMainLife) &&
    //     (!isUnderwritingReferred(_quotation) ||
    //       _quotation.underwritingManuallyAccepted)
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // } else {
    //   const mainLifeDeclarationsPassed = await getDeclarationsStatusForUser(
    //     _quotation.user.id,
    //     _quotation.id
    //   );
    //   const jointLifeDeclarationsPassed = await getDeclarationsStatusForUser(
    //     _quotation.userJoint.id,
    //     _quotation.id
    //   );

    //   // debugger;
    //   if (
    //     (_quotation.kycVerificationMain ||
    //       _quotation.shuftiManuallyVerifiedMainLife) &&
    //     (mainLifeDeclarationsPassed ||
    //       _quotation.declarationsManuallyVerifiedMainLife) &&
    //     (!isUnderwritingReferred(_quotation) ||
    //       _quotation.underwritingManuallyAccepted) &&
    //     (_quotation.kycVerificationJoint ||
    //       _quotation.shuftiManuallyVerifiedJointLife) &&
    //     (jointLifeDeclarationsPassed ||
    //       _quotation.declarationsManuallyVerifiedJointLife) &&
    //     (!isUnderwritingReferred(_quotation) ||
    //       _quotation.underwritingManuallyAccepted)
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // }
  };

  const getDeclarationsStatusForUser = async (userId, policyId) => {
    const url = process.env.REACT_APP_API_URL + `/user/declarations/${userId}`;
    // debugger;

    try {
      const response = await axiosPrivate.get(url);

      const declarationsForQuotation = response.data.data.filter(
        (item) => item.quotationId === policyId
      );

      // debugger;
      if (declarationsForQuotation.length === 0) {
        return false;
      }

      for (let i = 0; i < declarationsForQuotation.length; i++) {
        const item = declarationsForQuotation[i];
        if (item.consent) {
          return false;
        }
      }

      return true;
    } catch (err) {
      // console.log(err);
      return false;
    }
  };

  const sendContractsToEmail = async (application, index) => {
    const url =
      process.env.REACT_APP_API_URL +
      `/quotation/user/email/delivery-contracts/${application.id}`;
    setSendingContractsToEmail(
      updateObjectInArray(sendingContractsToEmail, "index", index, {
        index,
        status: true,
      })
    );

    try {
      const response = await axiosPrivate.get(url);
      setSendingContractsToEmail(
        updateObjectInArray(sendingContractsToEmail, "index", index, {
          index,
          status: false,
        })
      );
      if (response.data.success === true) {
        alert("Contracts have been sent to client's email");
      }
    } catch (error) {
      setSendingContractsToEmail(
        updateObjectInArray(sendingContractsToEmail, "index", index, {
          index,
          status: false,
        })
      );
      // console.log(error);
    }
  };

  const sendContractsToAgent = async (application, index) => {
    const url =
      process.env.REACT_APP_API_URL +
      `/quotation/worker/email/delivery-contracts/${application.id}`;
    // debugger;
    setSendingContractsToAgent(
      updateObjectInArray(sendingContractsToAgent, "index", index, {
        index,
        status: true,
      })
    );

    try {
      const response = await axiosPrivate.get(url);
      // debugger;
      setSendingContractsToAgent(
        updateObjectInArray(sendingContractsToAgent, "index", index, {
          index,
          status: false,
        })
      );
      if (response.data.success === true) {
        alert("Contracts have been sent to agent's email");
      }
    } catch (error) {
      // debugger;
      setSendingContractsToAgent(
        updateObjectInArray(sendingContractsToAgent, "index", index, {
          index,
          status: false,
        })
      );
      // console.log(error);
    }
  };

  const getPolicies = async () => {
    const url = process.env.REACT_APP_API_URL + `/quotation/worker/all`;
    setLoading(true);

    try {
      const response = await axiosPrivate.get(url);
      setLoading(false);
      if (response.data.success === true) {
        let policies = response.data.data.filter(
          (item) => item.status === "accepted"
        );

        // console.log(policies);
        const applicationsSpinners = [];
        policies = policies.map((policy, index) => {
          applicationsSpinners.push({
            index,
            status: false,
          });
          policy.lives = policy.joinLoan
            ? policy?.user?.name + " | " + policy?.userJoint?.name
            : policy?.user?.name;
          policy.quotationFormData = JSON.parse(policy.quotationFormData);
          return policy;
        });
        policies.sort(function (a, b) {
          var keyA = new Date(a.createdAt),
            keyB = new Date(b.createdAt);
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });

        setSendingContractsToEmail(applicationsSpinners);
        setSendingContractsToAgent([...applicationsSpinners]);
        setApplications(policies);
        console.log({ policies });
      }
    } catch (error) {
      setLoading(false);
      // console.log(error);
    }
  };
  useEffect(() => {
    const data = getUserDataFromLocalStorage();
    getPolicies();
    // console.log("data");
    // console.log(data);
    const firstname = data ? data?.data?.name?.split(" ")[0] : "";
    const lastname = data ? data?.data?.name?.split(" ")[1] : "";
    const email = data ? data?.data?.email : "";
    const title = data ? data?.data?.title : "";
    const phoneNumber = data ? data?.data?.phoneNumber : "";
    const gender = data ? data?.data?.gender : "";
    const birth = data ? new Moment(data?.data?.birth) : "";
    const country = data ? data?.data?.country : "";

    setState({
      firstname,
      lastname,
      title,
      email,
      phoneNumber,
      gender,
      birth,
      country,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Navbar
        name={
          userData
            ? `${userData?.data?.firstname} ${userData?.data?.lastname}`
            : ""
        }
      />
      <div className="container">
        <h4 className="mt-4">Policies</h4>
        {applications && (
          <table className="table mt-5">
            <thead>
              <tr>
                <th scope="col">Policy Number</th>
                <th scope="col">Product</th>
                <th scope="col">Lives</th>
                <th scope="col">Sum Assured</th>
                <th scope="col">Premium</th>
                <th scope="col">State</th>
                <th scope="col">Created At</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {applications.map((application, index) => {
                const premium = getTotalPremiumWithAddOnValue(application);
                return (
                  <tr>
                    <td scope="row">{application.permanentId}</td>
                    <td>
                      {getPolicyClassName(
                        application,
                        application.quotationFormData.loan_plan
                      )}
                    </td>
                    <td>{application.lives}</td>
                    <td>
                      {currencyFormatter.format(
                        parseFloat(
                          application.quotationFormData.initial_sum_assured
                        ),
                        { symbol: "Rs " }
                      )}
                    </td>
                    <td>
                      {currencyFormatter.format(parseFloat(premium), {
                        symbol: "Rs ",
                      })}
                    </td>
                    <td>{application.status}</td>
                    <td>{new Moment(application.createdAt).format("LLL")}</td>
                    <td>
                      <a
                        className={`btn btn-light me-3 ${
                          !application.permanentId ? "disabled" : ""
                        }`}
                        onClick={() => {
                          setSendingContractsToEmail(
                            updateObjectInArray(
                              sendingContractsToEmail,
                              "index",
                              index,
                              {
                                index,
                                status: true,
                              }
                            )
                          );
                          canSendContracts(application, index)
                            .then((res) => {
                              if (res) {
                                sendContractsToEmail(application, index);
                              } else {
                                setSendingContractsToEmail(
                                  updateObjectInArray(
                                    sendingContractsToEmail,
                                    "index",
                                    index,
                                    {
                                      index,
                                      status: false,
                                    }
                                  )
                                );
                                alert(
                                  "Was unable to send email. Kindly contact ILA."
                                );
                              }
                            })
                            .catch((err) => {
                              setSendingContractsToEmail(
                                updateObjectInArray(
                                  sendingContractsToEmail,
                                  "index",
                                  index,
                                  {
                                    index,
                                    status: false,
                                  }
                                )
                              );
                              // console.log(err);
                              alert(
                                "Was unable to send email. Kindly contact ILA."
                              );
                            });
                        }}
                      >
                        {sendingContractsToEmail[index].status && (
                          <span className="spinner-border spinner-border-sm me-2"></span>
                        )}
                        <i className="fa fa-envelope"></i> Send contracts to
                        email
                      </a>
                      <a
                        className={`btn btn-light ${
                          !application.permanentId ? "disabled" : ""
                        }`}
                        onClick={() => {
                          setSendingContractsToAgent(
                            updateObjectInArray(
                              sendingContractsToAgent,
                              "index",
                              index,
                              {
                                index,
                                status: true,
                              }
                            )
                          );
                          canSendContracts(application, index)
                            .then((res) => {
                              if (res) {
                                sendContractsToAgent(application, index);
                              } else {
                                setSendingContractsToAgent(
                                  updateObjectInArray(
                                    sendingContractsToAgent,
                                    "index",
                                    index,
                                    {
                                      index,
                                      status: false,
                                    }
                                  )
                                );
                                alert(
                                  "Please make sure both lives have verified their details and completed PEP and FATCA questions"
                                );
                              }
                            })
                            .catch((err) => {
                              setSendingContractsToAgent(
                                updateObjectInArray(
                                  sendingContractsToAgent,
                                  "index",
                                  index,
                                  {
                                    index,
                                    status: false,
                                  }
                                )
                              );
                              // console.log(err);
                              alert(
                                "Please make sure both lives have verified their details and completed PEP and FATCA questions"
                              );
                            });
                        }}
                      >
                        {sendingContractsToAgent[index].status && (
                          <span className="spinner-border spinner-border-sm me-2"></span>
                        )}
                        <i className="fa fa-envelope"></i> Send contracts to
                        agent
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        {/* {applications && (
          <CDBDataTable
            entriesOptions={[5, 10, 20, 25]}
            entries={10}
            pagesAmount={4}
            data={{ columns, applications }}
            materialSearch={true}
          />
        )} */}

        {loading && (
          <>
            <div
              style={{ marginTop: 150 }}
              className="d-flex flex-column justify-content-center align-items-center"
            >
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <div>Please wait...</div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Policies;
