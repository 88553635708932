import React from "react";
import NoDataLogo from "./no-data.svg";

const NoData = () => {
  return (
    <div>
      <div
        style={{ marginTop: 200 }}
        className="d-flex justify-content-center align-items-center h-75 flex-column"
      >
        <img src={NoDataLogo} style={{ width: "15%" }} />
        <div className="mt-3">No Data</div>
      </div>
    </div>
  );
};

export default NoData;
