import React from "react";

const TerminalDisplay = () => {
  return (
    <div className="row justify-content-center">
      <div className="col-md-4">
        <div
          className="card border-0"
          style={{ marginTop: 30, borderRadius: 2 }}
        >
          <div
            style={{ borderRadius: 2 }}
            className="card-header border-0 ila-bg p-4"
          >
            <h5 className="card-title text-white">Application in review</h5>
          </div>
          <div style={{ background: "#C1C1C115" }} className="card-body p-4">
            <p className="card-text">
              Your Application is being reviewed by ILA. You will be notified
              once the review process is done
            </p>
            <p className="mt-5">Thank You</p>
            <a href={`${process.env.REACT_APP_BASENAME}/quotations`} className="btn ila-accent-btn">
              Go back to quotations
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TerminalDisplay;
