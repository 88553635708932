import axios from "../api/axios";
import { saveUserDataInLocalStorage } from "../core/utils";
import useLogout from "./useLogout";

const useRefreshToken = () => {
  const logout = useLogout();

  const refresh = async () => {
    try {
      // Launch response
      const response = await axios.get("/auth/worker/refresh", {
        withCredentials: true,
      });
      console.log({response})
      // Get respon API
      // console.log("useRefresh Token----", response.data.access_token);
      saveUserDataInLocalStorage(response.data);
      // Return the Access token
      return response.data.access_token;
    } catch (error) {
      if (error?.response?.status === 401) {
        await logout();
      }
      return "";
    }
  };
  return refresh;
};

export default useRefreshToken;
