import React, { useEffect, useState } from "react";
import TextInput from "react-autocomplete-input";
import "react-autocomplete-input/dist/bundle.css";
import { getAutoPassList } from "../../../core/utils";
import lodash from "lodash";
import { MultiSelect } from "react-multi-select-component";

const ReflexoneConditions = ({ state, setState, number }) => {
  const [othersVisible, setOthersVisible] = useState(false);

  const toggleConditionReceivingTreatment = (item) => {
    if (
      state.conditionReceivingTreatment.filter((_item) => _item == item)
        .length > 0
    ) {
      const conditionReceivingTreatment =
        state.conditionReceivingTreatment.filter(
          (_conditionReceivingTreatementItem) =>
            _conditionReceivingTreatementItem != item
        );
      // // debugger;
      setState({
        ...state,
        conditionReceivingTreatment,
        diagnosedWith: [],
      });
    } else {
      setState({
        ...state,
        conditionReceivingTreatment: [
          ...state.conditionReceivingTreatment,
          item,
        ],
        diagnosedWith: [],
      });
    }
  };
  const toggleDiagnosedWithItem = (item) => {
    if (state.diagnosedWith.filter((_item) => _item.label == item).length > 0) {
      const diagnosedWith = state.diagnosedWith.filter(
        (_diagnosedWithItem) => _diagnosedWithItem.label != item
      );

      setState({
        ...state,
        diagnosedWith,
      });
    } else {
      setState({
        ...state,
        diagnosedWith: [...state.diagnosedWith, { label: item, value: item }],
      });
    }
  };

  const isChecked = (item) => {
    const diagnosedWithMapped = state.diagnosedWith.map((item) => item.label);
    return diagnosedWithMapped.includes(item);
  };
  const otherSpecified = () => {
    const checkList = [
      "hypertension",
      "blindness",
      "kidney-failure",
      "heart-attacks",
      "stroke",
      "lower-limb-amputation",
      "pulmonary-hypertension",
      "diabetes",
      "heart-coronary-arteries",
    ];
    if (state.diagnosedWith.length > 0) {
      for (let i = 0; i < state.diagnosedWith.length; i++) {
        if (!checkList.includes(state.diagnosedWith[i].value)) {
          return true;
          break;
        }
      }
      return false;
    }
    return false;
  };
  useEffect(() => {
    if (otherSpecified()) {
      setOthersVisible(true);
    }
  }, []);
  const helperListOne = [
    "hypertension",
    "blindness",
    "kidney-failure",
    "heart-attacks",
    "stroke",
    "lower-limb-amputation",
  ];
  const helperListTwo = [
    "pulmonary-hypertension",
    "diabetes",
    "kidney-disease",
    "heart-coronary-arteries",
  ];

  const diagnosedWithDoesNotContainItemList = () => {
    const combined = [...helperListTwo, ...helperListOne];
    const diagnosedWithMapped = state.diagnosedWith.map((item) => item.label);
    for (let i = 0; i < diagnosedWithMapped.length; i++) {
      if (combined.includes(diagnosedWithMapped[i])) {
        return false;
      }
    }
    return true;
  };

  const autoPassList = getAutoPassList();
  let autoPassListOptions = getAutoPassList().map((item) => {
    return { label: item, value: item };
  });
  return (
    <div>
      <div className="mx-4">
        <div className="row my-3">
          <div className="col-sm-12 mb-2">
            <h6 className="fw-bold">
              {number}. If yes, for which condition are you currently receiving
              treatment?
            </h6>{" "}
          </div>
          <div className="col-sm-12 mb-2">
            <div className="col-sm-12 mb-2">
              <div className="row mx-2">
                <div
                  className="col-sm-12 form-check"
                  onClick={() => {
                    toggleConditionReceivingTreatment("diabetes");
                    setOthersVisible(false);
                  }}
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={state.conditionReceivingTreatment.includes(
                      "diabetes"
                    )}
                  ></input>
                  <label className="form-check-label" htmlFor="refund1">
                    Diabetes
                  </label>
                </div>

                <div
                  className="col-sm-12 form-check"
                  onClick={() => {
                    toggleConditionReceivingTreatment("hypertension");
                    setOthersVisible(false);
                  }}
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={state.conditionReceivingTreatment.includes(
                      "hypertension"
                    )}
                  ></input>
                  <label className="form-check-label" htmlFor="refund2">
                    Hypertension
                  </label>
                </div>

                <div
                  className="col-sm-12 form-check"
                  onClick={() => {
                    toggleConditionReceivingTreatment("hypercholesterolaemia");
                    setOthersVisible(false);
                  }}
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={state.conditionReceivingTreatment.includes(
                      "hypercholesterolaemia"
                    )}
                  ></input>
                  <label className="form-check-label" htmlFor="refund2">
                    Hypercholesterolaemia
                  </label>
                </div>

                <div
                  className="col-sm-12 form-check"
                  onClick={() => {
                    toggleConditionReceivingTreatment("others");
                    setOthersVisible(true);
                  }}
                >
                  <input
                    className="form-check-input"
                    type="radio"
                    checked={state.conditionReceivingTreatment.includes(
                      "others"
                    )}
                  ></input>
                  <label className="form-check-label" htmlFor="refund2">
                    Specify Other
                  </label>
                </div>
              </div>
            </div>
            {othersVisible &&
              diagnosedWithDoesNotContainItemList() &&
              state.conditionReceivingTreatment == "others" && (
                <div className="row">
                  <div className="col-md-6">
                    <MultiSelect
                      options={autoPassListOptions}
                      value={state.diagnosedWith}
                      isCreatable={true}
                      onChange={(val) => {
                        setState({
                          ...state,
                          diagnosedWith: val,
                        });
                        // console.log("first");
                        // console.log(val);
                      }}
                      onCreateOption={(value) => ({
                        label: value.toUpperCase(),
                        value: value.toUpperCase(),
                      })}
                      labelledBy="Select"
                    />
                  </div>
                </div>
              )}
          </div>
        </div>
        <div className="col-sm-12 mb-2">
          <h6 className="fw-bold">
            Do you suffer from or have you ever been diagnosed with any of the
            following conditions:
          </h6>
          {state.conditionReceivingTreatment.includes("diabetes") && (
            <>
              <div className="ms-4">
                <div className="col-sm-12">
                  <label className="form-check-label">
                    <input
                      type="checkbox"
                      onClick={() => {
                        toggleDiagnosedWithItem("hypertension");
                        setOthersVisible(false);
                      }}
                      checked={isChecked("hypertension")}
                      className="form-check-input me-2"
                    />
                    Hypertension
                  </label>
                </div>

                <div className="col-sm-12">
                  <label className="form-check-label">
                    <input
                      type="checkbox"
                      onClick={() => {
                        toggleDiagnosedWithItem("blindness");
                        setOthersVisible(false);
                      }}
                      checked={isChecked("blindness")}
                      className="form-check-input me-2"
                    />
                    Blindness
                  </label>
                </div>

                <div className="col-sm-12">
                  <label className="form-check-label">
                    <input
                      type="checkbox"
                      onClick={() => {
                        toggleDiagnosedWithItem("kidney-failure");
                        setOthersVisible(false);
                      }}
                      checked={isChecked("kidney-failure")}
                      className="form-check-input me-2"
                    />
                    Kidney Failure
                  </label>
                </div>

                <div className="col-sm-12">
                  <label className="form-check-label">
                    <input
                      type="checkbox"
                      onClick={() => {
                        toggleDiagnosedWithItem("heart-attacks");
                        setOthersVisible(false);
                      }}
                      checked={isChecked("heart-attacks")}
                      className="form-check-input me-2"
                    />
                    Heart Attacks
                  </label>
                </div>

                <div className="col-sm-12">
                  <label className="form-check-label">
                    <input
                      type="checkbox"
                      onClick={() => {
                        toggleDiagnosedWithItem("stroke");
                        setOthersVisible(false);
                      }}
                      checked={isChecked("stroke")}
                      className="form-check-input me-2"
                    />
                    Stroke
                  </label>
                </div>

                <div className="col-sm-12">
                  <label className="form-check-label">
                    <input
                      type="checkbox"
                      onClick={() => {
                        toggleDiagnosedWithItem("lower-limb-amputation");
                        setOthersVisible(false);
                      }}
                      checked={isChecked("lower-limb-amputation")}
                      className="form-check-input me-2"
                    />
                    Lower Limb Amputation
                  </label>
                </div>
              </div>
            </>
          )}

          {(state.conditionReceivingTreatment.includes("hypertension") ||
            state.conditionReceivingTreatment.includes(
              "hypercholesterolaemia"
            )) && (
            <div className="ms-4">
              <div className="col-sm-12">
                <label className="form-check-label">
                  <input
                    type="checkbox"
                    onClick={() => {
                      toggleDiagnosedWithItem("pulmonary-hypertension");
                      setOthersVisible(false);
                    }}
                    checked={isChecked("pulmonary-hypertension")}
                    className="form-check-input me-2"
                  />
                  Pulmonary Hypertension
                </label>
              </div>
              <div className="col-sm-12">
                <label className="form-check-label">
                  <input
                    type="checkbox"
                    onClick={() => {
                      toggleDiagnosedWithItem("diabetes");
                      setOthersVisible(false);
                    }}
                    checked={isChecked("diabetes")}
                    className="form-check-input me-2"
                  />
                  Diabetes
                </label>
              </div>
              <div className="col-sm-12">
                <label className="form-check-label">
                  <input
                    type="checkbox"
                    onClick={() => {
                      toggleDiagnosedWithItem("kidney-disease");
                      setOthersVisible(false);
                    }}
                    checked={isChecked("kidney-disease")}
                    className="form-check-input me-2"
                  />
                  Kidney disease
                </label>
              </div>
              <div className="col-sm-12">
                <label className="form-check-label">
                  <input
                    type="checkbox"
                    onClick={() => {
                      toggleDiagnosedWithItem("heart-coronary-arteries");
                      setOthersVisible(false);
                    }}
                    checked={isChecked("heart-coronary-arteries")}
                    className="form-check-input me-2"
                  />
                  Heart Coronary Arteries
                </label>
              </div>
            </div>
          )}
          <div className="col-sm-12 ms-4">
            <div className="col-sm-12">
              <input
                className="form-check-input"
                type="radio"
                checked={othersVisible && diagnosedWithDoesNotContainItemList()}
                onClick={() => {
                  setState({ ...state, diagnosedWith: [] });
                  setOthersVisible(true);
                }}
              ></input>
              <label className="form-check-label" htmlFor="refund1">
                Specify others
              </label>
            </div>
            {othersVisible && diagnosedWithDoesNotContainItemList() && (
              <div className="row">
                <div className="col-md-6">
                  <MultiSelect
                    isCreatable={true}
                    options={autoPassListOptions}
                    value={state.diagnosedWith}
                    onChange={(val) => {
                      setState({
                        ...state,
                        diagnosedWith: val,
                      });
                      // console.log("second");
                      // console.log(val);
                    }}
                    onCreateOption={(value) => ({
                      label: value.toUpperCase(),
                      value: value.toUpperCase(),
                    })}
                    labelledBy="Select"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReflexoneConditions;
