import React from "react";

const UnderwritingCovid = ({ state, setState, number }) => {
  return (
    <div>
      <div className="mx-4">
        <div className="row my-3">
          <div className="col-sm-12 mb-2">
            <h6 className="fw-bold">
              {number}. Have you tested positive for Covid-19 in the last 30
              days?
            </h6>{" "}
          </div>
          <div className="col-sm-12 mb-2">
            <div className="row mx-2">
              <div
                onClick={() => {
                  setState({ ...state, hasCovid: true });
                }}
                className="col-sm-2 form-check"
              >
                <input
                  readOnly
                  className="form-check-input"
                  type="radio"
                  id="refund1"
                  checked={state.hasCovid}
                ></input>
                <label className="form-check-label" htmlFor="refund1">
                  Yes
                </label>
              </div>
              <div
                onClick={() => {
                  setState({ ...state, hasCovid: false });
                }}
                className="col-sm-2 form-check"
              >
                <input
                  readOnly
                  checked={state.hasCovid == false}
                  className="form-check-input"
                  type="radio"
                  id="refund2"
                ></input>
                <label className="form-check-label" htmlFor="refund2">
                  No
                </label>
              </div>
            </div>
          </div>
          {/* <div className="col-sm-6 mb-2 text-danger"><em>(If yes, then the proposal is postponed for 3 months)</em></div> */}
          <div className="col-sm-6 mb-2"></div>
        </div>
      </div>
    </div>
  );
};

export default UnderwritingCovid;
