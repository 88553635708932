/* eslint-disable eqeqeq */
import React from "react";
import {
  getAutoPassList,
  getClavicleCollarboneHamstringToeFingerList,
  getHipList,
  getKneeList,
  getShoulderList,
  getBackpainList,
  getMentalHealthList,
} from "../../../../core/utils";
import { MultiSelect } from "react-multi-select-component";
import CurrencyInput from "react-currency-input-field";

const PersonalIncome = ({ number, state, setState }) => {
  // eslint-disable-next-line no-unused-vars
  const autoPassList = getAutoPassList();
  const clavicleCollarboneHamstringToeFingerList =
    getClavicleCollarboneHamstringToeFingerList();
  const kneeList = getKneeList();
  const hipList = getHipList();
  const shoulderList = getShoulderList();
  const backPainList = getBackpainList();
  const mentalHealthList = getMentalHealthList();

  return (
    <div>
      <div className="mx-4">
        <div className="row my-3">
          <div className="col-sm-12 mb-2">
            <h6 className="fw-normal">Personal Protection</h6>
            <h6 className="fw-bold">
              {number}. What was your income during the last tax year from: -
            </h6>
          </div>

          <div className="px-4 mb-3">
            <table className="w-75">
              <th></th>
              <th></th>
              <tbody>
                <tr>
                  <td>
                    <h6 className="fw-normal col-12 text-dark">
                      a. Your Occupation :
                    </h6>
                  </td>
                  {state.life == "main-life" && (
                    <td>
                      <CurrencyInput
                        className={`form-control w-100`}
                        onValueChange={(event) => {
                          setState({
                            ...state,
                            occupation_income_main_life: event,
                          });
                        }}
                        prefix="Rs "
                        value={state.occupation_income_main_life}
                      />
                    </td>
                  )}
                  {state.life == "joint-life" && (
                    <td>
                      <CurrencyInput
                        className={`form-control w-100`}
                        onValueChange={(event) => {
                          setState({
                            ...state,
                            occupation_income_joint_life: event,
                          });
                        }}
                        prefix="Rs "
                        value={state.occupation_income_joint_life}
                      />
                    </td>
                  )}
                </tr>

                <tr>
                  <td>
                    <h6 className="fw-normal col-12 text-dark">
                      b. Investments :
                    </h6>
                  </td>
                  {state.life == "main-life" && (
                    <td>
                      <CurrencyInput
                        className={`form-control w-100`}
                        onValueChange={(event) => {
                          setState({
                            ...state,
                            investments_income_main_life: event,
                          });
                        }}
                        prefix="Rs "
                        value={state.investments_income_main_life}
                      />
                    </td>
                  )}
                  {state.life == "joint-life" && (
                    <td>
                      <CurrencyInput
                        className={`form-control w-100`}
                        onValueChange={(event) => {
                          setState({
                            ...state,
                            investments_income_joint_life: event,
                          });
                        }}
                        prefix="Rs "
                        value={state.investments_income_joint_life}
                      />
                    </td>
                  )}
                </tr>

                <tr>
                  <td>
                    <h6 className="fw-normal col-12 text-dark">
                      c. Other Sources (please specify) :
                    </h6>
                  </td>
                  {state.life == "main-life" && (
                    <td>
                      <textarea
                        className={`form-control w-100`}
                        onChange={(e) => {
                          setState({
                            ...state,
                            other_source_income_main_life: e.target.value,
                          });
                        }}
                      >
                        {state.other_source_income_main_life}
                      </textarea>
                    </td>
                  )}
                  {state.life == "joint-life" && (
                    <td>
                      <textarea
                        className={`form-control w-100`}
                        onChange={(e) => {
                          setState({
                            ...state,
                            other_source_income_joint_life: e.target.value,
                          });
                        }}
                      >
                        {state.other_source_income_joint_life}
                      </textarea>
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalIncome;
