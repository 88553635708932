import React from "react";

const UnderwritingTobacco = ({ state, setState, number }) => {
  return (
    <div>
      <div className="mx-4">
        <div className="row my-3">
          <div className="col-sm-12 mb-2">
            <h6 className="fw-bold">
              {number}. When was the last time you used tobacco or nicotine
              delivery products in any form?
            </h6>{" "}
          </div>
          <div className="col-sm-12 mb-2">
            <div className="row mx-2">
              <div
                className="col-sm-12 form-check"
                onClick={() => {
                  setState({
                    ...state,
                    tobaccoOrNicotineUse: "this-week",
                  });
                }}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  checked={state.tobaccoOrNicotineUse == "this-week"}
                ></input>
                <label className="form-check-label" htmlFor="refund1">
                  This week
                </label>
              </div>

              <div
                className="col-sm-12 form-check"
                onClick={() => {
                  setState({
                    ...state,
                    tobaccoOrNicotineUse: "past-three-months",
                  });
                }}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  checked={state.tobaccoOrNicotineUse == "past-three-months"}
                ></input>
                <label className="form-check-label" htmlFor="refund2">
                  In the past 3 months
                </label>
              </div>

              <div
                className="col-sm-12 form-check"
                onClick={() => {
                  setState({
                    ...state,
                    tobaccoOrNicotineUse: "past-twelve-months",
                  });
                }}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  checked={state.tobaccoOrNicotineUse == "past-twelve-months"}
                ></input>
                <label className="form-check-label" htmlFor="refund2">
                  In the past 12 months
                </label>
              </div>

              <div
                className="col-sm-12 form-check"
                onClick={() => {
                  setState({
                    ...state,
                    tobaccoOrNicotineUse: "one-to-five-years",
                    howManyCigarettesPerDay: null,
                  });
                }}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  checked={state.tobaccoOrNicotineUse == "one-to-five-years"}
                ></input>
                <label className="form-check-label" htmlFor="refund2">
                  1-5 years ago
                </label>
              </div>

              <div
                className="col-sm-12 form-check"
                onClick={() => {
                  setState({
                    ...state,
                    tobaccoOrNicotineUse: "more-than-five-years-ago",
                    howManyCigarettesPerDay: null,
                  });
                }}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  checked={
                    state.tobaccoOrNicotineUse == "more-than-five-years-ago"
                  }
                ></input>
                <label className="form-check-label" htmlFor="refund2">
                  More than 5 years ago
                </label>
              </div>

              <div
                className="col-sm-12 form-check"
                onClick={() => {
                  setState({
                    ...state,
                    tobaccoOrNicotineUse: "never",
                    howManyCigarettesPerDay: null,
                  });
                }}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  checked={state.tobaccoOrNicotineUse == "never"}
                ></input>
                <label className="form-check-label" htmlFor="refund2">
                  Never
                </label>
              </div>
            </div>
          </div>
        </div>
        {(state.tobaccoOrNicotineUse == "this-week" ||
          state.tobaccoOrNicotineUse == "past-three-months" ||
          state.tobaccoOrNicotineUse == "past-twelve-months") && (
          <div className="row my-3">
            <div className="col-sm-12 mb-2">
              <h6 className="fw-bold">How many cigarettes per day?</h6>{" "}
            </div>
            <div className="col-sm-12 mb-2">
              <div className="row mx-2">
                <input
                  className="form-control"
                  value={state.howManyCigarettesPerDay}
                  type="number"
                  min="1"
                  onChange={(event) => {
                    setState({
                      ...state,
                      howManyCigarettesPerDay: event.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UnderwritingTobacco;
