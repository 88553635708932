import React from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./WhatsappNumberInput.css";

const WhatsappNumberInput = ({
  defaultCountry,
  errors,
  state,
  setState,
  field = "whatsappNumber",
  quotation,
  disabled = false,
}) => {
  return (
    <PhoneInput
      defaultCountry={defaultCountry ? defaultCountry : "MU"}
      className={`form-control d-flex ${
        errors[field] && "is-invalid"
      } ila-inactive-input`}
      placeholder="Enter whatsapp number"
      disabled={disabled}
      value={state[field]}
      onChange={(val) => {
        // // debugger;
        // if (quotation !== undefined) {
        //   if (!quotation.userId) {
        //     const currentState = { ...state };
        //     currentState[field] = val;
        //     setState({ ...currentState });
        //   }
        // } else {
        const currentState = { ...state };
        currentState[field] = val;
        setState({ ...currentState });
        // }
      }}
    />
  );
};

export default WhatsappNumberInput;
