/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext, useEffect, useRef, useState } from "react";
import "./FirstPayment.css";
import { useNavigate, useParams } from "react-router-dom";
import BlinkLogo from "./blink.png";
import JuiceLogo from "./juice.png";
// import MytmoneyLogo from "./mytmoney.png";
import MipsLogo from "./mips.png";
import BlinkQR from "./blink-qr.jpeg";
import GoogleIcon from "./google-play.png";
import IosLogo from "./ios.png";
import uniqid from "uniqid";
import HwuaweiLogo from "./hwuawei.svg";
import Navbar from "../../core/components/NavBar";
import moment from "moment";
import UserContext from "../../core/UserContext";
import currencyFormatter from "currency-formatter";
// Axios
import {
  getTotalPremiumWithAddOnValue,
  getTotalPremiumWithAddOnValueAndServiceCharge,
  getUserDataFromLocalStorage,
} from "../../core/utils";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { Nav } from "react-bootstrap";

const FirstPayment = () => {
  const [selectedPayment, setSelectedPayment] = useState();
  const [state, setState] = useState({
    externalPaymentReference: "",
    amountPaid: 0,
  });
  const [cashState, setCashState] = useState({
    internalPaymentReference: "",
    amountPaid: 0,
    policyNumber: "",
  });
  const [posChannels, setPosChannels] = useState();
  const [paying, setPaying] = useState(false);
  const [payingCash, setPayingCash] = useState(false);
  const [error, setError] = useState();
  const [activePaymentTab, setActivePaymentTab] = useState("instant");
  const iframeRef = useRef();
  const [success, setSuccess] = useState();
  const [loading, setLoading] = useState(false);
  const [quotation, setQuotation] = useState();
  const [quotationId, setQuotationId] = useState();
  const { userData, setUserData } = useContext(UserContext);
  const userDataStorage = getUserDataFromLocalStorage();
  const axiosPrivate = useAxiosPrivate(userDataStorage);
  const navigate = useNavigate();
  const params = useParams();

  const generateInternalPaymentReference = (quotation) => {
    const internalPaymentReference = `IPR${uniqid()}${
      quotation.id
    }`.toUpperCase();

    setCashState({
      ...cashState,
      internalPaymentReference,
    });
  };

  const prePopulateAmount = (quotation) => {
    const amountPaid = getTotalPremiumWithAddOnValue(quotation);
    setCashState({
      ...cashState,
      amountPaid,
    });
    setState({ ...state, amountPaid });
  };

  useEffect(() => {
    getAllPaymentChannels();
    getQuotationDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (quotation) {
      generateInternalPaymentReference(quotation);
      prePopulateAmount(quotation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quotation]);

  const payCash = async () => {
    try {
      const url =
        process.env.REACT_APP_API_URL + `/payment-transaction/worker/create`;
      const userDetails = getUserDataFromLocalStorage();
      const userId = userDetails?.data?.id;

      const paymentChannelId = posChannels.find(
        (item) => item.type === "cash"
      ).id;

      const policyNumber = quotation.permanentId
        ? quotation.permanentId
        : quotation.temporaryId;
      const amountExpected = getTotalPremiumWithAddOnValue(quotation);
      const amountPaid = parseInt(cashState.amountPaid, 10);
      // const externalPaymentReference = cashState.externalPaymentReference;
      const internalPaymentReference = `IPR${uniqid()}${
        quotation.id
      }`.toUpperCase();
      const dateOfPayment = new moment().format("YYYY-MM-DD");
      const premiumDate = new moment().format("YYYY-MM-DD");

      setError(null);
      setPayingCash(true);

      const payload = {
        paymentChannelId,
        userId,
        policyNumber,
        amountExpected,
        quotationId,
        amountPaid,
        externalPaymentReference: "",
        internalPaymentReference,
        transactionType: "initial",
        status: "pending",
        dateOfPayment,
        premiumDate,
      };

      const response = await axiosPrivate.post(url, payload);

      if (response.data.success) {
        setSuccess("Payment successfully recorded");
        await updateQuotationStatus({
          status: "review",
          paymentStatus: "firstdepositpayment",
        });
      } else {
        setError("Could not record payment");
      }

      setPayingCash(false);
    } catch (err) {
      // console.log(err);
      setError("Could not record payment");
      setPayingCash(false);
    }
  };
  const createMembershipPaymentAndPopulateIframe = async () => {
    // debugger;
    const orderId = `${uniqid()}-${quotation.id}`;
    const amountPaid = getTotalPremiumWithAddOnValueAndServiceCharge(quotation);

    const paymentChannelId = posChannels.find(
      (item) => item.name === "Mips Gateway"
    ).id;
    // console.log(orderId);
    let payload = {
      loadPayment: {
        order: {
          id_order: orderId,
          currency: "MUR",
          amount: amountPaid,
        },
        iframe_behavior: {
          height: 400,
          width: 350,
          custom_redirection_url: `${window.location.hostname}/mips-redirect/${
            quotation.id
          }/transaction/${paymentChannelId}/${
            quotation.user.id
          }/${quotation.temporaryId.replaceAll(
            "/",
            "-"
          )}/${amountPaid}/${amountPaid}/${orderId}`,
          language: "EN",
        },
        additional_params: [
          {
            param_name: "temporary_id",
            param_value: quotation.temporaryId,
          },
          {
            param_name: "quotation_id",
            param_value: quotation.id,
          },
          {
            param_name: "payment_type",
            param_value: "firstpayment",
          },
        ],
        request_mode: "simple",
        touchpoint: "native_app",
      },
    };

    try {
      const url =
        process.env.REACT_APP_API_URL + `/utils/mips/load-payment-zone`;
      const response = await axiosPrivate.post(url, payload);

      const iframeUrl = response.data.data.payment_zone_data;
      iframeRef.current.setAttribute("src", iframeUrl);
      // console.log(response);
    } catch (err) {
      // console.log(err);
    }
  };

  const getQuotationDetails = async () => {
    setLoading(true);
    const id = params.quotationId;
    const url = process.env.REACT_APP_API_URL + `/quotation/${id}`;

    try {
      const response = await axiosPrivate.get(url);
      setLoading(false);
      if (response.data.success === true) {
        const _quotation = response.data.data;
        _quotation.quotationFormData = JSON.parse(_quotation.quotationFormData);
        const premium = getTotalPremiumWithAddOnValue(_quotation);
        _quotation.premium = premium;

        setQuotation(_quotation);

        setQuotationId(_quotation.id);
      }
      // console.log(response);
    } catch (error) {
      setLoading(false);
      // console.log(error);
    }
  };

  const getAllPaymentChannels = async () => {
    const url = process.env.REACT_APP_API_URL + `/payment-channel`;

    try {
      const response = await axiosPrivate.get(url);

      if (response.data.success === true) {
        const posChannels = response.data.data.filter(
          (item) =>
            item.type === "pos" ||
            item.type === "cash" ||
            item.type === "gateway"
        );
        setPosChannels(posChannels);
      }
      // console.log(response);
    } catch (error) {
      // console.log(error);
    }
  };

  const selectPaymentTab = (tab) => {
    setActivePaymentTab(tab);
  };

  const payWithJuice = async () => {
    try {
      const url =
        process.env.REACT_APP_API_URL + `/payment-transaction/worker/create`;
      const userDetails = getUserDataFromLocalStorage();
      const userId = userDetails?.data?.id;

      const paymentChannelId = posChannels.find(
        (item) => item.name === "Juice"
      ).id;

      const policyNumber = quotation.permanentId
        ? quotation.permanentId
        : quotation.temporaryId;
      const amountExpected = quotation.premium;
      const amountPaid = parseInt(state.amountPaid, 10);
      const externalPaymentReference = state.externalPaymentReference;
      const internalPaymentReference = uniqid();
      const dateOfPayment = new moment().format("YYYY-MM-DD");
      const premiumDate = new moment().format("YYYY-MM-DD");

      setError(null);
      setPaying(true);

      const payload = {
        paymentChannelId,
        userId,
        policyNumber,
        amountExpected,
        quotationId,
        amountPaid,
        externalPaymentReference,
        internalPaymentReference,
        transactionType: "initial",
        status: "pending",
        dateOfPayment,
        premiumDate,
      };

      const response = await axiosPrivate.post(url, payload);

      if (response.data.success) {
        setSuccess("Payment successfully recorded");
        await updateQuotationStatus({
          status: "review",
          paymentStatus: "firstdepositpayment",
        });
      } else {
        setError("Could not record payment");
      }

      setPaying(false);
    } catch (err) {
      console.log({ err });
      setError("Could not record payment");
      setPaying(false);
    }
  };

  const updateQuotationStatus = async (payload) => {
    // setSavingDetails(true);
    const url =
      process.env.REACT_APP_API_URL +
      `/quotation/worker/status-update/${quotationId}`;

    try {
      payload = { ...payload, userId: quotation?.user?.id };
      const { data } = await axiosPrivate.patch(url, payload);

      if (!data.success) {
        // console.log(
        //   data.message
        //     ? data.message
        //     : "Quotation update was unsuccessful, please try again"
        // );
      } else {
        navigate(`/application`);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const payWithBlink = async () => {
    try {
      const url =
        process.env.REACT_APP_API_URL + `/payment-transaction/worker/create`;
      const userDetails = getUserDataFromLocalStorage();
      const userId = userDetails?.data?.id;
      const paymentChannelId = posChannels.find(
        (item) => item.name === "Blink"
      ).id;
      const policyNumber = quotation.permanentId
        ? quotation.permanentId
        : quotation.temporaryId;
      const amountExpected = quotation.premium;
      const amountPaid = parseInt(state.amountPaid, 10);
      const externalPaymentReference = state.externalPaymentReference;
      const internalPaymentReference = uniqid();
      const dateOfPayment = new moment().format("YYYY-MM-DD");
      const premiumDate = new moment().format("YYYY-MM-DD");
      setError(null);

      setPaying(true);

      //   {
      //     "paymentChannelId": 2,
      //     "userId": 1,
      //     "quotationId": 2,
      //     "policyNumber": "Code16",
      //     "amountExpected": 2000,
      //     "amountPaid": 4300,
      //     "paymentDescription": null,
      //     "paymentType": "initial",
      //     "paymentReconciliationStatus": "pending",
      //     "fullPaymentIndicator": null,
      //     "internalPaymentReference": "",
      //     "externalPaymentReference": "",
      //     "dateOfPayment": "2022-11-29",
      //     "premiumDate": "2022-11-29"
      // }

      const payload = {
        paymentChannelId,
        userId,
        policyNumber,
        amountExpected,
        quotationId,
        amountPaid,
        externalPaymentReference,
        internalPaymentReference,
        transactionType: "initial",
        status: "pending",
        dateOfPayment,
        premiumDate,
      };

      const response = await axiosPrivate.post(url, payload);

      if (response.data.success) {
        setSuccess("Payment successfully recorded");
        await updateQuotationStatus({
          status: "review",
          paymentStatus: "firstdepositpayment",
        });
      } else {
        setError("Could not record payment");
      }

      setPaying(false);
    } catch (err) {
      // console.log(err);
      setError("Could not record payment");
      setPaying(false);
    }
  };

  return (
    <>
      <Navbar
        name={
          userData
            ? `${userData?.data?.firstname} ${userData?.data?.lastname}`
            : ""
        }
      />
      <div className="container">
        <h4 className="mt-4">First Payment</h4>

        <div className="mb-3 pb-2">
          <Nav
            variant="tabs"
            onSelect={selectPaymentTab}
            activeKey={activePaymentTab}
            defaultActiveKey={activePaymentTab}
          >
            <Nav.Item>
              <Nav.Link eventKey="instant" title="instant">
                Instant Payment
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="non-instant" title="non-instant">
                Non-Instant Payment
              </Nav.Link>
            </Nav.Item>
          </Nav>

          {activePaymentTab == "instant" && (
            <div className="container mt-3">
              <ul
                className="nav nav-pills mb-3 mt-2"
                id="pills-tab"
                role="tablist"
              >
                <li className=" nav-item" role="presentation">
                  <button
                    onClick={() => {
                      setSelectedPayment("mips");
                      createMembershipPaymentAndPopulateIframe();
                    }}
                    className="payment nav-link"
                    id="pills-profile-tab"
                    data-coreui-toggle="pill"
                    data-coreui-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    Mips Gateway
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                  tabindex="0"
                >
                  <h5 style={{ marginTop: 40 }}>Mips Gateway</h5>
                  <div class="container">
                    <div style={{ marginTop: 0 }} class="row">
                      <div className={`col-lg-1 col-md-3 col-xs-4 mt-1 `}>
                        {/* <div
        onClick={() => {
          setSelectedPayment("mips");
          createMembershipPaymentAndPopulateIframe();
        }}
        className={`payment-option p-3  border d-flex justify-content-center ${
          selectedPayment === "mips" ? "selected" : ""
        }`}
      >
        <img className="w-75" src={MipsLogo} />
      </div> */}
                      </div>
                    </div>
                    <br />
                    <br />
                    <div className="d-flex  align-items-center">
                      <div className="container ">
                        <div className="row">
                          <div className="col-lg-9">
                            {selectedPayment === "mips" && (
                              <div className="">
                                <div
                                  className="row "
                                  style={{ backgroundColor: "#eee" }}
                                >
                                  <div className="col-lg-6">
                                    <div className="d-flex justify-content-center align-items-center h-100 w-100">
                                      <div className=" d-flex py-3 h-100 flex-column justify-content-center  align-items-start">
                                        <div className="mb-2 ">
                                          <span className=" mt-3 me-2">
                                            First Premium
                                          </span>
                                          <h5>
                                            {quotation &&
                                              currencyFormatter.format(
                                                parseFloat(
                                                  getTotalPremiumWithAddOnValue(
                                                    quotation
                                                  ) || ""
                                                ),
                                                { symbol: "Rs " }
                                              )}
                                          </h5>
                                        </div>

                                        <div className="mb-2">
                                          <span className="">
                                            Service Charge
                                          </span>
                                          <h5>{0}</h5>
                                        </div>
                                        <div className="mb-2">
                                          <span className="">Total </span>
                                          <h5>
                                            {quotation &&
                                              currencyFormatter.format(
                                                parseFloat(
                                                  getTotalPremiumWithAddOnValueAndServiceCharge(
                                                    quotation
                                                  )
                                                ) || "",
                                                { symbol: "Rs " }
                                              )}
                                          </h5>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <iframe
                                      ref={iframeRef}
                                      src=""
                                      style={{
                                        width: "100%",
                                        height: 500,
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          )}

          {activePaymentTab == "non-instant" && (
            <div className="container mt-3">
              <ul
                className="nav nav-pills mb-3 mt-2"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    onClick={() => {
                      setSelectedPayment(null);
                    }}
                    className="payment nav-link active "
                    id="pills-home-tab"
                    data-coreui-toggle="pill"
                    data-coreui-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Payment Apps
                  </button>
                </li>
                <li className=" nav-item" role="presentation">
                  <button
                    onClick={() => {
                      setSelectedPayment(null);
                    }}
                    className="payment nav-link"
                    id="pills-cash-tab"
                    data-coreui-toggle="pill"
                    data-coreui-target="#pills-cash"
                    type="button"
                    role="tab"
                    aria-controls="pills-cash"
                    aria-selected="false"
                  >
                    Cash Payment
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                  tabindex="0"
                >
                  <h5 style={{ marginTop: 40 }}>Payment Apps</h5>
                  <div className="container">
                    <div style={{ marginTop: 20 }} className="row">
                      <div className={`col-lg-1 col-md-3 col-xs-4 mt-3 `}>
                        <div
                          onClick={() => {
                            setSelectedPayment("blink");
                          }}
                          className={`payment-option p-3  border d-flex justify-content-center ${
                            selectedPayment === "blink" ? "selected" : ""
                          }`}
                        >
                          <img className="w-75" src={BlinkLogo} />
                        </div>
                      </div>
                      <div className={`col-lg-1 col-md-3 col-xs-4 mt-3 `}>
                        <div
                          onClick={() => {
                            setSelectedPayment("juice");
                          }}
                          className={`payment-option p-3  border d-flex justify-content-center h-100 ${
                            selectedPayment === "juice" ? "selected" : ""
                          }`}
                        >
                          <img className="w-100 my-auto" src={JuiceLogo} />
                        </div>
                      </div>
                      {/* <div className={`col-lg-1 col-md-3 col-xs-4 mt-3 `}>
      <div
        onClick={() => {
          setSelectedPayment("mytmoney");
        }}
        className={`payment-option p-3  border d-flex justify-content-center h-100 ${
          selectedPayment == "mytmoney" ? "selected" : ""
        }`}
      >
        <img className="w-100 my-auto" src={MytmoneyLogo} />
      </div>
    </div> */}
                    </div>
                    <br />
                    <br />
                    <div className="d-flex  align-items-center">
                      <div className="container ">
                        <div className="row">
                          <div className="col-lg-5">
                            {selectedPayment === "blink" && (
                              <>
                                <div className="alert alert-info">
                                  <ol>
                                    <li>
                                      Ensure you have your blink app downloaded
                                      and linked to your bank account
                                    </li>
                                    <li>
                                      If you don't, click on the link relevant
                                      to your device.
                                      <br />
                                      <a
                                        href="https://play.google.com/store/apps/details?id=com.emtel.payment&hl=en&gl=US"
                                        target="_blank"
                                        className="me-3"
                                        rel="noreferrer"
                                      >
                                        <img
                                          style={{ width: "100px" }}
                                          src={GoogleIcon}
                                        />
                                      </a>
                                      <a
                                        href="https://apps.apple.com/us/app/blink-by-emtel/id1607506713"
                                        target="_blank"
                                        className="me-3"
                                        rel="noreferrer"
                                      >
                                        <img
                                          style={{ height: "30px" }}
                                          src={IosLogo}
                                        />
                                      </a>
                                      <a
                                        href="https://appgallery.huawei.com/app/C104485991"
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <img
                                          style={{ height: "30px" }}
                                          src={HwuaweiLogo}
                                        />
                                      </a>
                                    </li>
                                    <li>
                                      Scan the merchant QR code below and make
                                      payment. Please quote your policy number
                                      as{" "}
                                      <span className="badge mx-1 badge-lg bg-info">
                                        <h5>
                                          {quotation?.temporaryId
                                            ? quotation.temporaryId
                                            : "Policy Number"}
                                        </h5>
                                      </span>{" "}
                                      in your transaction reference and amount
                                      as{" "}
                                      <span className="badge mx-1 badge-lg mt-1 bg-info">
                                        <h5>
                                          {" "}
                                          {currencyFormatter.format(
                                            parseFloat(
                                              quotation?.premium || ""
                                            ),
                                            { symbol: "Rs " }
                                          ) || "Amount"}
                                        </h5>
                                      </span>
                                    </li>
                                    <li>
                                      Enter the transaction id in the space
                                      provided
                                    </li>
                                    <li>
                                      ILA will send you a notification upon
                                      receipt of your premium you are done
                                    </li>
                                  </ol>
                                </div>
                                <img src={BlinkQR} style={{ width: "100%" }} />
                                <div>
                                  <label>Payment Reference</label>
                                  <input
                                    onChange={(event) => {
                                      setState({
                                        ...state,
                                        externalPaymentReference:
                                          event.target.value,
                                      });
                                      console.log(
                                        state.externalPaymentReference
                                      );
                                    }}
                                    value={state.externalPaymentReference}
                                    className="form-control"
                                    type="text"
                                  />
                                </div>
                                <div>
                                  <label>Amount</label>
                                  <input
                                    onChange={(event) => {
                                      // setState({
                                      //   ...state,
                                      //   amountPaid: event.target.value,
                                      // });
                                    }}
                                    disabled={true}
                                    value={parseFloat(state.amountPaid)}
                                    className={`form-control disabled`}
                                    type="text"
                                  />
                                </div>
                                {/* <label>Amount</label>
              <input className="form-control" type="text" /> */}
                                {error && (
                                  <div className="alert alert-danger mt-3">
                                    {error}
                                  </div>
                                )}
                                {success && (
                                  <div className="alert alert-success mt-3">
                                    {success}
                                  </div>
                                )}
                                {posChannels && (
                                  <button
                                    onClick={() => {
                                      payWithBlink();
                                    }}
                                    className="btn btn-light mt-4"
                                  >
                                    {paying && (
                                      <span className="spinner-border spinner-border-sm me-3"></span>
                                    )}
                                    Submit details{" "}
                                  </button>
                                )}
                              </>
                            )}
                            {selectedPayment === "juice" && (
                              <>
                                <div className="alert alert-info">
                                  <ol>
                                    <li>
                                      Ensure you have your juice app downloaded
                                      and linked to your bank account
                                    </li>
                                    <li>
                                      If you don't, click on the link relevant
                                      to your device.
                                      <br />
                                      <a
                                        href="https://play.google.com/store/apps/details?id=com.mcbmu.juice"
                                        target="_blank"
                                        className="me-3"
                                        rel="noreferrer"
                                      >
                                        <img
                                          style={{ width: "100px" }}
                                          src={GoogleIcon}
                                        />
                                      </a>
                                      <a
                                        href="https://apps.apple.com/us/app/mcb-juice/id1525428458"
                                        target="_blank"
                                        className="me-3"
                                        rel="noreferrer"
                                      >
                                        <img
                                          style={{ height: "30px" }}
                                          src={IosLogo}
                                        />
                                      </a>
                                      <a
                                        href="https://appgallery.huawei.com/app/C104285497"
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <img
                                          style={{ height: "30px" }}
                                          src={HwuaweiLogo}
                                        />
                                      </a>
                                    </li>
                                    <li>
                                      Go to payment page and enter our account
                                      number,
                                      <span className=" me-2 badge badge-lg bg-info">
                                        <h5>000010217975</h5>
                                      </span>
                                      Please quote your policy number as{" "}
                                      <span className="mx-1 mt-1 badge badge-lg bg-info">
                                        <h5>
                                          {quotation?.permanentId ||
                                            quotation?.temporaryId ||
                                            "Policy Number"}
                                        </h5>
                                      </span>{" "}
                                      in your transaction reference and amount
                                      as{" "}
                                      <span className="badge mx-1 badge-lg mt-1 bg-info">
                                        <h5>
                                          {currencyFormatter.format(
                                            parseFloat(
                                              quotation?.premium || ""
                                            ),
                                            { symbol: "Rs " }
                                          ) || "Amount"}
                                        </h5>
                                      </span>
                                    </li>
                                    <li>
                                      Enter the transaction id in the space
                                      provided
                                    </li>
                                    <li>
                                      ILA will send you a notification upon
                                      receipt of your premium you are done
                                    </li>
                                  </ol>
                                </div>
                                <div>
                                  <label>Payment Reference</label>
                                  <input
                                    onChange={(event) => {
                                      setState({
                                        ...state,
                                        externalPaymentReference:
                                          event.target.value,
                                      });
                                    }}
                                    value={state.externalPaymentReference}
                                    className="form-control"
                                    type="text"
                                  />
                                </div>
                                <div>
                                  <label>Amount</label>
                                  <input
                                    disabled={true}
                                    onChange={(event) => {}}
                                    value={parseFloat(state.amountPaid)}
                                    className="form-control disabled"
                                    type="text"
                                  />
                                </div>
                                {error && (
                                  <div className="alert alert-danger mt-3">
                                    {error}
                                  </div>
                                )}
                                {success && (
                                  <div className="alert alert-success mt-3">
                                    {success}
                                  </div>
                                )}
                                {posChannels && (
                                  <button
                                    onClick={() => {
                                      payWithJuice();
                                    }}
                                    className="btn btn-light mt-4"
                                  >
                                    {paying && (
                                      <span className="spinner-border spinner-border-sm me-3"></span>
                                    )}
                                    Submit details{" "}
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                  </div>
                </div>

                <div
                  className="tab-pane fade show "
                  id="pills-cash"
                  role="tabpanel"
                  aria-labelledby="pills-cash-tab"
                  tabindex="0"
                >
                  <div className="col-lg-6 mt-5">
                    <label>Amount</label>
                    <input
                      onChange={(event) => {}}
                      disabled={true}
                      className="form-control"
                      type="number"
                      value={parseFloat(cashState.amountPaid)}
                    />
                  </div>
                  <div className="col-lg-6 mt-2">
                    <label>Policy Number</label>
                    <input
                      onChange={(event) => {}}
                      disabled={true}
                      className="form-control"
                      type="text"
                      value={
                        quotation?.permanentId
                          ? quotation?.permanentId
                          : quotation?.temporaryId
                      }
                    />
                  </div>
                  {/* <div className="col-lg-6 mt-2">
    <label>Reference Number</label>
    <input
      disabled={true}
      className="form-control"
      type="text"
      value={cashState.internalPaymentReference}
    />
  </div> */}
                  {/* // TODO: Show reference number */}
                  <button
                    onClick={() => {
                      payCash();
                    }}
                    className="btn ila-primary-btn  mt-5"
                  >
                    {" "}
                    {payingCash && (
                      <span className="spinner-border spinner-border-sm me-3"></span>
                    )}
                    Submit
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FirstPayment;
