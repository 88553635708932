import React, { useEffect, useState } from "react";

const UnderwritingHeight = ({ state, setState, number }) => {
  const [bmiResult, setBmiResult] = useState("N/A");
  // const calculateBmi = () => {};

  useEffect(() => {
    if (state.height && state.weight) {
      //calculate BMI
      setBmiResult((state.weight / Math.pow(state.height, 2)).toFixed(2));
      setState({
        ...state,
        bmi: (state.weight / Math.pow(state.height, 2)).toFixed(2),
      });
    } else {
      setBmiResult("N/A");
    }
  }, [state.height, state.weight]);
  return (
    <div>
      <div className="mx-4">
        <div className="row my-3">
          <div className="col-sm-12 mb-2">
            <h6 className="fw-bold">{number}. What is your current </h6>
          </div>
          <div className="col-sm-12 my-3">
            <div className="row mx-2">
              <div className="col-lg-4">
                <div className=" row mb-3">
                  <label
                    htmlFor="inputPassword"
                    className="col-lg-12 col-form-label"
                  >
                    Height
                  </label>
                  <div className="col-lg-12">
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        value={state.height}
                        onChange={(event) => {
                          setState({ ...state, height: event.target.value });
                        }}
                        style={{ width: 80 }}
                      ></input>
                      <span className="input-group-text" id="basic-addon2">
                        m
                      </span>
                    </div>
                    <small>
                      Eg. <b>1.7</b> is 1 metre, 70 centimeters{" "}
                    </small>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className=" row mb-3">
                  <label
                    htmlFor="inputPassword"
                    className="col-lg-12 col-form-label"
                  >
                    Weight
                  </label>
                  <div className="col-lg-12">
                    <div className="input-group">
                      <input
                        onChange={(event) => {
                          setState({ ...state, weight: event.target.value });
                        }}
                        value={state.weight}
                        type="number"
                        className="form-control"
                        style={{ width: 80 }}
                      ></input>
                      <span className="input-group-text" id="basic-addon2">
                        kg
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className=" row mb-3">
                  <label
                    htmlFor="inputPassword"
                    className="col-lg-12 col-form-label"
                  >
                    Pant Size
                  </label>
                  <div className="col-lg-8">
                    <select
                      className="form-select form-check"
                      value={state.pantSize}
                      onChange={(event) => {
                        setState({ ...state, pantSize: event.target.value });
                      }}
                    >
                      <option value="">Select...</option>
                      <option value="xs">Extra Small</option>
                      <option value="s">Small</option>
                      <option value="m">Medium</option>
                      <option value="l">Large</option>
                      <option value="xl">Extra Large</option>
                      <option value="xxl">Extra Extra Large</option>
                    </select>
                  </div>
                </div>
              </div>

              {/* <div className="col-sm-3 form-check">
                        BMI Calculator Here
                    </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnderwritingHeight;
