/* eslint-disable eqeqeq */
import React from "react";
import {
  getAutoPassList,
  getClavicleCollarboneHamstringToeFingerList,
  getHipList,
  getKneeList,
  getShoulderList,
  getBackpainList,
  getMentalHealthList,
} from "../../../../core/utils";
import { MultiSelect } from "react-multi-select-component";

const InheritanceTaxNetWorth = ({ number, state, setState }) => {
  // eslint-disable-next-line no-unused-vars
  const autoPassList = getAutoPassList();
  const clavicleCollarboneHamstringToeFingerList =
    getClavicleCollarboneHamstringToeFingerList();
  const kneeList = getKneeList();
  const hipList = getHipList();
  const shoulderList = getShoulderList();
  const backPainList = getBackpainList();
  const mentalHealthList = getMentalHealthList();

  return (
    <div>
      <div className="mx-4">
        <div className="row my-3">
          <div className="col-sm-12 mb-2">
            <h6 className="fw-normal">Inheritance Tax Cover</h6>
            <h6 className="fw-bold">
              {number}. Please state the estimate net worth of the estate?
            </h6>
          </div>
          <div className="col-sm-12 mb-2">
            {/* First life assured */}
            {state.life == "main-life" && (
              <div className="row mx-2 mb-3">
                <div className="col-sm-5 d-flex">
                  <textarea
                    onChange={(e) => {
                      setState({
                        ...state,
                        inheritancetax_net_worth_main_life: e.target.value,
                      });
                    }}
                    className="form-control w-100"
                  >
                    {state.inheritancetax_net_worth_main_life}
                  </textarea>
                </div>
              </div>
            )}

            {/* Second life assured */}
            {state.life == "joint-life" && (
              <div className="row mx-2 mb-3 mt-2">
                <div className="col-sm-5 d-flex">
                  <textarea
                    onChange={(e) => {
                      setState({
                        ...state,
                        inheritancetax_net_worth_joint_life: e.target.value,
                      });
                    }}
                    className="form-control w-100"
                  >
                    {state.inheritancetax_net_worth_joint_life}
                  </textarea>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InheritanceTaxNetWorth;
