import React from "react";

const ReflexoneMedicationCheckBox = ({
  state,
  setState,
  medicationControlKey,
  medicationControlValue,
}) => {
  return (
    <td
      style={{ cursor: "pointer" }}
      onClick={() => {
        const medicationControl = state.medicationControl;
        medicationControl[medicationControlKey] = medicationControlValue;
        setState({
          ...state,
          medicationControl,
        });
      }}
    >
      <input
        readOnly
        class="form-check-input ms-1"
        type="radio"
        checked={
          state.medicationControl
            ? state.medicationControl[medicationControlKey] ===
              medicationControlValue
            : false
        }
      ></input>
    </td>
  );
};

export default ReflexoneMedicationCheckBox;
