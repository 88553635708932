import React, { useEffect } from "react";
import ReflexoneMedicationCheckBox from "../../../core/components/ReflexoneMedicationCheckbox";

const ReflexoneMedication = ({ state, setState, number }) => {
  useEffect(() => {
    if (!state.medicationControl) {
      setState({ ...state, medicationControl: {} });
    }
  }, []);

  return (
    <div>
      <div className="mx-4">
        <div className="row my-3">
          <div className="col-sm-12 mb-2">
            <h6 className="fw-bold">{number}.</h6>
          </div>
          <div className="col-sm-12 my-3">
            <div className="row mx-2">
              <table className="table table-bordered ">
                <thead>
                  <tr classNama="font-size-sm">
                    <th width="50%"></th>
                    <th className="table-title">Yes</th>
                    <th className="table-title">No</th>
                  </tr>
                </thead>
                <tbody>
                  {state.conditionReceivingTreatment.includes("diabetes") && (
                    <tr>
                      <td>Do you take medication to control your Diabetes?</td>
                      <ReflexoneMedicationCheckBox
                        state={state}
                        setState={setState}
                        medicationControlKey="diabetes"
                        medicationControlValue={true}
                      />
                      <ReflexoneMedicationCheckBox
                        state={state}
                        setState={setState}
                        medicationControlKey="diabetes"
                        medicationControlValue={false}
                      />
                    </tr>
                  )}
                  {state.conditionReceivingTreatment.includes(
                    "hypertension"
                  ) && (
                    <tr>
                      <td>
                        Do you take medication to control your Hypertension?
                      </td>

                      <ReflexoneMedicationCheckBox
                        state={state}
                        setState={setState}
                        medicationControlKey="hypertension"
                        medicationControlValue={true}
                      />
                      <ReflexoneMedicationCheckBox
                        state={state}
                        setState={setState}
                        medicationControlKey="hypertension"
                        medicationControlValue={false}
                      />
                    </tr>
                  )}
                  {(state.conditionReceivingTreatment.includes("cholestrol") ||
                    state.conditionReceivingTreatment.includes(
                      "hypercholesterolaemia"
                    )) && (
                    <tr>
                      <td>
                        Do you take medication to control your Cholestrol?
                      </td>

                      <ReflexoneMedicationCheckBox
                        state={state}
                        setState={setState}
                        medicationControlKey="cholestrol"
                        medicationControlValue={true}
                      />
                      <ReflexoneMedicationCheckBox
                        state={state}
                        setState={setState}
                        medicationControlKey="cholestrol"
                        medicationControlValue={false}
                      />
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReflexoneMedication;
