/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import CustomNumberInput from "../core/components/custom-number-input/CustomNumberInput";
import Navbar from "../core/components/NavBar";
import UserContext from "../core/UserContext";
import { getUserDataFromLocalStorage } from "../core/utils";
import Moment from "moment";

const InsurancePolicy = () => {
  const { userData, setUserData } = useContext(UserContext);
  // const [editMode, setEditMode] = useState(false);
  const [state, setState] = useState({
    firstname: "",
    lastname: "",
    email: "",
    title: "",
    birth: "",
    phoneNumber: "",
  });

  useEffect(() => {
    const data = getUserDataFromLocalStorage();
    // console.log("data");
    // console.log(data);
    const firstname = data ? data?.data?.name?.split(" ")[0] : "";
    const lastname = data ? data?.data?.name?.split(" ")[1] : "";
    const email = data ? data?.data?.email : "";
    const title = data ? data?.data?.title : "";
    const phoneNumber = data ? data?.data?.phoneNumber : "";
    const gender = data ? data?.data?.gender : "";
    const birth = data ? new Moment(data?.data?.birth) : "";
    const country = data ? data?.data?.country : "";

    setState({
      firstname,
      lastname,
      title,
      email,
      phoneNumber,
      gender,
      birth,
      country,
    });
  }, []);

  return (
    <>
      <Navbar
        name={
          userData
            ? `${userData?.data?.firstname} ${userData?.data?.lastname}`
            : ""
        }
      />
      <div className="container">
        <h4 className="mt-4">Insurance Policy</h4>

        <table className="table mt-5 table-bordered">
          <thead>
            <tr style={{ backgroundColor: "#b9c980" }}>
              <th colspan="5">Temporal Policy ID : LC-2022-00001</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>Plan Name</td>
              <td>
                <input
                  className="form-control"
                  type="text"
                  value="LC-2022-00001"
                  readonly="readonly"
                />
              </td>
              <td colspan="3" className="form-check pl-5 mt-2">
                <div className="form-check pl-5 mt-2">
                    
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="check1"
                    name="option1"
                    value="something"
                  ></input>
                  <label className="form-check-label">Refund</label>
                </div>{" "}
              </td>
            </tr>

            <tr>
              <td>Sum Assured</td>
              <td>
                <input
                  className="form-control"
                  type="text"
                  value="Sum Assured"
                  readonly="readonly"
                />
              </td>
              <td>Term</td>
              <td>
                <select
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option value="AL">Select</option>
                  <option value="WY">Option One</option>
                  <option value="AM">Option Two</option>
                </select>
              </td>
              <td>
                <div className="form-check pl-5 mt-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="check1"
                    name="option1"
                    value="something"
                  ></input>
                  <label className="form-check-label">Joint Life</label>
                </div>
              </td>
            </tr>

            <tr>
              <td>Premium</td>
              <td>
                <input
                  className="form-control"
                  type="text"
                  value="Premium"
                  readonly="readonly"
                />
              </td>
              <td colspan="3"></td>
            </tr>

            <tr>
              <td>Pay Frequency</td>
              <td>
                <select
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option value="AL">Select</option>
                  <option value="WY">Option One</option>
                  <option value="AM">Option Two</option>
                </select>
              </td>
              <td colspan="3"></td>
            </tr>
          </tbody>
        </table>

        <div className="row my-3 pt-4">
          <div className="col-sm-12 d-flex justify-content-end">
            <button type="button" className="btn btn-ila me-2 mb-3">
              Save
            </button>
            <button type="button" className="btn btn-ila me-2 mb-3">
              Download
            </button>
            <button type="button" className="btn btn-ila me-2 mb-3">
              Approve & Sign
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default InsurancePolicy;
