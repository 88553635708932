/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  // SubMenu,
  useProSidebar,
  sidebarClasses,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";
import ilaLogo from "../../../core/assets/images/ila-logo.png";
import coupleImg from "../../../core/assets/images/couple.png";
import waterMark from "../../../core/assets/images/watermark.png";

export default function LoggedInLayout({ component, navImage }) {
  const { collapseSidebar } = useProSidebar();
  return (
    <div
      style={{
        display: "flex",
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      }}
    >
      <Sidebar
        rootStyles={{
          [`.${sidebarClasses.container}`]: {
            background: "white",
            height: "100%",
          },
        }}
        collapsedWidth={0}
        width={"300px"}
        breakPoint="sm"
      >
        <div className="d-flex justify-content-center">
          <img
            src={ilaLogo}
            style={{ width: 100, marginTop: 50, marginBottom: 40 }}
          />
        </div>
        <Menu>
          <MenuItem
            style={{
              borderTop: "1px solid #e5e5e5",
              paddingLeft: 80,
              color: "#8E8E8E",
              fontWeight: "medium",
              fontSize: 21,
            }}
            component={<Link to="/quotations" />}
          >
            Quotations
          </MenuItem>
          <MenuItem
            style={{
              borderTop: "1px solid #e5e5e5",
              paddingLeft: 80,
              color: "#8E8E8E",
              fontWeight: "medium",
              fontSize: 21,
            }}
            component={<Link to="/application" />}
          >
            {" "}
            Applications{" "}
          </MenuItem>
          <MenuItem
            style={{
              borderTop: "1px solid #e5e5e5",
              paddingLeft: 80,
              color: "#8E8E8E",
              fontWeight: "medium",
              fontSize: 21,
            }}
            component={<Link to="/policies" />}
          >
            {" "}
            My Policies{" "}
          </MenuItem>
        </Menu>
        <img
          src={navImage ? navImage : coupleImg}
          style={{ width: "100%", bottom: 0, position: "absolute" }}
        />
      </Sidebar>

      <main
        className="flex-grow-1 h-100 "
        style={{
          overflow: "scroll",
          backgroundImage: `url(${waterMark})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "550px",
          backgroundAttachment: "fixed",
          backgroundPositionX: "100%",
          backgroundPositionY: "120%",
        }}
      >
        <div className="container position-relative">
          <span
            style={{
              cursor: "pointer !important",
              color: "#8e8e8e",
              position: "absolute",
              bottom: -45,
              zIndex: 100,
            }}
            role="button"
            className="fa fa-bars mt-4 fa-2x "
            onClick={() => {
              collapseSidebar();
              // console.log("clicked");
            }}
          ></span>
        </div>

        {component}
        <a
          href="https://support.redpearcommunications.com/im_livechat/support/2"
          target="_blank"
          rel="noreferrer"
        >
          <div
            style={{
              borderRadius: 4,
              padding: 20,
              bottom: 20,
              right: 20,
              position: "absolute",
              background: "#bbc543",
              color: "white",
              textDecoration: "none",
              fontStyle: "none",
            }}
            className="d-flex justify-content-center align-content-center"
          >
            <i className="fa fa-comments " aria-hidden="true"></i>
          </div>
        </a>
      </main>
    </div>
  );
}
