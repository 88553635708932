import React from "react";

const UnderwritingChronic = ({ state, setState, number }) => {
  return (
    <div>
      <div className="mx-4">
        <div className="row my-3">
          <div className="col-sm-12 mb-2">
            <h6 className="fw-bold">
              {number}. Do you currently suffer from Diabetes or Raised blood
              Sugar or sugar in urine, hypertension, Hypercholesterolaemia or
              any chronic condition that requires ongoing treatment and
              monitoring? (Excluding vitamins and Supplements, and medication
              for birth control or hormone replacement)
            </h6>{" "}
          </div>

          <div className="col-sm-12 mb-2">
            <div className="row mx-2">
              <div
                className="col-sm-12 form-check"
                onClick={() => {
                  setState({ ...state, chronicCondition: "yes" });
                }}
              >
                <input
                  checked={state.chronicCondition == "yes"}
                  className="form-check-input"
                  type="radio"
                  name="refund"
                  id="refund1"
                  value="option1"
                ></input>
                <label className="form-check-label" htmlFor="refund1">
                  Yes
                </label>
              </div>
            </div>
            <div className="row mx-2">
              <div
                className="col-sm-12 form-check"
                onClick={() => {
                  setState({ ...state, chronicCondition: "no" });
                }}
              >
                <input
                  checked={state.chronicCondition == "no"}
                  className="form-check-input"
                  type="radio"
                  name="refund"
                  id="refund1"
                  value="option1"
                ></input>
                <label className="form-check-label" htmlFor="refund1">
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnderwritingChronic;
