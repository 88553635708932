/* eslint-disable no-unused-vars */
import Moment from "moment";
import lodash from "lodash";
import { Buffer } from "buffer";
import axiosOriginal from "axios";
import _ from "lodash";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { axiosPrivate } from "../api/axios";

const saveUserDataInLocalStorage = (data) => {
  localStorage.setItem("userData", JSON.stringify(data));
};

const getUserDataFromLocalStorage = () => {
  try {
    return JSON.parse(localStorage.getItem("userData"));
  } catch (error) {
    return null;
  }
};

const getPolicyClassName = (quotation, policyName) => {
  if (quotation.isLtaResult && !quotation.isDtaResult) {
    if (quotation.withRefund) {
      policyName = "Island Protect Refund Plan";
    } else {
      policyName = "Island Protect Plan";
    }
  } else if (!quotation.isLtaResult && quotation.isDtaResult) {
    if (quotation.withRefund) {
      policyName = "Island Loan Cover Refund Plan";
    } else {
      policyName = "Island Loan Cover";
    }
  } else {
    policyName = "Both Loan Cover Protect Plan";
  }
  return policyName;
};

const getLives = (qtn) => {
  if (qtn.userId) {
    if (qtn.joinLoan) {
      return qtn.user.name + " | " + qtn.userJoint.name;
    } else {
      return qtn.user.name;
    }
  } else {
    if (qtn.joinLoan) {
      return (
        qtn.quotationFormData.main_user_name +
        " | " +
        qtn.quotationFormData.joint_user_name
      );
    } else {
      return qtn.quotationFormData.name;
    }
  }
};

const processUnderwritingData = (data) => {
  const autoPassList = getAutoPassList().map((item) => item.toLowerCase());
  const humanReadable = [];
  const statusMessages = {};

  /**
   * CHECKS
   */

  const keys = Object.keys(data);
  keys.forEach((_key) => {
    let status = "Passed";

    if (_key == "hasCovid") {
      if (data[_key]) {
        status = "Refer to ILA";
      }
    }

    if (_key == "pantSize") {
      if (data[_key] == "xs" || data[_key] == "xxl" || data[_key] == "xxxl") {
        status = "Refer to ILA";
      }
    }

    if (_key == "bmi") {
      if (data[_key] <= 16 || data[_key] >= 38) {
        status = "Refer to ILA";
      }
    }

    if (_key == "medicalCondition") {
      const medicalConditionItems = Object.keys(data[_key]);
      for (let i = 0; i < medicalConditionItems.length; i++) {
        if (
          data[_key][medicalConditionItems[i]] ==
            "diagnosed-and-receiving-treatment" ||
          data[_key][medicalConditionItems[i]] == "diagnosed-and-treated" ||
          data[_key][medicalConditionItems[i]] ==
            "diagnosed-but-not-following-treatment"
        ) {
          status = "Refer to ILA";
          break;
        }
      }
    }

    if (_key == "hasPhysicalOrSensoryLimitation") {
      if (data[_key]) {
        status = "Refer to ILA";
      }
    }

    if (_key == "howManyCigarettesPerDay") {
      if (data[_key] !== null && parseInt(data[_key].toString(), 10) > 20) {
        status = "Refer to ILA";
      }
    }
    if (_key == "numberOfAlchoholicDrinksPerWeek") {
      // // debugger;
      if (data[_key] == "22-units-and-above") {
        status = "Refer to ILA";
      }
    }

    if (_key == "hiv") {
      if (
        data[_key] == "positive" ||
        data[_key] == "inclusive" ||
        data[_key] == "awaiting-results"
      ) {
        status = "Refer to ILA";
      }
    }

    if (
      _key == "hasPreviouslyDeclinedLifeOrDisabilityBecauseOfMedicalCondition"
    ) {
      // console.log(data[_key]);
      if (data[_key] && data[_key] != "") {
        status = "Refer to ILA";
      }
    }

    /**
     * NEED TO RELOOK AT THIS
     */

    if (_key == "diagnosedWith" || _key == "conditionReceivingTreatment") {
      const autoPassList = getAutoPassList();
      const theList = data["diagnosedWith"].map((item) => item.label);

      for (let i = 0; i < theList.length; i++) {
        if (!autoPassList.includes(theList[i])) {
          status = "Refer to ILA";
          break;
        }
      }
    }
    /**
     * END OF WHAT NEEDS TO BE RELOOKED AT
     */

    if (_key == "medicationControl") {
      const medicationControlItems = Object.keys(data[_key]);
      for (let i = 0; i < medicationControlItems.length; i++) {
        if (!data[_key][medicationControlItems[i]]) {
          status = "Refer to ILA";
          break;
        }
      }
    }

    if (_key == "hadCheckupRecently") {
      if (data[_key]) {
        status = "Refer to ILA";
      }
    }

    if (_key == "conditionControlled") {
      const conditionControlledItems = Object.keys(data[_key]);
      for (let i = 0; i < conditionControlledItems.length; i++) {
        if (!data[_key][conditionControlledItems[i]]) {
          status = "Refer to ILA";
          break;
        }
      }
    }

    if (_key == "conditionsOrComplaintsToConsult") {
      const autoPassList = getAutoPassList();
      const conditionsOrComplaintsToConsult = data[_key].map(
        (item) => item.label
      );

      for (let i = 0; i < conditionsOrComplaintsToConsult.length; i++) {
        if (!autoPassList.includes(conditionsOrComplaintsToConsult[i])) {
          status = "Refer to ILA";
          break;
        }
      }
    }
    if (_key == "previouslyDeclinedLifeOrDisabilityCondition") {
      const autoPassList = getAutoPassList();
      const previouslyDeclinedLifeOrDisabilityCondition = data[_key].map(
        (item) => item.label
      );

      for (
        let i = 0;
        i < previouslyDeclinedLifeOrDisabilityCondition.length;
        i++
      ) {
        if (
          !autoPassList.includes(previouslyDeclinedLifeOrDisabilityCondition[i])
        ) {
          status = "Refer to ILA";
          break;
        }
      }
    }

    if (_key == "conditionOrComplaintHospitalizedFor") {
      const autoPassList = getAutoPassList();
      const conditionOrComplaintHospitalizedFor = data[_key].map(
        (item) => item.label
      );

      for (let i = 0; i < conditionOrComplaintHospitalizedFor.length; i++) {
        if (!autoPassList.includes(conditionOrComplaintHospitalizedFor[i])) {
          status = "Refer to ILA";
          break;
        }
      }
    }

    if (_key == "conditionOrComplaintBookedOffFor") {
      const autoPassList = getAutoPassList();
      const conditionOrComplaintBookedOffFor = data[_key].map(
        (item) => item.label
      );

      for (let i = 0; i < conditionOrComplaintBookedOffFor.length; i++) {
        if (!autoPassList.includes(conditionOrComplaintBookedOffFor[i])) {
          status = "Refer to ILA";
          break;
        }
      }
    }

    try {
      if (_key == "backpainCondition") {
        const backpainReferToIlaList = getBackPainReferToIlaList();
        if (backpainReferToIlaList.includes(data[_key].toLowerCase())) {
          status = "Refer to ILA";
        }
      }
    } catch (err) {
      // console.log(err);
    }

    if (_key == "suicidalThoughts") {
      if (data[_key]) {
        status = "Refer to ILA";
      }
    }

    if (_key == "multipleSuicidalThoughts") {
      if (data[_key]) {
        status = "Refer to ILA";
      }
    }
    if (_key == "hasRecoveredCompletelyWithNoComplications") {
      if (data[_key]) {
        status = "Refer to ILA";
      }
    }
    if (_key == "hadMultipleMusculoskeletalConditionOrSymptom") {
      if (data[_key]) {
        status = "Refer to ILA";
      }
    }
    if (_key == "whenLastExperiencedMusculoskeletalSymptoms") {
      if (data[_key] == "less-than-24-months") {
        status = "Refer to ILA";
      }
      if (
        data[_key] == "more-than-24-months-and-less-than-36-months" &&
        data["musculoskeletalCategory"] !=
          "clavicle-collarbone-hamstring-toe-finger"
      ) {
        status = "Refer to ILA";
      }
    }

    if (_key == "hasDoctorSuggestedSurgicalInterventionToBackpain") {
      if (data[_key]) {
        status = "Refer to ILA";
      }
    }
    if (_key == "hadSymptomsOrTreatmentForBackpainInPastThreeYears") {
      if (data[_key]) {
        status = "Refer to ILA";
      }
    }

    if (_key == "sucidalSymptomsDuration") {
      if (
        data[_key] == "no-symptoms-more-than-4-weeks" ||
        data[_key] == "no-not-working-full-time-due-to-symptoms"
      ) {
        status = "Refer to ILA";
      }
    }

    if (_key == "whenWasLastMentalHealthSymptoms") {
      if (data[_key] == "6-months-ago-or-less") {
        status = "Refer to ILA";
      }
      if (data[_key] == "6-months-to-2-years-ago") {
        status = "Refer TPD to ILA";
      }
    }

    if (
      !(
        data[_key] === null ||
        (typeof data[_key] == "object" &&
          Object.keys(data[_key]).filter(
            (__item) => data[_key][__item] === null
          ).length === Object.keys(data[_key]).length)
      )
    ) {
      const current = {
        key: _key,
        question: lodash.startCase(_key),
        response: data[_key],
        status,
      };
      humanReadable.push(current);
    }
  });

  // console.log(humanReadable);
  return humanReadable;
};

const getDate_ddmmyyyy = (date) => {
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const dd = String(date.getDate()).padStart(2, "0");
  return `${yyyy}-${mm}-${dd}`;
};

export const convertCamelCaseFieldNamesToSnakeCase = (
  obj,
  exclusions = ["userId", "userJointId"]
) => {
  const keys = Object.keys(obj);
  let output = {};
  keys.forEach((key) => {
    if (!exclusions.includes(key)) {
      output[_.snakeCase(key)] = obj[key];
    } else {
      output[key] = obj[key];
    }
  });
  return output;
};

const getTenMillionRuleBoolean = async (
  amount,
  userId,
  userJointId = null,
  quotationId
) => {
  const url =
    process.env.REACT_APP_API_URL + `/quotation/check/exceeded/tenmillion`;

  const data = getUserDataFromLocalStorage();
  const token = data?.access_token;

  try {
    const response = await axios.post(
      url,
      { amount, userId, userJointId, quotationId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.success ? response.data.data : false;
  } catch (err) {
    // debugger;
    console.log(err);
    return false;
  }
};

const getEightMillionRuleBoolean = async (
  amount,
  userId,
  userJointId = null,
  quotationId
) => {
  const data = getUserDataFromLocalStorage();
  const token = data?.access_token;
  const url = process.env.REACT_APP_API_URL + `/quotation/check/exceeded`;
  try {
    const response = await axios.post(
      url,
      { amount, userId, userJointId, quotationId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.success ? response.data.data : false;
  } catch (err) {
    // debugger;
    console.log(err);
    return false;
  }
};

const isUnderwritingReferred = async (_quotation, isMainLife = true) => {
  try {
    if (_quotation?.Underwriting) {
      if (
        typeof _quotation?.Underwriting?.underwritingData != "object" &&
        typeof _quotation?.Underwriting?.underwritingDataJoint != "object"
      ) {
        _quotation.Underwriting = {
          ..._quotation?.Underwriting,
          underwritingData: JSON.parse(
            _quotation?.Underwriting?.underwritingData
          ),
          underwritingJointData: JSON.parse(
            _quotation?.Underwriting?.underwritingJointData
          ),
        };
      }
    }
  } catch (e) {
    // console.log(e);
  }

  //   const tenMillionRuleBoolean = await getTenMillionRuleBoolean(
  //   _quotation?.quotationFormData?.initial_sum_assured,
  //   _quotation?.user?.id,
  //   _quotation?.userJoint?.id || null,
  //   _quotation?.id
  // );

  // const eightMillionRuleBoolean = await getEightMillionRuleBoolean(
  //   _quotation?.quotationFormData?.initial_sum_assured,
  //   _quotation?.user?.id,
  //   _quotation?.userJoint?.id || null,
  //   _quotation?.id
  // );

  const tenMillionRuleBoolean = _quotation?.livesTenMillionData
    ? JSON.parse(_quotation?.livesTenMillionData)
    : null;
  const eightMillionRuleBoolean = _quotation?.livesEightMillionData
    ? JSON.parse(_quotation?.livesEightMillionData)
    : null;

  if (isMainLife) {
    let isReferredToILA = _quotation.Underwriting.underwritingData.data
      .map((item) => item.status)
      .includes("Refer to ILA");

    let isReferredTPDToILA = _quotation.Underwriting.underwritingData.data
      .map((item) => item.status)
      .includes("Refer TPD to ILA");

    let isSumAbove8Million =
      _quotation.quotationFormData.initial_sum_assured > 8000000;

    let checkUnderwritingDataLength =
      _quotation.Underwriting.underwritingData.data.length === 0;

    let isDTACheck = _quotation.isDtaResult
      ? eightMillionRuleBoolean?.mainLifeAmountExceededEightMillion
      : false;

    let isLTACheck = _quotation.isLtaResult
      ? eightMillionRuleBoolean?.mainLifeAmountExceededEightMillion ||
        tenMillionRuleBoolean?.mainLifeAmountExceededTenMillion
      : false;

    if (
      ((isReferredToILA ||
        isReferredTPDToILA ||
        isSumAbove8Million ||
        checkUnderwritingDataLength) &&
        !_quotation.underwritingManuallyAccepted) ||
      isDTACheck ||
      isLTACheck
    ) {
      // //for lta
      // if (_quotation.isLtaResult == true && _quotation.isDtaResult == false) {
      //   if (
      //     eightMillionRuleBoolean?.mainLifeAmountExceededEightMillion == true ||
      //     tenMillionRuleBoolean?.mainLifeAmountExceededTenMillion == true
      //   ) {
      //     return true;
      //   }
      // }

      // // for dta
      // if (_quotation.isLtaResult == false && _quotation.isDtaResult == true) {
      //   if (
      //     eightMillionRuleBoolean?.mainLifeAmountExceededEightMillion == true
      //   ) {
      //     return true;
      //   }
      // }

      return true;
    }
    return false;
  } else {
    // debugger;

    try {
      let isReferredToILA = _quotation.Underwriting.underwritingJointData.data
        .map((item) => item.status)
        .includes("Refer to ILA");

      let isReferredTPDToILA =
        _quotation.Underwriting.underwritingJointData.data
          .map((item) => item.status)
          .includes("Refer TPD to ILA");

      let isSumAbove8Million =
        _quotation.quotationFormData.initial_sum_assured > 8000000;

      let checkUnderwritingDataLength =
        _quotation.Underwriting.underwritingJointData.data.length === 0;

      let isDTACheck = _quotation.isDtaResult
        ? eightMillionRuleBoolean?.jointLifeAmountExceededEightMillion
        : false;

      let isLTACheck = _quotation.isLtaResult
        ? eightMillionRuleBoolean?.jointLifeAmountExceededEightMillion ||
          tenMillionRuleBoolean?.jointLifeAmountExceededTenMillion
        : false;

      if (
        ((isReferredToILA ||
          isReferredTPDToILA ||
          isSumAbove8Million ||
          checkUnderwritingDataLength) &&
          !_quotation.underwritingManuallyAccepted) ||
        isDTACheck ||
        isLTACheck
      ) {
        // //for lta
        // if (_quotation.isLtaResult == true && _quotation.isDtaResult == false) {
        //   if (
        //     eightMillionRuleBoolean?.jointLifeAmountExceededEightMillion ==
        //       true ||
        //     tenMillionRuleBoolean?.jointLifeAmountExceededTenMillion == true
        //   ) {
        //     return true;
        //   }
        // }

        // // for dta
        // if (_quotation.isLtaResult == false && _quotation.isDtaResult == true) {
        //   if (
        //     eightMillionRuleBoolean?.jointLifeAmountExceededEightMillion == true
        //   ) {
        //     return true;
        //   }
        // }

        return true;
      }
      return false;
    } catch (e) {
      // console.log(e);
      return false;
    }
  }

  // if (isMainLife) {
  //   if (
  //     (_quotation.Underwriting.underwritingData.data
  //       .map((item) => item.status)
  //       .includes("Refer to ILA") ||
  //       _quotation.Underwriting.underwritingData.data
  //         .map((item) => item.status)
  //         .includes("Refer TPD to ILA") ||
  //       _quotation.quotationFormData.initial_sum_assured > 8000000 ||
  //       _quotation.Underwriting.underwritingData.data.length === 0) &&
  //     !_quotation.underwritingManuallyAccepted
  //   ) {

  //     //for lta
  //     if(_quotation.isLtaResult == true && _quotation.isDtaResult == false) {
  //       if(eightMillionRuleBoolean?.mainLifeAmountExceededEightMillion == true ||
  //         tenMillionRuleBoolean?.mainLifeAmountExceededTenMillion == true){
  //           return true;
  //         }
  //     }

  //     // for dta
  //     if(_quotation.isLtaResult == false && _quotation.isDtaResult == true) {
  //       if(eightMillionRuleBoolean?.mainLifeAmountExceededEightMillion == true){
  //           return true;
  //         }
  //     }
  //   }
  //   return false;
  // } else {
  //   // debugger;

  //   try {
  //     if (
  //       (_quotation.Underwriting.underwritingJointData.data
  //         .map((item) => item.status)
  //         .includes("Refer to ILA") ||
  //         _quotation.Underwriting.underwritingJointData.data
  //           .map((item) => item.status)
  //           .includes("Refer TPD to ILA") ||
  //         _quotation.quotationFormData.initial_sum_assured > 8000000 ||
  //         _quotation.Underwriting.underwritingJointData.data.length === 0) &&
  //       !_quotation.underwritingManuallyAccepted
  //     ) {

  //     //for lta
  //     if(_quotation.isLtaResult == true && _quotation.isDtaResult == false) {
  //       if(eightMillionRuleBoolean?.jointLifeAmountExceededEightMillion == true ||
  //         tenMillionRuleBoolean?.jointLifeAmountExceededTenMillion == true){
  //           return true;
  //         }
  //     }

  //     // for dta
  //     if(_quotation.isLtaResult == false && _quotation.isDtaResult == true) {
  //       if(eightMillionRuleBoolean?.jointLifeAmountExceededEightMillion == true){
  //           return true;
  //         }
  //     }
  //     }
  //     return false;
  //   } catch (e) {
  //     // console.log(e);
  //     return false;
  //   }
  // }
};

axiosOriginal.interceptors.response.use(
  (response) => response,
  (error) => {
    try {
      const { status } = error.response;
      // // debugger;
      if (
        status === 401 &&
        !window.location.href.includes("login") &&
        !error.request.responseURL.includes("shufti")
      ) {
        clearLocalStorageData();
        window.location.href = `${process.env.REACT_APP_BASENAME}/login`;
      }
    } catch (e) {
      // // debugger;
      const userDetails = getUserDataFromLocalStorage();
      if (!userDetails && !window.location.href.includes("login")) {
        window.location.href = `${process.env.REACT_APP_BASENAME}/login`;
      }
    }

    return Promise.reject(error);
  }
);
const axios = axiosOriginal;

const getOnboardingData = () => {
  try {
    return JSON.parse(localStorage.getItem("onboardingData"));
  } catch (error) {
    return null;
  }
};

const setOnboardingData = (data) => {
  localStorage.setItem("onboardingData", JSON.stringify(data));
};

const clearLocalStorageData = () => {
  localStorage.clear();
};

const setOTPData = (data) => {
  localStorage.setItem("otpData", JSON.stringify(data));
};

const getOTPData = () => {
  try {
    return JSON.parse(localStorage.getItem("otpData"));
  } catch (error) {
    return null;
  }
};

const isValidNic = (nic, birthday) => {
  // console.log(birthday, nic);
  if (nic.length == 0 || nic.length != 14) {
    return false;
  }
  try {
    const nicDay = nic.substr(1, 2);
    const nicMonth = nic.substr(3, 2);
    const nicYear = nic.substr(5, 2);
    const nicBirthDate = new Moment(
      `${nicDay}-${nicMonth}-${nicYear}`,
      "DD-MM-YY"
    );
    const formattedBirthday = new Moment(birthday, "YYYY-MM-DD");
    return (
      formattedBirthday.format("DD-MM-YY") == nicBirthDate.format("DD-MM-YY")
    );
  } catch (err) {
    // console.log(err);
    return false;
  }
};

const canModifyQuotation = (quotation, workerId = null) => {
  if (quotation.workerId !== workerId) {
    return false;
  }
  return !(
    quotation.status === "accepted" ||
    quotation.status === "review" ||
    quotation.status === "expired"
  );
};

const calculateAgeNextBirthday = (birthday) => {
  const momentBirthday = Moment(birthday);
  return Math.floor(new Moment().diff(momentBirthday, "years", true)) + 1;
};

const getOccupations = () => {
  return ["Software Engineer", "Engineer", "Lawyer", "Business man"];
};

const getSalaryRanges = () => {
  return ["Rs0-Rs1000", "Rs1001-Rs3000", "Rs3001-Rs5000"];
};

const getEducationalQualifications = () => {
  return ["Bsc.", "Msc.", "Ba."];
};

const getCountries = () => {
  return [
    { name: "Afghanistan", code: "AF" },
    { name: "Åland Islands", code: "AX" },
    { name: "Albania", code: "AL" },
    { name: "Algeria", code: "DZ" },
    { name: "American Samoa", code: "AS" },
    { name: "AndorrA", code: "AD" },
    { name: "Angola", code: "AO" },
    { name: "Anguilla", code: "AI" },
    { name: "Antarctica", code: "AQ" },
    { name: "Antigua and Barbuda", code: "AG" },
    { name: "Argentina", code: "AR" },
    { name: "Armenia", code: "AM" },
    { name: "Aruba", code: "AW" },
    { name: "Australia", code: "AU" },
    { name: "Austria", code: "AT" },
    { name: "Azerbaijan", code: "AZ" },
    { name: "Bahamas", code: "BS" },
    { name: "Bahrain", code: "BH" },
    { name: "Bangladesh", code: "BD" },
    { name: "Barbados", code: "BB" },
    { name: "Belarus", code: "BY" },
    { name: "Belgium", code: "BE" },
    { name: "Belize", code: "BZ" },
    { name: "Benin", code: "BJ" },
    { name: "Bermuda", code: "BM" },
    { name: "Bhutan", code: "BT" },
    { name: "Bolivia", code: "BO" },
    { name: "Bosnia and Herzegovina", code: "BA" },
    { name: "Botswana", code: "BW" },
    { name: "Bouvet Island", code: "BV" },
    { name: "Brazil", code: "BR" },
    { name: "British Indian Ocean Territory", code: "IO" },
    { name: "Brunei Darussalam", code: "BN" },
    { name: "Bulgaria", code: "BG" },
    { name: "Burkina Faso", code: "BF" },
    { name: "Burundi", code: "BI" },
    { name: "Cambodia", code: "KH" },
    { name: "Cameroon", code: "CM" },
    { name: "Canada", code: "CA" },
    { name: "Cape Verde", code: "CV" },
    { name: "Cayman Islands", code: "KY" },
    { name: "Central African Republic", code: "CF" },
    { name: "Chad", code: "TD" },
    { name: "Chile", code: "CL" },
    { name: "China", code: "CN" },
    { name: "Christmas Island", code: "CX" },
    { name: "Cocos (Keeling) Islands", code: "CC" },
    { name: "Colombia", code: "CO" },
    { name: "Comoros", code: "KM" },
    { name: "Congo", code: "CG" },
    { name: "Congo, The Democratic Republic of the", code: "CD" },
    { name: "Cook Islands", code: "CK" },
    { name: "Costa Rica", code: "CR" },
    { name: "Cote D'Ivoire", code: "CI" },
    { name: "Croatia", code: "HR" },
    { name: "Cuba", code: "CU" },
    { name: "Cyprus", code: "CY" },
    { name: "Czech Republic", code: "CZ" },
    { name: "Denmark", code: "DK" },
    { name: "Djibouti", code: "DJ" },
    { name: "Dominica", code: "DM" },
    { name: "Dominican Republic", code: "DO" },
    { name: "Ecuador", code: "EC" },
    { name: "Egypt", code: "EG" },
    { name: "El Salvador", code: "SV" },
    { name: "Equatorial Guinea", code: "GQ" },
    { name: "Eritrea", code: "ER" },
    { name: "Estonia", code: "EE" },
    { name: "Ethiopia", code: "ET" },
    { name: "Falkland Islands (Malvinas)", code: "FK" },
    { name: "Faroe Islands", code: "FO" },
    { name: "Fiji", code: "FJ" },
    { name: "Finland", code: "FI" },
    { name: "France", code: "FR" },
    { name: "French Guiana", code: "GF" },
    { name: "French Polynesia", code: "PF" },
    { name: "French Southern Territories", code: "TF" },
    { name: "Gabon", code: "GA" },
    { name: "Gambia", code: "GM" },
    { name: "Georgia", code: "GE" },
    { name: "Germany", code: "DE" },
    { name: "Ghana", code: "GH" },
    { name: "Gibraltar", code: "GI" },
    { name: "Greece", code: "GR" },
    { name: "Greenland", code: "GL" },
    { name: "Grenada", code: "GD" },
    { name: "Guadeloupe", code: "GP" },
    { name: "Guam", code: "GU" },
    { name: "Guatemala", code: "GT" },
    { name: "Guernsey", code: "GG" },
    { name: "Guinea", code: "GN" },
    { name: "Guinea-Bissau", code: "GW" },
    { name: "Guyana", code: "GY" },
    { name: "Haiti", code: "HT" },
    { name: "Heard Island and Mcdonald Islands", code: "HM" },
    { name: "Holy See (Vatican City State)", code: "VA" },
    { name: "Honduras", code: "HN" },
    { name: "Hong Kong", code: "HK" },
    { name: "Hungary", code: "HU" },
    { name: "Iceland", code: "IS" },
    { name: "India", code: "IN" },
    { name: "Indonesia", code: "ID" },
    { name: "Iran, Islamic Republic Of", code: "IR" },
    { name: "Iraq", code: "IQ" },
    { name: "Ireland", code: "IE" },
    { name: "Isle of Man", code: "IM" },
    { name: "Israel", code: "IL" },
    { name: "Italy", code: "IT" },
    { name: "Jamaica", code: "JM" },
    { name: "Japan", code: "JP" },
    { name: "Jersey", code: "JE" },
    { name: "Jordan", code: "JO" },
    { name: "Kazakhstan", code: "KZ" },
    { name: "Kenya", code: "KE" },
    { name: "Kiribati", code: "KI" },
    { name: "Korea, Democratic People'S Republic of", code: "KP" },
    { name: "Korea, Republic of", code: "KR" },
    { name: "Kuwait", code: "KW" },
    { name: "Kyrgyzstan", code: "KG" },
    { name: "Lao People'S Democratic Republic", code: "LA" },
    { name: "Latvia", code: "LV" },
    { name: "Lebanon", code: "LB" },
    { name: "Lesotho", code: "LS" },
    { name: "Liberia", code: "LR" },
    { name: "Libyan Arab Jamahiriya", code: "LY" },
    { name: "Liechtenstein", code: "LI" },
    { name: "Lithuania", code: "LT" },
    { name: "Luxembourg", code: "LU" },
    { name: "Macao", code: "MO" },
    { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
    { name: "Madagascar", code: "MG" },
    { name: "Malawi", code: "MW" },
    { name: "Malaysia", code: "MY" },
    { name: "Maldives", code: "MV" },
    { name: "Mali", code: "ML" },
    { name: "Malta", code: "MT" },
    { name: "Marshall Islands", code: "MH" },
    { name: "Martinique", code: "MQ" },
    { name: "Mauritania", code: "MR" },
    { name: "Mauritius", code: "MU" },
    { name: "Mayotte", code: "YT" },
    { name: "Mexico", code: "MX" },
    { name: "Micronesia, Federated States of", code: "FM" },
    { name: "Moldova, Republic of", code: "MD" },
    { name: "Monaco", code: "MC" },
    { name: "Mongolia", code: "MN" },
    { name: "Montserrat", code: "MS" },
    { name: "Morocco", code: "MA" },
    { name: "Mozambique", code: "MZ" },
    { name: "Myanmar", code: "MM" },
    { name: "Namibia", code: "NA" },
    { name: "Nauru", code: "NR" },
    { name: "Nepal", code: "NP" },
    { name: "Netherlands", code: "NL" },
    { name: "Netherlands Antilles", code: "AN" },
    { name: "New Caledonia", code: "NC" },
    { name: "New Zealand", code: "NZ" },
    { name: "Nicaragua", code: "NI" },
    { name: "Niger", code: "NE" },
    { name: "Nigeria", code: "NG" },
    { name: "Niue", code: "NU" },
    { name: "Norfolk Island", code: "NF" },
    { name: "Northern Mariana Islands", code: "MP" },
    { name: "Norway", code: "NO" },
    { name: "Oman", code: "OM" },
    { name: "Pakistan", code: "PK" },
    { name: "Palau", code: "PW" },
    { name: "Palestinian Territory, Occupied", code: "PS" },
    { name: "Panama", code: "PA" },
    { name: "Papua New Guinea", code: "PG" },
    { name: "Paraguay", code: "PY" },
    { name: "Peru", code: "PE" },
    { name: "Philippines", code: "PH" },
    { name: "Pitcairn", code: "PN" },
    { name: "Poland", code: "PL" },
    { name: "Portugal", code: "PT" },
    { name: "Puerto Rico", code: "PR" },
    { name: "Qatar", code: "QA" },
    { name: "Reunion", code: "RE" },
    { name: "Romania", code: "RO" },
    { name: "Russian Federation", code: "RU" },
    { name: "RWANDA", code: "RW" },
    { name: "Saint Helena", code: "SH" },
    { name: "Saint Kitts and Nevis", code: "KN" },
    { name: "Saint Lucia", code: "LC" },
    { name: "Saint Pierre and Miquelon", code: "PM" },
    { name: "Saint Vincent and the Grenadines", code: "VC" },
    { name: "Samoa", code: "WS" },
    { name: "San Marino", code: "SM" },
    { name: "Sao Tome and Principe", code: "ST" },
    { name: "Saudi Arabia", code: "SA" },
    { name: "Senegal", code: "SN" },
    { name: "Serbia and Montenegro", code: "CS" },
    { name: "Seychelles", code: "SC" },
    { name: "Sierra Leone", code: "SL" },
    { name: "Singapore", code: "SG" },
    { name: "Slovakia", code: "SK" },
    { name: "Slovenia", code: "SI" },
    { name: "Solomon Islands", code: "SB" },
    { name: "Somalia", code: "SO" },
    { name: "South Africa", code: "ZA" },
    { name: "South Georgia and the South Sandwich Islands", code: "GS" },
    { name: "Spain", code: "ES" },
    { name: "Sri Lanka", code: "LK" },
    { name: "Sudan", code: "SD" },
    { name: "Suriname", code: "SR" },
    { name: "Svalbard and Jan Mayen", code: "SJ" },
    { name: "Swaziland", code: "SZ" },
    { name: "Sweden", code: "SE" },
    { name: "Switzerland", code: "CH" },
    { name: "Syrian Arab Republic", code: "SY" },
    { name: "Taiwan, Province of China", code: "TW" },
    { name: "Tajikistan", code: "TJ" },
    { name: "Tanzania, United Republic of", code: "TZ" },
    { name: "Thailand", code: "TH" },
    { name: "Timor-Leste", code: "TL" },
    { name: "Togo", code: "TG" },
    { name: "Tokelau", code: "TK" },
    { name: "Tonga", code: "TO" },
    { name: "Trinidad and Tobago", code: "TT" },
    { name: "Tunisia", code: "TN" },
    { name: "Turkey", code: "TR" },
    { name: "Turkmenistan", code: "TM" },
    { name: "Turks and Caicos Islands", code: "TC" },
    { name: "Tuvalu", code: "TV" },
    { name: "Uganda", code: "UG" },
    { name: "Ukraine", code: "UA" },
    { name: "United Arab Emirates", code: "AE" },
    { name: "United Kingdom", code: "GB" },
    { name: "United States", code: "US" },
    { name: "United States Minor Outlying Islands", code: "UM" },
    { name: "Uruguay", code: "UY" },
    { name: "Uzbekistan", code: "UZ" },
    { name: "Vanuatu", code: "VU" },
    { name: "Venezuela", code: "VE" },
    { name: "Viet Nam", code: "VN" },
    { name: "Virgin Islands, British", code: "VG" },
    { name: "Virgin Islands, U.S.", code: "VI" },
    { name: "Wallis and Futuna", code: "WF" },
    { name: "Western Sahara", code: "EH" },
    { name: "Yemen", code: "YE" },
    { name: "Zambia", code: "ZM" },
    { name: "Zimbabwe", code: "ZW" },
  ];
};

const getClavicleCollarboneHamstringToeFingerList = () => {
  return [
    "Damaged bone in foot",
    "Damaged cruciate ligament",
    "Damaged ligament",
    "Damaged tendon",
    "Dislocated clavicle",
    "Dislocated collar bone",
    "Dislocated finger",
    "Dislocated fingers",
    "Dislocated jaw",
    "Dislocated toe",
    "Hamstring injury",
    "Jaw injury",
    "Ruptured tendon",
    "Foot condition",
  ];
};

const getShoulderList = () => {
  return [
    "Frozen shoulder",
    "Rotator cuff injury",
    "Rotator cuff problem",
    "Shoulder operation",
    "Dislocated shoulder",
    "Pulled muscle in shoulder",
    "Shoulder condition",
    "Shoulder discomfort",
    "Shoulder dislocated",
    "Shoulder fracture",
    "Shoulder impingement",
    "Shoulder injury",
    "shoulder pain",
    "Shoulder sprain",
    "Shoulder surgery",
  ];
};

const getBackpainList = () => {
  return [...getBackpainDontReferToIlaList(), ...getBackPainReferToIlaList()];
};

const getBackpainDontReferToIlaList = () => {
  return [
    "Aching back",
    "Backache",
    "Back discomfort",
    "Back inflammatory",
    "Back injury",
    "Back manipulation",
    "Back muscle pulled",
    "Back muscle spasm",
    "Back pain",
    "Back problem",
    "Back spasm",
    "Back stiffness",
    "Back strain",
    "Bad back",
    "Lumbar pain",
    "Muscular backache",
    "Neck pain",
    "Stiff back",
    "Whiplash",
    "Pulled muscle in back",
    "Pulled muscle in back/neck",
    "Cervical neck pain",
    "Cervical pain",
  ];
};

const getBackPainReferToIlaList = () => {
  return [
    "Back operation",
    "Back surgery",
    "Bulging disc",
    "Bulging disc in spine",
    "Compressed disc in back",
    "Compressed disc in spine",
    "Compressed Vertebrae",
    "Crushed disc in spine",
    "Collapsed disc",
    "Degenerative changes to the spine",
    "Degenerative disc disorder",
    "Disc damage",
    "Disc degeneration",
    "Disc disorder",
    "Disc prolapse",
    "Disc prolapse in neck",
    "Fused spine",
    "Neck broken",
    "Neck injury",
    "Neck prolapsed disc",
    "Prolapsed disc in back",
    "Prolapsed disc in neck",
    "Sciatic nerve damage",
    "Sciatica Slipped disc",
    "Spinal decompression",
    "Spinal fusion operation",
    "Spinal injury",
    "Spinal operation",
    "Spinal stenosis",
    "Trapped nerve in back",
    "Trapped nerve in lower back",
    "Trapped nerve in shoulder",
    "Spinal fusion",
    "Cervical fusion",
    "Cervical spondylitis",
  ];
};

const getMentalHealthList = () => {
  return [
    "Baby Blues",
    "Bereavement",
    "Breakdown",
    "Burnout Syndrome",
    "Cyclothymia",
    "Cyclothymic disorder",
    "Depression after pregnancy",
    "Divorce stress",
    "Dysthymia",
    "Dysthymic disorder",
    "Emotional stress",
    "Feeling down",
    "Grief",
    "History of work related stress",
    "Low mood",
    "Post natal depression",
    "Postpartum depression",
    "Pressure of work",
    "Reactive stress",
    "Seasonal affective disorder",
    "Stress",
    "Stress due to accident",
    "Stress due to divorce",
    "Stress due to marital problems",
    "Stress due to marriage break up",
    "Stress due to overwork",
    "Stress due to relationship problems",
    "Work related stress",
    "Worry",
  ];
};

const getKneeList = () => {
  return ["Damaged knee", "Dislocated knee", "Housemaid's Knee"];
};

const getHipList = () => {
  return ["Hip pain", "Hip condition", "Hip problem"];
};

const getAutoPassList = () => {
  return [
    "ARMPIT CYST",
    "BARTHOLON CYST",
    "QUINSY",
    "BLOATING",
    "CONSTIPATION",
    "GASTRIC UPSET",
    "INDIGESTION",
    "PILONIDAL CYST",
    "PILONIDAL CYSTS",
    "PILONIDAL SINUS",
    "STOMACH ACHE",
    "STOMACH BUG",
    "TUMMY BUG",
    "TUMMY UPSET",
    "MUMPS WITHOUT COMPLICATION (SG)",
    "CUT ARM",
    "CUT FINGER",
    "FOOT FUNGAL INFECTION",
    "INCISED WOUND",
    "HIGH FEVER",
    "BODY ACHES",
    "FATIGUE",
    "SORE THROAT",
    "FLU LIKE SYMPTOMS",
    "CAT ALLERGY",
    "DOG ALLERGY",
    "DUST MITE ALLERGY",
    "PET ALLERGY",
    "PLANT ALLERGY",
    "BLOOD DONATION",
    "DEFICIENCY OF VITAMINS",
    "DONATED BLOOD",
    "GIVING BLOOD",
    "BENIGN CYST",
    "BENIGN CYSTS",
    "CYST ON GUM",
    "CYSTIC ACNE",
    "DERMOID CYST",
    "SEBACEOUS CYST",
    "VERRUCA REMOVED",
    "BOIL",
    "COIL REMOVAL",
    "HICCUP",
    "INDIGESTION",
    "COIL FITTING",
    "COIL REMOVAL",
    "ASTIGMATISM",
    "BROKEN NOSE",
    "DECREASED SENSE OF SMELL",
    "DENTAL BRACE",
    "DENTAL CARIES",
    "LOSS OF SMELL",
    "MOUTH ABSCESS",
    "NASAL BLOCKAGE",
    "NASAL CONDITION",
    "NASAL SEPTUM STRAIGHTENING",
    "NASAL SEPTUM SURGERY",
    "NEARSIGHTED",
    "PERENNIAL RHINITIS",
    "ROOT CANAL TREATMENT",
    "SORE THROAT",
    "SPECTACLES",
    "STYE",
    "STYE REMOVED",
    "STYES",
    "SYRINGED EARS",
    "TONSIL ABSCESS",
    "TONSIL STUMPS REMOVED",
    "TONSILLECTOMY",
    "TONSILLITIS",
    "WATER IN EAR",
    "ALLERGIC RHINITIS ",
    "BLOCKED EAR",
    "BLOCKED NOSE",
    "BLOCKED SALIVA DUCT",
    "BLOCKED SALIVARY GLAND",
    "BLOCKED SINUS(ES)",
    "BLOOD SHOT EYE",
    "COLOUR BLINDNESS",
    "CONGESTED SINUS(ES)",
    "CONTACT LENSES",
    "DROOPING EYELID",
    "DRY EYES",
    "DRY MOUTH",
    "EAR SYRINGED",
    "EYESIGHT TESTS ROUTINE",
    "GLASSES",
    "GUM SHIELD",
    "HAYFEVER",
    "HYPERMETROPIA",
    "INFECTED SINUS(ES)",
    "INFLAMMATION OF CONJUNCTIVA",
    "INFLAMMATION OF SINUS(ES)",
    "LASER EYESIGHT CORRECTION",
    "NOSE BLOCKAGE",
    "NOSE INJURY",
    "ORAL ULCER",
    "PERIAPICAL ABSCESS",
    "PERIODONTAL ABSCESS",
    "POLLINOSIS",
    "POSTNASAL DRIP",
    "READING GLASSES",
    "RHINOPLASTY",
    "SCRATCHED EYE",
    "SINUS BLOCKAGE",
    "SINUS INFECTION",
    "SINUS INFECTIONS",
    "SINUS OPERATION",
    "STREP THROAT",
    "THRUSH IN THROAT",
    "ULCERS IN MOUTH",
    "WAX REMOVED FROM EARS",
    "FATTY DEPOSIT",
    "FATTY TISSUE",
    "HEAT BUMPS",
    "VARICOSE VEINS",
    "FUNGAL ECZEMA",
    "FUNGAL FOOT INFECTION",
    "FUNGAL INFECTION",
    "FUNGAL INFECTION OF THE GROIN",
    "FUNGAL INFECTION OF THE SCALP",
    "FUNGAL NAIL INFECTION",
    "FUNGAL SCAB",
    "FUNGAL TOE INFECTION",
    "HERPES SIMPLEX",
    "NAIL FUNGAL INFECTION",
    "RINGWORM",
    "RINGWORM OF BEARD",
    "RINGWORM OF BODY",
    "RINGWORM OF GENITOCRURAL REGION",
    "RINGWORM OF SCALP",
    "RINGWORM OF THE HAND",
    "RINGWORM OF THE NAILS",
    "SCALY RINGWORM",
    "SERPIGO",
    "SHINGLES (COMPLETE RECOVERY WITH NO FURTHER TREATMENT)",
    "TRICHOPHYTOSIS BARBAE",
    "TRICHOPHYTOSIS CORPORIS",
    "TRICHOPHYTOSIS CRURIS",
    "VARICELLA",
    "VARICELLA (COMPLETE RECOVERY WITH NO FURTHER TREATMENT)",
    "VARICELLA-ZOSTER VIRUS (COMPLETE RECOVERY WITH NO FURTHER TREATMENT)",
    "WART",
    "WATERPOX (COMPLETE RECOVERY WITH NO FURTHER TREATMENT)",
    "WHITLOW",
    "ZONA IGNEA (COMPLETE RECOVERY WITH NO FURTHER TREATMENT)",
    "ZONA SERPIGINOSA (COMPLETE RECOVERY WITH NO FURTHER TREATMENT)",
    "ZOSTER",
    "CELSUS KERION",
    "CHICKENPOX (COMPLETE RECOVERY WITH NO FURTHER TREATMENT)",
    "COLD SORE",
    "DHOBIE ITCH",
    "FACIAL WARTS",
    "FOLLICULITIS BARBAE",
    "HERPES",
    "HERPES DESQUAMANS",
    "HERPES OF LIP",
    "HERPES ZOSTER",
    "HERPES ZOSTER (COMPLETE RECOVERY WITH NO FURTHER TREATMENT)",
    "HUMAN HERPESVIRUS 3 (COMPLETE RECOVERY WITH NO FURTHER TREATMENT)",
    "MALABAR ITCH",
    "MEASLES WITHOUT COMPLICATION (SG)",
    "NAIL FUNGAL INFECTION",
    "ORIENTAL RINGWORM",
    "PORRIGO FURFURANS",
    "TINEA",
    "TINEA AMIANTACEA",
    "TINEA BARBAE",
    "TINEA CAPITIS",
    "TINEA CIRCINATA",
    "TINEA CORPORIS",
    "TINEA CRURIS",
    "TINEA GLABROSA",
    "TINEA IMBRICATA",
    "TINEA INGUINALIS",
    "TINEA KERION",
    "TINEA MANUS",
    "TINEA NIGRA",
    "TINEA SYCOSIS",
    "TINEA TONSURANS",
    "TINEA TROPICALIS",
    "TINEA UNGUIUM",
    "BAD BREATHING",
    "TICKLY COUGH",
    "DENTAL OPERATION",
    "GANGLION OPERATION",
    "GAVE BLOOD",
    "HAEMORRHOID OPERATION",
    "LASER EYE SURGERY",
    "PREGNANCY SCAN",
    "PREGNANCY TEST",
    "PRENATAL CHECKS",
    "REMOVAL OF ADENOIDS",
    "REMOVAL OF EAR WAX",
    "REMOVAL OF INGROWING TOENAIL",
    "REMOVAL OF PLANTAR WART",
    "REMOVAL OF THE TONSILS",
    "REVERSAL OF VASECTOMY",
    "SEPTOPLASTY",
    "SMEAR TEST NORMAL",
    "STERILISATION",
    "TESTS FOR FERTILITY",
    "TETANUS BOOSTER",
    "TETANUS INJECTION",
    "TONSILLECTOMY (SG)",
    "TRAVEL VACCINATION",
    "TUBES TIED",
    "VASECTOMY",
    "AIRSICKNESS",
    "BAD COLD",
    "BIRTH CONTROL ADVICE",
    "BLACK EYES",
    "GIVING UP SMOKING",
    "PINGUECULUM",
    "SEASICKNESS",
    "SICKNESS WHILE FLYING",
    "STOPPED SMOKING",
    "ADENOIDECTOMY",
    "ADENOIDECTOMY",
    "AMNIOCENTESIS",
    "AMNIOCENTESIS (CURRENT PREGNANCY)",
    "AMNIOCENTESIS (PAST PREGNANCY)",
    "BIRTH OF CHILD",
    "BIRTH OF CHILD BY CAESAREAN SECTION",
    "BLEEDING DURING PAST PREGNANCY",
    "BREAST FEEDING ADVICE",
    "CAESAREAN SECTION",
    "CHILDBIRTH",
    "CONTRACEPTION",
    "CONTRACEPTIVE DEVICE",
    "CONTRACEPTIVE PILL",
    "CURRENT PREGNANCY",
    "FAMILY PLANNING ADVICE",
    "FERTILITY PROBLEM",
    "FERTILITY TESTS",
    "GIVING BIRTH",
    "IN VITRO FERTILISATION",
    "INABILITY TO CONCEIVE",
    "INFERTILITY TREATMENT",
    "IVF TREATMENT",
    "MISCARRIAGE",
    "MORNING SICKNESS (CURRENT PREGNANCY)",
    "NORMAL CHILD BIRTH",
    "NORMAL PREGNANCY",
    "ON WAITING LIST FOR IVF",
    "PLANNING FAMILY",
    "PREGNANCY (CURRENT)",
    "PREGNANCY NORMAL",
    "ROUTINE PREGNANCY CHECK UPS",
    "SYMPHYSIS PUBIS DYSFUNCTION (PAST PREGNANCY)",
    "TEST FOR PREGNANCY",
    "PREGNANCY ROUTINE CHECKS",
    "NIGHTMARES",
    "IMPOTENCE",
    "ABORTION",
    "BLOCKED MILK DUCT",
    "CIRCUMCISION",
    "EARLY MENOPAUSE",
    "HEAVY MENSTRUAL PERIODS",
    "HEAVY PERIODS",
    "HORMONE REPLACEMENT THERAPY",
    "MALE STERILISATION",
    "MENOPAUSE AT AGE 50 OR OLDER",
    "MENORRHAGIA",
    "MENSTRUAL CRAMPS",
    "MENSTRUAL DISORDER",
    "MENSTRUAL IRREGULARITY",
    "PAINFUL MENSTRUATION",
    "PAINFUL PERIODS",
    "PERIOD PAIN",
    "PRE MENSTRUAL SYNDROME ",
    "SNORING",
    "INFERTILITY TESTS",
    "MENSTRUAL PAIN",
    "NETTLE RASH",
    "PLANTAR WART REMOVED",
    "ABRASIONS",
    "ABSCESS MOUTH",
    "ABSCESS TEETHTOOTH",
    "ABSCESS TONSILS",
    "ACHE IN ANKLE",
    "ACHING FOOT",
    "ACNE",
    "ANT BITES",
    "ATHLETE'S FOOT",
    "BAD TOOTH",
    "BARBERS ITCH",
    "BILATERAL GROIN STRAIN",
    "BIRTHMARK",
    "BLEMISH",
    "BLOCKED SWEAT GLAND",
    "BLOCKED TEAR DUCT",
    "BLOOD BLISTER REMOVED",
    "BODY ODOUR",
    "BROKEN CHEEK BONE",
    "BROKEN NOSE",
    "CALF PAIN",
    "CALLUSES",
    "CAT BITE",
    "CHIN CUT",
    "CLICKING JAW",
    "CLUB FEET",
    "CLUB FOOT",
    "CUT",
    "CUT ELBOW",
    "CUT FACE",
    "CUT FINGERS",
    "CUT HAND",
    "CUT HEAD",
    "CUT KNEE",
    "CUT LEG",
    "DANDRUFF",
    "DENTAL PAIN",
    "DRY SKIN",
    "ECZEMA MARGINATUM",
    "EXTRACTION AND RECONSTRUCTION OF TEETH (MG)",
    "EXTRACTION OF TOOTH",
    "FACE CUT",
    "FEVER BLISTER",
    "FLAT FEET/FOOT",
    "FOOT BUNION",
    "FOOT CRAMP",
    "FOOT ODOUR",
    "FRACTURED NOSE",
    "FRECKLE",
    "GANGLION OPERATION",
    "GANGLION REMOVAL",
    "GOLFER'S ELBOW",
    "GRAZES",
    "GROIN STRAIN",
    "HALLUX VALGUS",
    "HAMMER TOE",
    "HAND CUT",
    "HARD SKIN",
    "HAY FEVER",
    "HEAD GRAZE",
    "HEAD LICE",
    "HEEL PAIN",
    "HOT FLUSHES",
    "HYPERHIDROSIS",
    "HYPERMOBILE JOINTS",
    "IMPACTED TEETH",
    "INFECTED HAIR FOLLICLE",
    "INFLAMMATION OF NAIL BED",
    "INGROWING HAIR",
    "INGROWING TOENAIL",
    "INGROWING TOENAIL REMOVED",
    "ITCHING",
    "ITCHY SCALP",
    "JAW CLICKING",
    "JOCK ITCH",
    "KNEE CUT",
    "KNEE GRAZE",
    "KNOCK KNEED",
    "LACERATION",
    "LACERATION OF CHIN",
    "LACERATION OF ELBOW",
    "LACERATION OF FACE",
    "LACERATION OF FINGER",
    "LACERATION OF HAND",
    "LACERATION OF KNEE",
    "LACERATION OF KNEE",
    "LACERATION OF LEG",
    "LACERATION OF THUMB",
    "LEG LACERATION",
    "PIGEON TOED",
    "PIMPLE",
    "PITYRIASIS AMIANTACEA",
    "PITYRIASIS NIGRA",
    "REMOVAL OF TOOTHTEETH",
    "REMOVAL OF VERRUCA",
    "REMOVAL OF WISDOM TOOTHTEETH",
    "SCAR TISSUE",
    "SCAR TISSUE (INTERNAL)",
    "SCAR TISSUE (SKIN)",
    "SCAR TISSUE REMOVED",
    "SHIN SPLINTS",
    "SPLINTER",
    "STITCHES",
    "STITCHES IN ARM",
    "STITCHES IN ELBOW",
    "STITCHES IN FACE",
    "STITCHES IN HANDS",
    "STITCHES IN KNEE",
    "STITCHES IN LEG",
    "STRAINED GROIN",
    "STRAINED GROIN",
    "SUNBURN",
    "SWEAT RASH",
    "TEETH REMOVED",
    "TOE FUNGAL INFECTION",
    "TOE FUNGAL INFECTION",
    "TOE INFECTION",
    "TOENAIL INFECTION",
    "TOENAIL INGROWING",
    "TOKELAU RINGWORM",
    "TOOTH INFECTION",
    "TOOTH INFECTIONS",
    "TOOTH REMOVED",
    "TORTICOLLIS",
    "TRAPPED WIND",
    "WISDOM TOOTH PAIN",
    "WISDOM TOOTH PAIN",
    "WISDOM TOOTH REMOVED",
    "FERTILITY TREATMENT",
    "FERTILITY TREATMENT",
    "FLU JAB",
    "HOLIDAY INJECTIONS",
    "IMMUNISATION",
    "INJECTIONS FOR HOLIDAY",
    "INJECTIONS FOR INFERTILITY",
    "HALITOSIS",
  ];
};

const isValidAgeNextBirthdayWithTerm = (birthday, numberOfTerms) => {
  const ageNextBirthday = calculateAgeNextBirthday(birthday);
  return ageNextBirthday + numberOfTerms <= 75;
};

const getTotalPremium = (_quotation) => {
  // Get loan plan
  const loanPlan =
    _quotation.isDtaResult && !_quotation.isLtaResult
      ? "dta"
      : !_quotation.isDtaResult && _quotation.isLtaResult
      ? "lta"
      : "dta";
  // Check if tpd have been removed or not
  if (!_quotation.tpdManualReduction) {
    if (!_quotation.withRefund) {
      if (_quotation.paymentInsurancePlan === "yearly") {
        if (loanPlan === "lta") {
          return _quotation.ltaYearlyPremiumDefault;
        } else {
          return _quotation.yearlyPremiumDefault;
        }
      } else if (_quotation.paymentInsurancePlan === "monthly") {
        if (loanPlan === "lta") {
          return _quotation.ltaDeathAndTpd;
        } else {
          return _quotation.deathAndTpd;
        }
      } else if (_quotation.paymentInsurancePlan === "half-yearly") {
        if (loanPlan === "lta") {
          return _quotation.ltaHalfYearlyPremiumDefault;
        } else {
          return _quotation.halfYearlyPremiumDefault;
        }
      } else if (_quotation.paymentInsurancePlan === "quarterly") {
        if (loanPlan === "lta") {
          return _quotation.ltaQuarterlyPremiumDefault;
        } else {
          return _quotation.quarterlyPremiumDefault;
        }
      } else if (_quotation.paymentInsurancePlan === "single") {
        if (loanPlan === "lta") {
          return _quotation.ltaSinglePremiumDefault;
        } else {
          return _quotation.singlePremiumDefault;
        }
      } else {
        return null;
      }
    } else {
      if (_quotation.paymentInsurancePlan === "yearly") {
        if (loanPlan === "lta") {
          return _quotation.ltaYearlyPremiumWithRefundDefault;
        } else {
          return _quotation.yearlyPremiumWithRefundDefault;
        }
      } else if (_quotation.paymentInsurancePlan === "monthly") {
        if (loanPlan === "lta") {
          return _quotation.ltaDeathAndTpdWithRefund;
        } else {
          return _quotation.deathAndTpdWithRefund;
        }
      } else if (_quotation.paymentInsurancePlan === "half-yearly") {
        if (loanPlan === "lta") {
          return _quotation.ltaHalfYearlyPremiumWithRefundDefault;
        } else {
          return _quotation.halfYearlyPremiumWithRefundDefault;
        }
      } else if (_quotation.paymentInsurancePlan === "quarterly") {
        if (loanPlan === "lta") {
          return _quotation.ltaQuarterlyPremiumWithRefundDefault;
        } else {
          return _quotation.quarterlyPremiumWithRefundDefault;
        }
      } else if (_quotation.paymentInsurancePlan === "single") {
        if (loanPlan === "lta") {
          return _quotation.ltaSinglePremiumWithRefundDefault;
        } else {
          return _quotation.singlePremiumWithRefundDefault;
        }
      } else {
        return null;
      }
    }
  } else {
    return _quotation.tpdManualReductionAmount;
  }
};

const getTotalPremiumWithAddOnValue = (_quotation) => {
  // // debugger;
  // Get loan plan
  const loanPlan =
    _quotation.isDtaResult && !_quotation.isLtaResult
      ? "dta"
      : !_quotation.isDtaResult && _quotation.isLtaResult
      ? "lta"
      : "dta";
  // Set Addon Amount
  let addOnValue = 0;
  if (_quotation?.Underwriting?.addonAmount) {
    const addonAmountFromUnderwriting =
      _quotation?.Underwriting?.addonAmount || 0;
    addOnValue += parseFloat(addonAmountFromUnderwriting);
  }

  // Check if tpd have been removed or not
  if (!_quotation.tpdManualReduction) {
    if (!_quotation.withRefund) {
      if (_quotation.paymentInsurancePlan === "yearly") {
        if (loanPlan === "lta") {
          return parseFloat(_quotation.ltaYearlyPremiumDefault) + addOnValue;
        } else {
          return parseFloat(_quotation.yearlyPremiumDefault) + addOnValue;
        }
      } else if (_quotation.paymentInsurancePlan === "monthly") {
        if (loanPlan === "lta") {
          return parseFloat(_quotation.ltaDeathAndTpd) + addOnValue;
        } else {
          return parseFloat(_quotation.deathAndTpd) + addOnValue;
        }
      } else if (_quotation.paymentInsurancePlan === "half-yearly") {
        if (loanPlan === "lta") {
          return (
            parseFloat(_quotation.ltaHalfYearlyPremiumDefault) + addOnValue
          );
        } else {
          return parseFloat(_quotation.halfYearlyPremiumDefault) + addOnValue;
        }
      } else if (_quotation.paymentInsurancePlan === "quarterly") {
        if (loanPlan === "lta") {
          return parseFloat(_quotation.ltaQuarterlyPremiumDefault) + addOnValue;
        } else {
          return parseFloat(_quotation.quarterlyPremiumDefault) + addOnValue;
        }
      } else if (_quotation.paymentInsurancePlan === "single") {
        if (loanPlan === "lta") {
          return parseFloat(_quotation.ltaSinglePremiumDefault) + addOnValue;
        } else {
          return parseFloat(_quotation.singlePremiumDefault) + addOnValue;
        }
      } else {
        return null;
      }
    } else {
      if (_quotation.paymentInsurancePlan === "yearly") {
        if (loanPlan === "lta") {
          return (
            parseFloat(_quotation.ltaYearlyPremiumWithRefundDefault) +
            addOnValue
          );
        } else {
          return (
            parseFloat(_quotation.yearlyPremiumWithRefundDefault) + addOnValue
          );
        }
      } else if (_quotation.paymentInsurancePlan === "monthly") {
        if (loanPlan === "lta") {
          return parseFloat(_quotation.ltaDeathAndTpdWithRefund) + addOnValue;
        } else {
          return parseFloat(_quotation.deathAndTpdWithRefund) + addOnValue;
        }
      } else if (_quotation.paymentInsurancePlan === "half-yearly") {
        if (loanPlan === "lta") {
          return (
            parseFloat(_quotation.ltaHalfYearlyPremiumWithRefundDefault) +
            addOnValue
          );
        } else {
          return (
            parseFloat(_quotation.halfYearlyPremiumWithRefundDefault) +
            addOnValue
          );
        }
      } else if (_quotation.paymentInsurancePlan === "quarterly") {
        if (loanPlan === "lta") {
          return (
            parseFloat(_quotation.ltaQuarterlyPremiumWithRefundDefault) +
            addOnValue
          );
        } else {
          return (
            parseFloat(_quotation.quarterlyPremiumWithRefundDefault) +
            addOnValue
          );
        }
      } else if (_quotation.paymentInsurancePlan === "single") {
        if (loanPlan === "lta") {
          return (
            parseFloat(_quotation.ltaSinglePremiumWithRefundDefault) +
            addOnValue
          );
        } else {
          return (
            parseFloat(_quotation.singlePremiumWithRefundDefault) + addOnValue
          );
        }
      } else {
        return null;
      }
    }
  } else {
    return parseFloat(_quotation.tpdManualReductionAmount) + addOnValue;
  }
};

const getTotalPremiumWithAddOnValueAndServiceCharge = (_quotation) => {
  // Get loan plan
  const loanPlan =
    _quotation.isDtaResult && !_quotation.isLtaResult
      ? "dta"
      : !_quotation.isDtaResult && _quotation.isLtaResult
      ? "lta"
      : "dta";
  // Set Addon Amount and Service charge Amount
  let addOnValue = 0;
  let serviceChargeAmount = 0;
  if (_quotation?.Underwriting?.addonAmount) {
    const addonAmountFromUnderwriting =
      _quotation?.Underwriting?.addonAmount || 0;
    addOnValue += parseFloat(addonAmountFromUnderwriting);
  }
  // Check if tpd have been removed or not
  if (!_quotation.tpdManualReduction) {
    if (!_quotation.withRefund) {
      if (_quotation.paymentInsurancePlan === "yearly") {
        if (loanPlan === "lta") {
          return (
            parseFloat(_quotation.ltaYearlyPremiumDefault) +
            addOnValue +
            serviceChargeAmount
          );
        } else {
          return (
            parseFloat(_quotation.yearlyPremiumDefault) +
            addOnValue +
            serviceChargeAmount
          );
        }
      } else if (_quotation.paymentInsurancePlan === "monthly") {
        if (loanPlan === "lta") {
          return (
            parseFloat(_quotation.ltaDeathAndTpd) +
            addOnValue +
            serviceChargeAmount
          );
        } else {
          return (
            parseFloat(_quotation.deathAndTpd) +
            addOnValue +
            serviceChargeAmount
          );
        }
      } else if (_quotation.paymentInsurancePlan === "half-yearly") {
        if (loanPlan === "lta") {
          return (
            parseFloat(_quotation.ltaHalfYearlyPremiumDefault) +
            addOnValue +
            serviceChargeAmount
          );
        } else {
          return (
            parseFloat(_quotation.halfYearlyPremiumDefault) +
            addOnValue +
            serviceChargeAmount
          );
        }
      } else if (_quotation.paymentInsurancePlan === "quarterly") {
        if (loanPlan === "lta") {
          return (
            parseFloat(_quotation.ltaQuarterlyPremiumDefault) +
            addOnValue +
            serviceChargeAmount
          );
        } else {
          return (
            parseFloat(_quotation.quarterlyPremiumDefault) +
            addOnValue +
            serviceChargeAmount
          );
        }
      } else if (_quotation.paymentInsurancePlan === "single") {
        if (loanPlan === "lta") {
          return (
            parseFloat(_quotation.ltaSinglePremiumDefault) +
            addOnValue +
            serviceChargeAmount
          );
        } else {
          return (
            parseFloat(_quotation.singlePremiumDefault) +
            addOnValue +
            serviceChargeAmount
          );
        }
      } else {
        return null;
      }
    } else {
      if (_quotation.paymentInsurancePlan === "yearly") {
        if (loanPlan === "lta") {
          return (
            parseFloat(_quotation.ltaYearlyPremiumWithRefundDefault) +
            addOnValue +
            serviceChargeAmount
          );
        } else {
          return (
            parseFloat(_quotation.yearlyPremiumWithRefundDefault) +
            addOnValue +
            serviceChargeAmount
          );
        }
      } else if (_quotation.paymentInsurancePlan === "monthly") {
        if (loanPlan === "lta") {
          return (
            parseFloat(_quotation.ltaDeathAndTpdWithRefund) +
            addOnValue +
            serviceChargeAmount
          );
        } else {
          return (
            parseFloat(_quotation.deathAndTpdWithRefund) +
            addOnValue +
            serviceChargeAmount
          );
        }
      } else if (_quotation.paymentInsurancePlan === "half-yearly") {
        if (loanPlan === "lta") {
          return (
            parseFloat(_quotation.ltaHalfYearlyPremiumWithRefundDefault) +
            addOnValue +
            serviceChargeAmount
          );
        } else {
          return (
            parseFloat(_quotation.halfYearlyPremiumWithRefundDefault) +
            addOnValue +
            serviceChargeAmount
          );
        }
      } else if (_quotation.paymentInsurancePlan === "quarterly") {
        if (loanPlan === "lta") {
          return (
            parseFloat(_quotation.ltaQuarterlyPremiumWithRefundDefault) +
            addOnValue +
            serviceChargeAmount
          );
        } else {
          return (
            parseFloat(_quotation.quarterlyPremiumWithRefundDefault) +
            addOnValue +
            serviceChargeAmount
          );
        }
      } else if (_quotation.paymentInsurancePlan === "single") {
        if (loanPlan === "lta") {
          return (
            parseFloat(_quotation.ltaSinglePremiumWithRefundDefault) +
            addOnValue +
            serviceChargeAmount
          );
        } else {
          return (
            parseFloat(_quotation.singlePremiumWithRefundDefault) +
            addOnValue +
            serviceChargeAmount
          );
        }
      } else {
        return null;
      }
    }
  } else {
    return (
      parseFloat(_quotation.tpdManualReductionAmount) +
      addOnValue +
      serviceChargeAmount
    );
  }
};

const splitNameToFirstNamesAndSurname = (name) => {
  if (!name) {
    return { firstnames: "", lastname: "" };
  }
  const nameArr = name.split(" ").filter((item) => item != "");
  if (nameArr.length === 0) {
    return { firstnames: "", lastname: "" };
  }
  if (nameArr.length === 1) {
    return { firstnames: nameArr[0], lastname: "" };
  }
  if (nameArr.length === 2) {
    return { firstnames: nameArr[0], lastname: nameArr[1] };
  }
  if (nameArr.length > 2) {
    const lastname = nameArr[nameArr.length - 1];
    let firstnames = nameArr.slice(0, -1).join(" ");
    return { firstnames, lastname };
  }
};
// const splitNameToFirstnameOthernamesAndSurname = (name) => {
//   if (!name) {
//     return { firstname: "", othernames: "", lastname: "" };
//   }
//   const nameArr = name.split(" ").filter((item) => item != "");
//   if (nameArr.length === 0) {
//     return { firstname: "", othernames: "", lastname: "" };
//   }
//   if (nameArr.length === 1) {
//     return { firstname: nameArr[0], othernames: "", lastname: "" };
//   }
//   if (nameArr.length === 2) {
//     return { firstname: nameArr[0], othernames: "", lastname: nameArr[1] };
//   }
//   if (nameArr.length > 2) {
//     const firstname = nameArr[0];
//     const lastname = nameArr[nameArr.length - 1];
//     let othernames = nameArr.slice(1, -1).join(" ");
//     return { firstname, othernames, lastname };
//   }
// };
const getMipsMembershipBlock = (quotation) => {
  const startDate = new Moment(
    quotation.quotationFormData.intended_commencement_date
  ).format("YYYY-MM-DD");
  const endDate = new Moment(
    quotation.quotationFormData.intended_commencement_date
  )
    .add(parseInt(quotation.quotationFormData.term_number, 10), "years")
    .format("YYYY-MM-DD");

  if (quotation.quotationFormData.payment_insurance_plan === "yearly") {
    return {
      interval: 12,
      start_date: startDate,
      frequency: "month",
      end_date: endDate,
      day_to_process: 5,
      membership_amount:
        getTotalPremiumWithAddOnValueAndServiceCharge(quotation),
    };
  } else if (quotation.quotationFormData.payment_insurance_plan === "monthly") {
    return {
      interval: 1,
      start_date: startDate,
      frequency: "month",
      end_date: endDate,
      day_to_process: 5,
      membership_amount:
        getTotalPremiumWithAddOnValueAndServiceCharge(quotation),
    };
    // return quotation.monthlyPremiumDefault;
  } else if (
    quotation.quotationFormData.payment_insurance_plan === "half-yearly"
  ) {
    return {
      interval: 6,
      start_date: startDate,
      frequency: "month",
      end_date: endDate,
      day_to_process: 5,
      membership_amount:
        getTotalPremiumWithAddOnValueAndServiceCharge(quotation),
    };
  } else if (
    quotation.quotationFormData.payment_insurance_plan === "quarterly"
  ) {
    return {
      interval: 3,
      start_date: startDate,
      frequency: "month",
      end_date: endDate,
      day_to_process: 5,
      membership_amount:
        getTotalPremiumWithAddOnValueAndServiceCharge(quotation),
    };
  } else if (quotation.quotationFormData.payment_insurance_plan === "single") {
    return {
      interval: 1,
      start_date: startDate,
      frequency: "month",
      end_date: endDate,
      day_to_process: 5,
      membership_amount:
        getTotalPremiumWithAddOnValueAndServiceCharge(quotation),
    };
  } else {
    return null;
  }
};

const updateObjectInArray = (data, conditionKey, conditionValue, newObject) => {
  // debugger;
  const objIndex = data.findIndex(
    (item) => item[conditionKey] === conditionValue
  );
  if (objIndex != -1) {
    return [...data.slice(0, objIndex), newObject, ...data.slice(objIndex + 1)];
  }
  return data;
};

// Hide console log on prod
function log(message) {
  if (process.env.NODE_ENV === "development") {
    console.log(message);
  }
}

// Remove trailing zeros
const removeTrailingZeros = (number) => {
  // Check if number is undefined and return null
  if (!number) {
    return null;
  }

  // Convert number to string
  let numberString = number.toString();
  // Remove trailing zeros using regular expression
  numberString = numberString.replace(/\.?0+$/, "");
  // Convert the modified string back to a number
  const result = parseFloat(numberString);

  return result;
};

/**
 * Convert file to base64
 *
 * @param  File  file
 * @return  string
 */
const convertFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.onerror = () => {
      reject(null);
    };
  });
};

/**
 * Get base 64 and file type
 *
 * @param  string  rawDate
 * @return  string
 */
const getBase64AndFileType = async (file) => {
  if (!file) {
    return null;
  }

  const base64Url = await convertFileToBase64(file);
  // console.log("🚀 ~ getBase64AndFileType ~ values:", values);
  return base64Url;
};

export {
  isUnderwritingReferred,
  updateObjectInArray,
  splitNameToFirstNamesAndSurname,
  getMipsMembershipBlock,
  saveUserDataInLocalStorage,
  getUserDataFromLocalStorage,
  clearLocalStorageData,
  setOnboardingData,
  getOnboardingData,
  setOTPData,
  getOTPData,
  isValidNic,
  calculateAgeNextBirthday,
  isValidAgeNextBirthdayWithTerm,
  getTotalPremium,
  getTotalPremiumWithAddOnValue,
  getCountries,
  getAutoPassList,
  getClavicleCollarboneHamstringToeFingerList,
  getTotalPremiumWithAddOnValueAndServiceCharge,
  getShoulderList,
  getKneeList,
  getHipList,
  getBackpainList,
  getPolicyClassName,
  getBackpainDontReferToIlaList,
  getBackPainReferToIlaList,
  getMentalHealthList,
  processUnderwritingData,
  getOccupations,
  getLives,
  getSalaryRanges,
  getEducationalQualifications,
  canModifyQuotation,
  getDate_ddmmyyyy,
  Buffer,
  axios,
  log,
  removeTrailingZeros,
  getBase64AndFileType,
};
