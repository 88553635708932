import { useLocation, Navigate, Outlet } from "react-router-dom";
import { getUserDataFromLocalStorage } from "../../core/utils";

function RequireAuth() {
  const userData = getUserDataFromLocalStorage();
  const location = useLocation();
  const isAuthenticated = userData && userData.data;
  // log("Is Authenticated----", isAuthenticated);

  // Check if user is connected
  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <Outlet />;
}

export default RequireAuth;
