import React from "react";

const UnderwritingSpecialist = ({ state, setState, number }) => {
  return (
    <div>
      <div className="mx-4">
        <div className="row my-3">
          <div className="col-sm-12 mb-2">
            <h6 className="fw-bold">
              {number}. Have you in the past 5 yrs consulted a medical
              specialist other than a general practitioner for symptoms that
              resulted in medical treatment or follow-up? Medical specialists
              include: psychologist, physiotherapist, chiropractor
            </h6>{" "}
          </div>
          <div className="col-sm-12 text-danger mb-2">
            <em>
              (Excluding routine annual medicals that found nothing abnormal or
              consultations with gynecologist for pregnancy)
            </em>
          </div>
          <div className="col-sm-12 mb-2">
            <div className="row mx-2">
              <div
                className="col-sm-2 form-check"
                onClick={() => {
                  setState({ ...state, consultedSpecialistInFiveYears: true });
                }}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  checked={state.consultedSpecialistInFiveYears}
                ></input>
                <label className="form-check-label" htmlFor="refund1">
                  Yes
                </label>
              </div>
              <div
                className="col-sm-2 form-check"
                onClick={() => {
                  setState({ ...state, consultedSpecialistInFiveYears: false });
                }}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  checked={state.consultedSpecialistInFiveYears == false}
                ></input>
                <label className="form-check-label" htmlFor="refund2">
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnderwritingSpecialist;
