/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/scope */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import SweetAlert2 from "react-sweetalert2";
import { useParams, useNavigate } from "react-router-dom";
import "./QuotationView.css";
import currencyFormatter from "currency-formatter";
import Navbar from "../core/components/NavBar";
import UserContext from "../core/UserContext";
import Accordion from "react-bootstrap/Accordion";
import Moment from "moment";
import ReactSwitch from "react-switch";
import PaymentPlan from "../core/components/payment-plan/PaymentPlan";
// Axios
import {
  axios,
  canModifyQuotation,
  processUnderwritingData,
  getTotalPremium,
  getUserDataFromLocalStorage,
  getPolicyClassName,
} from "../core/utils";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

export const getLives = (qtn) => {
  if (qtn?.userId) {
    if (qtn?.joinLoan) {
      return qtn?.user.name + " | " + qtn?.userJoint.name;
    } else {
      return qtn?.user.name;
    }
  } else {
    if (qtn?.joinLoan) {
      return (
        qtn?.quotationFormData.main_user_name +
        " | " +
        qtn?.quotationFormData.joint_user_name
      );
    } else {
      return qtn?.quotationFormData.name;
    }
  }
};

const QuotationView = () => {
  const [quotation, setQuotation] = useState();
  const [swalProps, setSwalProps] = useState({});
  const [authorized, setAuthorized] = useState();
  // const [searchParams, setSearchParams] = useSearchParams();
  const [sendingEmail, setSendingEmail] = useState(false);
  const [forwaringQuotation, setForwardingQuotation] = useState(false);
  const [updatingQuotationStatus, setUpdatingQuotationStatus] = useState(false);
  const [quotationId, setQuotationId] = useState();
  const [quotations, setQuotations] = useState([]);
  const [existingUserData, setExistingUserData] = useState();
  const [refund, setRefund] = useState(false);
  const [loading, setLoading] = useState(false);
  const [policyClassName, setPolicyClassName] = useState("");
  const [quotationLives, setQuotationLives] = useState("");
  const [loanCoverRefund, setLoanCoverRefund] = useState(false);
  const [loanProtectRefund, setLoanProtectRefund] = useState(false);
  const [underwritingForBothDone, setUnderwritingForBothDone] = useState(false);
  const [downloadQuotation, setDownloadQuotation] = useState(false);
  const [state, setState] = useState({
    payment_insurance_plan: "",
    loan_plan: "",
    hasChosenLoanPlan: false,
  });
  const [error, setError] = useState();
  const params = useParams();
  const { userData, setUserData } = useContext(UserContext);
  const userDataStorage = getUserDataFromLocalStorage();
  const axiosPrivate = useAxiosPrivate(userDataStorage);
  const navigate = useNavigate();
  const [userConfirmationState, setUserConfirmationState] = useState({
    userId: null,
    userJointId: null,
  });
  // const downloadableAreaRef = useRef();
  // const linkRef = useRef();

  const checkUnderwriting = async (qtn) => {
    const getUnderwritingUrl =
      process.env.REACT_APP_API_URL + `/underwriting/${params.id}`;
    const currentUnderwritingDetails = await axiosPrivate.get(
      getUnderwritingUrl
    );
    // // debugger;

    if (currentUnderwritingDetails.data.success) {
      if (!qtn?.quotationFormData?.join_loan) {
        const fieldsFromGET = JSON.parse(
          currentUnderwritingDetails.data.data.underwritingData
        ).data.map((item) => {
          return { key: item.key, response: item.response };
        });
        let fieldsAsObject = {};
        fieldsFromGET.forEach((field) => {
          fieldsAsObject[field.key] = field.response;
        });

        const processedJointData = processUnderwritingData(fieldsAsObject);
        if (
          (processedJointData
            .map((item) => item.status)
            .includes("Refer to ILA") ||
            processedJointData
              .map((item) => item.status)
              .includes("Refer TPD to ILA") ||
            qtn?.quotationFormData?.initial_sum_assured > 8000000) &&
          !qtn?.underwritingManuallyAccepted
        ) {
          navigate("/application-in-review");
        }
      } else if (qtn?.quotationFormData.join_loan) {
        const fieldsFromGETMain = JSON.parse(
          currentUnderwritingDetails.data.data.underwritingData
        ).data.map((item) => {
          return { key: item.key, response: item.response };
        });
        let fieldsAsObjectMain = {};
        fieldsFromGETMain.forEach((field) => {
          fieldsAsObjectMain[field.key] = field.response;
        });

        const processedJointDataMain =
          processUnderwritingData(fieldsAsObjectMain);

        // Joint
        const fieldsFromGET = JSON.parse(
          currentUnderwritingDetails.data.data.underwritingJointData
        ).data.map((item) => {
          return { key: item.key, response: item.response };
        });
        let fieldsAsObject = {};
        fieldsFromGET.forEach((field) => {
          fieldsAsObject[field.key] = field.response;
        });

        const processedJointData = processUnderwritingData(fieldsAsObject);

        if (
          ((processedJointDataMain
            .map((item) => item.status)
            .includes("Refer to ILA") ||
            processedJointDataMain
              .map((item) => item.status)
              .includes("Refer TPD to ILA") ||
            qtn?.quotationFormData?.initial_sum_assured > 8000000) &&
            !qtn?.underwritingManuallyAccepted) ||
          ((processedJointData
            .map((item) => item.status)
            .includes("Refer to ILA") ||
            processedJointData
              .map((item) => item.status)
              .includes("Refer TPD to ILA") ||
            qtn?.quotationFormData?.initial_sum_assured > 8000000) &&
            !qtn?.underwritingManuallyAccepted)
        ) {
          navigate("/application-in-review");
        }
      }
    }
  };

  const getQuotationLives = (qtn) => {
    // console.log(qtn);
    if (qtn?.userId) {
      if (qtn?.joinLoan) {
        return qtn?.user?.name + " | " + qtn?.userJoint?.name;
      } else {
        return qtn?.user?.name;
      }
    } else {
      if (qtn?.joinLoan) {
        return (
          qtn?.quotationFormData?.main_user_name +
          " | " +
          qtn?.quotationFormData?.joint_user_name
        );
      } else {
        return qtn?.quotationFormData?.name;
      }
    }
  };

  const getDeclarationsStatusForUser = async (userId) => {
    const url = process.env.REACT_APP_API_URL + `/user/declarations/${userId}`;
    // debugger;

    try {
      const response = await axiosPrivate.get(url);

      const declarationsForQuotation = response.data.data.filter(
        (item) => item.quotationId === quotationId
      );

      // debugger;
      if (declarationsForQuotation.length === 0) {
        return false;
      }

      for (let i = 0; i < declarationsForQuotation.length; i++) {
        const item = declarationsForQuotation[i];
        if (item.consent) {
          return false;
        }
      }

      return true;
    } catch (err) {
      // console.log(err);
      return false;
    }
  };
  const checkKyc = async (qtn) => {
    if (qtn?.quotationFormData?.join_loan) {
      const userDeclarationStatus = await getDeclarationsStatusForUser(
        qtn?.user?.id
      );

      const userJointDeclarationStatus = await getDeclarationsStatusForUser(
        qtn?.userJoint?.id
      );

      if (
        (!qtn?.user?.kycVerification || !userDeclarationStatus) &&
        !(
          qtn?.user?.declarationsManuallyVerified &&
          qtn?.user?.shuftiManuallyVerified
        )
      ) {
        await updateQuotationStatus({ status: "kyc" });
        navigate(`/client-kyc/${qtn?.user?.id}/${qtn?.id}/main`);
      } else if (
        (!qtn?.userJoint?.kycVerification || !userJointDeclarationStatus) &&
        !(
          qtn?.userJoint?.declarationsManuallyVerified &&
          qtn?.userJoint?.shuftiManuallyVerified
        )
      ) {
        await updateQuotationStatus({ status: "kyc" });
        navigate(`/client-kyc/${qtn?.userJoint?.id}/${qtn?.id}/joint`);
      } else {
        await updateQuotationStatus({ status: "review" });
        navigate(`/review/${qtn?.id}`);
      }
      //TODO: PEP check for joint loan
    } else if (!qtn?.quotationFormData?.join_loan) {
      const userDeclarationStatus = await getDeclarationsStatusForUser(
        qtn?.user?.id
      );

      if (
        (!qtn?.user?.kycVerification || !userDeclarationStatus) &&
        !(
          qtn?.user?.declarationsManuallyVerified &&
          qtn?.user?.shuftiManuallyVerified
        )
      ) {
        await updateQuotationStatus({ status: "kyc" });
        navigate(`/client-kyc/${qtn?.user?.id}/${qtn?.id}/main`);
      } else {
        await updateQuotationStatus({ status: "review" });
        navigate(`/review/${qtn?.id}`);
      }
    } else {
      await updateQuotationStatus({ status: "review" });
      navigate(`/review/${qtn?.id}`);
    }
  };

  useEffect(() => {
    setQuotationId(params.id);

    getQuotationDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log({ state });
  }, [state]);

  const updateWithRefund = async (payload) => {
    const url =
      process.env.REACT_APP_API_URL +
      `/quotation/worker/with-refund/update/${params.id}`;

    try {
      // payload = { ...payload, userId: userDetails.data.id };
      const { data } = await axiosPrivate.patch(url, payload);

      if (!data.success) {
        // console.log(
        //   data.message
        //     ? data.message
        //     : "Quotation update was unsuccessful, please try again"
        // );
      } else {
        setQuotation({ ...quotation, with_refund: payload.with_refund });
      }
    } catch (error) {
      // console.log(error);
    }
  };

  // /update/quotation/{quotationShadowId}/{quotationId}

  const setThisShadowQuotation = async (shadowQuotationId) => {
    const url =
      process.env.REACT_APP_API_URL +
      `/quotation-shadow/update/quotation/${shadowQuotationId}/${params.id}`;

    try {
      const { data } = await axiosPrivate.get(url);

      if (!data.success) {
        // console.log(
        //   data.message
        //     ? data.message
        //     : "Quotation update was unsuccessful, please try again"
        // );
      } else {
        getQuotationDetails(true);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const updatePaymentFrequency = async (payload) => {
    const url =
      process.env.REACT_APP_API_URL +
      `/quotation/worker/payment-insurance-plan/update/${params.id}`;

    try {
      // payload = { ...payload, userId: userDetails.data.id };
      const { data } = await axiosPrivate.patch(url, payload);

      if (!data.success) {
        // console.log(
        //   data.message
        //     ? data.message
        //     : "Quotation update was unsuccessful, please try again"
        // );
      } else {
        setQuotation({
          ...quotation,
          payment_insurance_plan: payload.payment_insurance_plan,
        });
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const updateQuotationStatus = async (payload) => {
    setUpdatingQuotationStatus(true);
    const url =
      process.env.REACT_APP_API_URL +
      `/quotation/worker/status-update/${params.id}`;

    try {
      payload = { ...payload, userId: quotation?.user?.id };
      const { data } = await axiosPrivate.patch(url, payload);

      setUpdatingQuotationStatus(false);

      if (!data.success) {
        // console.log(
        //   data.message
        //     ? data.message
        //     : "Quotation update was unsuccessful, please try again"
        // );
      } else {
        if (payload.status === "review") {
          navigate(`/review/${quotationId}`);
        } else {
          navigate(`/underwriting/${quotationId}/main-life`);
        }
      }
    } catch (error) {
      setUpdatingQuotationStatus(false);
      // console.log(error);
    }
  };
  const getUnderwritingDataValue = async (life, qtnId) => {
    const getUnderwritingUrl =
      process.env.REACT_APP_API_URL + `/underwriting/${qtnId}`;

    const currentUnderwritingDetails = await axiosPrivate.get(
      getUnderwritingUrl
    );
    // // debugger;

    if (currentUnderwritingDetails.data.success) {
      if (life === "main-life") {
        const fieldsFromGET = JSON.parse(
          currentUnderwritingDetails.data.data.underwritingData
        ).data.map((item) => {
          return { key: item.key, response: item.response };
        });
        let fieldsAsObject = {};
        fieldsFromGET.forEach((field) => {
          fieldsAsObject[field.key] = field.response;
        });

        return fieldsAsObject;
      } else if (life === "joint-life") {
        const fieldsFromGET = JSON.parse(
          currentUnderwritingDetails.data.data.underwritingJointData
        ).data.map((item) => {
          return { key: item.key, response: item.response };
        });
        let fieldsAsObject = {};
        fieldsFromGET.forEach((field) => {
          fieldsAsObject[field.key] = field.response;
        });
        return fieldsAsObject;
      }
    } else {
      return {};
    }
  };
  const getQuotationDetails = async (fromShadowUpdate = false) => {
    const id = params.id;
    const url = process.env.REACT_APP_API_URL + `/quotation/${id}`;
    const userData = getUserDataFromLocalStorage();
    const userId = userData?.data?.id;

    try {
      setLoading(true);
      const response = await axiosPrivate.get(url);
      if (response.data.success === true) {
        setLoading(false);
        const _quotation = response.data.data;
        // console.log(_quotation);

        _quotation.quotationFormData = JSON.parse(_quotation.quotationFormData);

        // debugger;
        const _loanPlan =
          _quotation.isLtaResult === false && _quotation.isDtaResult === true
            ? "dta"
            : _quotation.isLtaResult === true &&
              _quotation.isDtaResult === false
            ? "lta"
            : "dta-lta";
        const _paymentInsurancePlan = _quotation?.paymentInsurancePlan;
        const _withRefund = _quotation?.withRefund;

        if (
          _quotation?.quotationFormData?.loan_plan === "lta" ||
          _quotation?.quotationFormData?.loan_plan === "dta"
        ) {
          setRefund(_withRefund);
        } else if (_quotation?.quotationFormData?.loan_plan === "dta-lta") {
          if (
            _quotation.isDtaResult === true &&
            _quotation.isLtaResult === false
          ) {
            setLoanCoverRefund(_withRefund);
          }
          if (
            _quotation.isDtaResult === false &&
            _quotation.isLtaResult === true
          ) {
            setLoanProtectRefund(_withRefund);
          }
        }

        const totalPremium = getTotalPremium(_quotation);
        _quotation.totalPremium = totalPremium;
        if (!canModifyQuotation(_quotation, userId)) {
          setAuthorized(false);
          return;
        } else {
          setAuthorized(true);
        }

        // setState({
        //   ...state,
        //   payment_insurance_plan:
        //     _quotation.quotationFormData.payment_insurance_plan,
        //   loan_plan:
        //     _quotation.quotationFormData?.loan_plan ?? "dta",
        //   hasChosenLoanPlan: true
        // });

        setQuotation(_quotation);
        setQuotationLives(getQuotationLives(_quotation));

        setPolicyClassName(getPolicyClassName(_quotation, _loanPlan));

        setState({
          ...state,
          payment_insurance_plan: _paymentInsurancePlan,
          loan_plan: _loanPlan,
          hasChosenLoanPlan: _paymentInsurancePlan ? true : false,
        });

        setUserConfirmationState({
          ...userConfirmationState,
          userJointId: _quotation.userJointId ? _quotation.userJointId : null,
        });
        setQuotations(_quotation.QuotationShadowTables);

        checkIfLivesHaveSuccessfullyDoneUnderwriting(_quotation);
        if (fromShadowUpdate) {
          alert("Shadow quotation is now main quotation");
        }
      }
      // console.log(response);
    } catch (error) {
      console.log({ error });
    }
  };

  const getQuotationLinkAndDownload = async (_quotation) => {
    const url =
      process.env.REACT_APP_API_URL +
      `/quotation/user/generate-pdf/${_quotation.id}`;

    try {
      setDownloadQuotation(true);
      const response = await axiosPrivate.get(url);

      if (response.data.success === true) {
        await new Promise((r) => setTimeout(r, 3000));
        const fileResponse = await axios({
          responseType: "blob",
          url: response.data.data.pdfLink,
          method: "GET",
        });
        setDownloadQuotation(false);
        const url = window.URL.createObjectURL(fileResponse.data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "quotation.pdf");
        link.click();
      } else {
        setDownloadQuotation(false);
      }

      // console.log(response);
    } catch (error) {
      setDownloadQuotation(false);
      // console.log(error);
    }
  };

  const forwardQuotationToCustomer = async (_quotation) => {
    let url;
    if (!_quotation.quotationFormData.join_loan) {
      url =
        process.env.REACT_APP_API_URL +
        `/quotation/worker/forward/single-loan/${_quotation.id}`;
    } else {
      url =
        process.env.REACT_APP_API_URL +
        `/quotation/worker/forward/join-loan/${_quotation.id}`;
    }
    const payload = !_quotation?.quotationFormData?.join_loan
      ? { userId: null }
      : userConfirmationState;

    try {
      setForwardingQuotation(true);
      const response = await axiosPrivate.post(url, payload);

      setForwardingQuotation(false);
      if (response.data.success === true) {
        alert("client details have been successfully validated");
        navigate(0);
      }

      // console.log(response);
    } catch (error) {
      if (error.response.status === 400) {
        setError(error.response.data.message);
      } else {
        try {
          setExistingUserData({
            errorMessage: error.response.data.message,
            userId: error.response.data.data.id,
            name: error.response.data.data.name,
            email: error.response.data.data.email,
            nic: error.response.data.data.nic,
            isMainLife: error.response.data.data.isMainLife,
          });
          // console.log(error.response.data);

          if (
            error.response.data.data.isMainLife ||
            !_quotation.quotationFormData.join_loan
          ) {
            setUserConfirmationState({
              ...userConfirmationState,
              userId: error.response.data.data.id,
            });
          } else {
            setUserConfirmationState({
              ...userConfirmationState,
              userJointId: error.response.data.data.id,
            });
          }
          setForwardingQuotation(false);
          // console.log(error);
        } catch (err) {
          setForwardingQuotation(false);
        }
      }
    }
  };
  const confirmExistingUser = async () => {
    let url;
    if (!quotation.quotationFormData.join_loan) {
      url =
        process.env.REACT_APP_API_URL +
        `/quotation/worker/forward/single-loan/${quotation.id}`;
    } else {
      url =
        process.env.REACT_APP_API_URL +
        `/quotation/worker/forward/join-loan/${quotation.id}`;
    }

    try {
      setForwardingQuotation(true);
      const response = await axiosPrivate.post(url, userConfirmationState);

      setForwardingQuotation(false);
      if (response.data.success === true) {
        alert("client details have been successfully validated");
        navigate(0);
      }

      // console.log(response);
    } catch (error) {
      if (error.response.status === 400) {
        setError(error.response.data.message);
      } else {
        setExistingUserData({
          errorMessage: error.response.data.message,
          userId: error.response.data.data.id,
          name: error.response.data.data.name,
          email: error.response.data.data.email,
          nic: error.response.data.data.nic,
          isMainLife: error.response.data.data.isMainLife,
        });

        if (error.response.data.data.isMainLife) {
          setUserConfirmationState({
            ...userConfirmationState,
            userId: error.response.data.data.id,
          });
        } else {
          setUserConfirmationState({
            ...userConfirmationState,
            userJointId: error.response.data.data.id,
          });
        }

        setForwardingQuotation(false);
        // console.log(error);
      }
    }
  };
  const sendQuotationAsEmail = async (_quotation) => {
    let userId = _quotation?.user?.id ? _quotation.user.id : null;
    const url =
      process.env.REACT_APP_API_URL +
      `/quotation/worker/user/email/generate-pdf/${_quotation.id}/${userId}`;

    try {
      setSendingEmail(true);
      const response = await axiosPrivate.get(url);
      setSendingEmail(false);
      if (response.data.success === true) {
        getQuotationDetails();
        alert("quotation has been sent to customer");
      }

      // console.log(response);
    } catch (error) {
      setSendingEmail(false);
      // console.log(error);
    }
  };

  const checkIfLivesHaveSuccessfullyDoneUnderwriting = async (qtn) => {
    let mainLifeHasPerformedUnderwriting = false;
    let jointLifeHasPerformedUnderwriting = false;

    const underwritingState = await getUnderwritingDataValue(
      "main-life",
      qtn?.id
    );
    const processedData = processUnderwritingData(underwritingState);
    if (
      !(
        (processedData.map((item) => item.status).includes("Refer to ILA") ||
          processedData
            .map((item) => item.status)
            .includes("Refer TPD to ILA") ||
          quotation?.quotationFormData?.initial_sum_assured > 8000000) &&
        !quotation.underwritingManuallyAccepted
      )
    ) {
      mainLifeHasPerformedUnderwriting = true;
    }

    if (qtn?.quotationFormData.join_loan) {
      const underwritingJointState = await getUnderwritingDataValue(
        "joint-life",
        qtn?.id
      );
      const processedJointData = processUnderwritingData(
        underwritingJointState
      );
      if (
        !(
          (processedJointData
            .map((item) => item.status)
            .includes("Refer to ILA") ||
            processedJointData
              .map((item) => item.status)
              .includes("Refer TPD to ILA") ||
            quotation?.quotationFormData?.initial_sum_assured > 8000000) &&
          !quotation.underwritingManuallyAccepted
        )
      ) {
        jointLifeHasPerformedUnderwriting = true;
      }
      if (
        jointLifeHasPerformedUnderwriting &&
        mainLifeHasPerformedUnderwriting
      ) {
        setUnderwritingForBothDone(true);
      } else {
        setUnderwritingForBothDone(false);
      }
    } else {
      if (mainLifeHasPerformedUnderwriting) {
        setUnderwritingForBothDone(true);
      } else {
        setUnderwritingForBothDone(false);
      }
    }
  };

  return (
    <>
      <Navbar
        name={
          userData
            ? `${userData?.data?.firstname} ${userData?.data?.lastname}`
            : ""
        }
      />
      {authorized && (
        <div className="container">
          <h4 className="mt-4" style={{ color: "#707070" }}>
            {policyClassName}
          </h4>
          <div className="alert alert-info">
            The rates applied and premiums have been calculated based on the
            details provided. Provision of incorrect information, whether
            intentionally or unintentionally will have a direct bearing on the
            premium and the underwriting decision. Failure to disclose any facts
            may lead to the insurance contract being null and void and any
            future claim may as well be voided
          </div>
          {error && (
            <div className="alert alert-danger">
              {error}

              <button
                style={{ marginLeft: 10 }}
                onClick={() => {
                  navigate(`/quotations/${quotationId}/edit`);
                }}
                className="btn btn-danger"
              >
                Edit Quotation
              </button>
            </div>
          )}

          {existingUserData && (
            <div className="alert alert-info">
              {existingUserData.errorMessage}

              <ul>
                <li>
                  <b>Name: </b> {existingUserData.name}
                </li>
                <li>
                  <b>Email: </b>
                  {existingUserData.email}
                </li>
                <li>
                  <b>NIC Number: </b>
                  {existingUserData.nic}
                </li>
              </ul>
              <button
                onClick={() => {
                  confirmExistingUser();
                }}
                className="btn btn-success text-white me-3"
              >
                Yes
              </button>
              <button
                onClick={() => {
                  navigate(`/quotations/${quotationId}/edit`);
                }}
                className="btn btn-info text-white"
              >
                Change
              </button>
            </div>
          )}

          <div className="d-flex justify-content-between align-items-center">
            <a
              className="ila-grey-link"
              href={`${process.env.REACT_APP_BASENAME}/quotations`}
            >
              <span className="fa fa-chevron-left me-2 my-4"></span>
              Back to my Quotations
            </a>

            {quotation && (
              <div>
                {quotation.isForwardedToCustomer && (
                  <>
                    {/* {!quotation.quotationFormData.join_loan &&
                    !quotation.user.kycVerification && (
                      <a
                        className="btn ila-primary-btn"
                        type="button"
                        href={`${process.env.REACT_APP_BASENAME}/client-kyc/${quotation.user.id}/${quotation.id}/main`}
                      >
                        {updatingQuotationStatus && (
                          <span
                            className="spinner-border spinner-border-sm me-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                        Kyc for main life
                      </a>
                    )} */}
                    {/* {quotation.quotationFormData.join_loan &&
                  !quotation.user.kycVerification ? (
                    <a
                      className="btn ila-primary-btn"
                      type="button"
                      href={`${process.env.REACT_APP_BASENAME}/client-kyc/${quotation.user.id}/${quotation.id}/main`}
                    >
                      {updatingQuotationStatus && (
                        <span
                          className="spinner-border spinner-border-sm me-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                      Kyc for main life
                    </a>
                  ) : (
                    quotation.userJoint &&
                    !quotation.userJoint.kycVerification && (
                      <a
                        className="btn ila-primary-btn"
                        type="button"
                        href={`${process.env.REACT_APP_BASENAME}/client-kyc/${quotation.userJoint.id}/${quotation.id}/joint`}
                      >
                        {updatingQuotationStatus && (
                          <span
                            className="spinner-border spinner-border-sm me-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                        Kyc for joint life
                      </a>
                    )
                  )} */}
                  </>
                )}
                {/* {quotation.isForwardedToCustomer &&
                !quotation.quotationFormData.join_loan &&
                quotation.user &&
                quotation.user.kycVerification &&
                !underwritingForBothDone && (
                  <a
                    className="btn ila-primary-btn"
                    type="button"
                    onClick={() => {
                      updateQuotationStatus({ status: "underwriting" });
                    }}
                  >
                    {updatingQuotationStatus && (
                      <span
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                    Perform Underwriting 1
                  </a>
                )} */}
                {quotation.isForwardedToCustomer &&
                  // quotation.quotationFormData.join_loan &&
                  // quotation.user &&
                  // quotation.user.kycVerification &&
                  // quotation.userJoint &&
                  // quotation.userJoint.kycVerification &&
                  !underwritingForBothDone && (
                    <a
                      className="btn ila-primary-btn"
                      type="button"
                      onClick={() => {
                        if (
                          state.hasChosenLoanPlan &&
                          state.loan_plan != "dta-lta"
                        ) {
                          updateQuotationStatus({ status: "underwriting" });
                        } else {
                          alert("Please select a loan plan");
                        }
                      }}
                    >
                      {updatingQuotationStatus && (
                        <span
                          className="spinner-border spinner-border-sm me-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                      Perform Underwriting
                    </a>
                  )}

                {quotation.isForwardedToCustomer &&
                  // ((!quotation?.quotationFormData?.join_loan &&
                  // quotation?.user &&
                  // quotation?.user?.kycVerification) ||
                  // (quotation?.user?.declarationsManuallyVerified &&
                  //   quotation?.user?.shuftiManuallyVerified)) &&
                  (underwritingForBothDone ||
                    quotation?.underwritingManuallyAccepted) && (
                    <a
                      className="btn ila-primary-btn"
                      type="button"
                      onClick={() => {
                        if (state.hasChosenLoanPlan) {
                          updateQuotationStatus({ status: "review" });
                        } else {
                          alert("Please select a loan plan");
                        }
                      }}
                    >
                      {updatingQuotationStatus && (
                        <span
                          className="spinner-border spinner-border-sm me-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                      Accept and Proceed to Purchase
                    </a>
                  )}

                {/* {quotation.isForwardedToCustomer &&
                ((quotation?.quotationFormData?.join_loan &&
                  quotation?.user &&
                  quotation?.user?.kycVerification) ||
                  (quotation?.user?.declarationsManuallyVerified &&
                    quotation?.user?.shuftiManuallyVerified)) &&
                ((quotation?.quotationFormData?.join_loan &&
                  quotation?.userJoint &&
                  quotation?.userJoint?.kycVerification) ||
                  (quotation?.userJoint?.declarationsManuallyVerified &&
                    quotation?.userJoint?.shuftiManuallyVerified)) &&
                (underwritingForBothDone ||
                  quotation?.underwritingManuallyAccepted) && (
                  <a
                    className="btn ila-primary-btn"
                    type="button"
                    onClick={() => {
                      updateQuotationStatus({ status: "review" });
                    }}
                  >
                    {updatingQuotationStatus && (
                      <span
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                    Accept and Proceed to Purchase
                  </a>
                )} */}
                {/* {quotation.isForwardedToCustomer &&
                quotation.quotationFormData.join_loan &&
                quotation.userJoint &&
                quotation.user &&
                quotation.userJoint.kycVerification &&
                quotation.user.kycVerification &&
                !underwritingForBothDone && (
                  <a
                    className="btn ila-primary-btn"
                    type="button"
                    onClick={() => {
                      updateQuotationStatus({ status: "underwriting" });
                    }}
                  >
                    {updatingQuotationStatus && (
                      <span
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                    Perform Underwriting 3
                  </a>
                )} */}
              </div>
            )}
          </div>

          {quotation && (
            <div>
              <div className="d-flex justify-content-between mt-3 mb-3">
                <div>
                  <h5 className="mb-2" style={{ color: "#707070" }}>
                    {quotation.permanentId
                      ? `Policy Number: ${quotation.permanentId}`
                      : `Temporary ID: ${quotation.temporaryId}`}
                  </h5>
                  <h5 className="mb-2" style={{ color: "#707070" }}>
                    Lives: {quotationLives}
                  </h5>
                  <h5 className="mb-2" style={{ color: "#707070" }}>
                    Sum Assured:{" "}
                    {currencyFormatter.format(
                      parseFloat(quotation.initialSumAssured),
                      { symbol: "Rs " }
                    )}
                  </h5>
                  <h5 className="mb-2" style={{ color: "#707070" }}>
                    Term: {quotation.termNumber} {quotation.termOption}
                  </h5>

                  <h5 style={{ color: "#707070" }}>
                    <span className="mb-2">Creation Date: </span>
                    {Moment(quotation.createdAt).format("LL")}
                  </h5>
                  <h5 style={{ color: "#707070" }}>
                    <span className="mb-2">Expiry Date: </span>
                    {Moment(quotation.createdAt).add(15, "days").format("LL")}
                  </h5>
                </div>
                <div className="d-flex flex-column align-items-start">
                  <a
                    style={{
                      color: "#BEC663",
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                    className="btn btn-text"
                    href={`${process.env.REACT_APP_BASENAME}/quotations/${quotationId}/edit`}
                  >
                    <span className="fa fa-edit me-2"></span>Edit Quotation
                  </a>
                  <a
                    style={{
                      color: "#707070",
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                    className={`btn btn-text ${
                      !quotation.isForwardedToCustomer ? "disabled" : ""
                    }`}
                    onClick={() => {
                      if (state.hasChosenLoanPlan) {
                        getQuotationLinkAndDownload(quotation);
                      } else {
                        alert("Please select a loan plan");
                      }
                    }}
                  >
                    {downloadQuotation && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}{" "}
                    <span className="fa fa-file-pdf me-2"></span>Download as PDF
                  </a>
                  <a
                    style={{
                      color: "#707070",
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                    className="btn btn-text"
                    href={`${process.env.REACT_APP_BASENAME}/clients/${quotationId}`}
                  >
                    <span className="fa fa-user me-2"></span>View Contract
                    Partners
                  </a>
                  <a
                    style={{
                      color: "#707070",
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                    className={`btn btn-text ${
                      !quotation.isForwardedToCustomer ? "disabled" : ""
                    }`}
                    onClick={() => {
                      // debugger;
                      if (state.hasChosenLoanPlan) {
                        sendQuotationAsEmail(quotation);
                      } else {
                        alert("Please select a loan plan");
                      }
                    }}
                  >
                    {sendingEmail && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}{" "}
                    <span className="fa fa-envelope me-2"></span>Send a copy to
                    email
                  </a>
                  <a
                    style={{
                      color: "#707070",
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                    className={`btn btn-text ${
                      quotation && quotation.isForwardedToCustomer
                        ? "disabled"
                        : ""
                    }`}
                    onClick={() => {
                      // debugger;
                      if (quotation && !quotation.isForwardedToCustomer) {
                        forwardQuotationToCustomer(quotation);
                      }
                    }}
                  >
                    {forwaringQuotation && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}{" "}
                    <span className="fa fa-handshake me-2"></span>Validate
                    Customer
                  </a>
                </div>
              </div>
            </div>
          )}

          {/* New Layout */}
          {quotation?.quotationFormData?.loan_plan !== "dta-lta" ||
          !quotation?.quotationFormData?.loan_plan ? (
            <div className="">
              {quotation?.termNumber >= 11 && (
                <div className="mt-1">
                  <label className="d-md-flex align-items-middle">
                    <ReactSwitch
                      uncheckedIcon={false}
                      checkedIcon={false}
                      offColor="#fff"
                      onColor="#BEC663"
                      offHandleColor="#8E8E8E"
                      onHandleColor="#fff"
                      height={20}
                      width={40}
                      handleDiameter={13}
                      onChange={(val) => {
                        setRefund(val);
                        console.log(val);
                        updateWithRefund({ with_refund: val });
                      }}
                      checked={refund}
                    />
                    <span className="ms-2 ila-primary-text">Add Refund</span>
                  </label>
                  {refund && (
                    <>
                      {quotation?.termNumber < 15 && (
                        <div style={{ color: "#977428" }} className="d-block">
                          75% refund of premium on maturity
                        </div>
                      )}
                      {quotation?.termNumber >= 15 && (
                        <div style={{ color: "#977428" }} className="d-block">
                          100% refund of premium on maturity
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}

              {/* Loan Cover Plan */}
              {((quotation?.quotationFormData?.loan_plan &&
                quotation?.quotationFormData?.loan_plan === "dta") ||
                (!quotation?.quotationFormData?.loan_plan &&
                  quotation?.isLtaResult == false &&
                  quotation?.isDtaResult == true)) && (
                <div className="d-flex row mt-3 p-2 col-12 col-lg-11">
                  <div className="col-lg-4">
                    <PaymentPlan
                      refundAmount={quotation?.deathAndTpdWithRefund}
                      withRefund={refund}
                      updatePaymentInsuranceFn={updatePaymentFrequency}
                      state={state}
                      setState={setState}
                      isDtaResult={quotation?.isDtaResult}
                      isLtaResult={quotation?.isLtaResult}
                      quotationInsurancePlan={quotation?.paymentInsurancePlan}
                      paymentInsurancePlan="monthly"
                      loanPlan="dta"
                      policyClass={quotation?.quotationFormData?.loan_plan}
                      name="Monthly Premium"
                      amount={quotation?.deathAndTpd}
                    />
                  </div>
                  <div className="col-lg-4">
                    <PaymentPlan
                      refundAmount={
                        quotation?.quarterlyPremiumWithRefundDefault
                      }
                      withRefund={refund}
                      updatePaymentInsuranceFn={updatePaymentFrequency}
                      state={state}
                      setState={setState}
                      isDtaResult={quotation?.isDtaResult}
                      isLtaResult={quotation?.isLtaResult}
                      quotationInsurancePlan={quotation?.paymentInsurancePlan}
                      policyClass={quotation?.quotationFormData?.loan_plan}
                      paymentInsurancePlan="quarterly"
                      loanPlan="dta"
                      name="Quarterly Premium"
                      amount={quotation?.quarterlyPremiumDefault}
                    />
                  </div>
                  <div className="col-lg-4">
                    <PaymentPlan
                      refundAmount={
                        quotation?.halfYearlyPremiumWithRefundDefault
                      }
                      withRefund={refund}
                      updatePaymentInsuranceFn={updatePaymentFrequency}
                      state={state}
                      setState={setState}
                      isDtaResult={quotation?.isDtaResult}
                      isLtaResult={quotation?.isLtaResult}
                      quotationInsurancePlan={quotation?.paymentInsurancePlan}
                      policyClass={quotation?.quotationFormData?.loan_plan}
                      paymentInsurancePlan="half-yearly"
                      loanPlan="dta"
                      name="Half Yearly Premium"
                      amount={quotation?.halfYearlyPremiumDefault}
                    />
                  </div>
                  <div className="col-lg-4">
                    <PaymentPlan
                      refundAmount={quotation?.yearlyPremiumWithRefundDefault}
                      withRefund={refund}
                      updatePaymentInsuranceFn={updatePaymentFrequency}
                      state={state}
                      setState={setState}
                      isDtaResult={quotation?.isDtaResult}
                      isLtaResult={quotation?.isLtaResult}
                      quotationInsurancePlan={quotation?.paymentInsurancePlan}
                      policyClass={quotation?.quotationFormData?.loan_plan}
                      paymentInsurancePlan="yearly"
                      loanPlan="dta"
                      name="Yearly Premium"
                      amount={quotation?.yearlyPremiumDefault}
                    />
                  </div>
                  <div className="col-lg-8">
                    <PaymentPlan
                      refundAmount={quotation?.singlePremiumWithRefundDefault}
                      withRefund={refund}
                      updatePaymentInsuranceFn={updatePaymentFrequency}
                      state={state}
                      setState={setState}
                      isDtaResult={quotation?.isDtaResult}
                      isLtaResult={quotation?.isLtaResult}
                      quotationInsurancePlan={quotation?.paymentInsurancePlan}
                      policyClass={quotation?.quotationFormData?.loan_plan}
                      paymentInsurancePlan="single"
                      loanPlan="dta"
                      name="Single Premium"
                      amount={quotation?.singlePremiumDefault}
                    />
                  </div>
                </div>
              )}

              {/* Protect Plan */}
              {((quotation?.quotationFormData?.loan_plan &&
                quotation?.quotationFormData?.loan_plan === "lta") ||
                (!quotation?.quotationFormData?.loan_plan &&
                  quotation?.isLtaResult == true &&
                  quotation?.isDtaResult == false)) && (
                <div className="d-flex row mt-3 p-2 col-12 col-lg-11">
                  <div className="col-lg-4">
                    <PaymentPlan
                      refundAmount={quotation?.ltaDeathAndTpdWithRefund}
                      withRefund={refund}
                      updatePaymentInsuranceFn={updatePaymentFrequency}
                      state={state}
                      setState={setState}
                      isDtaResult={quotation?.isDtaResult}
                      isLtaResult={quotation?.isLtaResult}
                      quotationInsurancePlan={quotation?.paymentInsurancePlan}
                      paymentInsurancePlan="monthly"
                      loanPlan="lta"
                      policyClass={quotation?.quotationFormData?.loan_plan}
                      name="Monthly Premium"
                      amount={quotation?.ltaDeathAndTpd}
                    />
                  </div>
                  <div className="col-lg-4">
                    <PaymentPlan
                      refundAmount={
                        quotation?.ltaQuarterlyPremiumWithRefundDefault
                      }
                      withRefund={refund}
                      updatePaymentInsuranceFn={updatePaymentFrequency}
                      state={state}
                      setState={setState}
                      isDtaResult={quotation?.isDtaResult}
                      isLtaResult={quotation?.isLtaResult}
                      quotationInsurancePlan={quotation?.paymentInsurancePlan}
                      policyClass={quotation?.quotationFormData?.loan_plan}
                      paymentInsurancePlan="quarterly"
                      loanPlan="lta"
                      name="Quarterly Premium"
                      amount={quotation?.ltaQuarterlyPremiumDefault}
                    />
                  </div>
                  <div className="col-lg-4">
                    <PaymentPlan
                      refundAmount={
                        quotation?.ltaHalfYearlyPremiumWithRefundDefault
                      }
                      withRefund={refund}
                      updatePaymentInsuranceFn={updatePaymentFrequency}
                      state={state}
                      setState={setState}
                      isDtaResult={quotation?.isDtaResult}
                      isLtaResult={quotation?.isLtaResult}
                      quotationInsurancePlan={quotation?.paymentInsurancePlan}
                      policyClass={quotation?.quotationFormData?.loan_plan}
                      paymentInsurancePlan="half-yearly"
                      loanPlan="lta"
                      name="Half Yearly Premium"
                      amount={quotation?.ltaHalfYearlyPremiumDefault}
                    />
                  </div>
                  <div className="col-lg-4">
                    <PaymentPlan
                      refundAmount={
                        quotation?.ltaYearlyPremiumWithRefundDefault
                      }
                      withRefund={refund}
                      updatePaymentInsuranceFn={updatePaymentFrequency}
                      state={state}
                      setState={setState}
                      isDtaResult={quotation?.isDtaResult}
                      isLtaResult={quotation?.isLtaResult}
                      quotationInsurancePlan={quotation?.paymentInsurancePlan}
                      policyClass={quotation?.quotationFormData?.loan_plan}
                      paymentInsurancePlan="yearly"
                      loanPlan="lta"
                      name="Yearly Premium"
                      amount={quotation?.ltaYearlyPremiumDefault}
                    />
                  </div>
                  <div className="col-lg-8">
                    <PaymentPlan
                      refundAmount={
                        quotation?.ltaSinglePremiumWithRefundDefault
                      }
                      withRefund={refund}
                      updatePaymentInsuranceFn={updatePaymentFrequency}
                      state={state}
                      setState={setState}
                      isDtaResult={quotation?.isDtaResult}
                      isLtaResult={quotation?.isLtaResult}
                      quotationInsurancePlan={quotation?.paymentInsurancePlan}
                      policyClass={quotation?.quotationFormData?.loan_plan}
                      paymentInsurancePlan="single"
                      loanPlan="lta"
                      name="Single Premium"
                      amount={quotation?.ltaSinglePremiumDefault}
                    />
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="pt-3 pb-5">
              <Accordion defaultActiveKey={["0", "1"]} alwaysOpen>
                <Accordion.Item eventKey="0">
                  <Accordion.Header
                    style={{ background: "#fefefe", color: "#000" }}
                  >
                    Loan Cover Plan
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="">
                      {quotation?.quotationFormData.term_number >= 11 && (
                        <div className="mt-1">
                          <label className="d-md-flex align-items-middle">
                            <ReactSwitch
                              uncheckedIcon={false}
                              checkedIcon={false}
                              offColor="#fff"
                              onColor="#BEC663"
                              offHandleColor="#8E8E8E"
                              onHandleColor="#fff"
                              height={20}
                              width={40}
                              handleDiameter={13}
                              onChange={(val) => {
                                setLoanCoverRefund(val);
                                updateWithRefund({ with_refund: val });
                              }}
                              checked={loanCoverRefund}
                            />
                            <span className="ms-2 ila-primary-text">
                              Add Refund
                            </span>
                          </label>
                          {loanCoverRefund && (
                            <>
                              {quotation?.termNumber < 15 && (
                                <div
                                  style={{ color: "#977428" }}
                                  className="d-block"
                                >
                                  75% refund of premium on maturity
                                </div>
                              )}
                              {quotation?.termNumber >= 15 && (
                                <div
                                  style={{ color: "#977428" }}
                                  className="d-block"
                                >
                                  100% refund of premium on maturity
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="d-flex row p-2 col-12 col-lg-12 justify-content-center">
                      <div className="col">
                        <PaymentPlan
                          refundAmount={quotation?.deathAndTpdWithRefund}
                          withRefund={loanCoverRefund}
                          updatePaymentInsuranceFn={updatePaymentFrequency}
                          state={state}
                          isDtaResult={quotation?.isDtaResult}
                          isLtaResult={quotation?.isLtaResult}
                          quotationInsurancePlan={
                            quotation?.paymentInsurancePlan
                          }
                          quotationLoanPlan={state.loan_plan}
                          setState={setState}
                          paymentInsurancePlan="monthly"
                          loanPlan="dta"
                          name="Monthly Premium"
                          isOnAccordion={true}
                          amount={quotation?.deathAndTpd}
                        />
                      </div>
                      <div className="col">
                        <PaymentPlan
                          refundAmount={
                            quotation?.quarterlyPremiumWithRefundDefault
                          }
                          withRefund={loanCoverRefund}
                          updatePaymentInsuranceFn={updatePaymentFrequency}
                          state={state}
                          setState={setState}
                          isDtaResult={quotation?.isDtaResult}
                          isLtaResult={quotation?.isLtaResult}
                          quotationInsurancePlan={
                            quotation?.paymentInsurancePlan
                          }
                          quotationLoanPlan={state.loan_plan}
                          paymentInsurancePlan="quarterly"
                          loanPlan="dta"
                          name="Quarterly Premium"
                          isOnAccordion={true}
                          amount={quotation?.quarterlyPremiumDefault}
                        />
                      </div>
                      <div className="col">
                        <PaymentPlan
                          refundAmount={
                            quotation?.halfYearlyPremiumWithRefundDefault
                          }
                          withRefund={loanCoverRefund}
                          updatePaymentInsuranceFn={updatePaymentFrequency}
                          state={state}
                          setState={setState}
                          isDtaResult={quotation?.isDtaResult}
                          isLtaResult={quotation?.isLtaResult}
                          quotationInsurancePlan={
                            quotation?.paymentInsurancePlan
                          }
                          quotationLoanPlan={state.loan_plan}
                          paymentInsurancePlan="half-yearly"
                          loanPlan="dta"
                          name="Half Yearly Premium"
                          isOnAccordion={true}
                          amount={quotation?.halfYearlyPremiumDefault}
                        />
                      </div>
                      <div className="col">
                        <PaymentPlan
                          refundAmount={
                            quotation?.yearlyPremiumWithRefundDefault
                          }
                          withRefund={loanCoverRefund}
                          updatePaymentInsuranceFn={updatePaymentFrequency}
                          state={state}
                          setState={setState}
                          isDtaResult={quotation?.isDtaResult}
                          isLtaResult={quotation?.isLtaResult}
                          quotationInsurancePlan={
                            quotation?.paymentInsurancePlan
                          }
                          quotationLoanPlan={state.loan_plan}
                          paymentInsurancePlan="yearly"
                          loanPlan="dta"
                          name="Yearly Premium"
                          isOnAccordion={true}
                          amount={quotation?.yearlyPremiumDefault}
                        />
                      </div>
                      <div className="col">
                        <PaymentPlan
                          refundAmount={
                            quotation?.singlePremiumWithRefundDefault
                          }
                          withRefund={loanCoverRefund}
                          updatePaymentInsuranceFn={updatePaymentFrequency}
                          state={state}
                          setState={setState}
                          isDtaResult={quotation?.isDtaResult}
                          isLtaResult={quotation?.isLtaResult}
                          quotationInsurancePlan={
                            quotation?.paymentInsurancePlan
                          }
                          quotationLoanPlan={state.loan_plan}
                          paymentInsurancePlan="single"
                          loanPlan="dta"
                          name="Single Premium"
                          isOnAccordion={true}
                          amount={quotation?.singlePremiumDefault}
                        />
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                  <Accordion.Header
                    style={{ background: "#fefefe", color: "#000" }}
                  >
                    Protect Plan
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="">
                      {quotation?.termNumber >= 11 && (
                        <div className="mt-1">
                          <label className="d-md-flex align-items-middle">
                            <ReactSwitch
                              uncheckedIcon={false}
                              checkedIcon={false}
                              offColor="#fff"
                              onColor="#BEC663"
                              offHandleColor="#8E8E8E"
                              onHandleColor="#fff"
                              height={20}
                              width={40}
                              handleDiameter={13}
                              onChange={(val) => {
                                setLoanProtectRefund(val);
                                updateWithRefund({ with_refund: val });
                              }}
                              checked={loanProtectRefund}
                            />
                            <span className="ms-2 ila-primary-text">
                              Add Refund
                            </span>
                          </label>
                          {loanProtectRefund && (
                            <>
                              {quotation?.termNumber < 15 && (
                                <div
                                  style={{ color: "#977428" }}
                                  className="d-block"
                                >
                                  75% refund of premium on maturity
                                </div>
                              )}
                              {quotation?.termNumber >= 15 && (
                                <div
                                  style={{ color: "#977428" }}
                                  className="d-block"
                                >
                                  100% refund of premium on maturity
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="d-flex row p-2 col-12 col-lg-12 justify-content-center">
                      <div className="col">
                        <PaymentPlan
                          refundAmount={quotation?.ltaDeathAndTpdWithRefund}
                          withRefund={loanProtectRefund}
                          updatePaymentInsuranceFn={updatePaymentFrequency}
                          state={state}
                          setState={setState}
                          isDtaResult={quotation?.isDtaResult}
                          isLtaResult={quotation?.isLtaResult}
                          quotationInsurancePlan={
                            quotation?.paymentInsurancePlan
                          }
                          quotationLoanPlan={state.loan_plan}
                          paymentInsurancePlan="monthly"
                          loanPlan="lta"
                          name="Monthly Premium"
                          isOnAccordion={true}
                          amount={quotation?.ltaDeathAndTpd}
                        />
                      </div>
                      <div className="col">
                        <PaymentPlan
                          refundAmount={
                            quotation?.ltaQuarterlyPremiumWithRefundDefault
                          }
                          withRefund={loanProtectRefund}
                          updatePaymentInsuranceFn={updatePaymentFrequency}
                          state={state}
                          setState={setState}
                          isDtaResult={quotation?.isDtaResult}
                          isLtaResult={quotation?.isLtaResult}
                          quotationInsurancePlan={
                            quotation?.paymentInsurancePlan
                          }
                          quotationLoanPlan={state.loan_plan}
                          paymentInsurancePlan="quarterly"
                          loanPlan="lta"
                          name="Quarterly Premium"
                          isOnAccordion={true}
                          amount={quotation?.ltaQuarterlyPremiumDefault}
                        />
                      </div>
                      <div className="col">
                        <PaymentPlan
                          refundAmount={
                            quotation?.ltaHalfYearlyPremiumWithRefundDefault
                          }
                          withRefund={loanProtectRefund}
                          updatePaymentInsuranceFn={updatePaymentFrequency}
                          state={state}
                          setState={setState}
                          isDtaResult={quotation?.isDtaResult}
                          isLtaResult={quotation?.isLtaResult}
                          quotationInsurancePlan={
                            quotation?.paymentInsurancePlan
                          }
                          quotationLoanPlan={state.loan_plan}
                          paymentInsurancePlan="half-yearly"
                          loanPlan="lta"
                          name="Half Yearly Premium"
                          isOnAccordion={true}
                          amount={quotation?.ltaHalfYearlyPremiumDefault}
                        />
                      </div>
                      <div className="col">
                        <PaymentPlan
                          refundAmount={
                            quotation?.ltaYearlyPremiumWithRefundDefault
                          }
                          withRefund={loanProtectRefund}
                          updatePaymentInsuranceFn={updatePaymentFrequency}
                          state={state}
                          setState={setState}
                          isDtaResult={quotation?.isDtaResult}
                          isLtaResult={quotation?.isLtaResult}
                          quotationInsurancePlan={
                            quotation?.paymentInsurancePlan
                          }
                          quotationLoanPlan={state.loan_plan}
                          paymentInsurancePlan="yearly"
                          loanPlan="lta"
                          name="Yearly Premium"
                          isOnAccordion={true}
                          amount={quotation?.ltaYearlyPremiumDefault}
                        />
                      </div>
                      <div className="col">
                        <PaymentPlan
                          refundAmount={
                            quotation?.ltaSinglePremiumWithRefundDefault
                          }
                          withRefund={loanProtectRefund}
                          updatePaymentInsuranceFn={updatePaymentFrequency}
                          state={state}
                          setState={setState}
                          isDtaResult={quotation?.isDtaResult}
                          isLtaResult={quotation?.isLtaResult}
                          quotationInsurancePlan={
                            quotation?.paymentInsurancePlan
                          }
                          quotationLoanPlan={state.loan_plan}
                          paymentInsurancePlan="single"
                          loanPlan="lta"
                          name="Single Premium"
                          isOnAccordion={true}
                          amount={quotation?.ltaSinglePremiumDefault}
                        />
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          )}

          {quotations && quotations.length > 0 && (
            <div>
              <h5 className="mt-5">Related Quotations</h5>

              <table className="table mt-3">
                <thead>
                  <tr>
                    <th scope="col">Quotation</th>
                    <th scope="col">Sum Assured</th>
                    <th scope="col">Premium</th>
                    <th scope="col">Payment Frequency</th>
                    <th scope="col">Term</th>
                    <th scope="col">Class of Policy</th>
                    <th scope="col">Type</th>
                    <th scope="col">Created At</th>
                    <th scope="col">Status</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {quotations.map((_quotation, index) => {
                    try {
                      _quotation.quotationFormData = JSON.parse(
                        _quotation?.quotationFormData
                      );
                    } catch (err) {}
                    const premium = getTotalPremium(_quotation);
                    return (
                      <tr key={_quotation?.id}>
                        <td scope="row">
                          Quotation #{quotations.length - index}
                        </td>
                        <td>
                          {currencyFormatter.format(
                            parseFloat(
                              _quotation?.quotationFormData.initial_sum_assured
                            ),
                            { symbol: "Rs " }
                          )}
                        </td>
                        <td>
                          {currencyFormatter.format(parseFloat(premium), {
                            symbol: "Rs ",
                          })}
                        </td>
                        <td>
                          {_quotation?.quotationFormData.payment_insurance_plan}
                        </td>
                        <td>
                          {_quotation?.quotationFormData.term_number +
                            " " +
                            _quotation?.quotationFormData.term_option}
                        </td>
                        <td>
                          {getPolicyClassName(
                            _quotation,
                            _quotation?.quotationFormData?.loan_plan
                          )}
                        </td>
                        <td>
                          {_quotation?.quotationFormData.join_loan
                            ? "Joint"
                            : "Single"}
                        </td>
                        <td>{Moment(_quotation?.createdAt).format("LLL")}</td>
                        <td>
                          {!_quotation?.expired ? (
                            <span className="badge bg-success">Active</span>
                          ) : (
                            <span className="badge bg-danger">Expired</span>
                          )}
                        </td>
                        <td>
                          {!_quotation?.expired && (
                            <div>
                              <div
                                onClick={() => {
                                  // setSwalProps({
                                  //   show: true,
                                  //   showDenyButton: true,
                                  //   denyButtonText: "No",
                                  //   confirmButtonText: "Yes",
                                  //   title: "Please Confirm",
                                  //   text: "Are you sure you want to make this the main quotation?",
                                  // });
                                  setThisShadowQuotation(_quotation?.id);
                                }}
                                className="btn btn-light"
                              >
                                Accept
                              </div>
                            </div>
                          )}
                          <SweetAlert2
                            {...swalProps}
                            onConfirm={(result) => {
                              setSwalProps({});
                              setThisShadowQuotation(quotation?.id);
                            }}
                            onError={(error) => {
                              setSwalProps({});
                              // console.log(error);
                              // run when promise rejected...
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}

      {authorized === undefined && (
        <div>
          <div className="text-center">
            <span className="spinner-border  mt-5"></span>
          </div>
          <div className="text-center">Please wait ...</div>
        </div>
      )}
      {authorized === false && (
        <div className="container">
          {/* <h1 className="text-center">
            You are Unauthorized to modify this quotation
          </h1> */}

          <div
            style={{ marginTop: 50 }}
            className="alert alert-danger"
            role="alert"
          >
            You are Unauthorized to modify this quotation
          </div>
        </div>
      )}
    </>
  );
};

export default QuotationView;
