import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
// Axios
import axios from "../api/axios";
import { getOnboardingData, setOnboardingData } from "../core/utils";
// Components
import CustomNumberInput from "../core/components/custom-number-input/CustomNumberInput";
import { isValidPhoneNumber } from "react-phone-number-input";
import WhatsappNumberInput from "../core/components/whatsapp-number-input/WhatsappNumberInput";
import TogglePassword from "../core/components/toggle-password/TogglePassword";

const Onboarding = () => {
  const navigate = useNavigate();

  const [errors, setErrors] = useState({
    email: null,
    phoneNumber: null,
    provider: null,
    password: null,
    confirmPassword: null,
  });

  const phoneNumberRef = useRef();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const email = searchParams.get("email");
  const firstname = searchParams.get("firstname");
  const lastname = searchParams.get("lastname");
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();

  const [state, setState] = useState({
    email,
    firstname,
    lastname,
    phoneNumber: "+230",
    whatsappNumber: "+230",
    provider: "email",
    password: "",
    confirmPassword: "",
    receiveAds: false,
  });

  useEffect(() => {
    if (email) {
      setState({ ...state, email });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setState({ ...state, whatsappNumber: state.phoneNumber });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.phoneNumber]);
  const submitData = async () => {
    //validate data
    if (!isValidPhoneNumber(state.phoneNumber)) {
      setError("Please enter a valid phone number");
      return;
    }
    const url = process.env.REACT_APP_API_URL + "/user/quotation/sign-up";
    try {
      if (!state.receiveAds) {
        setError("Please agree to terms and conditions");
        return;
      }
      setError(null);

      setLoading(true);

      const payload = { ...state };

      payload.name = `${state.firstname} ${state.lastname}`;

      delete payload.receiveAds;
      const { data } = await axios.post(url, payload);

      setLoading(false);

      setError(null);
      if (data.success) {
        const onBoardingData = getOnboardingData();
        setOnboardingData({
          ...onBoardingData,
          initialDataTaken: true,
          initialOnBoardingDetails: firstname
            ? { ...state, firstname, lastname }
            : state,
        });

        let redirectUrl = `/verification?provider=${state.provider}&source=onboarding`;
        if (state.provider === "email") {
          redirectUrl += `&email=${state.email}`;
        } else {
          redirectUrl += `&phone=${state.phoneNumber}`;
        }
        navigate(redirectUrl);
      } else {
        // // debugger;
        setError(
          data.message
            ? data.message
            : "Onboarding was unsuccessful, please try again"
        );
      }
    } catch (error) {
      //Handing Form Errors
      // // debugger;
      setLoading(false);
      setError(null);
      // // debugger;
      if (
        error?.response?.data?.errors &&
        error?.response?.data?.errors.length > 0
      ) {
        try {
          const errorsFromServer = error?.response?.data?.errors;
          console.error(errorsFromServer);
          let errorData = {};
          if (errorsFromServer.length > 0) {
            errorsFromServer.forEach((err) => {
              if (!errorData[err.field]) {
                errorData[err.field] = [];
              }
              errorData[err.field].push(err.message);
            });
          }
          setErrors(errorData);
        } catch (err) {
          setError(
            error?.response?.data
              ? error?.response?.data?.message
              : "Onboarding was unsuccessful, please try again"
          );
        }
      } else {
        setError(
          error?.response?.data
            ? error?.response?.data?.message
            : "Onboarding was unsuccessful, please try again"
        );
      }
    }
  };

  return (
    <div className="container-scroller my-5">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-md-6 grid-margin stretch-card mx-auto">
              <div className="card">
                <div className="card-body">
                  {error && (
                    <div className="alert alert-danger" role="alert">
                      {error}
                    </div>
                  )}
                  <h4 className="card-title">Welcome to ILA</h4>
                  <p className="card-description">
                    Please provide some details to start generating quotations,
                    Thank You
                  </p>
                  <form className="forms-sample">
                    <div className="form-group row mb-3">
                      <label
                        htmlFor="with_refund"
                        className="col-sm-4  col-form-label"
                      >
                        How do you want to validate your account?
                      </label>
                      <div className="col-sm-8 mt-2">
                        <div
                          className="form-check form-check-inline"
                          onClick={() =>
                            setState({ ...state, provider: "email" })
                          }
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="providerRadio"
                            id="providerRadioEmail"
                            checked={state.provider === "email"}
                          />
                          <label
                            className="form-check-label"
                            for="providerRadioEmail"
                          >
                            Email
                          </label>
                        </div>
                        <div
                          className="form-check form-check-inline"
                          onClick={() =>
                            setState({ ...state, provider: "phone" })
                          }
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="providerRadio"
                            id="providerRadioPhone"
                            checked={state.provider === "phone"}
                          />
                          <label
                            className="form-check-label"
                            for="providerRadioPhone"
                          >
                            Phone
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="form-group row mb-3">
                      <label
                        htmlFor="firstname"
                        className="col-sm-4 col-form-label"
                      >
                        First Names
                      </label>
                      <div className="col-sm-8">
                        <input
                          onChange={(event) => {
                            setState({
                              ...state,
                              firstname: event.target.value,
                            });
                          }}
                          value={state.firstname}
                          type="text"
                          className={`form-control ${
                            errors.name && "is-invalid"
                          }`}
                          id="firstname"
                          placeholder="First Names"
                        />
                        {errors.firstname && (
                          <div className="invalid-feedback d-inline-block">
                            {errors.name.map((message) => {
                              return <div>{message}</div>;
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group row mb-3">
                      <label
                        htmlFor="lastname"
                        className="col-sm-4 col-form-label"
                      >
                        Last Name
                      </label>
                      <div className="col-sm-8">
                        <input
                          onChange={(event) => {
                            setState({
                              ...state,
                              lastname: event.target.value,
                            });
                          }}
                          value={state.lastname}
                          type="text"
                          className={`form-control ${
                            errors.lastname && "is-invalid"
                          }`}
                          id="lastname"
                          placeholder="Last Name"
                        />
                        {errors.lastname && (
                          <div className="invalid-feedback d-inline-block">
                            {errors.lastname.map((message) => {
                              return <div>{message}</div>;
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group row mb-3">
                      <label
                        htmlFor="email"
                        className="col-sm-4 col-form-label"
                      >
                        Email
                      </label>
                      <div className="col-sm-8">
                        <input
                          onChange={(event) => {
                            setState({ ...state, email: event.target.value });
                          }}
                          value={state.email}
                          type="email"
                          className={`form-control ${
                            errors.email && "is-invalid"
                          }`}
                          id="email"
                          placeholder="Email"
                        />
                        {errors.email && (
                          <div className="invalid-feedback d-inline-block">
                            {errors.email.map((message) => {
                              return <div>{message}</div>;
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group row mb-3">
                      <label
                        htmlFor="phoneNumber"
                        className="col-sm-4 col-form-label"
                      >
                        Mobile
                      </label>
                      <div className="col-sm-8">
                        <CustomNumberInput
                          ref={phoneNumberRef}
                          errors={errors}
                          setState={setState}
                          state={state}
                        />
                        {errors.phoneNumber && (
                          <div className="invalid-feedback d-inline-block">
                            {errors.phoneNumber.map((message) => {
                              return <div>{message}</div>;
                            })}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group row mb-3">
                      <label
                        htmlFor="phoneNumber"
                        className="col-sm-4 col-form-label"
                      >
                        Whatsapp
                      </label>
                      <div className="col-sm-8">
                        <WhatsappNumberInput
                          errors={errors}
                          setState={setState}
                          state={state}
                        />
                        {errors.whatsappNumber && (
                          <div className="invalid-feedback d-inline-block">
                            {errors.whatsappNumber.map((message) => {
                              return <div>{message}</div>;
                            })}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group row mb-3">
                      <label
                        htmlFor="password"
                        className="col-sm-4 col-form-label"
                      >
                        Password
                      </label>
                      <div className="col-sm-8">
                        <div className="position-relative">
                          <input
                            ref={passwordRef}
                            onChange={(event) => {
                              setState({
                                ...state,
                                password: event.target.value,
                              });
                            }}
                            value={state.password}
                            type="password"
                            className={`form-control ${
                              errors.password && "is-invalid"
                            }`}
                            id="password"
                            placeholder="Password"
                          />
                          <TogglePassword inputRef={passwordRef} />
                        </div>

                        {errors.password && (
                          <>
                            <div className="invalid-feedback d-inline-block">
                              {errors.password.map((message) => {
                                return <div>{message}</div>;
                              })}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="form-group row mb-3">
                      <label
                        htmlFor="confirmPassword"
                        className="col-sm-4 col-form-label"
                      >
                        Confirm Password
                      </label>
                      <div className="col-sm-8">
                        <div className="position-relative">
                          <input
                            ref={confirmPasswordRef}
                            onChange={(event) => {
                              setState({
                                ...state,
                                confirmPassword: event.target.value,
                              });
                            }}
                            value={state.confirmPassword}
                            type="password"
                            className={`form-control ${
                              errors.confirmPassword && "is-invalid"
                            }`}
                            id="confirmPassword"
                            placeholder="Confirm Password"
                          />
                          <TogglePassword inputRef={confirmPasswordRef} />
                        </div>
                        {errors.confirmPassword && (
                          <div className="invalid-feedback d-inline-block">
                            {errors.confirmPassword.map((message) => {
                              return <div>{message}</div>;
                            })}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-check form-check-flat form-check-primary mb-3 mt-3">
                      <label className="form-check-label">
                        {/* <input type="checkbox" onClick={(event)=>{setState({...state,receiveAds:!state.receiveAds})}} checked={state.receiveAds} className="form-check-input"/> */}
                        <input
                          onClick={(event) => {
                            setState({
                              ...state,
                              receiveAds: !state.receiveAds,
                            });
                          }}
                          checked={state.receiveAds}
                          type="checkbox"
                          className="form-check-input"
                        />
                        By checking this box, I state that I have read and
                        understood the{" "}
                        <a
                          href="https://ila.mu/terms-of-use/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          terms and conditions
                        </a>
                        .
                      </label>
                    </div>

                    <div className="d-flex justify-content-between">
                      <a
                        href={`${process.env.REACT_APP_BASENAME}/login`}
                        onClick={() => {
                          submitData();
                        }}
                        type="button"
                        className="btn btn-outline-secondary me-2 mb-3"
                        disabled={loading}
                      >
                        {loading && (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}{" "}
                        Back to Login
                      </a>
                      <button
                        onClick={() => {
                          submitData();
                        }}
                        type="button"
                        className="btn btn-ila me-2 mb-3"
                        disabled={loading}
                      >
                        {loading && (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}{" "}
                        Proceed
                      </button>
                    </div>

                    {/* <button className="btn btn-light mb-3">Cancel</button> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
